.popup-card {
	display: inline-block;
	position: absolute;
	.content {
    margin: 0 auto;
    position: relative;
    min-width: 150px;
    min-height: 100px;
    padding: 0.5rem;
    text-align: center;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px 2px;
    border-radius: 10px;
  }

  .content::before {
    content: "";
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: -10px;
    left: 50%;
    transform: tranlationX(-50%);
    border-width: 10px;
    border-style: solid;
  }

  .triangle-down {
    width: 25px;
    height: 25px;
    position: relative;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    z-index: -1;
    margin: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
  }
}
