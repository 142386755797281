.get-direction-header {
  /** small screen mobile */
  @include breakpoint($ip-5) {
    .header {
      height: 90px;
      img {
        height: 100%;
        width: auto;
      }
    }
  }

  @include breakpoint($ip-6-x) {
    .header {
      height: 150px;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
}