.group-button {
  display: flex;
  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.left {
    justify-content: flex-start;
  }
  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }
  > button {
    border: 1px solid #000;
    @include transition($transition-duration $transition-timing);
  }
  > button:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }
  > button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}