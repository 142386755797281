$shop-directional-card-box-image-size: ('small': 30px, 'normal': 50px, 'medium': 60px, 'large': 90px);

.shop-directional-card {
  width: 100%;
  height: 80px;
  border-radius: 3px;
  padding: $padding-layout;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: row;

  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    box-shadow: $box-shadow;
    flex: 0 0 50px;
    height: 50px;
    overflow: hidden;
    margin-right: $padding-layout;
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
  }

  .title{
    font-size: $size-5;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .sub-title {
    opacity: 0.6;
    font-size: $size-7;
  }

  &-floor {
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    h2 {
      font-weight: 500;
    }
  }
}