.shop-banner {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  width: 100%;

  &-name-container {
    position: absolute;
    top: 0;
    left: 0;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    font-weight: 500;
    font-size: $size-4;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
  }

  // start pin add more
  &-name-container-shopdetail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  // end pin add more

  > div {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%; // pin add more
    // height: -webkit-fill-available; // pin comment
    object-fit: contain;
    background-color: transparent;
  }
}