.search-origin-page {
  @include page-basis;

  .auto-complete-container {
    padding: $padding-layout;
    padding-right: 0;
  }

  .list-item-title {
    text-transform: uppercase;
    letter-spacing: $title-letter-spacing;
    font-size: $size-5;
  }

  .list-item-description {
    font-size: $size-6;
  }

  .separator {
    margin: $padding-layout 0;
    height: 1px;
  }

  .recent-search {
    &-title-container {
      padding-bottom: $padding-layout;
    }

    &-title {
      font-size: $size-7;
    }
  }
}