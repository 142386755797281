.is-borderless {
  border-width: 0 !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-shadowless {
  filter: drop-shadow(0 0 0) !important;
  text-shadow: 0 0;
  box-shadow: 0 0;
}

.has-content-centered {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.has-full-height {
  height: 100%;
}

.is-full-height {
  height: 100%;
}

.is-full-width {
  width: 100%;
}

.has-padding-layout {
  padding: 0 16px;
}

.is-clickable {
  cursor: pointer;
}

.is-fullwidth {
  width: 100%;
}

.is-text-right {
  text-align: right;
}

.is-text-center {
  text-align: center;
}

.is-text-left {
  text-align: left;
}

