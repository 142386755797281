.has-text-centered {
  text-align: center;
}

.has-text-left {
  text-align: left;
}

.has-text-right {
  text-align: right;
}

.has-text-justified {
  text-align: justify;
}

.has-vertical-content-centered {
  display: flex;
  justify-content: center;
}

@include breakpoint-desktop-first ($sm-screen) {
  .has-mobile-text-centered {
    text-align: center;
  }

  .has-mobile-text-left {
    text-align: left;
  }

  .has-mobile-text-right {
    text-align: right;
  }

  .has-mobile-text-justified {
    text-align: justify;
  }
}

/* text weight */

$font-weights: (
  ultralight: 100,
  light: 200,
  thin: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  heavy: 800,
  black: 900,
);

@each $name, $weight in $font-weights {
  .is-weight-#{$name} {
    font-weight: $weight;
  };
}

/* text transform */
$text-transforms: (
  none: none,
  uppercase: uppercase,
  capitalise: capitalize,
  lowercase: lowercase,
  inherit: inherit,
);

@each $name, $style in $text-transforms {
  .is-text-#{$name} {
    text-transform: $style;
  }
}

/* align text */
$text-alignment: left, right, center, justify, initial, inherit;
@each $alignment in $text-alignment {
  .has-text-#{$alignment} {
    text-align: $alignment;
  }
}
