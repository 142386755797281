.hero {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-repeat: no-repeat;

  &.is-medium {
    .hero-body {
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
  }

  &.is-large {
    .hero-body {
      padding-top: 18rem;
      padding-bottom: 18rem;
    }
  }

  &.is-full {
    min-height: 100vh;

    .hero-body {
      display: flex;
      align-items: center;
    }
  }

  &-header {

  }

  &-body {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 3rem 1.5rem;
  }

  &-footer {

  }

}