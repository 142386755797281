.language-switcher {
  display: flex;
  justify-content: center;
  :not(:last-child) {
    margin-right: 10px;
  }
  .flag-container {
    filter: grayscale(1);
    &.is-active {
      filter: none;
    }
  }
}