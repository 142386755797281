$modal-dimension: 100%;
$modal-border-radius: 14px;
$buttons-height: 49px;

$positions: center, right, left;

.app-modal {
  position: fixed;
  top: 0;
  width: $modal-dimension;
  height: $modal-dimension;
  z-index: $modal-z-index;
  transition: visibility $transition-duration $transition-timing;

  &.is-open {
    visibility: visible;
    display: block;
    z-index: 1000000;

    .app-modal-back-drop {
      opacity: 1;
    }

    .app-modal-content-container {
      &.is-position-center {
        transform: translate(-50%, -50%) scale(1);
      }

      &.is-position-right, &.is-position-left {
        transform: translateX(0);
      }
    }
  }

  &.is-close {
    visibility: hidden;

    .app-modal-back-drop {
      opacity: 0;
    }

    .app-modal-content-container {
      &.is-position-center {
        transform: translate(-50%, -50%) scale(0);
      }

      &.is-position-right {
        transform: translateX(100%);
      }

      &.is-position-left {
        transform: translateX(-100%);
      }
    }
  }

  &-back-drop {
    width: 100%;
    height: 100%;
    transition: opacity $transition-duration $transition-timing;
  }

  &-content-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: absolute;
    width: calc(100% - calc(#{$padding-layout * 4}));
    min-height: 300px;
    overflow: hidden;
    transition: transform $transition-duration cubic-bezier(0.45, 0.25, 0.60, 0.95);

    /** position */
    &.is-position-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      border-radius: $modal-border-radius;
    }

    &.is-position-left, &.is-position-right {
      height: 100%;
      width: 80%;
    }

    &.is-position-right {
      top: 0;
      right: 0;
    }

    &.is-position-left {
      top: 0;
      left: 0;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $padding-layout;
    overflow: auto;

    &.is-centered {
      justify-content: center;
      align-items: center;

      .app-modal-title, .app-modal-body {
        text-align: center;
      }

      .app-modal-title {
        font-size: $size-4;
      }

      .app-modal-body {
        font-size: $size-6;
      }
    }
  }

  &-buttons {
    display: inline-flex;
    justify-self: flex-end;
    width: 100%;
    height: $buttons-height;

    &-item {
      flex: 1;
      border: 0;
      border-top: $border-width $border-style;
      font-size: $size-6;
      font-weight: 500;
      text-align: center;

      &.is-cancel-style {
        font-weight: 400;
      }
    }
  }

  &-header, &-logo-container, &-body {
    padding: $padding-layout;
  }

  &-header {
    border-bottom-width: $border-width;
    border-bottom-style: $border-style;
  }

  &-logo-container {
    margin: 0 auto;
  }

  &-logo {
    width: 100px;
    height: 100px;
  }

  &-body {}

  &-footer {
    display: flex;
    align-items: stretch;
    border-top-style: $border-style;
    border-top-width: $border-width;

    &-item {
      display: flex;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center;
      padding: .75rem;
      transition: opacity $transition-duration $transition-timing;
    }
  }

  /* desktop */
  @include breakpoint ($sm-screen) {
    &-content-container {
      &.is-position-center {
        width: auto;
      }

      &.is-position-right, &.is-position-left {
        width: 300px;
      }
    }
  }
}
