$item-per-row: 3;
$gutter-width: 8px;

.gallery-container {
  overflow: hidden;

  .gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin: -$gutter-width;

    .gallery-item {
      flex: 0 0 calc(100% / #{$item-per-row});
      max-width: calc(100% / #{$item-per-row});
      padding: calc(#{$gutter-width} / 2);
      cursor: pointer;

      &:hover {
        opacity: .9;
      }

      & > img {
        width: 100%;
        height: auto;
      }
    }
  }
}