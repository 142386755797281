$section-margin: 1.25rem 0;

.section {
  width: 100%;

  &.has-horizontal-padding {
    padding: 0 $padding-layout;
  }

  &.has-vertical-margin {
    margin: $section-margin;
  }
}