.see-more-page {

  .shop-card {
    &-title {
      font-size: $size-4;
    }
  
    &-sub-title {
      font-size: $size-5;
    }
  }

  .page-title {
    font-size: $size-1;
  }

  .page-sub-title {
    font-size: $size-4;
  }
}