$form-field-margin-bottom: .75rem;

.form {
  &-field {
    margin-bottom: $form-field-margin-bottom;
  }

  &-control {
    position: relative;
    box-sizing: border-box;
    text-align: left;
  }
}