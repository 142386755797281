.app-card {
  box-shadow: $box-shadow;
  border-radius: $card-border-radius;
  overflow: hidden;
  height: 100%;
  @include transition($transition-duration $transition-timing);

  &:hover {
    .card-footer {
      &-item {
        &.is-primary {
          opacity: 1;
        }
      }
    }
  }

  &.is-clickable {
    cursor: pointer;
    &:hover {
      box-shadow: $hover-box-shadow;
    }
  }

  &-header {}

  &-image {
    position: relative;
    padding-bottom: 66.6666%; /* 3:2 Aspect Ratio */
    width: 100%;
    border-bottom-width: 0.5px;
    border-bottom-style: $border-style;

    & > img {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &-body {
    padding: $padding-layout;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: space-between;

    &-item {
      display: flex;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center;
      padding: .75rem;
      transition: opacity $transition-duration $transition-timing;
    }
  }
}