$category-bar-height: 40px;

.category-bar {
  position: relative;
  margin: $padding-layout 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: $send-to-back-z-index;
    height: 65%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-body {
    display: flex;
    align-items: center;
    height: $category-bar-height;
    margin: 5px;
    &:has(.category-bar-subtitle) {

    }
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    padding: 0 $padding-layout;
  }

  &-title {
    width: 100%;
    text-align: center;
  }

  &-subtitle {
    text-align: center;
  }

  /* tablet and mobile */
  @include breakpoint-desktop-first ($sm-screen) {
    &-icon, &-body.with-subtitle {
      display: none;
    }
  }
}