$button-border-radius: $border-radius;
$button-height: 2.25em;

$button-icon-margin: calc(#{$padding-layout} / 2);

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: $button-border-radius;
  border-style: $border-style;
  border-width: $border-width;
  outline: $outline;
  font-size: $size-6; /** normal size */
  padding: 0 $padding-layout;
  height: $button-height;

  &.is-large {
    font-size: $size-4;
  }

  &.is-medium {
    font-size: $size-5;
  }

  &.is-small {
    font-size: $size-7;
  }

  &.is-full-width {
    width: 100%;
  }

  &.is-rounded {
    border-radius: calc(#{$button-height} / 2);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: $disabled-opacity;
    pointer-events: none;
  }

  .icon-left {
    margin-right: $button-icon-margin;
  }

  .icon-right {
    margin-left: $button-icon-margin;
  }
}