/*
Theme Name: ROYAL - 8theme WordPress theme
Theme URI: https://8theme.com/
Author: 8theme
Author URI: https://themeforest.net/user/8theme
Description: ThemeForest Premium Theme
Text Domain: royal
Version: 2.8
License: GNU General Public License version 3.0
License URI: https://www.gnu.org/licenses/gpl-3.0.html
*/
/*------------------------------------------------------------------
[Table of contents]

1. Body / #body
	1.1 Headings & Titles / #headings
	1.2 Input & Textarea / #input
	1.3 Buttons / #buttons
	1.4 Helper Classes / #helpers
	1.5 Boxed Styles / #boxed
2. Header / #header
	2.1. Menu / #menu
	2.2 Mega Menu / #mega-menu
	2.3 Header Search / #search
	2.4 Fixed Header / #fixed-header
	2.5 Header Type / #header-type
	2.6 Vertical Header / #vertical-header
	2.7 Shopping Cart Widget / #cart-widget
3. Content / #content
	3.1 Revolution Slider / #slider
	3.2 Banners / #banners
	3.3 Products Carousel / #carousel
	3.4 Products Grid / #products-grid
	3.5 Products List / #products-list
	3.6 Products Sidebar / #products-sidebar
	3.7 Sidebar Widgets / #widgets
4. Pages / #pages
	4.1 Breadcrumbs Types
	4.2 Default Post / #post-default
	4.3 Comments List / #comments-list
	4.4 Single Product page / #product-page
	4.5 Categories Carousel / #cat-carousel
	4.6 Project Page / #project
	4.7 Shopping Cart Page / #shopping-cart
	4.8 Blog Types / #blog-types
	4.9 Login & Register / #login-reg
	4.9 Checkout Page / #checkout
5. Shortcodes / #shortcodes
6. Footer / #footer
	5.1 Color scheme for footer / #footer-color
7. Woocommerce styles / #woocommerce
8. Responsive / #responsive
9. CSS of Third-Party Plugins / #plugins-css
10. Styles for Demo Variants / #demo-styles
11. IE fix / #ie
-------------------------------------------------------------------*/
/*========================================*/
/* Variables */
/*========================================*/
/* ## Gray and brand colors for use across Bootstrap. */
/* * Background color for `<body>`. */
/* * Global text color on `<body>`. */

#customise {
.woocommerce-message,
.alert-success,
.success,
.woocommerce-error,
.alert-error,
.error,
.woocommerce-info,
.alert-info,
.info,
.woocommerce-warning,
.alert-warning,
.warning {
  border: 1px solid transparent;
  font-size: 13px;
  color: #767676;
  padding: 15px;
  float: left;
  width: 100%;
  position: relative;
  padding-left: 60px;
  line-height: 24px;
  margin-bottom: 20px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.woocommerce-message:before,
.alert-success:before,
.success:before,
.woocommerce-error:before,
.alert-error:before,
.error:before,
.woocommerce-info:before,
.alert-info:before,
.info:before,
.woocommerce-warning:before,
.alert-warning:before,
.warning:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 25px;
  margin-top: -11px;
  line-height: 1;
  font-size: 24px;
  font-family: FontAwesome;
}
.woocommerce-message .button,
.alert-success .button,
.success .button,
.woocommerce-error .button,
.alert-error .button,
.error .button,
.woocommerce-info .button,
.alert-info .button,
.info .button,
.woocommerce-warning .button,
.alert-warning .button,
.warning .button {
  float: right;
}
.woocommerce-message,
.alert-success,
.success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.woocommerce-message:before,
.alert-success:before,
.success:before {
  content: "\f00c";
}
.woocommerce-error,
.alert-error,
.error {
  background-color: #f2dede;
  border-color: #ebccd1;
}
.woocommerce-error:before,
.alert-error:before,
.error:before {
  content: "\f00d";
}
.woocommerce-info,
.alert-info,
.info,
.info {
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.woocommerce-info:before,
.alert-info:before,
.info:before,
.info:before {
  content: "\f129";
}
.woocommerce-warning,
.alert-warning,
.warning,
.warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.woocommerce-warning:before,
.alert-warning:before,
.warning:before,
.warning:before {
  content: "\f12a";
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 20px;
}
table th,
table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: middle;
  border-top: 1px solid #efefef;
}
table th {
  font-weight: bold;
}
table thead th {
  vertical-align: bottom;
}
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 0;
}
table tbody + tbody {
  border-top: 1px solid #dddddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}
/*.make-grid-columns() {
  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) when (@index = 1) { // initial
    @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
    @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when (@index > @grid-columns) { // terminal
    @{list} {
      position: relative;
      // Prevent columns from collapsing when empty
      min-height: 1px;
      // Inner gutter via padding
      padding-left:  (@grid-gutter-width / 2);
      padding-right: (@grid-gutter-width / 2);
    }
  }
  .col(1); // kickstart it
}
/*========================================*/
/*========================================*/
* {
  padding: 0;
  margin: 0;
}
body {
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  height: auto !important;
  -ms-overflow-x: hidden !important;
  overflow-x: hidden !important;
}
a {
  text-decoration: none;
  -webkit-transition: color .25s ease;
  -moz-transition: color .25s ease;
  -o-transition: color .25s ease;
  -ms-transition: color .25s ease;
  transition: color .25s ease;
  color: #000;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
  color: #cda85c;
  outline: none !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
a:focus {
  text-decoration: underline;
  outline: none;
  color: #cda85c;
}
a.active {
  color: #cda85c;
}
a.active:hover {
  color: #252525;
}
p {
  margin-bottom: 20px;
  color: #767676;
  line-height: 1.8;
}
p.active,
em.active,
li.active,
strong.active,
span.active {
  color: #cda85c;
}
li {
  list-style: none;
}
.content li {
  list-style: inherit;
}
.content ul,
.content ol {
  padding-left: 20px;
}
.lis-none li {
  list-style: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', Georgia, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  line-height: 1.3;
}
h1.active,
h2.active,
h3.active,
h4.active,
h5.active,
h6.active {
  color: #cda85c;
}
h1.active a,
h2.active a,
h3.active a,
h4.active a,
h5.active a,
h6.active a {
  color: #cda85c;
}
h1.active a:hover,
h2.active a:hover,
h3.active a:hover,
h4.active a:hover,
h5.active a:hover,
h6.active a:hover {
  color: #767676;
}
h3.underlined {
  font: 15px/16px 'Roboto', Georgia, Helvetica, sans-serif;
  color: #000;
  margin: 0 0 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  text-decoration: none;
}
h5.font-alt,
h6.font-alt {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 2px;
}
.brand-title {
  font: 72px/70px 'Bodoni_Regular', Georgia, Helvetica, sans-serif;
  color: #fff;
  margin: 40px 0 50px;
  text-transform: uppercase;
  position: relative;
}
.brand-title:after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 50%;
  bottom: -37px;
  margin-left: -25px;
}
.brand-title:before {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: -25px;
  margin-left: -25px;
}
.filter-title {
  font: 14px/15px 'Roboto', Georgia, Helvetica, sans-serif;
  font-weight: 500;
  color: #252525;
  margin: 0 0 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #ddd;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 28px;
}
.title {
  font-size: 22px;
  margin: 0 0 15px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #ddd;
  text-align: center;
  padding: 20px 0;
}
.show_if_seller label.error {
  padding: 0;
  border: none;
}
.show_if_seller label.error:before {
  content: none;
}
.title-alt {
  font: 18px/20px 'Roboto', sans-serif;
  color: #000;
  margin: 30px 0 5px;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  position: relative;
  width: 100%;
}
.title-alt span {
  padding: 0 6px 0 0;
  background: #fff;
  position: relative;
  z-index: 2;
}
.title-alt:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
}
h1.largest {
  font-size: 72px;
}
input[type="checkbox"] {
  float: left;
  display: block !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #d5d5d5;
  width: 12px !important;
  height: 12px !important;
  border-radius: 0 !important;
  padding: 0 !important;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}
input[type="checkbox"]:focus {
  border-color: #d5d5d5 !important;
  outline: none;
}
input[type="checkbox"]:checked {
  background-color: transparent;
}
input[type="checkbox"]:checked:before {
  content: "";
  position: absolute;
  width: 13px;
  height: 11px;
  background-image: url(https://terminal21.co.th/images/check-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  left: 1px;
  top: -2px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  height: 38px;
  padding: 8px 10px;
  background: #fff;
  overflow: hidden;
  line-height: 22px;
  font-size: 13px;
  -webkit-border-radius: 0;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  border: 1px solid #D5D5D5;
  background-image: url(https://terminal21.co.th/images/select-arrow.jpg);
  background-position: 96% 50%;
  background-repeat: no-repeat;
}
select:focus {
  outline: none;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"] {
  display: block;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px;
  border: 1px solid #d5d5d5;
  height: 39px;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #252525;
}
.btn:active,
.btn.active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn[disabled] {
  opacity: 1;
}
textarea {
  padding: 9px 6px;
}
textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #252525;
}
label {
  font-weight: 400;
  font-size: 14px;
}
.tp-caption a.btn {
  color: #000 !important;
}
.payment_methods input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none !important;
  background-color: #FFF;
  background-image: url(https://terminal21.co.th/images/radio-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  margin-top: 0;
  margin-right: 0;
  padding: 0;
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
}
.payment_methods input[type="radio"]:checked {
  background-image: url(https://terminal21.co.th/images/radio-icon-check.png);
  background-repeat: no-repeat;
  background-position: center center;
}
button:focus,
input {
  outline: none !important;
}
del {
  font-size: smaller;
}
ins {
  text-decoration: none;
}
.tooltip.top {
  margin-top: -5px;
}
.tooltip-inner {
  background-color: #333;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #333;
}
small {
  font-size: 75%;
  color: #767676;
  font-weight: 400 !important;
}
.btn,
.button,
.wishlist_table .add_to_cart.button,
.wpcf7-submit {
  border-color: #CBCBCB;
  color: #000;
  text-transform: uppercase;
  border-radius: 0;
  font: 11px/15px 'Roboto', Arial, Helvetica, sans-serif;
  padding: 6px 18px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn:hover,
.button:hover,
.wishlist_table .add_to_cart.button:hover,
.wpcf7-submit:hover {
  color: #000;
  border: 1px solid #000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn:focus,
.button:focus,
.wishlist_table .add_to_cart.button:focus,
.wpcf7-submit:focus {
  outline: none !important;
}
.btn.gray {
  color: #fff;
  border: none;
  background: #BCBCBC;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 13px;
  padding: 10px 6px;
  -webkit-transition: background 0.25s ease-in;
  -ms-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}
.btn.gray:hover,
.btn.gray:focus {
  background: #a3a3a3;
  color: #fff;
}
button,
.button,
input[type=button],
input[type=submit],
.wishlist_table .add_to_cart.button,
.btn-black,
.btn.btn-black,
.subscription-toggle {
  color: #fff;
  padding: 6px 18px;
  display: inline-block;
  -webkit-border-radius: 0;
  border: none;
  background-color: #262626;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: background 0.25s ease-in;
  -ms-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}
button:hover,
.button:hover,
input[type=button]:hover,
input[type=submit]:hover,
.wishlist_table .add_to_cart.button:hover,
.btn-black:hover,
.btn.btn-black:hover,
.subscription-toggle:hover,
button:focus,
.button:focus,
input[type=button]:focus,
input[type=submit]:focus,
.wishlist_table .add_to_cart.button:focus,
.btn-black:focus,
.btn.btn-black:focus,
.subscription-toggle:focus {
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn-active {
  background-color: #cda85c;
  color: #FFF;
  border-color: #cda85c;
}
.btn-active:hover {
  text-decoration: underline;
}
.btn.small,
.button.small {
  font-size: 11px;
  padding: 6px 8px !important;
  letter-spacing: 1px;
}
.btn.small i,
.button.small i {
  margin-right: 5px;
}
.btn.medium,
.button.medium,
button.btn.medium-btn {
  padding: 9px 28px !important;
  line-height: 15px !important;
  font-size: 13px !important;
}
.btn.medium i,
.button.medium i,
button.btn.medium-btn i {
  margin-right: 8px;
  font-size: 16px;
}
.btn.big,
.button.big,
.subscription-toggle,
.bag-total-table .large-btn {
  line-height: 40px !important;
  height: 40px;
  padding: 0 28px !important;
  font-size: 13px !important;
  letter-spacing: 1px;
}
.btn.big i,
.button.big i,
.subscription-toggle i,
.bag-total-table .large-btn i {
  margin-right: 12px;
  font-size: 20px;
  position: relative;
  top: 2px;
}
.btn.filled {
  color: #fff;
  padding: 6px 18px;
  display: inline-block;
  -webkit-border-radius: 0;
  border-color: #262626;
  border: none;
  background-color: #262626;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: background 0.25s ease-in;
  -ms-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}
.btn.filled:hover,
.btn.filled:focus {
  background-color: #000;
  color: #fff !important;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn.filled.big {
  line-height: 38px;
}
.btn.filled.medium {
  padding: 9px 28px;
}
.border-btn {
  border-color: #000;
  border-radius: 0;
  width: 42px;
  height: 40px;
  padding: 0;
  text-align: center;
  line-height: 40px;
}
.border-btn:focus {
  outline: none !important;
}
.border-btn:hover {
  background: #000;
}
.border-white {
  border: 2px solid #fff;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0;
  line-height: 15px;
  font-size: 13px;
  padding: 7px 30px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.border-white:focus,
.border-white:hover {
  color: #000;
  outline: none !important;
  background-color: #FFF;
  border: 2px solid #fff;
}
.btn-white {
  border: 2px solid #fff;
  color: #FFF !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn-white:hover {
  border: 2px solid #fff;
  background-color: #FFF !important;
  color: #000 !important;
}
.tp-caption a.btn-white {
  color: #FFF !important;
}
.tp-caption a.btn-white:hover {
  color: #000 !important;
}
.btn.filled.active {
  color: #fff;
  background: #cda85c;
  -webkit-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}
.btn.filled.active:hover,
.btn.filled.active:focus {
  opacity: 0.9;
  color: #fff;
}
.btn.filled.active.medium {
  border: 2px solid #cda85c;
}
.btn-black2 {
  color: #fff;
  border: none;
  background: #252525;
  font: 23px/25px 'Bodoni_Italic', Arial, Helvetica, sans-serif;
  border-radius: 0;
  padding: 5px 12px;
  -webkit-transition: background 0.25s ease-in;
  -ms-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}
.btn-black2:hover,
.btn-black2:focus {
  background: #3f3f3f;
  color: #fff;
}
.btn.bordered {
  border: 2px solid #d9d7ce;
  color: #494949;
}
.btn.bordered:hover {
  border-color: #cda85c;
  color: #cda85c;
}
button {
  -webkit-font-smoothing: antialiased;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.margin0,
.wpb_content_element.margin0 {
  margin: 0 !important;
}
.wpb_content_element.mb0,
.wpb_row.mb0 {
  margin-bottom: 0 !important;
}
.wpb_column > .wpb_wrapper > *:last-child {
  margin-bottom: 0;
}
.wpb_row.pb0 {
  padding-bottom: 0 !important;
}
.wpb_raw_html {
  margin-bottom: 0 !important;
}
.clear {
  clear: both;
}
.edit-link {
  display: none;
}
.wpb_raw_code.wpb_content_element {
  margin-bottom: 0 !important;
}
.underlined {
  text-decoration: underline;
}
.wpb_row.minimalistic-subscribe {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.responsive-center {
  text-align: center !important;
}
.responsive-center .banner .banner-content h1 {
  margin-left: 0 !important;
  text-align: center !important;
}
.responsive-center .banner .banner-content hr {
  left: 0 !important;
  float: none !important;
}
.responsive-center .banner {
  text-align: center !important;
}
.responsive-center .banner a {
  margin-left: 0 !important;
}
.landing-copyright.responsive-center {
  text-align: left !important;
}
.admin-bar .fixed-header-area.fixed-already {
  top: 28px;
}
.mfp-container {
  padding: 0 30px !important;
}
hr,
.hr.divider {
  height: 1px;
  display: inline-block;
  background: 0;
  border-top: 0;
  border-bottom: 1px solid #dddddd;
  border-left: 0;
  border-right: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}
.divider.white {
  background-color: #FFF;
}
.divider.dark {
  background-color: #999;
}
hr.space {
  border: none;
  margin: 0;
}
hr.double {
  border-top: 1px solid #dddddd;
  height: 10px;
}
hr.double.dashed {
  border-top: 1px dashed #dddddd;
}
hr.double.dotted {
  border-top: 1px dotted #dddddd;
}
hr.dashed {
  border-bottom: 1px dashed #dddddd;
}
hr.dotted {
  border-bottom: 1px dotted #dddddd;
}
hr.horizontal-break,
hr.break {
  width: 50px;
  margin: 20px auto;
  display: block;
  height: 3px;
  background: #e6e6e6;
  border: none;
}
hr.active {
  background-color: #cda85c;
}
.wp-caption,
.wp-caption-text,
.gallery-caption,
.bypostauthor,
.aligncenter {
  opacity: 1;
}
img.aligncenter {
  margin: 5px auto;
}
img.alignright {
  float: right;
  margin: 2px 0 20px 20px;
}
img.alignleft {
  float: left;
  margin: 2px 20px 20px 0px;
}
.banner-content hr.horizontal-break {
  margin: 10px auto !important;
  width: 45px;
}
.horizontal-break-alt {
  display: block;
  margin: 15px auto;
  width: 100%;
  border: none;
  position: relative;
}
.horizontal-break-alt:after {
  content: "";
  display: block;
  width: 64px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -32px;
  background: #cda85c;
}
iframe,
.vimeo-video iframe,
.youtube-video iframe {
  max-width: 100% !important;
}
iframe {
  border: none;
}
.blog-masonry article iframe {
  max-width: 100% !important;
  height: auto !important;
}
.fl-l {
  float: left !important;
}
.fl-r {
  float: right !important;
}
.color-black {
  color: #000;
}
.color-white {
  color: #fff;
}
.color-white p,
.color-white.et-twitter-slider a {
  color: #FFF !important;
}
.color-white a:hover {
  text-decoration: underline;
}
.f-oswald {
  font-family: "Oswald", sans-serif;
}
.color-gray {
  color: #767676;
}
.color-main {
  color: #cda85c;
}
.aio-icon-description {
  color: #808080;
  line-height: 22px;
}
.font-alt {
  font-family: 'Bodoni_Regular';
}
.size-x2 {
  font-size: 56px;
  line-height: 60px;
}
.size-x3 {
  font-size: 90px;
  line-height: 90px;
}
/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
body.boxed {
  background-attachment: fixed;
}
.boxed .st-container {
  width: 970px;
  margin: 0 auto;
  box-shadow: 0 1px 6px;
  -webkit-box-shadow: 0 1px 6px;
}
.boxed .st-container .hide-filters-block {
  left: auto !important;
}
@media only screen and (max-width: 992px) {
  .boxed .st-container {
    width: 100%;
  }
}
.boxed .carousel-area .owl-controls .owl-prev {
  left: -29px;
}
.boxed .carousel-area .owl-controls .owl-next {
  right: -29px;
}
.boxed .login-link .login-popup {
  left: -180px;
}
.boxed .login-link .login-popup:before {
  left: 197px;
}
.boxed .register-link .register-popup {
  left: -250px;
}
.boxed .register-link .register-popup:before {
  left: 268px;
}
/* Transparent Container */
.container-transparent .st-container,
.container-transparent .st-pusher,
.container-transparent .st-content,
.container-transparent .footer-top-2,
.container-transparent .main-footer,
.container-transparent .copyright {
  background-color: transparent !important;
}
/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
body.bordered {
  border: 20px solid #f5f5f5;
  border-top: none;
}
body.bordered .body-border-left,
body.bordered .body-border-top,
body.bordered .body-border-right,
body.bordered .body-border-bottom {
  position: fixed;
  z-index: 10001;
  background-color: #f5f5f5;
}
body.bordered .body-border-left {
  left: 0;
  top: 0;
  bottom: 0;
  width: 20px;
}
body.bordered .body-border-right {
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
}
body.bordered .body-border-top {
  height: 20px;
  left: 0;
  right: 0;
  top: 0;
}
body.bordered .body-border-bottom {
  height: 20px;
  left: 0;
  right: 0;
  bottom: 0;
}
body.bordered.fixNav-enabled .fixed-header-area.fixed-already {
  top: 20px;
}
body.bordered.header-vertical-enable .page-wrapper .header-type-vertical,
body.bordered.header-vertical-enable .page-wrapper .header-type-vertical2 {
  left: 20px;
}
body.bordered.admin-bar .body-border-top {
  height: 52px;
}
body.bordered.admin-bar.fixNav-enabled .fixed-header-area.fixed-already {
  top: 52px;
}
body.bordered .st-menu {
  top: 20px;
  left: 20px;
}
/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
.top-bar {
  background: #000;
  color: #fff;
  padding: 6px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.top-bar a {
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
}
.top-bar a:focus {
  color: #FFF;
  text-decoration: none;
}
.top-bar .languages-area .menu-social-icons {
  margin-bottom: 0;
}
.top-bar .languages-area .menu-social-icons li {
  margin-right: 5px;
}
.top-bar .languages-area .menu-social-icons li i {
  background-color: transparent;
  color: inherit;
  height: 16px;
  line-height: 16px;
}
.top-bar .languages-area .menu-social-icons li .circle {
  display: none;
}
.top-bar .languages-area .topbar-widget {
  padding-right: 10px;
}
.top-bar #lang_sel a.lang_sel_sel {
  color: #444!important;
}
.top-bar .topbar-widget {
  display: inline-block;
  width: auto;
  float: left;
}
.top-bar .topbar-widget ul li {
  display: inline-block;
  border: none;
  width: auto;
}
.top-bar .topbar-widget ul li a {
  padding-top: 0;
  padding-bottom: 0;
}
.top-bar .topbar-widget a {
  color: #fff;
}
.top-bar .topbar-widget a:hover {
  text-decoration: underline;
  color: #FFF;
}
.top-bar .topbar-widget.widget_nav_menu li a {
  color: #FFF !important;
  line-height: 20px;
}
.top-bar .topbar-widget.widget_nav_menu li a:hover {
  text-decoration: underline;
  color: #FFF !important;
}
.top-bar .topbar-widget.widget_nav_menu li a:after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 8px;
  background: #fff;
  margin: 7px 12px 0;
}
.top-bar .topbar-widget.widget_nav_menu li:last-child a:after {
  display: none;
}
.top-bar .menu-social-icons,
.header-custom-block .menu-social-icons,
.blog-description .menu-social-icons {
  margin-bottom: 0;
}
.top-bar .menu-social-icons li,
.header-custom-block .menu-social-icons li,
.blog-description .menu-social-icons li {
  margin-right: 5px;
}
.top-bar .menu-social-icons li i,
.header-custom-block .menu-social-icons li i,
.blog-description .menu-social-icons li i {
  background-color: transparent;
  color: inherit;
  height: auto;
  width: 20px;
  font-size: 14px;
}
.top-bar .menu-social-icons li .circle,
.header-custom-block .menu-social-icons li .circle,
.blog-description .menu-social-icons li .circle {
  display: none;
}
.blog-description .menu-social-icons li {
  line-height: 12px;
}
.blog-description .menu-social-icons li i {
  color: #747474;
}
.header-type-9 .top-bar {
  background-color: #cda85c;
}
.header-type-9 .top-bar a {
  color: #FFF;
}
.header-type-9 .top-bar a:hover {
  color: #FFF;
  text-decoration: underline;
}
.languages-area {
  float: left;
}
.languages-area #lang_sel li {
  z-index: 1;
  width: 170px;
}
.languages-area .lang_sel_list_horizontal a {
  line-height: 20px !important;
}
.languages-area .lang_sel_list_horizontal a:hover {
  color: #cda85c;
}
.languages-area .lang_sel_list_horizontal #lang_sel_list img.iclflag {
  top: 0;
}
.languages-area .lang_sel_list_horizontal#lang_sel_list {
  font-family: 'Open Sans';
  font-size: 11px !important;
  height: 20px;
}
.languages-area .lang_sel_list_horizontal#lang_sel_list li {
  margin-right: 5px !important;
}
.languages-area .widget_icl_lang_sel_widget + .widget_currency_sel_widget,
.languages-area .widget_currency_sel_widget + .widget_icl_lang_sel_widget {
  position: relative;
}
.languages-area .widget_icl_lang_sel_widget + .widget_currency_sel_widget:before,
.languages-area .widget_currency_sel_widget + .widget_icl_lang_sel_widget:before {
  content: "";
  display: inline-block;
  float: left;
  left: -20px;
  width: 1px;
  height: 8px;
  background: #fff;
  margin: 6px 12px 0;
}
.languages-area .widget_icl_lang_sel_widget {
  float: left;
  padding-top: 3px;
}
.languages-area .widget_icl_lang_sel_widget #lang_sel a.lang_sel_sel {
  background-image: url(https://terminal21.co.th/images/select-arrow.jpg);
  background-position: 97% 50%;
  background-repeat: no-repeat;
}
.languages-area .widget_currency_sel_widget {
  float: left;
  margin-right: 20px;
  padding-top: 3px;
}
.languages-area .widget_currency_sel_widget select {
  height: 26px;
  line-height: 20px;
  border: 1px solid #cdcdcd;
  min-width: 70px;
}
.languages-area .widget_currency_sel_widget ul.wcml_currency_switcher li {
  border: none;
  font-size: 11px;
  text-transform: uppercase;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.languages-area .widget_currency_sel_widget ul.wcml_currency_switcher li:hover {
  color: #cda85c;
}
.languages-area .widget_currency_sel_widget .wcml_currency_switcher.curr_list_horizontal {
  display: inline-block;
}
.languages-area .languages .links li:after {
  display: none;
}
.top-links .topbar-widget {
  padding-left: 10px;
}
.links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  float: right;
  padding-left: 20px;
}
.links li {
  display: inline-block;
  position: relative;
}
.links li a:hover {
  text-decoration: underline;
}
.links li:after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 7px;
  background: #fff;
  margin: 7px 12px 0;
}
.navbar-toggle .icon-bar {
  background-color: #888;
}
.navbar-toggle {
  border-color: #DDD;
  float: left;
  margin: 59px 30px 0 0;
  background: #dfdfdf;
}
.navbar-toggle.collapsed {
  background: #F8F8F8;
}
.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
  max-height: none;
}
.collapse {
  vertical-align: middle;
}
.tbs {
  display: table-cell;
  vertical-align: middle;
  padding-top: 15px;
}
.tbs p {
  margin-bottom: 0;
}
.navbar {
  margin-bottom: 0;
  padding: 30px 0;
  display: table;
  margin: 0;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar .container-fluid {
  display: table-row;
}
.languages {
  float: left;
}
.languages li {
  margin-right: 8px;
  font-size: 11px;
}
.languages li.lastItem {
  margin-right: 0;
}
.languages li.lastItem:after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 8px;
  background: #fff;
  margin: 7px 12px 0;
}
.languages li a {
  font-size: 11px;
}
.languages li.active {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
.languages .links {
  padding-left: 0;
}
.currency {
  float: left;
}
.currency li {
  font-size: 11px;
  margin-right: 8px;
}
.currency li.lastItem {
  margin-right: 0;
}
.currency li a {
  font-size: 11px;
}
.currency li.active {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
.currency li:after {
  display: none;
}
.top-links {
  float: right;
}
.top-links li.lastItem a:after {
  content: none;
}
.top-links li.popup_link:before {
  content: "\f003";
  position: relative;
  left: -10px;
  font-family: FontAwesome;
}
.top-links li.popup_link:hover:before {
  text-decoration: none;
}
.top-links .lastItem:after {
  content: none;
}
.main-header > .container {
  position: relative;
}
.header-logo {
  float: none;
  display: table-cell;
  vertical-align: middle;
  max-width: 340px;
  padding-right: 20px;
  position: relative;
}
.header-logo img {
  max-width: 320px;
  float: left;
}
.header-logo .hidden-tooltip {
  position: absolute;
  right: 0;
  top: 0;
}
.header-type-4 .header-logo,
.header-type-3 .header-logo {
  position: relative;
}
.header-type-4 .header-logo .hidden-tooltip,
.header-type-3 .header-logo .hidden-tooltip {
  position: absolute;
  top: 0;
  right: 0;
}
.fixed-header-area .hidden-tooltip {
  display: none;
}
.hidden-tooltip {
  position: relative;
  width: 26px;
  height: 26px;
  float: right;
  top: -15px;
  z-index: 1000;
}
.hidden-tooltip .open-tooltip {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}
.hidden-tooltip .open-tooltip i {
  padding: 5px 9px;
  border: 1px solid #bcbcbc;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-animation: tooltip-color 1.5s ease-in infinite;
  -moz-animation: tooltip-color 1.5s ease-in infinite;
  -ms-animation: tooltip-color 1.5s ease-in infinite;
  animation: tooltip-color 1.5s ease-in infinite;
}
.hidden-tooltip .open-tooltip i:hover {
  border: 1px solid #000;
  color: #000;
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}
.hidden-tooltip .tooltip-content {
  display: none;
  position: absolute;
  background-color: #222;
  top: 35px;
  color: #FFF;
  width: 300px;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1001;
  padding: 20px;
}
.hidden-tooltip .tooltip-content .btn-close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.hidden-tooltip .tooltip-content .btn-close i {
  font-size: 0;
  background-image: url(https://terminal21.co.th/images/btn-close-tooltip.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 11px;
  height: 11px;
  position: relative;
  top: -12px;
}
.hidden-tooltip .tooltip-content .btn-close i:hover {
  border: none !important;
}
.hidden-tooltip .tooltip-content p {
  color: #a4a4a4;
  margin-bottom: 10px;
}
.hidden-tooltip .tooltip-content img {
  max-width: 100%;
}
.hidden-tooltip .tooltip-content .tooltip_inner {
  width: 260px;
  opacity: 0;
  text-align: left !important;
}
.hidden-tooltip .tooltip-content.opened-tooltip .tooltip_inner {
  -webkit-animation: tooltip-animation 0.3s ease-in 0.2s forwards;
  -moz-animation: tooltip-animation 0.3s ease-in 0.2s forwards;
  -ms-animation: tooltip-animation 0.3s ease-in 0.2s forwards;
  animation: tooltip-animation 0.3s ease-in 0.2s forwards;
}
@-webkit-keyframes tooltip-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tooltip-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes tooltip-color {
  0% {
    color: #fff;
  }
  50% {
    color: #333;
  }
  100% {
    color: #fff;
  }
}
@keyframes tooltip-color {
  0% {
    color: #fff;
  }
  50% {
    color: #333;
  }
  100% {
    color: #fff;
  }
}
@-webkit-keyframes tooltip-color-white {
  0% {
    color: #444;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #444;
  }
}
@keyframes tooltip-color-white {
  0% {
    color: #444;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #444;
  }
}
/*---------------------------------------------------------------*/
/* Transparent Header
/*---------------------------------------------------------------*/
.header-wrapper.header-transparent,
.header-wrapper.header-transparent .container {
  background-color: transparent !important;
}
/*---------------------------------------------------------------*/
/* Header on FullPage layout
/*---------------------------------------------------------------*/
.full-page-on {
  /* FlexBox for section */
}
.full-page-on .header-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 100;
}
.full-page-on .fixed-header-area {
  display: none;
}
.full-page-on .content-page,
.full-page-on .page-content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.full-page-on .vc_row-fluid {
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-wrap: no-wrap;
  -webkit-flex-wrap: no-wrap;
}
@media only screen and (max-width: 992px) {
  .full-page-on .vc_row-fluid {
    -webkit-flex-wrap: wrap;
    padding: 0 30px;
  }
}
.sections-nav {
  position: fixed;
  top: 45%;
  right: 30px;
  width: 15px;
  text-align: center;
}
.sections-nav li {
  font-size: 0;
  width: 10px;
  height: 10px;
  background: #8E8E8E;
  transition: 0.1s all ease-in-out;
  display: inline-block;
  cursor: pointer;
  margin: 5px auto;
  border-radius: 100%;
}
.sections-nav li.active-nav {
  width: 15px;
  height: 15px;
  background: #524B4B;
}
/*---------------------------------------------------------------*/
/* Main Menu / #menu
/*---------------------------------------------------------------*/
.navigation {
  margin-bottom: 0;
}
.menu {
  padding: 0;
  margin: 0;
}
.menu > li {
  text-decoration: none;
  list-style: none;
  position: relative;
}
.menu > li a {
  font-family: 'Roboto';
  font-size: 12px;
  font-style: normal;
}
.menu > li.current-menu-item > a,
.menu > li.current_page_ancestor > a {
  color: #cda85c;
}
.menu > li.current-menu-item > a:after,
.menu > li.current_page_ancestor > a:after {
  color: #cda85c;
}
.menu > li {
  float: left;
}
.menu > li.menu-item-has-children > a:after {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 13px;
  color: #b9b9b9;
  margin: 0 0 0 3px;
}
.menu > li:hover .nav-sublist-dropdown {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.menu > li > a {
  float: left;
  display: block;
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  padding: 20px 10px 15px 10px;
  color: #000;
  position: relative;
}
.menu > li.sfHover > a,
.menu > li > a:hover {
  text-decoration: none;
  color: #CDA85C;
}
.menu .nav-sublist-dropdown {
  position: absolute;
  top: 50px !important;
  left: 0;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  background-color: #FFF;
  margin-top: -5px;
  transition: 0.15s linear;
  transform: translateY(60px);
}
.menu .nav-sublist-dropdown:before {
  content: "";
  position: absolute;
  top: -10px;
  width: 100%;
  height: 50px;
  left: 0;
}
.menu .nav-sublist-dropdown .container {
  width: auto;
  padding: 0;
}
.menu .nav-sublist-dropdown ul {
  padding: 15px 0 15px;
  border: 3px solid #E6E6E6;
  width: 200px;
  display: inline-block;
}
.menu .nav-sublist-dropdown ul > li {
  position: relative;
  display: inline-block;
  width: 100%;
}
.menu .nav-sublist-dropdown ul > li.menu-item-has-children > a {
  position: relative;
}
.menu .nav-sublist-dropdown ul > li.menu-item-has-children > a:after {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 13px;
  color: #b9b9b9;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -10px;
}
.menu .nav-sublist-dropdown ul > li.menu-item-has-children:hover:after {
  color: #cda85c;
}
.menu .nav-sublist-dropdown ul > li.current-menu-item > a {
  color: #cda85c;
}
.menu .nav-sublist-dropdown ul > li.lastItem {
  border-bottom: none;
}
.menu .nav-sublist-dropdown ul > li ul {
  position: absolute;
  top: -18px;
  z-index: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #FFF;
}
.menu .nav-sublist-dropdown ul > li ul ul {
  z-index: -2;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.menu .nav-sublist-dropdown ul > li > a {
  font-size: 12px;
  color: #767676;
  display: inline;
  padding: 8px 20px;
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-transform: uppercase;
}
.menu .nav-sublist-dropdown ul > li.menu-item-has-children > .nav-sublist {
  display: none;
}
.menu .nav-sublist-dropdown ul > li.menu-item-has-children:hover > .nav-sublist {
  display: block;
}
.menu .nav-sublist-dropdown ul > li.label-new > a:after {
  content: "";
  display: inline-block;
  background-image: url(https://terminal21.co.th/images/new-menu.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 42px;
  height: 16px;
  position: absolute;
  right: 0;
  top: -1px;
  margin-right: -42px;
}
.menu .nav-sublist-dropdown ul > li.label-sale > a:after {
  content: "";
  display: inline-block;
  background-image: url(https://terminal21.co.th/images/sale-menu.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 42px;
  height: 16px;
  position: absolute;
  right: 0;
  top: -1px;
  margin-right: -42px;
}
.menu .nav-sublist-dropdown ul > li.label-hot > a:after {
  content: "";
  display: inline-block;
  background-image: url(https://terminal21.co.th/images/hot-menu.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 42px;
  height: 16px;
  position: absolute;
  right: 0;
  top: -1px;
  margin-right: -42px;
}
.menu .nav-sublist-dropdown ul li a:hover {
  color: #cda85c;
  text-decoration: none;
}
.menu-item-language img.iclflag {
  margin-top: -3px;
}
#lang_sel_footer ul li img,
#lang_sel img.iclflag {
  top: -1px;
}
.menu > li.menu-item-language {
  position: relative;
}
.menu > li.menu-item-language ul {
  position: absolute;
  top: 100%;
  opacity: 0;
  width: 100%;
  padding: 10px;
  visibility: hidden;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  transition: all 0.2s ease-in-out;
}
.menu > li.menu-item-language ul li {
  padding: 5px 0 5px 8px;
  text-align: left;
}
.menu > li.menu-item-language:hover ul {
  opacity: 1;
  visibility: visible;
}
.header .menu .menu-full-width.open-by-click,
.fixed-header-area .menu .menu-full-width.open-by-click {
  visibility: visible;
}
.header .menu .menu-full-width.open-by-click .nav-sublist-dropdown,
.fixed-header-area .menu .menu-full-width.open-by-click .nav-sublist-dropdown {
  opacity: 0;
  transform: translateY(60px);
  -webkit-transition: 0.15s linear;
  -moz-transition: 0.15s linear;
  -ms-transition: 0.15s linear;
  -o-transition: 0.15s linear;
  transition: 0.15s linear;
}
.header .menu .menu-full-width.open-by-click.opened .nav-sublist-dropdown,
.fixed-header-area .menu .menu-full-width.open-by-click.opened .nav-sublist-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.menu .menu-full-width {
  position: relative;
}
.menu .menu-full-width:hover > a {
  position: relative;
}
.menu .menu-full-width:hover > a:before {
  content: "";
  position: absolute;
  top: 100%;
  height: 70px;
  width: 180px;
  left: -20px;
}
.menu .menu-full-width:hover .nav-sublist-dropdown {
  visibility: visible;
}
.menu .menu-full-width .nav-sublist-dropdown {
  background-color: #fff;
  padding: 25px 30px 15px 30px;
  border: 3px solid #E6E6E6;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.menu .menu-full-width .nav-sublist-dropdown ul {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  width: 100%;
  padding: 0;
  background-color: transparent;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li {
  float: left;
  width: 168px;
  margin-right: 10px;
  text-align: left;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.item-design2-image > a {
  font-size: 0;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.menu-disable_titles > a {
  font-size: 0;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.menu-disable_titles > a i {
  font-size: 16px;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.banner-li {
  width: 190px;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.banner-li a {
  border: none;
  padding: 0;
  margin: 0;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.lastItem {
  margin-right: 0;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li > a {
  font-size: 14px;
  color: #000;
  display: inline-block;
  padding: 0;
  padding-bottom: 10px;
  width: auto;
  position: relative;
  text-transform: uppercase;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li > a .nav-item-tooltip {
  position: absolute;
  display: none;
  left: 100%;
  margin-left: 20px;
  width: 240px;
  background-color: #252525;
  padding: 5px;
  z-index: 100;
  top: 0;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li > a:hover .nav-item-tooltip {
  display: block;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li .nav-sublist a {
  letter-spacing: 0;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li ul li ul {
  padding-left: 10px !important;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li ul li ul li a {
  font-family: 'Open Sans', sans-serif;
  font-size: 11px !important;
  padding: 4px 0;
  line-height: 10px;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li ul li ul li a:before {
  content: "";
  position: relative;
  width: 4px;
  height: 4px;
  left: -10px;
  top: 7px;
  display: block;
  background-color: #929292;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.menu-item-has-children > a:after {
  display: none !important;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.menu-item-has-children .nav-sublist {
  display: block !important;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.menu-item-has-children .nav-sublist ul {
  position: static !important;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.menu-item-has-children .nav-sublist ul li.item-design2-image a {
  font-size: 0;
}
.menu .menu-full-width .nav-sublist-dropdown ul > li.menu-item-has-children .nav-sublist ul li a {
  font-size: 12px;
  color: #767676;
  text-transform: uppercase;
  padding: 8px 0;
  font-weight: 400;
}
/* Header Search / #search */
.header-search {
  float: right;
  margin-top: 7px;
  margin-right: 15px;
}
.menu-search {
  margin: 20px 0 0;
  position: relative;
}
.menu-search input {
  width: 100%;
}
.menu-search .search-ico-mob {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -155px -51px;
  width: 15px;
  height: 16px;
  display: block;
  border: none;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -8px;
  z-index: 2;
}
.navbar-right {
  padding: 0;
  float: none !important;
  display: table-cell;
  vertical-align: middle;
}
.navbar-right .navbar-right {
  float: right !important;
}
.et-search-trigger {
  position: relative;
}
.et-search-trigger .fa-search {
  width: 33px;
  height: 33px;
  background: #c1c1c1;
  text-align: center;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  line-height: 33px;
  border-radius: 30px;
  -webkit-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
  -o-transition: background 0.25s ease;
  transition: background 0.25s ease;
}
.et-search-trigger .fa-search:hover {
  background: #d2d2d2;
}
.et-search-trigger form {
  position: absolute;
  top: 30px;
  right: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
}
.et-search-trigger form:before {
  content: '';
  display: block;
  top: -10px;
  right: 9px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #E6E6E6;
  border-width: 5px 6px;
  margin-left: -6px;
}
.et-search-trigger.search-dropdown {
  display: block;
}
.et-search-trigger.search-dropdown .fa-search {
  display: block;
}
.et-search-trigger.search-dropdown form {
  border: 3px solid #E6E6E6;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  width: 325px;
  top: 55px;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  z-index: 1001;
  right: -12px;
  background-color: #FFF;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.et-search-trigger.search-dropdown form .modal-form {
  margin-top: 0;
}
.et-search-trigger.search-dropdown form .modal-form .has-border > div {
  width: 100%;
}
.et-search-trigger.search-dropdown form .form-group {
  float: left;
  margin-bottom: 0;
}
.et-search-trigger.search-dropdown form .form-group.form-button {
  float: right;
}
.et-search-trigger.search-dropdown form .form-group.form-button .btn-black {
  padding: 12px 22px !important;
  margin-right: 10px;
}
.et-search-trigger.search-dropdown form:before {
  top: -13px;
}
.et-search-trigger.search-dropdown form:after {
  content: "";
  width: 250px;
  height: 35px;
  z-index: 1;
  position: absolute;
  right: 0;
  top: -25px;
  display: block;
}
.et-search-trigger.search-dropdown:hover form {
  top: 45px;
  visibility: visible;
  opacity: 1;
}
.et-search-trigger .popup-with-form > span {
  display: none;
}
.search-control {
  color: #a9a9a9;
  font-size: 13px;
  width: 248px;
  border: 2px solid #E6E6E6;
  padding: 11px 16px;
  position: relative;
  background: #fff;
}
.search-control:focus::-moz-placeholder {
  color: transparent;
  opacity: 1;
}
.search-control:focus:-ms-input-placeholder {
  color: transparent;
}
.search-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.search-control:focus::-moz-placeholder {
  color: transparent;
  opacity: 1;
}
.search-control:focus:-ms-input-placeholder {
  color: transparent;
}
.search-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.animForm {
  -webkit-animation: showForm 0.3s forwards ease;
  -ms-animation: showForm 0.3s forwards ease;
  -o-animation: showForm 0.3s forwards ease;
  animation: showForm 0.3s forwards ease;
}
@-moz-keyframes showForm {
  0% {
    top: 30px;
  }
  100% {
    top: 40px;
    opacity: 100;
    visibility: visible;
  }
}
@-webkit-keyframes showForm {
  0% {
    top: 30px;
  }
  100% {
    top: 40px;
    opacity: 100;
    visibility: visible;
  }
}
@-o-keyframes showForm {
  0% {
    top: 30px;
  }
  100% {
    top: 40px;
    opacity: 100;
    visibility: visible;
  }
}
@-ms-keyframes showForm {
  0% {
    top: 30px;
  }
  100% {
    top: 40px;
    opacity: 100;
    visibility: visible;
  }
}
@keyframes showForm {
  0% {
    top: 30px;
  }
  100% {
    top: 40px;
    opacity: 100;
    visibility: visible;
  }
}
.vc_non_responsive .header-logo {
  max-width: 280px;
}
.vc_non_responsive .header-logo img {
  max-width: 100%;
}
.vc_non_responsive .menu > li > a {
  padding-left: 6px;
  padding-right: 6px;
}
.vc_non_responsive .navbar-right {
  min-width: 200px;
}
/*---------------------------------------------------------------*/
/* Fixed header / #fixed-header
/*---------------------------------------------------------------*/
.fixed-header-area {
  position: fixed;
  top: -160px;
  left: 0;
  width: 100%;
  z-index: 10001;
  background-color: #FFF;
  border-bottom: 1px solid #ddd;
  -webkit-transition: 0.4s all ease-in-out;
  -moz-transition: 0.4s all ease-in-out;
  -ms-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
  min-height: 85px;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
}
.fixed-header-area .header-logo {
  padding: 10px 0;
}
.fixed-header-area .header-logo img {
  max-height: 65px;
  width: auto;
}
.fixed-header-area.fixed-already {
  top: 0;
}
.fixed-header-area .fixed-header > .container {
  display: table;
  position: relative;
  min-height: 85px;
}
.fixed-header-area .collapse {
  display: table-cell !important;
}
.fixed-header-area .modal-buttons,
.fixed-header-area #st-trigger-effects {
  display: none;
}
.fixed-header-area .menu > li > a:before {
  content: "";
  position: absolute;
  top: 100%;
  height: 20px;
  width: 180px;
  left: -10px;
}
.fixed-header-area .menu > li > a:hover:before {
  top: 85%;
  height: 50px;
  z-index: 1;
}
.fixed-header-area .menu .nav-sublist-dropdown {
  top: 50px;
  margin-top: 8px !important;
}
.fixed-header-area .menu .menu-full-width .nav-sublist-dropdown {
  margin-top: 0;
}
.fixed-header-area .menu .menu-full-width .nav-sublist-dropdown ul > li ul {
  border: none !important;
}
.fixed-header-area .badge-number {
  font-size: 11px;
  line-height: 18px;
  top: 3px;
}
.fixed-header-area .nav-sublist-dropdown {
  margin-top: 0;
  border: 1px solid #e6e6e6 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.fixed-header-area .nav-sublist-dropdown ul {
  border: none !important;
}
.fixed-header-area .nav-sublist-dropdown ul li {
  text-align: left;
}
.fixed-header-area .nav-sublist-dropdown ul > li ul {
  border: 1px solid #e6e6e6 !important;
  top: -16px;
}
.fixed-header-area .nav-sublist-dropdown:before {
  display: none;
}
.fixed-header-area .nav-sublist-dropdown .container:after {
  display: none;
}
.fixed-header-area h4.a-center {
  display: none;
}
.mfp-figure .mfp-close {
  right: -27px !important;
}
/*---------------------------------------------------------------*/
/* Header type / #header-type
/*---------------------------------------------------------------*/
.header-type-2.slider-overlap {
  z-index: 10000;
  position: relative;
}
.header-type-2.slider-overlap .top-bar,
.header-type-2.slider-overlap .header {
  background-color: transparent;
}
.header-type-2.slider-overlap .top-bar a {
  color: #FFF !important;
}
.header-type-2.slider-overlap .top-bar a:hover {
  text-decoration: underline;
}
.header-type-2.slider-overlap .top-bar #lang_sel a {
  color: #444 !important;
}
.header-type-2.slider-overlap .languages li.active,
.header-type-2.slider-overlap .currency li.active {
  color: #FFF !important;
  text-decoration: underline;
}
.header-type-2.slider-overlap .menu-icon:before {
  color: #FFF;
}
.header-type-2.slider-overlap .header .menu > li > a {
  color: #FFF;
}
.header-type-2.slider-overlap .header .menu > li > a:hover {
  color: #FFF !important;
  text-decoration: underline;
}
.header-type-2.slider-overlap .header .menu > li.menu-item-has-children:after {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 13px;
  color: #fff;
  margin: 0 0 0 3px;
  position: relative;
  right: 8px;
  top: 7px;
}
.header-type-2.slider-overlap .header .menu > li.menu-item-has-children > a:after {
  display: none;
}
.header-type-2.slider-overlap .header .menu .nav-sublist-dropdown {
  margin-top: -5px;
}
.header-type-2.slider-overlap .header .menu > li.menu-item-has-children > a:after {
  color: #FFF;
}
.header-type-2.slider-overlap .header .header-search {
  margin-top: 5px;
}
.header-type-2.slider-overlap .header .et-search-trigger .fa-search {
  background-color: transparent;
  font-size: 16px;
}
.header-type-2.slider-overlap .header .cart-bag {
  background-color: transparent;
  top: -2px;
  right: auto;
  left: 0;
  padding: 0;
}
.header-type-2.slider-overlap .header .cart-bag .ico-sum {
  background-color: #fff;
}
.header-type-2.slider-overlap .header .cart-bag .ico-sum:before {
  border-color: #fff;
}
.header-type-2.slider-overlap .header .cart-bag .ico-sum:after {
  background-color: #fff;
}
.header-type-2.slider-overlap .header .shop-text {
  font-size: 0;
}
.header-type-2.slider-overlap .header .shop-text .total {
  color: #fff;
  padding-left: 26px;
}
.header-type-2.slider-overlap .header .cart-summ {
  color: #FFF;
  padding-left: 30px;
}
.header-type-2.slider-overlap .header .cart-summ .badge-number {
  display: none;
}
.header-type-2.slider-overlap .header .shopping-container {
  top: 11px;
}
.header-type-2.slider-overlap .header .shopping-container:hover .cart-popup-container {
  top: 40px;
}
.header-type-2.slider-overlap .header .shopping-cart-widget {
  border: none;
  padding: 0;
}
.header-type-2.slider-overlap .header .total {
  font-family: 'Roboto';
  font-weight: 700;
}
.header-type-3.slider-overlap {
  z-index: 10000;
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
}
.header-type-3.slider-overlap .header {
  background-color: transparent;
}
.header-type-3.slider-overlap .top-bar {
  background-color: rgba(0, 0, 0, 0.3);
}
.header-type-3.slider-overlap .header .menu > li > a {
  color: #FFF;
}
.header-type-3.slider-overlap .header .menu > li.menu-item-has-children > a:after {
  color: #FFF;
}
.header-type-3.slider-overlap .header .menu .nav-sublist-dropdown {
  margin-top: -5px;
}
.header-type-3.slider-overlap .header .header-search {
  margin-top: 5px;
}
.header-type-3.slider-overlap .header .shopping-cart-widget {
  border: 1px dashed #7d7d7d;
}
.header-type-3.slider-overlap .header .shopping-cart-widget .shop-text {
  color: #FFF;
}
.header-type-3.slider-overlap .header .shopping-cart-widget .cart-summ {
  color: #FFF;
}
.header-type-3.slider-overlap .header .et-search-trigger .fa-search {
  background-color: #424242;
}
.header-type-3.slider-overlap .header .badge-number {
  background-color: #424242;
  color: #FFF;
}
.header-type-4 .header .header-search {
  margin-top: 5px;
}
.header-type-4 .header .shopping-cart-widget {
  border: none;
}
.header-type-4 .header .shopping-cart-widget .shop-text,
.header-type-4 .header .shopping-cart-widget .total {
  display: none;
}
.header-type-5.slider-overlap {
  z-index: 10000;
  position: relative;
}
.header-type-5.slider-overlap .top-bar,
.header-type-5.slider-overlap .header {
  background-color: transparent;
}
.header-type-5.slider-overlap .top-bar > .container {
  background-color: rgba(34, 34, 34, 0.9);
}
.header-type-5.slider-overlap .header > .container {
  background-color: rgba(255, 255, 255, 0.9);
}
.header-type-5.slider-overlap .top-bar {
  padding: 0;
}
.header-type-5.slider-overlap .menu .nav-sublist-dropdown {
  opacity: 1;
}
.header-type-6 .top-bar,
.header-type-7 .top-bar,
.header-type-8 .top-bar,
.header-type-10 .top-bar,
.header-type-12 .top-bar {
  background-color: #F2F2F2;
  color: #767676;
}
.header-type-6 .top-bar a,
.header-type-7 .top-bar a,
.header-type-8 .top-bar a,
.header-type-10 .top-bar a,
.header-type-12 .top-bar a {
  color: #767676;
}
.header-type-6 .top-bar .languages-area .lang_sel_list_horizontal a,
.header-type-7 .top-bar .languages-area .lang_sel_list_horizontal a,
.header-type-8 .top-bar .languages-area .lang_sel_list_horizontal a,
.header-type-10 .top-bar .languages-area .lang_sel_list_horizontal a,
.header-type-12 .top-bar .languages-area .lang_sel_list_horizontal a {
  color: #767676 !important;
}
.header-type-6 .top-bar .languages-area .lang_sel_list_horizontal a:hover,
.header-type-7 .top-bar .languages-area .lang_sel_list_horizontal a:hover,
.header-type-8 .top-bar .languages-area .lang_sel_list_horizontal a:hover,
.header-type-10 .top-bar .languages-area .lang_sel_list_horizontal a:hover,
.header-type-12 .top-bar .languages-area .lang_sel_list_horizontal a:hover {
  color: #cda85c;
}
.header-type-6 .top-bar .languages-area .widget_icl_lang_sel_widget + .widget_currency_sel_widget:before,
.header-type-7 .top-bar .languages-area .widget_icl_lang_sel_widget + .widget_currency_sel_widget:before,
.header-type-8 .top-bar .languages-area .widget_icl_lang_sel_widget + .widget_currency_sel_widget:before,
.header-type-10 .top-bar .languages-area .widget_icl_lang_sel_widget + .widget_currency_sel_widget:before,
.header-type-12 .top-bar .languages-area .widget_icl_lang_sel_widget + .widget_currency_sel_widget:before,
.header-type-6 .top-bar .languages-area .widget_currency_sel_widget + .widget_icl_lang_sel_widget:before,
.header-type-7 .top-bar .languages-area .widget_currency_sel_widget + .widget_icl_lang_sel_widget:before,
.header-type-8 .top-bar .languages-area .widget_currency_sel_widget + .widget_icl_lang_sel_widget:before,
.header-type-10 .top-bar .languages-area .widget_currency_sel_widget + .widget_icl_lang_sel_widget:before,
.header-type-12 .top-bar .languages-area .widget_currency_sel_widget + .widget_icl_lang_sel_widget:before {
  background-color: #767676;
}
.header-type-6 .top-links li:after,
.header-type-7 .top-links li:after,
.header-type-8 .top-links li:after,
.header-type-10 .top-links li:after,
.header-type-12 .top-links li:after {
  background: #767676;
}
.header-type-6 .header .header-logo,
.header-type-7 .header .header-logo,
.header-type-8 .header .header-logo,
.header-type-10 .header .header-logo,
.header-type-12 .header .header-logo {
  text-align: center;
  padding-right: 0;
}
.header-type-6 .header .header-logo img,
.header-type-7 .header .header-logo img,
.header-type-8 .header .header-logo img,
.header-type-10 .header .header-logo img,
.header-type-12 .header .header-logo img {
  float: none;
}
.header-type-6 .header .tbs span,
.header-type-7 .header .tbs span,
.header-type-8 .header .tbs span,
.header-type-10 .header .tbs span,
.header-type-12 .header .tbs span {
  display: block;
  padding: 10px 15px;
  border: 1px dashed #DFDFDF;
  position: relative;
  float: left;
}
.header-type-6 .header .tbs span:before,
.header-type-7 .header .tbs span:before,
.header-type-8 .header .tbs span:before,
.header-type-10 .header .tbs span:before,
.header-type-12 .header .tbs span:before {
  content: "";
  width: 27px;
  height: 21px;
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff url(https://terminal21.co.th/images/sprite/icons.png) no-repeat -170px -78px;
}
.header-type-6 .menu-wrapper,
.header-type-7 .menu-wrapper,
.header-type-8 .menu-wrapper,
.header-type-10 .menu-wrapper,
.header-type-12 .menu-wrapper {
  border-top: 1px solid #ebebeb;
  position: relative;
  font-size: 0;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: -1px;
}
.header-type-6 .menu-wrapper .menu-main-container > ul,
.header-type-7 .menu-wrapper .menu-main-container > ul,
.header-type-8 .menu-wrapper .menu-main-container > ul,
.header-type-10 .menu-wrapper .menu-main-container > ul,
.header-type-12 .menu-wrapper .menu-main-container > ul {
  text-align: center;
  width: 100%;
  display: inline-block;
}
.header-type-6 .menu-wrapper .menu-main-container > ul > li,
.header-type-7 .menu-wrapper .menu-main-container > ul > li,
.header-type-8 .menu-wrapper .menu-main-container > ul > li,
.header-type-10 .menu-wrapper .menu-main-container > ul > li,
.header-type-12 .menu-wrapper .menu-main-container > ul > li {
  display: inline-block;
  float: none;
}
.header-type-6 .menu-wrapper .menu-main-container > ul > li > a,
.header-type-7 .menu-wrapper .menu-main-container > ul > li > a,
.header-type-8 .menu-wrapper .menu-main-container > ul > li > a,
.header-type-10 .menu-wrapper .menu-main-container > ul > li > a,
.header-type-12 .menu-wrapper .menu-main-container > ul > li > a {
  padding: 21px 17px;
  border-left: 1px solid  #ebebeb;
}
.header-type-6 .menu-wrapper .menu-main-container > ul > li:last-child > a,
.header-type-7 .menu-wrapper .menu-main-container > ul > li:last-child > a,
.header-type-8 .menu-wrapper .menu-main-container > ul > li:last-child > a,
.header-type-10 .menu-wrapper .menu-main-container > ul > li:last-child > a,
.header-type-12 .menu-wrapper .menu-main-container > ul > li:last-child > a {
  border-right: 1px solid #ebebeb;
}
.header-type-6 .menu-wrapper .menu-main-container > ul > li .nav-sublist-dropdown ul,
.header-type-7 .menu-wrapper .menu-main-container > ul > li .nav-sublist-dropdown ul,
.header-type-8 .menu-wrapper .menu-main-container > ul > li .nav-sublist-dropdown ul,
.header-type-10 .menu-wrapper .menu-main-container > ul > li .nav-sublist-dropdown ul,
.header-type-12 .menu-wrapper .menu-main-container > ul > li .nav-sublist-dropdown ul {
  border: none;
}
.header-type-6 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown,
.header-type-7 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown,
.header-type-8 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown,
.header-type-10 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown,
.header-type-12 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown {
  margin-top: 7px;
}
.header-type-6 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown ul,
.header-type-7 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown ul,
.header-type-8 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown ul,
.header-type-10 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown ul,
.header-type-12 .menu-wrapper .menu-main-container > ul .menu-full-width:hover .nav-sublist-dropdown ul {
  border: none;
}
.header-type-6 .menu-wrapper .menu-main-container > ul .menu-full-width .nav-sublist-dropdown ul,
.header-type-7 .menu-wrapper .menu-main-container > ul .menu-full-width .nav-sublist-dropdown ul,
.header-type-8 .menu-wrapper .menu-main-container > ul .menu-full-width .nav-sublist-dropdown ul,
.header-type-10 .menu-wrapper .menu-main-container > ul .menu-full-width .nav-sublist-dropdown ul,
.header-type-12 .menu-wrapper .menu-main-container > ul .menu-full-width .nav-sublist-dropdown ul {
  border: none !important;
}
.header-type-6 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown,
.header-type-7 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown,
.header-type-8 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown,
.header-type-10 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown,
.header-type-12 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown {
  border: 1px solid #e6e6e6;
  margin-top: -3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.header-type-6 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul li,
.header-type-7 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul li,
.header-type-8 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul li,
.header-type-10 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul li,
.header-type-12 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul li {
  text-align: left;
}
.header-type-6 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul > li ul,
.header-type-7 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul > li ul,
.header-type-8 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul > li ul,
.header-type-10 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul > li ul,
.header-type-12 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown ul > li ul {
  border: 1px solid #e6e6e6;
  top: -16px;
}
.header-type-6 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown:before,
.header-type-7 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown:before,
.header-type-8 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown:before,
.header-type-10 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown:before,
.header-type-12 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown:before {
  display: none;
}
.header-type-6 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown .container:after,
.header-type-7 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown .container:after,
.header-type-8 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown .container:after,
.header-type-10 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown .container:after,
.header-type-12 .menu-wrapper .menu-main-container > ul .nav-sublist-dropdown .container:after {
  display: none;
}
.header-type-7 .menu-wrapper {
  background-color: #252525;
  border: none;
}
.header-type-7 .menu-wrapper .menu > li > a {
  color: #aeaeae;
  border-color: #393939 !important;
}
.header-type-7 .menu-wrapper .menu > li > a:hover {
  color: #cda85c;
}
.header-type-8 .header-logo,
.header-type-8 .navbar-header {
  width: 50% !important;
  text-align: left !important;
}
.header-type-8 .menu-wrapper .navbar-collapse {
  float: left;
  width: auto;
}
.header-type-8 .menu-wrapper .navbar-collapse .menu-main-container .menu {
  float: left;
  text-align: left;
  width: auto;
}
.header-type-8 .menu-wrapper .languages-area {
  float: right;
  padding: 20px 0 0;
}
.header-type-8 .menu-wrapper .languages-area .lang_sel_list_horizontal ul {
  float: left;
}
.header-type-8 .menu-wrapper .languages-area .lang_sel_list_horizontal a {
  color: #b8b8b8 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 12px !important;
}
.header-type-8 .menu-wrapper .languages-area .lang_sel_list_horizontal a:hover {
  color: #cda85c;
}
.header-type-8 .menu-wrapper .languages-area .lang_sel_list_horizontal a.lang_sel_sel {
  color: #000 !important;
  font-family: 'Roboto', sans-serif;
}
.header-type-8 .menu-wrapper .languages-area .lang_sel_list_horizontal:after {
  content: "";
  display: inline-block;
  float: left;
  left: -20px;
  width: 1px;
  height: 8px;
  background: #ebebeb;
  margin: 6px 12px 0;
}
.header-type-8 .menu-wrapper .widget_currency_sel_widget {
  margin-right: 0;
}
.header-type-8 .menu-wrapper .widget_currency_sel_widget:before {
  display: none !important;
}
.header-type-8 .menu-wrapper .widget_currency_sel_widget ul.wcml_currency_switcher li {
  color: #b8b8b8 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 12px !important;
}
.header-type-8 .menu-wrapper .widget_currency_sel_widget ul.wcml_currency_switcher li:hover {
  color: #cda85c;
}
.header-type-8 .navbar-header .top-links {
  padding-top: 11px;
  padding-right: 40px;
}
.header-type-8 .navbar-header .top-links ul li a {
  font-size: 12px;
  font-family: 'Roboto';
  text-transform: uppercase;
}
.header-type-8 .currency li {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}
.header-type-8 .currency li a {
  font-size: 14px;
}
.header-type-8 .languages li {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
}
.header-type-8 .languages li a {
  font-size: 12px;
}
.languages-area .widget_currency_sel_widget ul.wcml_currency_switcher li:hover {
  text-decoration: underline;
  color: #FFF;
}
.header-type-10 .header-logo,
.header-type-10 .navbar-header {
  width: 50% !important;
  text-align: left !important;
}
.header-type-10 .menu-wrapper {
  background-color: #252525;
  border: none;
}
.header-type-10 .menu-wrapper .navbar-collapse {
  float: left;
  width: auto;
}
.header-type-10 .menu-wrapper .navbar-collapse .menu-main-container .menu {
  float: left;
  text-align: left;
  width: auto;
}
.header-type-10 .menu-wrapper .navbar-collapse .menu-main-container .menu > li > a {
  color: #aeaeae;
  border-color: #393939 !important;
}
.header-type-10 .menu-wrapper .navbar-collapse .menu-main-container .menu > li > a:hover {
  color: #cda85c;
}
.header-type-10 .menu-wrapper .languages-area {
  float: right;
  padding: 16px 0;
}
.header-type-10 .menu-wrapper .languages-area li a {
  color: #aeaeae;
}
.header-type-10 .menu-wrapper .languages-area .lang_sel_list_horizontal ul {
  float: left;
}
.header-type-10 .menu-wrapper .languages-area .lang_sel_list_horizontal a {
  color: #b8b8b8 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 12px !important;
}
.header-type-10 .menu-wrapper .languages-area .lang_sel_list_horizontal a:hover {
  color: #cda85c;
}
.header-type-10 .menu-wrapper .languages-area .lang_sel_list_horizontal a.lang_sel_sel {
  color: #b8b8b8 !important;
  font-family: 'Roboto', sans-serif;
}
.header-type-10 .menu-wrapper .languages-area .lang_sel_list_horizontal:after {
  content: "";
  display: inline-block;
  float: left;
  left: -20px;
  width: 1px;
  height: 8px;
  background: #ebebeb;
  margin: 6px 12px 0;
}
.header-type-10 .menu-wrapper .languages-area .menu-social-icons {
  margin-bottom: 0;
}
.header-type-10 .menu-wrapper .currency li {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}
.header-type-10 .menu-wrapper .currency li a {
  font-size: 14px;
  color: #aeaeae;
}
.header-type-10 .menu-wrapper .languages li {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
}
.header-type-10 .menu-wrapper .languages li a {
  font-size: 12px;
  color: #aeaeae;
}
.header-type-10 .menu-wrapper .languages li.lastItem:after {
  background-color: #aeaeae;
}
.header-type-10 .menu-wrapper .widget_currency_sel_widget {
  margin-right: 0;
}
.header-type-10 .menu-wrapper .widget_currency_sel_widget:before {
  display: none !important;
}
.header-type-10 .menu-wrapper .widget_currency_sel_widget ul.wcml_currency_switcher li {
  color: #b8b8b8 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 12px !important;
}
.header-type-10 .menu-wrapper .widget_currency_sel_widget ul.wcml_currency_switcher li:hover {
  color: #cda85c;
}
.header-type-10 .navbar-header .top-links {
  padding-top: 11px;
  padding-right: 40px;
}
.header-type-10 .navbar-header .top-links ul li a {
  font-size: 12px;
  font-family: 'Roboto';
  text-transform: uppercase;
}
.header-type-11 .header-logo,
.header-type-12 .header-logo {
  padding-right: 20px;
}
.header-type-11 .navbar-collapse,
.header-type-12 .navbar-collapse {
  float: right;
  border-top: 1px solid #f2f2f2;
}
.header-type-11 .top-links,
.header-type-12 .top-links {
  padding-top: 12px;
  margin-right: 60px;
}
.header-type-11 .top-links li a,
.header-type-12 .top-links li a {
  text-transform: uppercase;
  color: #000;
}
.header-type-11 .top-links li:after,
.header-type-12 .top-links li:after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #dcdcdc;
  margin: 7px 12px 0;
}
.header-type-11 .top-links li:last-child:after,
.header-type-12 .top-links li:last-child:after {
  display: none;
}
.header-type-11 .navbar-collapse {
  width: 100%;
  margin-top: 15px !important;
}
.header-type-11 .navbar-collapse .menu {
  float: right;
  display: inline-block;
}
.header-type-11 .navbar-collapse .menu li {
  float: none;
  display: inline-block;
}
.header-type-11 .header-search .et-search-trigger a span {
  font-size: 0;
  display: none;
}
.header-type-12 .navbar-right .navbar-right {
  margin-bottom: -1px;
}
.header-type-12 .nav-sublist-dropdown {
  border: 1px solid #e6e6e6 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.header-type-12 .nav-sublist-dropdown ul {
  border: none ;
}
.header-type-12 .nav-sublist-dropdown ul li {
  text-align: left;
}
.header-type-12 .nav-sublist-dropdown ul li ul {
  border: 1px solid #e6e6e6;
  top: -16px;
}
.header-type-12 .nav-sublist-dropdown:before {
  display: none;
}
.header-type-12 .nav-sublist-dropdown .container:after {
  display: none;
}
.header-type-12 .shopping-container:hover .cart-popup-container {
  top: 62px;
}
.header-type-12 .navbar-collapse {
  clear: both;
}
.header-type-12 .et-search-trigger .popup-with-form > span {
  display: inline-block;
}
.header-type-12 .cart-bag .ico-sum {
  background-color: #fff;
}
.header-type-12 .cart-bag .ico-sum:before {
  border-color: #fff;
}
.header-type-12 .cart-bag .ico-sum:after {
  background-color: #fff;
}
.header-type-1 .menu > li > a,
.header-type-2 .menu > li > a,
.header-type-3 .menu > li > a,
.header-type-5 .menu > li > a,
.header-type-9 .menu > li > a {
  padding-top: 10px;
  padding-bottom: 21px;
}
.header-type-1 .menu .nav-sublist-dropdown:before,
.header-type-2 .menu .nav-sublist-dropdown:before,
.header-type-3 .menu .nav-sublist-dropdown:before,
.header-type-5 .menu .nav-sublist-dropdown:before,
.header-type-9 .menu .nav-sublist-dropdown:before {
  top: -15px;
}
.header-type-1 .menu .menu-full-width .nav-sublist-dropdown,
.header-type-2 .menu .menu-full-width .nav-sublist-dropdown,
.header-type-3 .menu .menu-full-width .nav-sublist-dropdown,
.header-type-5 .menu .menu-full-width .nav-sublist-dropdown,
.header-type-9 .menu .menu-full-width .nav-sublist-dropdown {
  top: 50px;
}
.header-type-1 .menu .nav-sublist-dropdown,
.header-type-2 .menu .nav-sublist-dropdown,
.header-type-3 .menu .nav-sublist-dropdown,
.header-type-4 .menu .nav-sublist-dropdown,
.header-type-5 .menu .nav-sublist-dropdown,
.header-type-9 .menu .nav-sublist-dropdown {
  top: 50px;
}
/*---------------------------------------------------------------*/
/* Header type 17
/*---------------------------------------------------------------*/
.header-type-17 .header-top {
  width: 100%;
  display: inline-block;
  background-color: #1a1a1a;
  color: #868686;
  padding: 7px 0 6px;
  text-transform: uppercase;
}
.header-type-17 .header-top .header-custom-block a:not(.active) {
  color: #fff;
}
.header-type-17 .header-top .header-custom-block a:hover {
  color: #FFF;
  text-decoration: underline;
}
.header-type-17 .header-top .header-custom-block,
.header-type-17 .header-top .navbar-right {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.header-type-17 .header-top .header-custom-block ul,
.header-type-17 .header-top .navbar-right ul {
  margin-bottom: 0;
}
.header-type-17 .header-top .header-custom-block ul.order-list,
.header-type-17 .header-top .navbar-right ul.order-list {
  margin-bottom: 15px;
}
.header-type-17 .header-top .header-custom-block {
  float: left;
}
.header-type-17 .header-top .header-custom-block p {
  margin-bottom: 0;
}
.header-type-17 .header-top .container > .navbar-right {
  float: right !important;
  line-height: 1.8;
}
.header-type-17 .header-top .header-search,
.header-type-17 .header-top .shopping-container {
  float: left;
  display: inline-block;
  margin-top: 0 !important;
}
.header-type-17 .header-top .shopping-container {
  border-left: 1px solid #595959;
  text-transform: none;
}
.header-type-17 .header-top .shopping-cart-widget {
  border: none;
  float: none;
  padding: 0;
  position: relative;
  min-width: 100px;
  padding-left: 40px;
}
.header-type-17 .header-top .shopping-cart-widget .shop-text {
  color: #868686;
  font-size: inherit;
}
.header-type-17 .header-top .shopping-cart-widget .shop-text a {
  color: #FFF;
}
.header-type-17 .header-top .shopping-cart-widget .cart-bag {
  position: relative;
  top: -2px !important;
  float: left;
  padding: 0 !important;
  margin-top: 0;
}
.header-type-17 .header-top .shopping-cart-widget .badge-number {
  display: none;
}
.header-type-17 .header-top .shopping-cart-widget .total {
  color: #FFF;
  font-size: inherit;
}
.header-type-17 .header-top .header-search {
  margin-right: 30px;
}
.header-type-17 .header-top .header-search .et-search-trigger a:focus {
  text-decoration: none;
}
.header-type-17 .header-top .header-search .et-search-trigger .fa-search {
  width: auto;
  height: auto;
  line-height: 20px;
  color: #868686;
  font-size: 13px;
  background-color: transparent;
}
.header-type-17 .header-top .header-search .et-search-trigger .fa-search:hover,
.header-type-17 .header-top .header-search .et-search-trigger .fa-search:focus {
  text-decoration: none;
}
.header-type-17 .header-top .header-search .et-search-trigger.search-dropdown:hover i:after {
  content: "";
  display: block;
  position: absolute;
  width: 60px;
  height: 46px;
  left: -35px;
  top: 6px;
}
.header-type-17 .header-top .header-search .et-search-trigger.search-dropdown:hover form {
  top: 31px;
}
.header-type-17 .header-top .header-search .et-search-trigger.search-dropdown i {
  font-size: 14px;
  position: relative;
}
.header-type-17 .header-top .header-search span {
  display: inline-block;
  color: #868686;
  font-weight: 600;
}
.header-type-17 .header-top .header-search span:hover {
  text-decoration: underline;
}
.header-type-17 .navmenu-left,
.header-type-17 .navmenu-right {
  width: 35%;
}
.header-type-17 .navmenu-left {
  text-align: right;
}
.header-type-17 .navmenu-left .menu > li {
  float: none;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.header-type-17 .navmenu-left .menu > li .nav-sublist-dropdown ul > li {
  text-align: left;
}
.header-type-17 .header-logo {
  width: 30%;
  text-align: center;
  padding: 0 20px;
}
.header-type-17 .header-logo img {
  max-width: 100%;
  float: none;
}
/*---------------------------------------------------------------*/
/* Header type 18
/*---------------------------------------------------------------*/
.header-type-18 .header-logo {
  text-align: center;
  padding-right: 0;
}
.header-type-18 .header-logo img {
  float: none;
}
.header-type-18 .tbs {
  font-size: 16px;
  color: #868686;
  width: 33.33%;
}
.header-type-18 .menu {
  text-align: center;
  padding: 17px 0;
  margin-bottom: -1px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.header-type-18 .menu .nav-sublist-dropdown ul > li {
  text-align: left;
}
.header-type-18 .menu > li {
  float: none;
  display: inline-block;
}
.header-type-18 .navbar-right {
  width: 33.33%;
}
.header-type-18 .navbar-right .navbar-right {
  width: auto;
}
.header-type-18 .navbar-right .navbar-right .shopping-cart-widget {
  border: none;
}
.header-type-18 .navbar-right .navbar-right .cart-bag {
  position: relative;
  top: 0;
  right: 0;
  float: left;
  padding: 0;
  padding-right: 10px;
  padding-bottom: 6px;
}
.header-type-18 .navbar-right .navbar-right .cart-bag .badge-number {
  display: none;
}
.header-type-18 .navbar-right .navbar-right .shop-text {
  text-transform: none;
  font-size: 16px;
  color: #868686;
  font-weight: 400;
}
.header-type-18 .navbar-right .navbar-right .shop-text .total {
  font-family: inherit;
  font-weight: 400;
  text-decoration: underline;
  font-size: 16px;
  color: #000;
}
/*---------------------------------------------------------------*/
/* Header type vertical / #vertical-header */
/*---------------------------------------------------------------*/
.header-scrolling .header-custom-block {
  position: static !important;
  float: left;
}
/*---------------------------------------------------------------*/
/* Shopping Cart Widget / #cart-widget */
/*---------------------------------------------------------------*/
.shopping-container {
  float: right;
  position: relative;
}
.shopping-container .medium-h {
  border-bottom: 1px solid  #e4e4e4;
}
.shopping-container .small-h {
  font-size: 14px;
  letter-spacing: 1px;
  font-family: 'Roboto';
  color: #252525;
  line-height: 25px;
  text-transform: uppercase;
  border-bottom: none;
}
.shopping-container .big-coast {
  color: #252525;
  font-size: 16px;
  font-family: 'Roboto';
}
.shopping-container .big-coast:hover {
  color: #252525;
}
.shopping-container .btn.border-grey {
  padding: 10px 6px !important;
  margin-bottom: 10px;
  font-size: 13px !important;
  background-color: #B8B8B8;
  border-color: #B8B8B8;
  color: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.shopping-container .btn.border-grey:hover {
  background-color: #cda85c;
  border: 1px solid #cda85c;
  color: #fff;
}
.shopping-container:hover .cart-popup-container {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  top: 55px;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.shopping-cart-widget {
  padding: 10px 15px;
  border: 1px dashed #DFDFDF;
  position: relative;
  float: left;
}
.shop-text {
  font-size: 14px;
  text-transform: uppercase;
  color: #292929;
  float: left;
  margin: 0px 5px 0 0;
}
.total {
  font-size: 14px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
}
.cart-bag {
  position: absolute;
  top: -24px;
  right: 10px;
  padding: 12px 12px 0;
}
.badge-number {
  font-size: 13px;
  color: #000;
  padding: 0 6px;
  line-height: 20px;
  display: block;
  text-align: center;
  background: #E4E4E4;
  border-radius: 10px;
  position: absolute;
  top: -2px;
  right: -6px;
}
.ico-sum {
  width: 18px;
  height: 13px;
  position: relative;
  background-image: none;
  background-color: #D9D9D9;
  padding-bottom: 5px;
  display: block;
  top: 5px;
}
.ico-sum:before {
  content: "";
  position: relative;
  display: block;
  z-index: 1;
  width: 10px;
  height: 10px;
  top: -6px;
  left: 50%;
  background-color: transparent;
  border-color: #D9D9D9;
  border-width: 2px;
  border-style: solid;
  border-radius: 15px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: top 0.3s ease-in-out;
  -moz-transition: top 0.3s ease-in-out;
  transition: top 0.3s ease-in-out;
}
.ico-sum:after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #D9D9D9;
}
.cart-popup-container {
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0.25s;
  -ms-transition: all 0.3s ease 0.25s;
  -o-transition: all 0.3s ease 0.25s;
  transition: all 0.3s ease 0.25s;
  padding: 20px 25px 25px;
  width: 300px;
  background: #fff;
  border: 2px solid #E6E6E6;
  position: absolute;
  top: 65px;
  right: 0;
  -webkit-box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.07);
}
.cart-popup-container p {
  font-size: 14px;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
}
.cart-popup-container .cart-popup .empty {
  margin-bottom: 0;
}
.cart-popup-container:before {
  content: '';
  display: block;
  top: -15px;
  left: 230px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #E6E6E6;
  border-width: 7px 8px;
  margin-left: -6px;
}
.cart-popup-container:after {
  content: "";
  display: block;
  position: absolute;
  top: -32px;
  width: 100%;
  height: 30px;
  right: 0;
}
.cart-popup-container .product_list_widget li.removeble {
  opacity: 0.5;
}
.cart-popup-container .product_list_widget li.removed {
  -webkit-animation: removed-item-animation 0.3s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
  -o-animation: removed-item-animation 0.3s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
  animation: removed-item-animation 0.3s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
}
@keyframes removed-item-animation {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes openspace {
  to {
    height: auto;
  }
}
@-o-keyframes openspace {
  to {
    height: auto;
  }
}
@-webkit-keyframes removed-item-animation {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}
@-o-keyframes removed-item-animation {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}
.cart-summ {
  color: #000;
}
.cart-summ:hover,
.cart-summ:focus {
  color: #000;
  text-decoration: none;
}
.order-list {
  padding: 0;
  list-style: none;
  margin: 0 0 15px;
}
.order-list li {
  width: 100%;
  border-bottom: 1px solid #E4E4E4 !important;
  padding: 10px 0 10px !important;
  position: relative;
}
.order-list .media-heading {
  font-family: 'Roboto';
}
.order-list .media-heading a {
  font-size: 13px;
  color: #252525;
  line-height: 15px;
}
.order-list .media-heading a:hover {
  color: #767676;
}
.order-list .descr-box {
  line-height: 22px;
}
.order-list .descr-box dl {
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.order-list .descr-box dl dt,
.order-list .descr-box dl dd {
  font-size: 12px;
  color: #767676;
  display: block;
  font-weight: 400;
  float: left;
  clear: both;
}
.order-list .descr-box dl dt p,
.order-list .descr-box dl dd p {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
  line-height: 18px;
  font-size: 12px;
  color: #252525;
}
.order-list .descr-box dl dd {
  margin-right: 5px;
  clear: none;
}
.order-list .size {
  font-size: 12px;
  color: #767676;
  display: block;
}
.order-list .media {
  margin-top: 0;
}
.order-list .media-body {
  width: 140px;
}
.order-list .coast {
  color: #000;
}
.medium-coast {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
}
.close-order-li {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -120px -6px;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 18px;
  border: none;
}
.close-order-li:hover {
  opacity: 0.8;
}
.big-coast {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}
.big-coast:hover {
  text-decoration: none;
}
.bottom-btn .btn {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bottom-btn .btn.btn-grey {
  margin-bottom: 3px;
}
.bottom-btn .btn.btn-black:hover {
  background-color: #cda85c;
  border-color: #cda85c;
}
.modal-type-1,
.modal-type-2 {
  position: relative;
}
.modal-type-1 .modal-dialog {
  padding: 36px 10px 33px;
  background-clip: padding-box;
  background-color: #FFFFFF;
  margin: 0 auto;
  width: 600px;
  -webkit-box-shadow: 0 0 0 27px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 27px rgba(255, 255, 255, 0.2);
}
.mini-text {
  font: 13px/20px 'OpenSans_Italic', Arial, Helvetica, sans-serif;
  color: #6e6e6e;
}
.modal-form {
  margin: 40px 0 0;
}
#searchModal {
  width: 680px;
  margin: 0 auto;
  max-width: 85%;
}
#searchModal .large-h:after {
  background-color: #cda85c;
}
#searchModal .btn-black {
  background-color: #cda85c;
  color: #FFF;
}
#searchModal .btn-black:hover {
  background-color: #d9bd83;
}
.has-border .form-control {
  background: #fff;
  border: 1px solid #d5d5d5;
  border-radius: 0;
  box-shadow: none;
}
.has-border > div {
  float: none;
  margin: 0 auto;
}
.bounceInDown {
  -webkit-animation: bounceInDown 1s ease-out;
  -ms-animation: bounceInDown 1s ease-out;
  -o-animation: bounceInDown 1s ease-out;
  animation: bounceInDown 1s ease-out;
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.pm-details {
  float: left;
  width: 50%;
  padding-left: 30px;
}
.modal-type-2 .modal-dialog {
  width: 949px;
  margin: 0 auto 0;
  -webkit-box-shadow: 0 0 0 27px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 27px rgba(255, 255, 255, 0.2);
  padding: 35px 30px 40px 45px;
  background: #fff;
  overflow: hidden;
}
.modal-type-2 .modal-dialog .products-page-cats {
  margin-left: 0;
}
.modal-type-2 .modal-dialog .close {
  top: 30px;
  right: 30px;
}
.modal-type-2 .modal-dialog .pm-details {
  padding-left: 0;
}
.modal-type-2 .modal-dialog .sub-tools {
  margin-bottom: 20px;
}
.pm-image {
  float: left;
  width: 50%;
}
.pm-image img {
  max-width: 100%;
}
.pm-meta {
  padding-bottom: 28px;
}
img {
  height: auto;
  max-width: 100%;
}
.rating {
  float: left;
}
.review {
  float: left;
  padding: 4px 0 0 16px;
  font: 13px/15px 'Roboto', Arial, Helvetica, sans-serif;
}
.review a {
  color: #787878;
}
.review a:hover {
  color: #252525;
}
.xbig-coast {
  font: 30px/30px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #000;
  padding-bottom: 25px;
}
.pm-text {
  font: 13px/24px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #767676;
  margin-bottom: 26px;
}
.pm-filters .ffSelectWrapper {
  margin-bottom: 12px;
}
select.full-filter {
  width: 100%;
  position: relative;
  margin-bottom: 17px;
  background-position: 98% 50%;
}
.reset-filter {
  color: #cda85c;
  font-size: 12px;
  text-align: right;
}
.reset-filter:hover,
.reset-filter:focus {
  color: #767676;
}
.x {
  font-size: 11px;
  text-transform: uppercase;
}
.details-tools {
  padding: 24px 0 0;
}
.gray-diliver {
  background: #ddd;
  margin: 0;
}
.count-p {
  border: 1px solid #000;
  float: left;
}
.count-p .count-number {
  font: 18px/30px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  display: block;
  float: left;
  width: 38px;
  height: 30px;
  text-align: center;
  border-right: 1px solid #000;
}
.count-p .btn-count {
  font: 16px/15px 'Open Sans', Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #000;
  display: block;
  width: 26px;
  height: 15px;
  text-align: center;
  color: #000;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.count-p .btn-count:last-child {
  border-bottom: transparent;
}
.count-p .btn-count:hover,
.count-p .btn-count:focus {
  text-decoration: none;
  background-color: #000;
  color: #FFF;
}
.details-tools .btn-black {
  margin-left: 13px;
  width: 330px;
}
.details-tools .btn-black:hover {
  background-color: #cda85c;
  border-color: #cda85c;
}
.details-tools .border-btn {
  margin-left: 10px;
}
.details-tools p {
  margin-bottom: 0;
}
.details-tools .count-p .count-number {
  height: 38px;
  width: 46px;
  line-height: 38px;
}
.details-tools .count-p .btn-count {
  height: 19px;
  line-height: 19px;
}
.border-btn:hover .ico-like {
  background-position: -153px -33px;
}
.border-btn:hover .ico-next {
  background-position: -172px -33px;
}
.ico-like {
  display: inline-block;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -60px -7px;
  width: 14px;
  height: 12px;
}
.ico-next {
  display: inline-block;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -80px -8px;
  width: 14px;
  height: 11px;
}
.top-tools {
  padding: 20px 0;
}
.bott-tools {
  padding: 15px 0;
}
.bott-tools p {
  margin-top: 7px;
}
.menu-social-icons li {
  float: left;
  margin-right: 14px;
  position: relative;
  list-style: none;
}
.menu-social-icons li a:focus {
  outline: none;
}
.menu-social-icons li a {
  display: block;
}
.menu-social-icons li a:hover .circle {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.menu-social-icons i {
  position: relative;
  z-index: 2;
  font-style: normal;
  font-size: 16px;
  background-color: #252525;
  color: #FFF;
  border-radius: 100%;
  line-height: 26px;
  text-align: center;
}
.menu-social-icons .ico-twitter {
  display: block;
  width: 28px;
  height: 28px;
}
.menu-social-icons .ico-twitter:before {
  content: "\f099";
  font-family: FontAwesome;
}
.menu-social-icons .ico-facebook {
  display: block;
  width: 28px;
  height: 28px;
}
.menu-social-icons .ico-facebook:before {
  content: "\f09a";
  font-family: FontAwesome;
}
.menu-social-icons .ico-pinterest {
  display: block;
  width: 28px;
  height: 28px;
}
.menu-social-icons .ico-pinterest:before {
  content: "\f0d2";
  font-family: FontAwesome;
}
.menu-social-icons .ico-google-plus {
  display: block;
  width: 28px;
  height: 28px;
}
.menu-social-icons .ico-google-plus:before {
  content: "\f0d5";
  font-family: FontAwesome;
}
.menu-social-icons .ico-envelope {
  display: block;
  width: 28px;
  height: 28px;
}
.menu-social-icons .ico-envelope:before {
  content: "\f003";
  font-family: FontAwesome;
}
.menu-social-icons .ico-skype {
  display: block;
  width: 28px;
  height: 28px;
}
.menu-social-icons .ico-skype:before {
  content: "\f17e";
  font-family: FontAwesome;
}
.menu-social-icons .ico-instagram {
  display: block;
  width: 28px;
  height: 28px;
}
.menu-social-icons .ico-instagram:before {
  content: "\f16d";
  font-family: FontAwesome;
}
.menu-social-icons .ico-linkedin {
  display: block;
  width: 28px;
  height: 28px;
}
.menu-social-icons .ico-linkedin:before {
  content: "\f0e1";
  font-family: FontAwesome;
}
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.menu-social-icons .circle {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 1;
  -webkit-transform: scale(0.7, 0.7);
  -o-transform: scale(0.7, 0.7);
  -ms-transform: scale(0.7, 0.7);
  transform: scale(0.7, 0.7);
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
/*=========================================*/
/* Content / #content */
/*=========================================*/
.page-content {
  margin-bottom: 60px;
  margin-top: 30px;
}
/*Revolution Slider / #slider */
.page-heading-slider .wpb_revslider_element {
  overflow: hidden;
  float: left;
  width: 100%;
}
.rev_slider ul {
  padding-left: 0;
}
.rev_slider_wrapper .hesperiden.tparrows {
  background-image: none;
  width: 45px;
  height: 45px;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #fff;
  text-align: center;
  font-size: 0;
  font-style: normal;
  text-indent: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rev_slider_wrapper .hesperiden.tparrows:before {
  font-family: FontAwesome;
  font-size: 30px;
  position: absolute;
  color: #fff;
  line-height: 41px;
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.rev_slider_wrapper .hesperiden.tparrows:hover {
  background-color: #fff !important;
  opacity: 1;
}
.rev_slider_wrapper .hesperiden.tparrows:hover:before {
  color: #252525 !important;
}
.rev_slider_wrapper .hesperiden.tparrows.tp-leftarrow.default {
  left: -40px !important;
  top: 50% !important;
}
.rev_slider_wrapper .hesperiden.tparrows.tp-leftarrow.default:before {
  content: "\f104";
  left: -1px;
}
.rev_slider_wrapper .hesperiden.tparrows.tp-rightarrow.default {
  right: -40px !important;
  top: 50% !important;
}
.rev_slider_wrapper .hesperiden.tparrows.tp-rightarrow.default:before {
  content: "\f105";
  right: -1px;
}
.rev_slider_wrapper:hover .hesperiden.tparrows {
  opacity: 1;
}
.rev_slider_wrapper:hover .hesperiden.tp-leftarrow.default {
  left: 40px !important;
}
.rev_slider_wrapper:hover .hesperiden.tp-rightarrow.default {
  right: 40px !important;
}
.rev_slider_wrapper .type-label-2 {
  width: 80px;
  height: 80px;
  background-color: #cda85c;
  text-align: center;
}
.rev_slider_wrapper .type-label-2 .sale {
  border: none;
  margin: 0;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 6px;
}
.rev_slider_wrapper .type-label-2 span {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
}
.rev_slider_wrapper .type-label-2 span:first-child {
  padding-top: 17px;
}
.hesperiden.tparrows.tp-rightarrow:before {
  content: "\f105" !important;
  font-family: FontAwesome;
  left: 1px;
}
.hesperiden.tparrows.tp-leftarrow:before {
  content: "\f104" !important;
  font-family: FontAwesome;
  left: 1px;
}
/* Banners / #banners */
.banner {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.banner img {
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  height: auto;
}
.banner .ov_hidden {
  position: relative;
}
.banner .ov_hidden:hover img {
  -webkit-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.banner .banner-content {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  margin: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: white;
  position: absolute;
  z-index: 2;
  font-size: 13px;
}
.banner .banner-content h1 {
  width: 100%;
  margin: 10px 0;
}
.banner .banner-content .divider-small {
  width: 44px;
  height: 2px;
  background-color: #FFF;
  margin: 5px 0;
}
.banner .banner-content .divider-big {
  width: 70px;
  height: 6px;
  background-color: #FFF;
  position: relative;
  margin: auto;
}
.banner .banner-content p {
  float: left;
  width: 100%;
  margin: 0;
}
.banner .banner-content h5 {
  font: 25px/25px 'Bodoni_Italic', Georgia, Helvetica, sans-serif;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-transform: none;
}
.banner .banner-content .btn.border-white:hover {
  border: 2px solid #fff;
}
.banner.cursor-pointer {
  cursor: pointer;
}
.banner.align-left {
  text-align: left;
}
.banner.align-left .divider-small {
  float: left;
}
.banner.align-right {
  text-align: right;
}
.banner.align-right .divider-small {
  float: right;
}
.banner.align-center {
  text-align: center;
}
.banner.align-center .divider-small {
  position: relative;
  float: left;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.banner.valign-middle .banner-inner {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner.valign-bottom .banner-inner {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.banner.hover-zoom {
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}
.banner.hover-zoom img {
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}
.banner.hover-zoom:hover {
  background-color: #000;
}
.banner.hover-zoom:hover a {
  text-decoration: none !important;
}
.banner.hover-zoom:hover img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0.75;
}
.banner.hover-reduction {
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}
.banner.hover-reduction img {
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.banner.hover-reduction:hover {
  background-color: #000;
}
.banner.hover-reduction:hover a {
  text-decoration: none !important;
}
.banner.hover-reduction:hover img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 0.75;
}
.banner.hover-fade {
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.banner.hover-fade img {
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  width: 100%;
  opacity: 1;
}
.banner.hover-fade:hover img {
  opacity: 0.8;
  width: 100%;
}
.banner.big-banner h1 {
  font: 40px/40px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: 10px;
}
.banner.big-banner .banner-inner {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner.big-banner .abs-btn {
  bottom: -100px;
}
.banner.responsive-fonts .banner-content {
  zoom: 1;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .banner.responsive-fonts .banner-content {
    zoom: 0.8;
  }
}
.banner-subtitle {
  font-family: Roboto;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.banner-mb15 .banner {
  margin-bottom: 15px;
}
.ov_hidden {
  overflow: hidden;
}
.sm-title-banner {
  width: 308px;
  min-height: 160px;
  margin-top: -80px;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -154px;
  z-index: 1;
  text-align: center;
}
.arr-right {
  height: 0;
  width: 0;
  border: solid transparent;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #cda85c;
  margin-bottom: -1px;
  border-width: 6px 5px;
  display: inline-block;
}
.md-title-banner {
  position: absolute;
  width: 300px;
  top: 62%;
  left: 50%;
  margin-left: -150px;
  margin-top: -90px;
  z-index: 1;
  text-align: center;
}
.md-title-banner a:hover {
  text-decoration: none;
}
.big-banner .abs-btn {
  position: absolute;
  text-align: center;
  bottom: 20px;
  left: 0;
  width: 100%;
  margin-top: 140px;
  z-index: 2;
}
.big-banner img {
  width: auto;
}
.big-banner .md-title-banner {
  margin-top: -185px;
}
.open-video-popup {
  position: relative;
  display: block;
  overflow: hidden;
}
.open-video-popup img {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.open-video-popup:before {
  content: "";
  background-image: url(https://terminal21.co.th/images/video_homepage.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  margin-left: -35px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}
.open-video-popup:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.open-video-popup:hover:after {
  opacity: 1;
}
.open-video-popup:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
/* Products Carousel / #carousel */
.pagination-slider,
.owl-controls {
  position: absolute;
  right: 0;
  top: 15px;
  font-size: 0;
}
.pagination-slider .owl-next,
.owl-controls .owl-next,
.pagination-slider .owl-prev,
.owl-controls .owl-prev {
  background: none;
  width: 31px;
  position: relative;
  height: 31px;
  border: 1px solid #eee;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.pagination-slider .owl-next:hover,
.owl-controls .owl-next:hover,
.pagination-slider .owl-prev:hover,
.owl-controls .owl-prev:hover {
  background-color: #252525;
  border-color: #252525;
}
.pagination-slider .owl-next:hover:before,
.owl-controls .owl-next:hover:before,
.pagination-slider .owl-prev:hover:before,
.owl-controls .owl-prev:hover:before {
  color: #FFF;
}
.pagination-slider .owl-next:before,
.owl-controls .owl-next:before {
  content: "\f105";
  position: absolute;
  font-size: 20px;
  font-family: FontAwesome;
  color: #252525;
  left: 12px;
  top: 0px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.pagination-slider .owl-prev:before,
.owl-controls .owl-prev:before {
  content: "\f104";
  position: absolute;
  font-size: 20px;
  font-family: FontAwesome;
  color: #252525;
  left: 10px ;
  top: 0px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.related-posts .owl-controls {
  position: absolute;
  right: 0;
  top: 15px;
  font-size: 0;
}
.related-posts .owl-controls .owl-next,
.related-posts .owl-controls .owl-prev {
  width: 31px !important;
  height: 31px !important;
  border: 1px solid #eee !important;
}
.related-posts .owl-controls .owl-next:hover,
.related-posts .owl-controls .owl-prev:hover {
  border: 1px solid #252525 !important;
}
.related-posts .owl-controls .owl-next:after,
.related-posts .owl-controls .owl-prev:after {
  display: none;
}
.related-posts .owl-controls .owl-next:before {
  font-size: 20px !important;
  left: 12px !important;
}
.related-posts .owl-controls .owl-prev:before {
  font-size: 20px !important;
  left: 10px !important;
}
.carousel-area {
  position: relative;
}
.carousel-area .owl-controls {
  position: static;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.carousel-area .owl-controls .owl-prev {
  position: absolute;
  left: -50px;
  top: 32%;
  width: 45px;
  height: 45px;
  border-color: #555;
}
.carousel-area .owl-controls .owl-prev:before {
  font-size: 30px;
  left: 15px;
}
.carousel-area .owl-controls .owl-prev:after {
  content: "";
  position: absolute;
  width: 88px;
  height: 300px;
  left: -20px;
  top: -140px;
}
.carousel-area .owl-controls .owl-prev.disabled {
  opacity: 0.4;
  background-color: transparent !important;
  cursor: default;
}
.carousel-area .owl-controls .owl-prev.disabled:before {
  color: #252525;
}
.carousel-area .owl-controls .owl-next {
  position: absolute;
  right: -50px;
  top: 32%;
  width: 45px;
  border-color: #555;
  height: 45px;
}
.carousel-area .owl-controls .owl-next:before {
  font-size: 30px;
  left: 17px;
}
.carousel-area .owl-controls .owl-next:after {
  content: "";
  position: absolute;
  width: 88px;
  height: 300px;
  left: -20px;
  top: -140px;
}
.carousel-area .owl-controls .owl-next.disabled {
  opacity: 0.4;
  background-color: transparent !important;
  cursor: default;
}
.carousel-area .owl-controls .owl-next.disabled:before {
  color: #252525;
}
.carousel-area.categoriesCarousel .owl-prev,
.carousel-area.categoriesCarousel .owl-next {
  top: 50%;
  margin-top: -22px;
}
.carousel-area:hover .owl-controls {
  opacity: 1;
}
.owl-next {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -97px -56px #fff;
  width: 12px;
  height: 25px;
  display: inline-block;
}
.owl-next:focus {
  outline: none;
}
.owl-prev {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -82px -56px #fff;
  width: 12px;
  height: 25px;
  display: inline-block;
  margin-right: 5px;
}
.owl-prev:focus {
  outline: none;
}
#product-pager .owl-next {
  width: 35px;
  height: 100%;
  display: inline-block;
  background: none;
  border: none !important;
  border-radius: 0;
}
#product-pager .owl-next:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -12px;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -97px -56px;
  width: 12px;
  height: 25px;
  left: 12px;
  display: block;
}
#product-pager .owl-next:focus {
  outline: none;
}
#product-pager .owl-prev {
  width: 35px;
  height: 100%;
  display: inline-block;
  background: none;
  border: none !important;
  border-radius: 0;
}
#product-pager .owl-prev:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -12px;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -82px -56px;
  width: 12px;
  height: 25px;
  right: 12px;
  display: block;
}
#product-pager .owl-prev:focus {
  outline: none;
}
#product-pager .owl-controls {
  position: static;
}
.slide-item .product-type-variable {
  position: relative;
}
.slide-item .product-type-variable .btn:active {
  position: relative;
  top: 1px;
  right: -1px;
}
/*---------------------------------------------------------------*/
/* Products Grid / #products-grid */
/*---------------------------------------------------------------*/
.products-grid .product-excerpt {
  display: none;
}
.products-grid .product-title {
  font: 16px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #000;
  margin: 0 0 15px;
}
.product-category {
  text-align: center;
}
/*Nth-child for products (Disable Responsive) */
.vc_non_responsive .row-count-2 .product:nth-child(2n+1),
.vc_non_responsive .row-count-3 .product:nth-child(3n+1),
.vc_non_responsive .row-count-4 .product:nth-child(4n+1),
.vc_non_responsive .row-count-5 .product:nth-child(4n+1),
.vc_non_responsive .row-count-6 .product:nth-child(5n+1) {
  clear: both;
}
.vc_non_responsive .row-count-2 .product {
  width: 50%;
}
.vc_non_responsive .row-count-4 .product {
  width: 25%;
}
.vc_non_responsive .row-count-5 .product {
  width: 20%;
}
.vc_non_responsive .sidebar-position-without .row-count-4 .product {
  width: 25%;
}
.vc_non_responsive .sidebar-position-without .row-count-5 .product,
.vc_non_responsive .sidebar-position-without .row-count-6 .product {
  width: 194px;
}
.vc_non_responsive .sidebar-position-without .row-count-5 .product:nth-child(5n+1) {
  clear: both;
}
.vc_non_responsive .sidebar-position-without .row-count-5 .product:nth-child(4n+1) {
  clear: none;
}
/*Nth-child for products */
@media only screen and (min-width: 980px) and (max-width: 1200px) {
  .row-count-2 .product:nth-child(2n+1) .row-count-3 .product:nth-child(3n+1),
  .row-count-4 .product:nth-child(3n+1),
  .row-count-5 .product:nth-child(3n+1),
  .row-count-6 .product:nth-child(3n+1) {
    clear: both;
  }
  .row-count-3 .product:nth-child(3n+1),
  .sidebar-position-without .row-count-3 .product:nth-child(3n+1) {
    clear: both;
  }
  /*---------------------------------------------------------------*/
  /*
	/* Styles for social widget.
	/*
	 */
  /*---------------------------------------------------------------*/
  .et-follow-buttons.buttons-size-large a {
    width: 34px;
    height: 34px;
    line-height: 35px;
    font-size: 18px;
  }
}
@media only screen and (min-width: 980px) {
  .row-count-2 .product {
    width: 50%;
  }
  .row-count-2 .product:nth-child(2n+1) {
    clear: both;
  }
  .filter-wrap .woocommerce-result-count {
    display: none;
  }
  .shop_table.wishlist_table td.product-name {
    width: 240px !important;
  }
  .shop_table.wishlist_table td,
  .shop_table.wishlist_table th {
    padding: 15px 8px !important;
  }
  .product-information .cart button[type="submit"] {
    width: 230px !important;
  }
  #product-pager {
    width: 315px !important;
  }
  .sidebar-position-without .product-category,
  .products-grid .product-category {
    width: 33.3% !important;
  }
  .owl-carousel .product-category {
    width: 100% !important;
  }
  .row-count-2.products-grid .product-category {
    width: 50% !important;
  }
  .row-count-5 .product-category:nth-child(5n+1),
  .row-count-6 .product-category:nth-child(5n+1) {
    clear: none !important;
  }
  .vc_non_responsive .menu .menu-column5 .nav-sublist-dropdown {
    width: 960px !important;
  }
  .vc_non_responsive .menu .menu-column2 .nav-sublist-dropdown {
    width: 422px !important;
  }
  .vc_non_responsive .menu .menu-column2 .nav-sublist-dropdown ul li:nth-child(2n+1) {
    clear: both !important;
  }
  .vc_non_responsive .menu .menu-column2.with-image .nav-sublist-dropdown {
    width: 422px !important;
  }
  .vc_non_responsive .menu .menu-column3 .nav-sublist-dropdown {
    width: 600px !important;
  }
  .vc_non_responsive .menu .menu-column3 .nav-sublist-dropdown ul li:nth-child(3n+1) {
    clear: both;
  }
  .vc_non_responsive .menu .menu-column4 .nav-sublist-dropdown {
    width: 778px !important;
  }
  .vc_non_responsive .menu .menu-column4 .nav-sublist-dropdown ul li:nth-child(4n+1) {
    clear: both;
  }
  .vc_non_responsive .menu .menu-column5 .nav-sublist-dropdown {
    width: 960px !important;
  }
  .vc_non_responsive .menu .menu-column5 .nav-sublist-dropdown ul li:nth-child(5n+1) {
    clear: both;
  }
  .vc_non_responsive .menu .demo-column4 .nav-sublist-dropdown,
  .vc_non_responsive .menu .demo-column4-2 .nav-sublist-dropdown {
    width: 990px !important;
  }
  .vc_non_responsive .menu .demo-column5 .nav-sublist-dropdown {
    width: 1150px !important;
  }
  .vc_non_responsive .menu .demo-column-without-img .nav-sublist-dropdown {
    width: 760px !important;
  }
  .vc_non_responsive .menu .menu-column2.with-image .nav-sublist-dropdown {
    width: 590px !important;
  }
  .vc_non_responsive .menu .menu-column3.with-image .nav-sublist-dropdown {
    width: 770px !important;
  }
  .vc_non_responsive .menu .menu-column4.with-image .nav-sublist-dropdown {
    width: 960px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  @-ms-viewport {
    width: device-width;
  }
  .row-count-2 .product:nth-child(2n+1),
  .row-count-3 .product:nth-child(3n+1),
  .row-count-4 .product:nth-child(3n+1),
  .row-count-5 .product:nth-child(3n+1),
  .row-count-6 .product:nth-child(3n+1) {
    clear: both;
  }
  .row-count-2 .product {
    width: 50%;
  }
  .sidebar-position-without .product-category,
  .products-grid .product-category {
    width: 33.3% !important;
  }
  .owl-carousel .product-category {
    width: 100% !important;
  }
  .row-count-2.products-grid .product-category {
    width: 50% !important;
  }
}
@media only screen and (max-width: 767px) {
  @-ms-viewport {
    width: device-width;
  }
  .row-count-2 .product:nth-child(2n+1),
  .row-count-3 .product:nth-child(2n+1),
  .row-count-4 .product:nth-child(2n+1),
  .row-count-5 .product:nth-child(2n+1),
  .row-count-6 .product:nth-child(2n+1) {
    clear: both !important;
  }
  .row-count-2.products-grid .product-category {
    width: 50% !important;
  }
}
@media only screen and (max-width: 480px) {
  @-ms-viewport {
    width: device-width;
  }
  .product {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .row-count-2.products-grid .product-category {
    width: 100% !important;
  }
}
/* Category banner */
.category-description {
  margin-bottom: 30px;
}
/*---------------------------------------------------------------*/
/* Loader */
/*---------------------------------------------------------------*/
#floatingCirclesG {
  background: #fff url(https://terminal21.co.th/images/loading.gif) no-repeat center center;
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -30px;
  z-index: 10000;
  width: 60px;
  height: 60px;
}
.products-list #floatingCirclesG {
  width: 50px;
  left: 50%;
  margin-left: -25px;
}
/*---------------------------------------------------------------*/
/* 404 Page */
/*---------------------------------------------------------------*/
.page-404 {
  text-align: center;
  padding-bottom: 90px;
  display: inline-block;
  width: 100%;
}
.page-404 h1.largest {
  padding-top: 60px;
  line-height: 72px;
}
.page-404 h1 {
  margin-bottom: 0;
}
.page-404 p {
  display: inline-block;
  width: 100%;
}
.page-404 .form-horizontal {
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  margin-bottom: 30px;
}
.page-404 .form-group.has-border {
  width: 300px;
  display: inline-block;
  margin: 0;
}
.page-404 .form-group.has-border > div {
  width: 300px;
}
.page-404 .form-button {
  width: auto;
  float: none;
  margin: 0;
  display: inline-block;
}
.page-404 .form-button button {
  height: 39px;
}
.page-404 .button.medium {
  line-height: 40px !important;
  height: 40px;
  padding: 0 28px !important;
  font-size: 13px;
  letter-spacing: 1px;
}
/*---------------------------------------------------------------*/
/* Products List / #products-list */
/*---------------------------------------------------------------*/
.products-list .product {
  width: 100% !important;
  text-align: left;
}
.products-list .product .product-image-wrapper {
  float: left;
  margin-right: 30px;
  margin-bottom: 2em;
  width: 35%;
}
.products-list .product .product-image-wrapper .hover-mask {
  display: none;
}
.products-list .product .product-details {
  text-align: left !important;
  width: 58%;
  float: left;
}
.products-list .product .product-details .products-page-cats {
  margin: 6px 0 2px 0;
}
.products-list .product .product-details .product-title {
  font: 23px/25px 'Roboto', sans-serif;
  color: #000;
  margin: 0 0 9px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.products-list .product .product-details .price {
  font: 30px/30px 'Roboto', sans-serif;
}
.products-list .product .product-details .price del {
  font-size: 20px;
}
.products-list .product .product-details .price ins {
  text-decoration: none;
}
.products-list .product .product-details .product-excerpt {
  font: 13px/24px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #767676;
  margin-bottom: 26px;
}
.products-list .product .product-details .product-excerpt span {
  color: #767676 !important;
}
.row-count-4.products-list {
  width: 100%;
}
.product-image-wrapper.hover-effect-swap img.show-image {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.product-image-wrapper.hover-effect-swap img {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.product-image-wrapper.hover-effect-swap:hover img.show-image + img {
  opacity: 0;
}
.product-image-wrapper.hover-effect-swap:hover img.show-image {
  opacity: 1;
}
.products-grid .product-image-wrapper.hover-effect-mask,
.product-slide .product-image-wrapper.hover-effect-mask {
  position: relative;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 38, 38, 0.7);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-align: center;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .label-product,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .label-product {
  display: block;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  padding: 20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .product-title a,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .product-title a {
  color: #FFF;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .price,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .price {
  color: #FFF;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .woocommerce-price-suffix,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .woocommerce-price-suffix {
  width: 100%;
  color: #eee;
  display: inline-block;
  margin-top: 10px;
  position: relative;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"],
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"] {
  color: #FFF;
  -webkit-transition: all 0.2s ease-in-out !important;
  -moz-transition: all 0.2s ease-in-out !important;
  -ms-transition: all 0.2s ease-in-out !important;
  -o-transition: all 0.2s ease-in-out !important;
  transition: all 0.2s ease-in-out !important;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"] .ico-smallpacket,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"] .ico-smallpacket {
  background-position: -116px -90px;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"]:hover,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"]:hover {
  border-color: #fff;
  background-color: #FFF;
  color: #000;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"]:hover .ico-smallpacket,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"]:hover .ico-smallpacket {
  background-position: -63px -61px;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"] .progress-inner,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"] .progress-inner {
  background: #000;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"].state-success,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"].state-success {
  background-color: transparent;
  border-color: transparent;
  font-size: 0;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"].state-success:hover,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"].state-success:hover {
  background-color: transparent;
  border-color: transparent;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"].state-success .content:after,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button[data-style="shrink"].state-success .content:after {
  background-color: transparent;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .btn.border-grey,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .btn.border-grey {
  color: #FFF;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .btn.border-grey .ico-smallpacket,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .btn.border-grey .ico-smallpacket {
  background-position: -116px -90px;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .btn.border-grey:hover,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .btn.border-grey:hover {
  border-color: #fff;
  background-color: #FFF;
  color: #000;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .btn.border-grey:hover .ico-smallpacket,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .btn.border-grey:hover .ico-smallpacket {
  background-position: -63px -61px;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button.state-success .content,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .mask-content .progress-button.state-success .content {
  width: 30px;
  height: 13px;
}
.products-grid .product-image-wrapper.hover-effect-mask .hover-mask .out-stock,
.product-slide .product-image-wrapper.hover-effect-mask .hover-mask .out-stock {
  display: none;
}
.products-grid .product-image-wrapper.hover-effect-mask .footer-product,
.product-slide .product-image-wrapper.hover-effect-mask .footer-product {
  z-index: 100;
  background-color: rgba(63, 63, 63, 0.8);
}
.products-grid .product-image-wrapper.hover-effect-mask .footer-product .yith-wcwl-add-to-wishlist,
.product-slide .product-image-wrapper.hover-effect-mask .footer-product .yith-wcwl-add-to-wishlist {
  width: 50%;
  text-align: center;
  border-right: 1px solid #6e6e6e;
}
.products-grid .product-image-wrapper.hover-effect-mask .footer-product .show-quickly,
.product-slide .product-image-wrapper.hover-effect-mask .footer-product .show-quickly {
  width: 50%;
  text-align: center;
}
.products-grid .product-image-wrapper.hover-effect-mask:hover .hover-mask,
.product-slide .product-image-wrapper.hover-effect-mask:hover .hover-mask {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
.products-grid .product-image-wrapper.hover-effect-mask + .product-details,
.product-slide .product-image-wrapper.hover-effect-mask + .product-details {
  display: none;
}
.project-navigation .prev-project:hover a,
.project-navigation .next-project:hover a {
  color: #fff;
}
/* Toolbar */
.filter-wrap {
  border-top: 1px solid #000;
  border-bottom: 1px solid #DDD;
  padding: 15px 0 4px 0;
  margin-bottom: 30px;
  position: relative;
  float: left;
  width: 100%;
}
.filter-wrap a.btn {
  float: left;
  margin-bottom: 14px;
}
.filter-wrap .view-switcher {
  float: left;
}
.filter-wrap .view-switcher label {
  display: none;
}
.filter-wrap .view-switcher .switchToGrid,
.filter-wrap .view-switcher .switchToList {
  float: left;
  margin-left: 8px;
  margin-bottom: 14px;
  border: 1px solid #c1c1c1;
  width: 33px;
  height: 33px;
  line-height: 15px;
  color: #000;
  cursor: pointer;
  text-align: center;
  -webkit-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  font-size: 14px;
}
.filter-wrap .view-switcher .switchToGrid:hover,
.filter-wrap .view-switcher .switchToList:hover {
  background: #fff;
  color: #cda85c;
}
.filter-wrap .view-switcher .switchToGrid:hover .ico-grid,
.filter-wrap .view-switcher .switchToList:hover .ico-grid {
  background-position: -190px -32px;
}
.filter-wrap .view-switcher .switchToGrid .fa-bars,
.filter-wrap .view-switcher .switchToList .fa-bars {
  margin: 9px 0;
}
.filter-wrap .view-switcher .switchToGrid .icon-th-large {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 9px 0;
}
.filter-wrap .view-switcher .switchToGrid .icon-th-large:before {
  content: "\f009";
  font-family: FontAwesome;
  font-style: normal;
  font-size: 16px;
}
.filter-wrap .view-switcher .switchToList .icon-th-list {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 9px 0;
  font-style: normal;
  position: relative;
  top: -1px;
}
.filter-wrap .view-switcher .switchToList .icon-th-list:before {
  content: "\f0c9";
  font-family: FontAwesome;
}
.filter-wrap .woocommerce-message,
.filter-wrap .woocommerce-error {
  margin-top: 20px;
}
.filter-wrap .woocommerce-ordering {
  float: left;
  margin-right: 30px;
}
.filter-wrap .woocommerce-ordering select {
  height: 33px;
  line-height: 17px;
  background-position: 95% 50%;
  padding: 7px 30px 7px 9px;
}
.filter-wrap .woocommerce-result-count {
  float: left;
  font: 14px/15px 'Open Sans', Arial, sans-serif;
  color: #000;
  margin: 9px 30px 0 0;
}
.filter-wrap .pagination-cubic {
  float: right;
  margin-bottom: 8px;
  text-align: right;
}
.filter-wrap #st-trigger-effects {
  margin-right: 20px;
}
/* .product-image */
.product-image-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.product-image-wrapper:hover .footer-product {
  -webkit-transform: translateY(0) !important;
  -moz-transform: translateY(0) !important;
  -ms-transform: translateY(0) !important;
  -o-transform: translateY(0) !important;
  transform: translateY(0) !important;
}
.product-image-wrapper:hover .sm-arrow {
  display: block;
}
.label-product {
  text-align: center;
  z-index: 1;
}
.label-product > div {
  position: absolute;
  z-index: 1000;
}
.type-label-1 {
  top: 7px;
  right: 10px;
  background: rgba(121, 121, 121, 0.9);
  color: #fff;
  border-radius: 100px;
  width: 68px;
  height: 68px;
  text-transform: uppercase;
  font: 12px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 700;
}
.type-label-1 .new {
  margin-top: 23px;
  padding: 2px 0;
  display: inline-block;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  letter-spacing: 1px;
}
.type-label-2 {
  top: 7px;
  left: 10px;
  background: rgba(205, 168, 92, 0.9);
  color: #fff;
  border-radius: 100px;
  width: 68px;
  height: 68px;
  text-transform: uppercase;
  font: 12px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 700;
}
.type-label-2 .sale {
  padding: 4px 0;
  display: inline-block;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-top: 22px;
}
.type-label-2 .big-T {
  font-size: 18px;
  border: none;
  display: block;
}
.product-content-image {
  position: relative;
  z-index: 2;
  display: block;
  text-align: center;
}
.product-content-image img {
  max-width: 100%;
}
.out-stock {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -44px 0 0 -44px;
  width: 88px;
  height: 88px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 100px;
  text-align: center;
}
.out-stock .wr-c {
  padding: 5px 0 0 0;
  border-top: 1px solid #A5A5A4;
  border-bottom: 1px solid #A5A5A4;
  font: 12px/20px 'Roboto', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 24px;
  color: #515151;
}
.out-stock .wr-c .bigT {
  font-size: 24px;
  color: #000;
  border: none;
}
.sm-arrow,
.product-images-slider {
  margin-bottom: 20px;
  position: relative;
}
.sm-arrow .owl-controls,
.product-images-slider .owl-controls {
  position: static;
}
.sm-arrow .owl-controls .owl-buttons div,
.product-images-slider .owl-controls .owl-buttons div {
  position: absolute;
  z-index: 3;
  font-size: 0;
  top: 0;
  width: 30px;
  height: 100%;
  display: block;
  cursor: pointer;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.sm-arrow .owl-controls .owl-buttons div:before,
.product-images-slider .owl-controls .owl-buttons div:before {
  content: "" !important;
}
.sm-arrow .product-image,
.product-images-slider .product-image {
  cursor: default;
}
.sm-arrow {
  position: absolute;
  z-index: 3;
  font-size: 0;
  width: 30px;
  height: 100%;
  display: none;
  cursor: pointer;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.sm-arrow:before {
  content: "" !important;
}
.product-image-wrapper:hover .sm-arrow {
  display: block;
}
.arrow-left,
.product-images-slider .owl-controls .owl-prev {
  left: 0;
}
.arrow-left:hover,
.product-images-slider .owl-controls .owl-prev:hover {
  background: rgba(0, 0, 0, 0.05);
}
.arrow-right,
.product-images-slider .owl-controls .owl-next {
  right: 0;
}
.arrow-right:hover,
.product-images-slider .owl-controls .owl-next:hover {
  background: rgba(0, 0, 0, 0.05);
}
.arrow-left:after,
.product-images-slider .owl-controls .owl-prev:after {
  content: '';
  display: block;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat 0 -56px;
  width: 12px;
  height: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -12px;
  margin-left: -6px;
}
.arrow-right:after,
.product-images-slider .owl-controls .owl-next:after {
  content: '';
  display: block;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -15px -56px;
  width: 12px;
  height: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -12px;
  margin-left: -6px;
}
.product-images-slider .owl-item {
  background: url(https://terminal21.co.th/images/AjaxLoader.gif) no-repeat center center;
  min-height: 200px;
}
.footer-product {
  position: absolute;
  -webkit-transform: translateY(35px);
  -moz-transform: translateY(35px);
  -ms-transform: translateY(35px);
  -o-transform: translateY(35px);
  transform: translateY(35px);
  bottom: 0;
  height: 35px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background: #000;
  background: rgba(26, 26, 26, 0.85);
  color: #fff;
  z-index: 4;
  width: 100%;
  padding: 8px 0 9px;
}
.footer-product a {
  color: #fff;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 20px;
  font-size: 11px;
}
.footer-product a:hover {
  text-decoration: none;
}
.footer-product span {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 21px;
}
.footer-product span:hover {
  text-decoration: none;
}
.footer-product .clear {
  display: none;
}
.footer-product .yith-wcwl-add-button {
  margin-left: 6px;
}
.sidebar-position-left .row-count-4 .product .footer-product .yith-wcwl-add-to-wishlist,
.sidebar-position-right .row-count-4 .product .footer-product .yith-wcwl-add-to-wishlist,
.sidebar-position-without .row-count-5 .product .footer-product .yith-wcwl-add-to-wishlist {
  width: 50%;
  text-align: center;
  font-size: 0;
}
.sidebar-position-left .row-count-4 .product .footer-product .yith-wcwl-add-to-wishlist a,
.sidebar-position-right .row-count-4 .product .footer-product .yith-wcwl-add-to-wishlist a,
.sidebar-position-without .row-count-5 .product .footer-product .yith-wcwl-add-to-wishlist a {
  font-size: 0;
}
.sidebar-position-left .row-count-4 .product .footer-product .yith-wcwl-add-to-wishlist a:before,
.sidebar-position-right .row-count-4 .product .footer-product .yith-wcwl-add-to-wishlist a:before,
.sidebar-position-without .row-count-5 .product .footer-product .yith-wcwl-add-to-wishlist a:before {
  margin: 0 -20px -1px 9px;
}
.sidebar-position-left .row-count-4 .product .footer-product .show-quickly,
.sidebar-position-right .row-count-4 .product .footer-product .show-quickly,
.sidebar-position-without .row-count-5 .product .footer-product .show-quickly {
  width: 50%;
  text-align: center;
  margin-right: 0;
  font-size: 0;
}
.sidebar-position-left .row-count-4 .product .footer-product .show-quickly:before,
.sidebar-position-right .row-count-4 .product .footer-product .show-quickly:before,
.sidebar-position-without .row-count-5 .product .footer-product .show-quickly:before {
  margin: 2px 20px 0 0;
}
.sidebar-position-left .row-count-5 .product .footer-product a,
.sidebar-position-right .row-count-5 .product .footer-product a,
.row-count-6 .product .footer-product a,
.sidebar-position-left .row-count-5 .product .show-quickly,
.sidebar-position-right .row-count-5 .product .show-quickly,
.row-count-6 .product .show-quickly {
  font-size: 0;
}
.sidebar-position-left .row-count-5 .product .footer-product a:before,
.sidebar-position-right .row-count-5 .product .footer-product a:before,
.row-count-6 .product .footer-product a:before,
.sidebar-position-left .row-count-5 .product .show-quickly:before,
.sidebar-position-right .row-count-5 .product .show-quickly:before,
.row-count-6 .product .show-quickly:before {
  font-size: 14px;
}
.sidebar-position-left .row-count-5 .product .show-quickly,
.sidebar-position-right .row-count-5 .product .show-quickly,
.row-count-6 .product .show-quickly,
.sidebar-position-left .row-count-5 .product .yith-wcwl-add-to-wishlist,
.sidebar-position-right .row-count-5 .product .yith-wcwl-add-to-wishlist,
.row-count-6 .product .yith-wcwl-add-to-wishlist {
  width: 50%;
  text-align: center;
  margin: 0 !important;
  cursor: pointer;
}
.sidebar-position-left .row-count-5 .product .show-quickly,
.sidebar-position-right .row-count-5 .product .show-quickly,
.row-count-6 .product .show-quickly {
  position: relative;
  top: 2px;
}
.sidebar-position-left .row-count-5 .product .yith-wcwl-add-to-wishlist a,
.sidebar-position-right .row-count-5 .product .yith-wcwl-add-to-wishlist a,
.row-count-6 .product .yith-wcwl-add-to-wishlist a {
  position: relative;
  right: -10px;
}
.sidebar-position-left .row-count-5 .product .show-quickly,
.sidebar-position-right .row-count-5 .product .show-quickly,
.row-count-6 .product .show-quickly {
  position: relative;
  left: -10px;
}
.yith-wcwl-add-to-wishlist {
  float: left;
  margin-top: 0;
}
.yith-wcwl-add-to-wishlist .yith-wcwl-add-button img {
  width: 0 !important;
  height: 0 !important;
  display: block;
}
.yith-wcwl-add-to-wishlist .yith-wcwl-add-button img:before {
  content: "";
  position: relative;
  width: 40px;
  height: 40px;
  background-image: url(https://terminal21.co.th/images/new-loader.gif);
  background-repeat: no-repeat;
  background-position: center center;
}
.yith-wcwl-add-to-wishlist .yith-wcwl-add-button .button.alt {
  padding-left: 35px;
}
.yith-wcwl-add-to-wishlist .yith-wcwl-add-button .button.alt:before {
  left: -1px;
  top: 7px;
}
.yith-wcwl-add-to-wishlist .feedback {
  display: none;
}
body #yith-wcwl-popup-message {
  display: none !important;
}
.yith-wcwl-add-to-wishlist a:before {
  content: '';
  display: inline-block;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -152px -30px;
  width: 15px;
  height: 15px;
  margin: 0 3px -1px 3px;
}
.show-quickly {
  float: right;
  text-transform: uppercase;
  padding-right: 10px;
}
.show-quickly:before {
  content: '';
  display: inline-block;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -46px -62px;
  width: 13px;
  height: 13px;
  margin: 0 7px -2px 0;
}
.show-quickly:hover {
  text-decoration: underline;
  cursor: pointer;
}
.footer-product .show-quickly {
  color: #fff;
}
.wishlist-disabled .show-quickly {
  float: none;
  text-align: center;
  display: block;
  width: 100%;
}
.products-page-cats {
  font: 12px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 14px 14px 0 14px;
}
.products-page-cats a {
  color: #cda85c;
  letter-spacing: 2px;
}
.products-page-cats a:hover {
  color: #767676;
}
.product-title {
  font: 16px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #000;
  margin: 14px 0 15px;
}
.slide-item .product-excerpt a:hover {
  color: #767676;
}
.slide-item .product {
  padding: 0 10px;
}
.price {
  font: 18px/20px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
  margin: 0 0 11px;
  display: block;
}
.added-text {
  position: absolute;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Roboto';
  color: #fff;
  background-color: rgba(139, 182, 24, 0.9) !important;
  padding: 20px 15px 20px 50px;
  top: 25%;
  margin: 15px;
  left: 0;
  text-align: left;
  z-index: 1000;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.added-text:before {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -12px;
  background-image: url(https://terminal21.co.th/images/sprite/icons.png);
  background-position: -112px -60px;
  background-repeat: no-repeat;
}
/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0;
}
/*---------------------------------------------------------------*/
/* Products Sidebar / #products-sidebar
/*---------------------------------------------------------------*/
.price_slider_wrapper {
  margin-bottom: 50px;
}
.price_slider_wrapper .price_slider_amount {
  margin-top: 20px;
}
.price_slider_wrapper .price_slider_amount button {
  float: right;
  padding: 6px 25px;
}
.price_slider_wrapper .price_slider_amount button:hover {
  text-decoration: none;
}
.price_slider_wrapper .ui-slider .ui-slider-handle:last-child {
  margin-left: -5px;
}
.price_slider_wrapper .price_label {
  position: relative;
  top: 10px;
  display: inline-block;
}
.widget_layered_nav ul {
  margin-top: 5px;
}
.widget_layered_nav li {
  border-bottom: 1px solid #E9E9E9;
  position: relative;
}
.widget_layered_nav li a {
  font: 13px/18px 'Roboto', sans-serif;
  padding: 15px 0 15px 36px;
  text-transform: uppercase;
  color: #767676;
  display: block;
}
.widget_layered_nav li a:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 10px;
  margin-top: -6px;
  border: 1px solid #D9D9D9;
  display: inline-block;
}
.widget_layered_nav li .count {
  font: 13px/15px 'Roboto', sans-serif;
  color: #767676;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7px;
  cursor: pointer;
}
.widget_layered_nav li.chosen a:after {
  content: "";
  position: absolute;
  width: 13px;
  height: 11px;
  top: 50%;
  left: 10px;
  margin-top: -12px;
  margin-left: 2px;
  background-image: url(https://terminal21.co.th/images/check-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.widget_layered_nav li.firstItem a {
  padding-top: 0;
}
.widget_layered_nav li.firstItem a:before {
  margin-top: -13px;
}
.widget_layered_nav li.firstItem a:after {
  margin-top: -12px;
}
.widget_layered_nav li.firstItem .count {
  margin-top: -15px;
}
.single-product-sidebar .product-brands {
  text-align: center;
}
.single-product-sidebar .product-brands a {
  font-size: 22px;
}
.widget_layered_nav_filters ul {
  display: inline-block;
}
.widget_layered_nav_filters ul li {
  float: left;
}
.widget_layered_nav_filters ul li a {
  display: block;
  padding: 6px 30px 6px 12px;
  margin: 6px 6px 0 0;
  background-color: #bbb;
  color: #FFF;
  font-family: 'Roboto';
  font-size: 12px;
  position: relative;
}
.widget_layered_nav_filters ul li a:before {
  content: "";
  background-image: url(https://terminal21.co.th/images/delete.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 20px;
  height: 100%;
  background-color: #adadad;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.widget_layered_nav_filters ul li a:hover:before {
  background-color: #333;
}
/* Fullwidth Video Section */
.section-video-mask {
  background: url("https://terminal21.co.th/images/bg-dotted.png") repeat rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.et_section {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 68px 0 48px;
  background: #000;
}
.et_section.et-section-video {
  height: 430px;
}
.section-back-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.et-section-video {
  width: 100%;
}
.et-video-content {
  text-align: center;
  position: absolute;
  float: left;
  top: 50%;
  width: 100%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.et-video-inner {
  text-align: center;
}
.et-video-inner .ico-brand {
  margin: 0 10px -25px;
}
.ico-brand {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -1px -86px;
  width: 99px;
  height: 99px;
  display: inline-block;
}
.et-brands-slider {
  margin-bottom: 0;
}
.et-brands-slider .et-brand {
  text-align: center;
}
.brandCarousel .slide-item {
  margin: 0 25px;
}
.brandCarousel img {
  max-width: 100%;
}
.brandCarousel .et-brand {
  text-align: center;
}
.brandCarousel .et-brand img {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
}
.brandCarousel .et-brand:hover img {
  opacity: 0.6;
}
.et-brand img {
  border: 1px solid #e8e8e8;
}
.brand-c {
  margin-bottom: 70px;
}
.brand-c .category-product {
  margin: 0 0 35px;
}
/* brandGrid */
.et-brands-grid .et-brand {
  text-align: center;
  width: 25%;
  display: inline-block;
  padding: 0 20px 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.et-brands-grid.columns-number-2 .et-brand {
  width: 50%;
}
.et-brands-grid.columns-number-3 .et-brand {
  width: 33.3%;
}
.et-brands-grid.columns-number-4 .et-brand {
  width: 25%;
}
.et-brands-grid.columns-number-5 .et-brand {
  width: 20%;
}
.et-brands-grid.columns-number-6 .et-brand {
  width: 16.6%;
}
.sidebar-position-left .sidebar-right {
  display: none !important;
}
.sidebar-position-right .sidebar-left {
  display: none !important;
}
.sidebar-position-without .sidebar {
  display: none !important;
}
.et-search-result {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.et-search-result ul {
  margin-bottom: 20px;
}
.et-search-result ul li {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
.et-search-result ul li:first-child {
  padding-top: 0;
}
.sidebar.col-md-2 .widget_search .form-group.form-button {
  width: 100%;
  text-align: center;
}
.sidebar.col-md-2 .widget_search .form-group.form-button .btn {
  margin-top: 10px;
}
.sidebar.col-md-2 .widget_search .form-group.has-border {
  width: 100%;
}
.sidebar.col-md-2 .widget-title {
  line-height: 20px;
}
.sidebar.col-md-3 .widget-search .form-group.form-button {
  width: 100%;
  text-align: right;
}
.sidebar.col-md-3 .widget-search .form-group.form-button .btn {
  margin-top: 10px;
}
.sidebar.col-md-3 .widget-search .form-group.has-border {
  width: 100%;
}
.sidebar.col-md-3 .widget-title {
  line-height: 20px;
}
.sidebar.col-md-3 .etheme_widget_search input[type="text"] {
  margin-bottom: 10px;
}
.etheme_widget_search input[type="text"] {
  margin-bottom: 10px;
}
.sidebar-widget,
.sidebar-slider {
  margin-bottom: 20px;
  position: relative;
}
.sidebar-widget .widget-title,
.sidebar-slider .widget-title {
  border-top: 1px solid #000;
  border-bottom: 1px solid #ddd;
  margin: 0 0 22px;
  padding: 20px 0 20px;
  text-align: left;
}
.sidebar-widget .widget-title span,
.sidebar-slider .widget-title span {
  padding-left: 0;
}
.sidebar-widget .widget-title:after,
.sidebar-slider .widget-title:after {
  height: 0;
}
.sidebar-widget .blog-post-list .media-heading a,
.sidebar-slider .blog-post-list .media-heading a {
  color: #252525;
}
.sidebar-widget .blog-post-list .date-event,
.sidebar-slider .blog-post-list .date-event {
  background-color: #cda85c;
  outline-color: #fff;
  border-color: #cda85c;
  cursor: default !important;
}
.sidebar-widget .blog-post-list li,
.sidebar-slider .blog-post-list li {
  border-bottom: 1px solid #E9E9E9;
}
.sidebar-widget .blog-post-list .media-body strong,
.sidebar-slider .blog-post-list .media-body strong {
  color: #000;
}
.sidebar-widget .owl-carousel,
.sidebar-slider .owl-carousel {
  position: static;
}
.sidebar-widget .product_list_widget li:last-child,
.sidebar-slider .product_list_widget li:last-child {
  border-bottom: none;
}
.sidebar-widget.widget_product_categories .widget-title,
.sidebar-slider.widget_product_categories .widget-title {
  margin-bottom: 0;
}
.wpb_widgetised_column_heading {
  border-top: 1px solid #000;
  border-bottom: 1px solid #ddd;
  margin: 0 0 22px;
  padding: 20px 0 20px;
  font-size: 16px;
  line-height: 1em;
  text-align: left;
}
.widget_product_search .screen-reader-text {
  width: 100%;
}
.widget_product_search .search-field {
  height: 34px;
  color: #333;
  font-size: 13px;
  border: 1px solid #d5d5d5;
  padding: 10px;
}
.widget_product_search .search-field:focus {
  border-color: #252525;
}
.widget_product_search input[type="submit"] {
  height: 34px;
}
.widget_product_categories #dropdown_product_cat {
  width: 100%;
  line-height: 20px;
}
.sidebar-widget.null-instagram-feed {
  text-align: center;
}
.sidebar-widget.null-instagram-feed li {
  margin-bottom: 0.7142em;
  padding: 0 3px;
}
.sidebar-widget.null-instagram-feed .instagram-size-thumbnail li {
  width: 33.333%;
  padding: 0 0.3571em;
  display: inline-block;
}
.sidebar-widget.null-instagram-feed .instagram-size-small li {
  width: 50%;
  display: inline-block;
  padding: 0 0.3571em;
}
.sidebar-widget.null-instagram-feed .instagram-slider li {
  width: 100% !important;
  padding: 0 3px !important;
}
.sidebar-widget.null-instagram-feed .owl-controls .owl-next,
.sidebar-widget.null-instagram-feed .owl-controls .owl-prev {
  line-height: 28px;
}
.wpb_widgetised_column .widget-container.null-instagram-feed .instagram-size-small li {
  width: 25%;
}
.wpb_widgetised_column .widget-container.null-instagram-feed .instagram-size-thumbnail li {
  width: 12.5%;
}
.wpb_widgetised_column .widget-container.null-instagram-feed .instagram-size-large li {
  width: 33.33%;
  padding: 0 0.3571em;
}
.wpb_widgetised_column .widget-container.null-instagram-feed .instagram-no-space li {
  padding: 0;
  margin: 0;
}
.widget_shopping_cart .product_list_widget > p {
  border-bottom: 1px solid #cbcbcb;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.widget_shopping_cart .product_list_widget .media > .pull-left {
  padding: 0;
}
.widget_shopping_cart img {
  max-width: 65px;
}
.widget_shopping_cart .close-order-li {
  top: 25px;
}
.widget_shopping_cart .small-h.pull-left {
  text-transform: uppercase;
  color: #333;
  font-size: 14px;
}
.widget_shopping_cart .bottom-btn a {
  padding: 9px 26px !important;
  line-height: 15px !important;
  font-size: 13px;
  border: 1px solid #cbcbcb !important;
  color: #000;
}
.widget_shopping_cart .bottom-btn a:hover {
  background-color: #FFF;
  color: #000 !important;
}
.etheme_widget_brands li {
  border-top: 1px solid #e9e9e9;
}
.etheme_widget_brands li a {
  padding: 17px 0;
  display: block;
}
.etheme_widget_brands li a strong {
  font-weight: normal;
  color: #cda85c;
}
.etheme_widget_brands li.firstItem {
  border-top: none;
}
.etheme_widget_brands li.firstItem a {
  padding-top: 0;
}
.etheme_widget_brands li.lastItem {
  border-bottom: 1px solid #e9e9e9;
}
.widget_search {
  display: inline-block;
  width: 100%;
}
.widget_search .form-group {
  margin: 0 !important;
  display: inline-block;
}
.widget_search .form-group.has-border {
  width: 138px;
}
.widget_search .form-group.has-border input {
  height: 34px;
  border-color: #d5d5d5;
  color: #333;
  font-size: 13px;
}
.widget_search .form-group.has-border input:focus {
  border-color: #252525;
}
.widget_search .form-group.form-button {
  float: right;
}
.widget_search .form-group .col-xs-10 {
  width: 100% !important;
  padding: 0;
}
.widget_search .form-group button {
  height: 34px;
}
.widget_search .modal-form {
  margin-top: 0;
}
.etheme_widget_search {
  display: inline-block;
  width: 100%;
}
.etheme_widget_search input[type="text"],
.etheme_widget_search input[type="email"],
.etheme_widget_search input[type="password"] {
  display: inline-block;
  height: 34px;
}
.etheme_widget_search .button {
  height: 34px;
  margin-top: 0px;
  float: right;
}
.blog-post-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog-post-list a:focus {
  text-decoration: none;
}
.blog-post-list .media > .pull-left {
  margin: 0 20px 0 0;
}
.blog-post-list .media > .pull-left:hover {
  text-decoration: none;
}
.blog-post-list .media-heading {
  font: 16px/24px 'Roboto', Arial, Helvetica, sans-serif;
  margin: 5px 0 12px;
  color: #252525;
}
.blog-post-list .media-body {
  font: 13px/24px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #6e6e6e;
}
.blog-post-list .media-body strong {
  font-weight: 400 !important;
}
.blog-masonry .post {
  margin-bottom: 40px;
  margin-left: -1px;
}
.blog-masonry .post .wp-picture {
  margin-bottom: 0;
}
.blog-masonry .post h6.active {
  margin-top: 20px;
}
.date-event {
  display: block;
  font: 13px/15px 'Roboto', Arial, Helvetica, sans-serif;
  color: #fff;
  padding: 11px 7px;
  border: 4px solid #cda85c;
  outline: 1px solid #fff;
  text-transform: uppercase;
  outline-offset: -4px;
  background: #cda85c;
  text-align: center;
}
.date-event .number {
  font-size: 21px;
  padding-bottom: 5px;
  margin-bottom: 3px;
  border-bottom: 2px solid #fff;
  display: block;
}
.read-more {
  font: 12px/15px 'Roboto', Arial, Helvetica, sans-serif;
  color: #252525;
  text-transform: uppercase;
  margin-top: 18px;
  display: inline-block;
}
.read-more:hover {
  color: #cda85c;
}
.read-more:after {
  content: "\f105";
  display: inline-block;
  font-family: FontAwesome;
  color: #FFF;
  width: 6px;
  font-size: 14px;
  height: 100%;
  margin-left: 6px;
  position: relative;
  left: 7px;
}
.teaser-box {
  padding: 30px 30px 27px;
  border: 15px solid #eeeeee;
  margin-bottom: 40px !important;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  color: #767676;
  line-height: 24px;
}
.teaser-box h3 {
  font: 25px/26px 'Bodoni', Georgia, Helvetica, sans-serif;
  color: #252525;
  padding-bottom: 16px;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  margin: 0 0 16px;
}
.teaser-box h3:after {
  content: '';
  display: block;
  width: 64px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -32px;
  background: #cda85c;
  height: 2px;
}
.teaser-box h4 {
  font: 25px/26px 'Bodoni', Georgia, Helvetica, sans-serif;
  color: #252525;
}
.teaser-box strong {
  color: #252525;
}
.teaser-box .form-control {
  height: 40px;
  padding: 5px 12px;
}
.teaser-box:hover {
  border: 15px solid #ddd;
}
.mc4wp-form {
  margin: 10px 0 0;
  padding: 0;
  text-align: center;
}
.mc4wp-form small {
  font: 13px/20px 'OpenSans_Italic', Arial, Helvetica, sans-serif;
  color: #6e6e6e;
}
.mc4wp-form input[type="email"] {
  width: 85%;
  height: 40px;
  margin: 0 auto;
  margin-top: 10px;
  padding: 5px 12px;
  margin-bottom: 15px;
}
.mc4wp-form input[type="submit"] {
  color: #fff;
  border: none;
  background-color: #cda85c;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 12px;
  padding: 9px 28px;
  line-height: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  -webkit-apearance: none;
  -moz-apearance: none;
}
.mc4wp-form input[type="submit"]:hover {
  opacity: 0.7;
}
.mailchimp-inline small {
  display: none;
}
.mailchimp-inline input#mc4wp_email {
  float: left;
  width: 70%;
  margin-top: 0;
}
.mailchimp-inline input[type="submit"] {
  float: right;
  height: 40px;
  width: 25%;
  letter-spacing: 2px;
  opacity: 0.7 !important;
}
.mailchimp-inline input[type="submit"]:hover {
  opacity: 1 !important;
}
.mailchimp-white input[type="submit"] {
  background-color: #FFF !important;
  color: #262626;
  opacity: 0.9;
}
.mailchimp-white input[type="submit"]:hover {
  color: #262626 !important;
  opacity: 1 !important;
  text-decoration: underline;
}
.mailchimp-white input[type="email"] {
  border: 1px solid #fff !important;
  color: #262626 !important;
  margin-right: -10px;
  position: relative;
  left: 10px;
}
.empty-category-block h2 {
  text-align: center;
  font-family: 'Bodoni';
  margin-top: 150px;
  font-size: 32px;
  margin-bottom: 20px;
}
.empty-category-block h2:before {
  content: "";
  text-align: center;
  color: #000;
  width: 73px;
  height: 72px;
  bottom: 165px;
  margin-left: -36px;
  left: 50%;
  position: absolute;
  background-image: url(https://terminal21.co.th/images/empty-search.png);
  background-position: center center;
}
.empty-category-block p {
  text-align: center;
}
.empty-category-block .btn {
  position: relative;
  padding-left: 30px;
}
.empty-category-block .btn:before {
  content: "\f104";
  position: absolute;
  left: 15px;
  font-size: 14px;
  top: 50%;
  margin-top: -8px;
  font-family: FontAwesome;
}
.header-variants-page li {
  margin-bottom: 40px;
  list-style: none;
}
.header-variants-page li span {
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.et-twitter-slider {
  text-align: center;
  color: #FFF;
}
.et-twitter-slider .owl-controls {
  position: static;
}
.et-twitter-slider .owl-controls .owl-prev,
.et-twitter-slider .owl-controls .owl-next {
  position: absolute;
  top: 50%;
  background-image: none;
  width: 45px;
  height: 45px;
  margin-top: -35px;
  background-color: transparent;
  border: 2px solid #fff;
  text-align: center;
  font-size: 0;
  text-indent: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.et-twitter-slider .owl-controls .owl-prev:before,
.et-twitter-slider .owl-controls .owl-next:before {
  font-family: FontAwesome;
  font-size: 30px;
  position: absolute;
  color: #fff;
  line-height: 41px;
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.et-twitter-slider .owl-controls .owl-prev:hover,
.et-twitter-slider .owl-controls .owl-next:hover {
  background-color: #FFF;
}
.et-twitter-slider .owl-controls .owl-prev:hover:before,
.et-twitter-slider .owl-controls .owl-next:hover:before {
  color: #252525;
}
.et-twitter-slider .owl-controls .owl-next {
  right: -45px;
}
.et-twitter-slider .owl-controls .owl-next:before {
  content: "\f105";
  right: -1px;
  left: auto;
}
.et-twitter-slider .owl-controls .owl-prev {
  left: -45px;
}
.et-twitter-slider .owl-controls .owl-prev:before {
  content: "\f104";
  left: -1px;
}
.et-twitter-slider:hover .owl-next,
.et-twitter-slider:hover .owl-prev {
  opacity: 1;
}
.et-twitter-slider .et-tweet {
  font-size: 24px;
  line-height: 30px;
  padding: 0 40px;
  list-style: none;
}
.et-twitter-slider .et-tweet a {
  color: #cda85c;
}
.et-twitter-slider .et-tweet a:hover {
  text-decotation: underline !important;
}
.et-twitter-slider .twitter-slider-title {
  margin-bottom: 50px;
  font-size: 24px;
}
.et-twitter-slider .twitter-slider-title span {
  position: relative;
}
.et-twitter-slider .twitter-slider-title span:before {
  content: "\f099";
  font-size: 22px;
  color: #FFF;
  position: absolute;
  font-family: FontAwesome;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -40px;
}
.et-twitter-slider .twitter-info {
  font-size: 14px;
  margin-top: 10px;
}
.widget-wrap {
  margin: 0 0 46px;
}
.widget-title,
.footer-top .title {
  font-size: 16px;
  line-height: 20px;
  font-family: Roboto;
  color: #000;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1em;
}
.widget-title:after,
.footer-top .title:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  top: 8px;
  left: 0;
  position: absolute;
  background: #000;
  z-index: 1;
}
.widget-title span,
.footer-top .title span {
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 0 18px;
  text-transform: uppercase;
}
.footer-top .wpb_heading {
  font-size: 16px;
  font-weight: 400;
}
.footer-top .carousel-area .title {
  border-top: none;
  padding: 0px 0 15px 0;
  padding-top: 26px;
}
.star-rating {
  font-size: 0;
  width: 91px;
  height: 14px;
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
  background-image: url(https://terminal21.co.th/images/rating-inactive.png);
  background-repeat: no-repeat;
  background-position: 0 0;
}
.star-rating span {
  position: absolute;
  left: 0;
  top: 0;
  height: 14px;
  background-image: url(https://terminal21.co.th/images/rating-active.png);
  background-repeat: no-repeat;
  background-position: 0 0;
}
.product_list_widget {
  margin: 0;
  padding: 0;
  list-style: none;
}
.product_list_widget li {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}
.product_list_widget .media > .pull-left {
  margin-right: 15px;
  padding: 5px;
  border: 1px solid #ddd;
}
.product_list_widget .media-heading {
  margin: 0 0 8px;
}
.product_list_widget .media-heading a {
  font: 14px/18px 'Roboto', Arial, Helvetica, sans-serif;
}
.product_list_widget .media-heading a:hover {
  color: #767676;
}
.product_list_widget .rating {
  margin: 7px 0 0;
}
.product_list_widget .small-coast .amount {
  color: #cda85c;
}
.product_list_widget .small-coast del .amount {
  color: #000;
}
.product_list_widget .small-coast .woocommerce-price-suffix .amount {
  color: #767676;
}
.small-coast {
  font: 14px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
  display: block;
}
.small-coast del {
  font-size: 14px;
}
.default-link {
  color: #cda85c;
}
.default-link:hover,
.default-link:focus {
  color: #767676;
}
.twitter-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.twitter-list li {
  padding: 22px 0 28px;
  border-bottom: 1px solid #ddd;
}
.twitter-list li a {
  color: #cda85c;
}
.twitter-list li a:hover {
  color: #767676;
}
.twitter-list .media > .pull-left {
  margin-right: 16px;
  margin-top: 5px;
  font-size: 20px;
}
.twitter-list .media-body {
  font: 12px/23px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #6e6e6e;
}
.twitter-list .fa-twitter {
  color: #222;
}
.stripped-zone {
  font: 23px/11px 'Bodoni_Italic', Arial, Helvetica, sans-serif;
  color: #000;
  width: 90px;
  padding: 8px 0;
  background: url(https://terminal21.co.th/images/bg-striped.png) repeat;
  display: block;
  float: left;
  text-align: center;
  margin-right: 7px;
}
.fa-star-o {
  font-size: 18px;
  color: #a5a5a5;
}
.alert-message {
  font: 20px/22px 'Roboto', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.alert-message .fa-star-o {
  margin: 0 15px;
}
/* Widgets above shop page content */
.content .sidebar-widget {
  float: left;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.content .sidebar-widget ul {
  padding-left: 0;
}
.content .sidebar-widget ul li {
  list-style: none;
}
.content .sidebar-widget.widget_product_search label {
  float: left;
  width: 100%;
}
.content .sidebar-widget.widget_product_search input {
  float: left;
  margin-right: 20px;
}
.content .sidebar-widget.widget_product_search input[type="submit"] {
  height: 38px;
}
.content .sidebar-widget.widget_layered_nav ul {
  display: inline-block;
  width: 100%;
  margin-top: 0;
}
.content .sidebar-widget.widget_layered_nav li {
  width: 48%;
  display: inline-block;
}
.content .sidebar-widget.widget_layered_nav li:nth-child(2n+1) {
  clear: both;
  margin-right: 10px;
}
/* Shop Fullwidth */
.shop-filters-area .sidebar-widget {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.shop-filters-area .sidebar-widget > .widget-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 70px;
  right: 0 !important;
  padding-right: 10px;
  bottom: 0;
  width: 100%;
}
.shop-filters-area .sidebar-widget > .widget-content:focus {
  outline: none;
}
.shop-filters-area .sidebar-widget > .widget-content::-webkit-scrollbar {
  display: none !important;
}
.shop-filters-area .sidebar-widget::-webkit-scrollbar {
  display: none !important;
}
.shop-filters-area .nano-pane {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 5px;
  right: 0;
  top: 70px;
  bottom: 0;
  height: 150px;
  visibility: hidden;
  /* Target only IE7 and IE8 with this hack */
  opacity: .1;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.shop-filters-area .nano-pane > .nano-slider {
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 0 1px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.sidebar-widget:hover .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
  visibility: visible;
  /* Target only IE7 and IE8 with this hack */
  opacity: 0.99;
}
.shop-filters-area {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
}
.shop-filters-area .sidebar-widget {
  min-height: 190px;
  margin: 0 1%;
  flex: 1 1 23%;
  padding: 0;
  margin-bottom: 35px;
}
.shop-filters-area .sidebar-widget:first-child {
  padding-left: 0;
  margin-left: 0;
}
.shop-filters-area .sidebar-widget:last-child {
  padding-right: 0;
  margin-right: 0;
}
.shop-filters-area .sidebar-widget ul {
  padding-left: 0;
}
.shop-filters-area .sidebar-widget.widget_layered_nav li {
  width: 100%;
}
.shop-filters-area .widget_layered_nav .widget-title {
  margin-bottom: 15px;
}
.shop-filters-area .widget_layered_nav li a {
  padding-bottom: 15px;
  padding-top: 14px;
  line-height: 25px;
}
.shop-filters-area .widget_layered_nav li:first-child a {
  padding-top: 0;
}
.shop-filters-area .product_list_widget li {
  margin-right: 10px;
  padding: 10px 0;
}
.shop-filters-area .product_list_widget li img {
  width: 35px;
}
.shop-filters-area .product_list_widget li .media-heading a {
  font-size: 13px;
}
.shop-filters-area .product_list_widget li .small-coast {
  font-size: 15px;
}
.shop-filters-area .product_list_widget li .rating {
  display: none;
}
.shop-filters-area .price_slider_wrapper .ui-slider .ui-slider-handle:last-child {
  margin-left: -10px;
}
.shop-full-width .category-description {
  text-align: center;
}
.shop-full-width .products-grid {
  margin: 0 15px;
}
.shop-full-width .products-grid.row-count-2 .product {
  width: 50%;
}
.shop-full-width .products-grid.row-count-2 .product img {
  width: 100%;
}
.shop-full-width .products-grid.row-count-3 .product {
  width: 33.33%;
}
.shop-full-width .products-grid.row-count-3 .product img {
  width: 100%;
}
.shop-full-width .products-grid.row-count-4 .product {
  width: 25%;
}
.shop-full-width .products-grid.row-count-4 .product img {
  width: 100%;
}
.shop-full-width .products-grid.row-count-4 .product:nth-child(3n+1) {
  clear: none !important;
}
.shop-full-width .products-grid.row-count-5 .product {
  width: 20%;
}
.shop-full-width .products-grid.row-count-5 .product img {
  width: 100%;
}
.shop-full-width .products-grid.row-count-5 .product:nth-child(3n+1) {
  clear: none !important;
}
.shop-full-width .products-grid.row-count-6 .product {
  width: 16.66%;
}
.shop-full-width .products-grid.row-count-6 .product img {
  width: 100%;
}
.shop-full-width .products-grid.row-count-6 .product:nth-child(3n+1) {
  clear: none !important;
}
.shop-full-width .filter-wrap {
  padding: 0 30px;
  border-bottom: none;
  border-top: none;
}
.shop-full-width .filter-wrap .filter-content {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #ddd;
}
/*=====================================*/
/*=====================================*/
.page-heading {
  font-size: 13px !important;
}
.page-heading .title {
  font-size: 24px;
  font-style: normal;
  color: #252525;
  margin: 0 auto;
  text-transform: uppercase;
  width: 70%;
  border: none;
  padding: 0;
  text-align: center;
}
.page-heading .delimeter {
  color: #333;
  font-size: 13px !important;
}
.page-heading .hidden-tooltip {
  float: right;
  display: none;
  top: -60px;
  right: 65px;
}
.page-heading .hidden-tooltip .open-tooltip i {
  -webkit-animation: tooltip-color-white 1.5s ease-in infinite;
  -moz-animation: tooltip-color-white 1.5s ease-in infinite;
  -ms-animation: tooltip-color-white 1.5s ease-in infinite;
  animation: tooltip-color-white 1.5s ease-in infinite;
}
.page-heading .hidden-tooltip i {
  border: 1px solid #fff;
}
.page-heading .hidden-tooltip i:hover {
  border: 1px solid #fff !important;
  color: #FFF;
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}
.page-heading .hidden-tooltip .btn-close i {
  border: none;
}
.page-heading .hidden-tooltip .tooltip-content {
  right: 100%;
}
.page-heading .hidden-tooltip .tooltip-content .tooltip_inner {
  border: none;
  text-align: left;
}
.page-heading.bc-type-5 .hidden-tooltip,
.page-heading.bc-type-3 .hidden-tooltip {
  display: block;
}
.page-heading.bc-type-9 {
  display: none;
}
.page-heading #breadcrumb {
  font-size: 13px !important;
}
.page-heading a,
.page-heading .woocommerce-breadcrumb {
  font-size: 13px !important;
}
.page-heading.bc-type-2 {
  text-align: left;
  background: url(https://terminal21.co.th/images/bg-light-strippped.png) repeat;
}
.page-heading.bc-type-2 .title {
  width: auto;
  text-align: left;
}
.bc-type-1 {
  background: url(https://terminal21.co.th/images/bg-light-strippped.png) repeat;
}
.bc-type-3,
.bc-type-4,
.bc-type-5,
.bc-type-6 {
  padding: 50px 0 !important;
  background-attachment: fixed;
  border-bottom: none !important;
}
.bc-type-3 .title,
.bc-type-4 .title,
.bc-type-5 .title,
.bc-type-6 .title {
  font-family: 'Bodoni_Regular';
  font-size: 44px;
  line-height: 42px;
}
.page-heading.bc-type-4,
.page-heading.bc-type-6 {
  text-align: left;
}
.page-heading.bc-type-4 .title,
.page-heading.bc-type-6 .title {
  width: auto;
  text-align: left;
}
.page-heading.bc-type-5,
.page-heading.bc-type-6 {
  background-attachment: fixed !important;
}
/* Breadcrumbs type 7 & 8 */
.page-heading.bc-type-7,
.page-heading.bc-type-8 {
  padding: 100px 0;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-bottom: none;
}
.page-heading.bc-type-7 .title,
.page-heading.bc-type-8 .title {
  line-height: 1em;
}
.page-heading.bc-type-8 {
  padding: 180px 0 140px;
}
.page-heading.bc-type-8 .breadcrumbs {
  color: #666;
}
.page-heading.bc-type-8 .breadcrumbs a:hover {
  text-decoration: underline;
}
@media (min-width: 992px) {
  .breadcrumbs-type-8:not(.home) .header-wrapper {
    position: absolute;
    width: 100%;
  }
}
.section-breadcrumbs p.font-alt {
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  font-family: 'OpenSans', sans-serif !important;
}
.section-breadcrumbs p.size-x2 {
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
  font-family: 'Bodoni_Regular';
}
.post h2 {
  font: 20px/24px 'Roboto', Georgia, Helvetica, sans-serif;
  color: #000;
  margin: 0;
  text-transform: uppercase;
}
.wp-picture {
  position: relative;
  text-align: center;
}
.wp-picture img {
  max-width: 100%;
}
.page-heading {
  padding: 20px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: center;
}
.page-heading .container > .row {
  position: relative;
}
.breadcrumbs {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-top: 4px;
}
.breadcrumbs .prev-history a {
  color: #252525;
}
.breadcrumbs li {
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.breadcrumbs li a {
  color: #cda85c;
}
.breadcrumbs li a:hover {
  color: #767676;
}
.breadcrumbs li:after {
  content: '/';
  font-size: 13px;
  margin: 0 5px 0 7px;
}
.breadcrumbs li.lastItem:after {
  display: none;
}
.back-history {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -9px;
  font-size: 13px !important;
}
.back-history:hover,
.back-history:focus {
  color: #727272;
  text-decoration: none !important;
}
.back-history:before {
  content: "\f104";
  font-family: FontAwesome;
  font-size: 9px;
  margin: 0 9px 0 0;
  position: relative;
  top: -1px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.header-vertical-custom {
  display: none;
}
.header-vertical-enable .content-page {
  margin-bottom: 0;
  margin-top: 0;
}
.header-vertical-enable .content-page .page-content {
  margin-top: 0;
  margin-bottom: 0;
}
.header-vertical-enable .header-vertical-custom {
  display: block;
}
.shopping-page {
  margin-top: 17px;
  margin-bottom: 13px;
}
.store-page {
  margin-top: 20px;
  margin-bottom: 55px;
}
article.post h2 {
  margin-bottom: 20px;
}
.meta-post {
  margin: 4px 0 0;
  position: relative;
  color: #767676;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  margin-bottom: 28px;
}
.meta-post a {
  font: 12px/15px 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 500;
}
.meta-post a:hover {
  color: #767676;
}
.meta-post:after {
  content: '';
  display: block;
  width: 70px;
  height: 3px;
  background: #E5E5E5;
  position: absolute;
  bottom: -17px;
  left: 0;
}
.content-article {
  color: #767676;
  font: 13px/24px 'Open Sans', Arial, Helvetica, sans-serif;
}
.content-article p {
  margin-bottom: 28px;
}
.iframe-preload {
  position: relative;
}
.iframe-preload iframe {
  position: relative;
  z-index: 3;
}
.iframe-preload:after {
  content: '';
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 2.5em rgba(0, 0, 0, 0.75);
  height: 2.5em;
  left: 50%;
  margin: -1.25em 0 0 -1.25em;
  padding: 0.625em;
  position: absolute;
  top: 50%;
  width: 2.5em;
  z-index: 1;
  border-radius: 50%;
}
.iframe-preload:before {
  content: '';
  display: block;
  animation: 0.5s ease 0s normal none infinite imagelightbox-loading;
  background-color: #FFFFFF;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 1.25em;
  width: 1.25em;
  z-index: 2;
  border-radius: 50%;
  margin: -0.6em 0 0 -0.6em;
}
@-moz-keyframes imagelightbox-loading {
  0% {
    opacity: 0.5;
    transform: scale(0.75);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.5;
    transform: scale(0.75);
  }
}
@-moz-keyframes imagelightbox-loading {
  0% {
    opacity: 0.5;
    transform: scale(0.75);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.5;
    transform: scale(0.75);
  }
}
.share-post {
  padding-left: 21px;
  border: 1px solid #E5E5E5;
  margin: 30px 0 20px;
  overflow: hidden;
}
.share-post .share-title {
  float: left;
  font: 14px/15px 'Roboto' sans-serif;
  color: #000;
  margin: 0;
  margin-top: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.share-post .menu-social-icons {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
}
.share-post .menu-social-icons li {
  margin-right: 0;
  float: left;
}
.share-post .menu-social-icons li a {
  display: block;
  width: 43px;
  height: 43px;
  color: #a0a0a0;
  text-align: center;
  line-height: 43px;
  border-left: 1px solid #E5E5E5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.share-post .menu-social-icons li a:hover,
.share-post .menu-social-icons li a:focus {
  text-decoration: none;
  color: #555;
}
.share-post .menu-social-icons i {
  background: none;
  width: 42px;
  height: 42px;
  line-height: 42px;
  color: #a0a0a0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.share-post .menu-social-icons i:hover {
  color: #555;
  text-decoration: none;
}
.share-post .menu-social-icons .fa-circle,
.share-post .menu-social-icons svg {
  display: none;
}
.menu-social-icons.larger {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-social-icons.larger li {
  margin: 10px;
  float: left;
}
.menu-social-icons.larger li a {
  display: block;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  color: #222;
  background-color: #dbdbdb;
  line-height: 40px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.menu-social-icons.larger li a:hover,
.menu-social-icons.larger li a:focus {
  text-decoration: none;
  color: #555;
  background-color: #cda85c;
}
.menu-social-icons.larger li a:hover i,
.menu-social-icons.larger li a:focus i {
  color: #FFF;
}
.menu-social-icons.larger i {
  width: auto !important;
  height: auto !important;
  color: #fff;
  font-size: 22px !important;
  line-height: 39px !important;
  background-color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.menu-social-icons.larger i:hover {
  text-decoration: none;
}
.menu-social-icons.larger .fa-circle,
.menu-social-icons.larger svg {
  display: none;
}
.menu-social-icons.larger.white li a i {
  background-color: transparent;
  color: #FFF;
}
.comments {
  margin: 60px 0 0;
  float: left;
  width: 100%;
}
.comments .tab-pane {
  margin-bottom: 23px;
  float: left;
}
.author-info {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 60px;
  position: relative;
  float: left;
  width: 100%;
}
.author-info p {
  margin-top: 10px;
}
.author-info .zlarge-h {
  margin-bottom: 30px;
}
.author-info .avatar {
  border: 1px solid #E5E5E5;
  margin-right: 20px;
}
.author-info .media-heading {
  font: 14px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 25px;
  display: block;
  position: relative;
}
.author-info .media-heading:hover {
  color: #767676;
}
.author-info .media-heading:after {
  content: '';
  display: block;
  width: 70px;
  height: 3px;
  background: #E5E5E5;
  position: absolute;
  bottom: -17px;
  left: 0;
}
.author-info .media-body {
  color: #767676;
  font-size: 13px;
  line-height: 24px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}
.author-info .author-social {
  position: absolute;
  right: 0;
  top: -15px;
}
.author-info .author-social li {
  float: left;
  cursor: pointer;
}
.author-info .author-social li a {
  display: block;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.author-info .author-social li:last-child a {
  border-right: 1px solid #e5e5e5;
}
.author-info .author-social li i:after {
  font-size: 16px;
  color: #a0a0a0;
  display: block;
  font-style: normal;
  font-family: FontAwesome;
}
.author-info .author-social li:hover i:after {
  color: #555;
}
.author-info .author-social li .ico-facebook:after {
  content: "\f09a";
}
.author-info .author-social li .ico-in:after {
  content: "\f0e1";
}
.author-info .author-social li .ico-youtube:after {
  content: "\f167";
}
.author-info .author-social li .ico-twitter:after {
  content: "\f099";
}
.author-info .author-social li .ico-google:after {
  content: "\f0d5";
}
.related-posts {
  float: left;
  width: 100%;
  position: relative;
}
.related-posts .title {
  font: 18px/20px 'Roboto', Georgia, Helvetica, sans-serif;
  color: #000;
  margin: 0 0 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  border: none;
  text-align: left;
  padding: 0;
  margin-bottom: 30px;
}
.related-posts .title span {
  padding: 0 6px 0 0;
  background: #fff;
  position: relative;
  z-index: 2;
}
.related-posts .title:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
}
.related-posts .owl-controls {
  position: absolute;
  top: -55px;
  z-index: 10;
  right: 0;
  width: 80px;
  height: 30px;
  opacity: 1 !important;
  background-color: #FFF;
  padding-left: 10px;
}
.related-posts .owl-controls .owl-next {
  right: 0;
  top: 0;
  border-color: #eee;
}
.related-posts .owl-controls .owl-prev {
  left: 10px;
  top: 0;
  border-color: #eee;
}
.related-posts .zxmedium-h:after {
  height: 0 !important;
}
.related-posts .recentCarousel {
  margin-bottom: 0;
}
.related-posts h2.zxmedium-h {
  font-size: 16px;
}
.comments-list {
  margin: 0;
  padding: 0;
  margin-top: 30px;
  list-style: none;
}
.comments-list li {
  margin-bottom: 23px;
}
.comments-list .media > .pull-left {
  margin-right: 21px;
}
.comments-list .media {
  padding: 41px 21px 34px;
  position: relative;
  border: 1px solid #E5E5E5;
}
.comments-list .media p {
  color: #767676;
  font-size: 13px;
  line-height: 24px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}
.comments-list .media-body {
  color: #767676;
  font-size: 13px;
  line-height: 24px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}
.comments-list .media-object {
  border: 1px solid #E5E5E5;
}
.comments-list .media-heading a,
.comments-list .media-heading {
  font: 14px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 9px;
}
.comments-list .media-heading a:hover,
.comments-list .media-heading:hover {
  color: #767676;
}
.comments-list .children {
  margin-left: 80px;
  margin-top: 25px;
}
.comments-list .children li .media {
  overflow: visible;
}
.comments-list .children li .media:before {
  position: absolute;
  left: -51px;
  top: -27px;
  content: "";
  display: block;
  width: 50px;
  height: 65px;
  border-left: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
#comments .pagination-cubic {
  margin-right: 0;
  text-align: right;
}
.tab-pane .comments-list {
  margin-top: 0;
}
.meta-comm {
  font-size: 12px;
  position: relative;
  margin-bottom: 20px;
  color: #767676;
}
.meta-comm:after {
  content: '';
  display: block;
  width: 70px;
  height: 3px;
  background: #E5E5E5;
  position: absolute;
  bottom: -12px;
  left: 0;
}
.comment-reply-link {
  position: absolute;
  top: 44px;
  right: 23px;
  color: #cda85c;
  font: 12px/15px 'Roboto', Georgia, Helvetica, sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.comment-reply-link:hover {
  color: #767676;
}
#commentform {
  width: 100%;
  margin: 30px 0 0;
}
#commentform input.form-control {
  width: 60%;
}
#commentform textarea {
  height: 244px;
}
#commentform .form-submit {
  text-align: right;
  margin-bottom: 40px;
}
#commentform .form-submit input[type="submit"] {
  padding: 0 28px;
  line-height: 40px;
  font-size: 14px;
  letter-spacing: 1px;
}
.form-control {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px;
  border-color: #D5D5D5;
  height: 39px;
}
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #252525;
}
.comment-form-rating {
  margin-bottom: 10px;
}
.comment-reply-title {
  font: 18px/20px 'Roboto' sans-serif;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  float: left;
  width: 100%;
}
.comment-reply-title:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
}
.comment-reply-title small {
  background-color: #FFF;
  position: absolute;
  right: 0;
  padding: 4px 0 4px 10px;
  z-index: 2;
}
.comment-reply-title span {
  padding: 0 6px 0 0;
  background: #fff;
  position: relative;
  z-index: 2;
}
.control-label {
  font: 14px/22px 'Roboto', Georgia, Helvetica, sans-serif;
  color: #767676;
}
.wrap-h {
  border-top: 1px solid #000;
  border-bottom: 1px solid #ddd;
  margin: 0 0 22px;
  padding: 20px 0 20px;
  position: relative;
}
.style-paragraph {
  font: 13px/24px 'Open Sans', Georgia, Helvetica, sans-serif;
  color: #767676;
  margin: 0 0 10px;
}
.widget_categories {
  padding: 0;
  list-style: none;
}
.widget_categories li {
  border-bottom: 1px solid #ddd;
  position: relative;
  display: inline-block;
  width: 100%;
}
.widget_categories li.lastItem {
  border: none;
}
.widget_categories li.firstItem {
  padding-top: 0;
}
.widget_categories li.current-cat a {
  color: #000;
}
.widget_categories li .children li {
  border-top: 1px solid #ddd;
  padding-left: 0;
}
.widget_categories li .children li a {
  position: relative;
}
.widget_categories li .children li a:before {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  content: "\f105";
  -webkit-font-smoothing: antialiased;
  float: left;
  line-height: 16px;
  font-size: 16px;
  margin-right: 10px;
  padding-left: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.widget_categories select {
  width: 100%;
}
.widget_categories > ul > li.firstItem > a {
  padding-top: 0;
}
.widget_categories a {
  font: 14px/15px 'Roboto', Georgia, Helvetica, sans-serif;
  padding: 15px 0;
  color: #767676;
  display: inherit;
}
.widget_categories a:hover,
.widget_categories a:focus {
  text-decoration: none;
  color: #000;
}
.widget_categories .count {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -9px;
}
.widget_categories a {
  text-transform: capitalize;
}
.latest-post-list {
  padding: 0;
  margin: -10px 0 0;
  list-style: none;
}
.latest-post-list li {
  padding: 22px 0 24px;
  border-bottom: 1px solid #E9E9E9;
}
.latest-post-list li.lastItem {
  border-bottom: none;
  padding-bottom: 22px;
}
.latest-post-list .media > .pull-left {
  margin: 0 20px 0 0;
}
.latest-post-list .media > .pull-left:focus {
  text-decoration: none;
}
.latest-post-list .media > .pull-left:hover {
  text-decoration: none;
}
.latest-post-list .media-heading a {
  font: 14px/24px 'Roboto', Arial, Helvetica, sans-serif;
  margin: 0 0 5px;
  color: #252525;
}
.latest-post-list .media-heading a:hover {
  color: #767676;
}
.latest-post-list .media-body {
  font: 11px/20px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #6e6e6e;
}
.later-product-list {
  margin: -10px 0 0;
  padding: 0;
  list-style: none;
}
.later-product-list li {
  padding: 21px 0 23px;
  border-bottom: 1px solid #E9E9E9;
}
.later-product-list li.firstItem {
  padding-top: 19px;
}
.later-product-list li.lastItem {
  border-bottom: none;
}
.later-product-list .media > .pull-left {
  margin-right: 13px;
}
.later-product-list .media-heading {
  margin: -5px 0 10px;
}
.later-product-list .media-heading a {
  font: 14px/18px 'Roboto', Arial, Helvetica, sans-serif;
  color: #252525;
}
.later-product-list .media-heading a:hover {
  color: #767676;
}
.later-product-list .small-coast {
  font: 16px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #cda85c;
}
.tab-content .comments-list .media-heading a {
  font-family: 'Roboto', Georgia, sans-serif;
  text-transform: uppercase;
  color: #252525;
  font-size: 17px;
}
.tab-content .comments-list .media-heading a:hover {
  color: #767676;
}
/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
.product-information {
  margin-bottom: 30px;
}
.product-information .price {
  float: left;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  font: 30px/30px 'Roboto', sans-serif;
}
.product-information .price ins {
  text-decoration: none;
  display: inline-block;
}
.product-information .price del {
  font-size: 20px;
}
.product-information .product_title {
  font-size: 30px;
}
.product-information .short-description {
  float: left;
  width: 100%;
  margin-bottom: 25px;
  font: 13px/24px 'Open Sans', sans-serif;
  color: #767676;
}
.product-information .short-description p {
  margin-bottom: 0;
}
.product-information .size_guide {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}
.product-information .product_meta .wcml_currency_switcher {
  display: none;
}
.product-information .cart {
  float: left;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.product-information .cart button[type="submit"] {
  float: right;
  margin-left: 15px;
  width: 315px;
  font-size: 13px !important;
  line-height: 40px !important;
  padding: 0 18px !important;
}
.product-information .cart button[type="submit"]:hover {
  background-color: #cda85c;
  text-decoration: none;
}
.product-information .tabs {
  display: inline-block;
  width: 100%;
}
.product-information .menu-social-icons {
  float: left;
  width: 100%;
}
.product-information .woocommerce-price-suffix {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  color: #767676;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}
.product-information .out-stock-wrapper {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.product-information .out-stock-wrapper .out-stock {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  margin: 0;
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  left: 0;
}
.product-information .out-stock-wrapper .out-stock .wr-c {
  font-size: 16px;
  margin-top: 15px;
  color: #444;
  border: none;
}
.product-information .out-of-stock {
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  display: inline-block;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #cda85c;
}
.hide-product-name .product_title {
  display: none;
}
.fixed-product-block {
  display: table;
  min-height: 65vh;
}
.fixed-product-block .fixed-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.fixed-product-block .fixed-content .product_title {
  font-size: 20px;
}
.fixed-product-block .fixed-content hr {
  margin: 10px 0 20px;
}
.fixed-product-block .fixed-content .share-title {
  display: none;
}
.fixed-product-block .fixed-content .product-navigation {
  display: none;
}
.fixed-product-block .fixed-content .quantity {
  float: none;
  margin: 0 auto 20px;
  display: block;
}
.fixed-product-block .fixed-content .cart button[type="submit"],
.fixed-product-block .fixed-content .cart .button {
  float: none;
  margin: 0 auto;
  color: #fff;
  display: inline-block;
  width: auto !important;
}
.fixed-product-block .fixed-content .price {
  margin-top: 15px;
  margin-bottom: 15px;
}
.fixed-product-block .fixed-content .product_meta {
  margin-top: 5px;
  margin-bottom: 10px;
}
.fixed-product-block .fixed-content .email-link,
.fixed-product-block .fixed-content .yith-wcwl-add-to-wishlist a {
  font-size: 14px;
}
.fixed-product-block .woocommerce-product-rating .star-rating {
  float: none;
}
.fixed-product-block .woocommerce-product-rating .woocommerce-review-link {
  display: none;
}
/* Quantity */
.quantity {
  position: relative;
  float: left;
  width: 65px;
  height: 40px;
}
.quantity input[type="button"] {
  position: absolute;
  right: 0 !important;
  width: 27px !important;
  height: 21px !important;
  padding: 0 !important;
  border: 1px solid #ddd !important;
  color: #000;
  background-color: #FFF !important;
}
.quantity input[type="button"]:hover {
  color: #fff;
  background-color: #333 !important;
  text-decoration: none;
}
.quantity input[type="button"]:focus {
  color: #fff ;
  background-color: #333!important;
}
.quantity input[type="button"]:focus:hover {
  color: #FFF;
  background-color: #333 !important;
}
.quantity input[type="number"] {
  width: 40px !important;
  height: 40px !important;
  padding-right: 5px;
  border: 1px solid #ddd !important;
}
.quantity .minus {
  bottom: 0 ;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
}
.quantity .plus {
  top: 0 ;
  border-bottom: 1px solid #ddd !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
}
.quantity.buttons_added input::-webkit-outer-spin-button,
.quantity.buttons_added input::-webkit-inner-spin-button {
  display: none;
}
.group_table tr,
.group_table td {
  border: none;
}
.group_table td.price {
  width: auto;
  margin: 0;
  float: none;
  font-size: 24px;
  padding-top: 4px;
  display: inline-block;
}
.group_table td.price .out-of-stock {
  width: auto;
  font-size: 12px;
  padding: 0;
  padding-left: 10px;
  display: inline-block;
}
.group_table td.label {
  text-align: left;
  display: inline-block;
  padding: 0 10px;
}
.group_table td:first-child {
  vertical-align: top;
  padding: 0 8px 8px;
}
.woocommerce-product-rating {
  float: left;
  width: 100%;
}
.woocommerce-product-rating .star-rating {
  float: left;
  margin-right: 15px;
  margin-bottom: 0;
  top: 3px;
}
.woocommerce-product-rating .woocommerce-review-link {
  font: 13px/15px 'Roboto', Arial, Helvetica, sans-serif;
  color: #767676;
}
.woocommerce-product-rating .woocommerce-review-link:hover {
  color: #252525;
}
.comment-form-rating label {
  font: 14px/22px 'Roboto', Georgia, Helvetica, sans-serif;
  color: #767676;
  font-weight: 400;
}
p.stars {
  display: inline-block;
  margin: 0 0 0 10px !important;
  position: relative;
  top: 9px;
}
p.stars span {
  background: url(https://terminal21.co.th/images/staticks/star-silver.png) repeat-x left 0;
  float: left;
  height: 20px;
  position: relative;
  width: 75px;
}
p.stars span a {
  float: left;
  height: 0;
  left: 0;
  overflow: hidden;
  padding-top: 22px;
  position: absolute;
  top: 0;
  width: 15px;
}
p.stars span a:hover,
p.stars span a:focus,
p.stars span a:hover,
p.stars span a:focus {
  background: url(https://terminal21.co.th/images/staticks/star.png) repeat-x left 0;
}
p.stars span a.active,
p.stars span a.active {
  background: url(https://terminal21.co.th/images/staticks/star.png) repeat-x left 0;
  border: none;
}
p.stars span a.star-1,
p.stars span a.star-1 {
  width: 15px;
  z-index: 10;
}
p.stars span a.star-2,
p.stars span a.star-2 {
  width: 30px;
  z-index: 9;
}
p.stars span a.star-3,
p.stars span a.star-3 {
  width: 45px;
  z-index: 8;
}
p.stars span a.star-4,
p.stars span a.star-4 {
  width: 60px;
  z-index: 7;
}
p.stars span a.star-5,
p.stars span a.star-5 {
  width: 75px;
  z-index: 6;
}
.product-content {
  margin-bottom: 30px;
}
.product-images {
  margin-bottom: 30px;
  text-align: center;
}
.product-images .label-product {
  position: relative;
}
.product-images .label-product .out-stock {
  margin-top: 0;
}
.product-images .product-images-gallery > div {
  position: relative;
}
.product-slider .bx-wrapper .bx-controls-direction a {
  top: 0;
  display: block;
  margin-top: 0;
  background: none;
  width: 40px;
}
.product-slider .bx-wrapper {
  margin: 0 0 18px;
}
#product-pager {
  width: 350px;
  margin: 0 auto;
}
#product-pager .owl-item {
  text-align: center;
  padding: 0 6px;
}
#product-pager .owl-item img {
  width: 100%;
  opacity: 0.7;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#product-pager .owl-item a {
  padding: 4px;
  display: block;
  border: 1px solid #D5D5D5;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#product-pager .owl-item a:hover {
  border-color: #8d8d8d;
}
#product-pager .owl-item a:hover img {
  opacity: 1;
}
#product-pager .owl-item.active-thumbnail a {
  border-color: #8d8d8d;
}
#product-pager .owl-item.active-thumbnail a img {
  opacity: 1;
}
#product-pager .owl-item .active-thumbnail {
  border-color: #8d8d8d;
}
#product-pager .owl-item .active-thumbnail img {
  opacity: 1;
}
#product-pager .owl-next,
#product-pager .owl-prev {
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#product-pager .owl-next:hover,
#product-pager .owl-prev:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
#product-pager .owl-prev {
  left: -34px;
}
#product-pager .owl-next {
  right: -34px;
}
#product-pager .owl-item .video-thumbnail {
  border: 1px solid #D5D5D5;
  padding: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#product-pager .owl-item .video-thumbnail span {
  cursor: pointer;
  display: block;
  font-size: 0;
  background-image: url(https://terminal21.co.th/images/play1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 83px;
  height: 110px;
}
/*----------  Tab classic  ----------*/
.vc_tta-style-classic {
  border-top: 1px solid #000;
}
.vc_tta-style-classic .vc_tta-tabs-container {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px !important;
}
.vc_tta-style-classic .vc_tta-tabs-container a {
  background-color: transparent !important;
  border: none !important;
  padding: 18px 0 !important;
}
.vc_tta-style-classic .vc_tta-tabs-container a span {
  margin: 0 20px !important;
  font-size: 16px;
  color: #252525;
  text-transform: uppercase;
  font-family: 'Roboto';
  font-weight: 400;
  letter-spacing: 2px;
}
.vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab {
  position: relative;
}
.vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:before {
  content: '';
  border: none !important;
  display: block;
  width: 5px !important;
  height: 5px !important;
  position: absolute;
  top: 50% !important;
  right: 0;
  margin-top: -3px;
  left: auto !important;
  background-color: #000;
}
.vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab.lastItem:before {
  display: none !important;
}
.vc_tta-style-classic .vc_tta-panel-body {
  overflow: visible !important;
}
/* Reviews next to tabs */
.reviews-position-outside .tabs {
  width: 60% !important;
  float: left;
  padding-right: 15px;
  border-top: none;
}
.reviews-position-outside #reviews {
  width: 40%;
  padding-left: 15px;
  padding-top: 10px;
  float: left;
}
.reviews-position-outside #reviews h2 {
  font-weight: 500;
  font-size: 15px;
  color: #000;
}
.reviews-position-outside #reviews .comment-reply-link {
  position: static;
  float: right;
}
.reviews-position-outside #commentform input.form-control {
  width: 100%;
}
.product-images-slider video {
  max-width: 100%;
}
.product-images-slider .mejs-controls,
.product-images-slider .mejs-layers {
  display: none !important;
}
.product_meta {
  list-style: none;
  margin: 20px 0 30px;
  padding: 0;
  font: 13px/30px 'Roboto', Arial, sans-serif;
  float: left;
  width: 100%;
  color: #000;
}
.product_meta > span {
  display: inline-block;
  width: 100%;
}
.product_meta > span span {
  color: #cda85c;
}
.product_meta a {
  color: #cda85c;
}
.product_meta a:hover {
  color: #767676;
}
.product-navigation {
  width: 100%;
  padding: 15px 0;
  position: relative;
}
.product-navigation .back-history {
  position: static;
  line-height: 30px;
}
.product-navigation .back-history span {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.product-navigation .back-history:hover span {
  color: #767676 !important;
}
.product-navigation .product-arrows {
  position: absolute;
  right: 0;
  top: 7px;
  background-color: #FFF;
  padding-left: 5px;
  z-index: 1;
}
.product-navigation .next-product,
.product-navigation .prev-product {
  background: none;
  width: 31px;
  position: relative;
  height: 31px;
  border: 1px solid #eee;
  font-style: normal;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.product-navigation .next-product .hide-info,
.product-navigation .prev-product .hide-info {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 280px;
  bottom: 40px;
  left: -58px;
  background-color: #FFF;
  border: 1px solid #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 100;
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.product-navigation .next-product .hide-info:before,
.product-navigation .prev-product .hide-info:before {
  bottom: -22px;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 213, 213, 0);
  border-top-color: #D5D5D5;
  border-width: 11px;
  margin-left: 44px;
}
.product-navigation .next-product .hide-info:after,
.product-navigation .prev-product .hide-info:after {
  bottom: -20px;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 10px;
  margin-left: 45px;
}
.product-navigation .next-product .hide-info img,
.product-navigation .prev-product .hide-info img {
  float: left;
  margin-right: 10px;
  max-height: 80px;
  width: auto;
}
.product-navigation .next-product .hide-info span,
.product-navigation .prev-product .hide-info span {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 14px;
  width: auto;
  margin-top: 10px;
  -ms-text-overflow: clip;
  text-overflow: clip;
}
.product-navigation .next-product .hide-info span.price,
.product-navigation .prev-product .hide-info span.price {
  font-family: 'Roboto';
  font-weight: 500;
  color: #cda85c;
  margin-top: 2px;
  margin-bottom: 0;
  width: 100%;
  clear: both;
}
.product-navigation .next-product .hide-info > span,
.product-navigation .prev-product .hide-info > span {
  width: 100%;
}
.product-navigation .next-product .hide-info .price del,
.product-navigation .prev-product .hide-info .price del,
.product-navigation .next-product .hide-info .price ins,
.product-navigation .prev-product .hide-info .price ins {
  display: inline-block;
  margin-right: 10px;
}
.product-navigation .next-product .hide-info .price del,
.product-navigation .prev-product .hide-info .price del {
  color: #000;
}
.product-navigation .next-product .hide-info .price small,
.product-navigation .prev-product .hide-info .price small {
  width: auto;
}
.product-navigation .next-product .hide-info > div,
.product-navigation .prev-product .hide-info > div {
  float: left;
  padding: 5px 0 5px 10px;
  width: 175px;
}
.product-navigation .next-product:hover,
.product-navigation .prev-product:hover {
  background-color: #252525;
  cursor: pointer;
  border-color: #252525;
}
.product-navigation .next-product:hover:before,
.product-navigation .prev-product:hover:before {
  color: #FFF;
}
.product-navigation .next-product:hover .hide-info,
.product-navigation .prev-product:hover .hide-info {
  opacity: 1;
  visibility: visible;
}
.product-navigation .next-product {
  float: right;
}
.product-navigation .next-product:before {
  content: "\f105";
  position: absolute;
  font-size: 20px;
  font-family: FontAwesome;
  color: #252525;
  left: 12px;
  top: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.product-navigation .prev-product {
  float: left;
  margin-right: 5px;
}
.product-navigation .prev-product:before {
  content: "\f104";
  position: absolute;
  font-size: 20px;
  font-family: FontAwesome;
  color: #252525;
  left: 10px;
  top: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.product-navigation .prev-product .hide-info {
  left: -175px;
}
.product-navigation .prev-product .hide-info img {
  float: right;
  margin-right: 0;
  margin-left: 10px;
}
.product-navigation .prev-product .hide-info span {
  margin-left: 0;
}
.product-navigation .prev-product .hide-info span span {
  margin-left: 0;
}
.product-navigation .prev-product .hide-info:before,
.product-navigation .prev-product .hide-info:after {
  left: 48%;
}
.product-navigation .prev-product .woocommerce-price-suffix {
  font-size: 14px;
}
table.variations {
  width: 100%;
  float: left;
}
table.variations td {
  width: 100%;
  display: block;
  padding: 0;
  text-align: left;
  border: none;
}
table.variations td label {
  font: 15px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 12px;
  letter-spacing: 1px;
}
table.variations td select {
  width: 100%;
}
table.variations td.value {
  margin-bottom: 17px;
}
table.variations .reset_variations {
  margin-top: 7px;
  display: block;
  color: #cda85c;
}
table.variations .reset_variations:hover {
  color: #767676;
}
.single_variation_wrap {
  padding-top: 20px;
  float: left;
  width: 100%;
  border-top: 1px solid #eee;
  margin-top: 20px;
}
.tabs {
  margin: 0 0 38px;
}
.tabs .tabs-nav,
.tabs .wpb_tabs_nav {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
}
.tabs .tabs-nav li,
.tabs .wpb_tabs_nav li {
  display: inline-block;
}
.tabs .wpb_tabs_nav {
  margin-bottom: 20px;
}
.tabs .wpb_tab {
  padding: 0 !important;
  background-color: transparent !important;
}
.tabs .wpb_tabs_nav li {
  background-color: transparent !important;
}
.tabs .wpb_tabs_nav li:focus {
  outline: none;
}
.tabs .tab-title,
.tabs .wpb_tabs_nav li a {
  margin: 10px 13px 10px 0;
  display: inline-block;
  padding: 0 !important;
  position: relative;
  font: 15px/15px 'Roboto', Arial, sans-serif;
  font-weight: 500;
  color: #767676;
  background-color: transparent !important;
  text-transform: uppercase;
}
.tabs .tab-title:hover,
.tabs .wpb_tabs_nav li a:hover {
  color: #000;
}
.tabs .tab-title:hover,
.tabs .wpb_tabs_nav li a:hover,
.tabs .tab-title:focus,
.tabs .wpb_tabs_nav li a:focus {
  text-decoration: none !important;
}
.tabs .tab-title:first-child,
.tabs .wpb_tabs_nav li a:first-child {
  margin-left: 0 !important;
}
.tabs .tab-title:after,
.tabs .wpb_tabs_nav li a:after {
  content: '';
  display: block;
  width: 1px;
  height: 12px;
  position: absolute;
  right: -19px;
  top: 2px;
}
.tabs .tab-title.lastItem:after,
.tabs .wpb_tabs_nav li a.lastItem:after {
  display: none;
}
.tabs .tab-title.opened,
.tabs .wpb_tabs_nav li.ui-state-active a {
  position: relative;
  color: #000;
}
.tabs .tab-title.opened:hover,
.tabs .wpb_tabs_nav li.ui-state-active a:hover {
  text-decoration: none;
}
.tabs .tab-title.opened:after,
.tabs .wpb_tabs_nav li.ui-state-active a:after {
  content: '';
  display: block;
  width: 100%;
  height: 36px;
  border-bottom: 3px solid #3d3d3d;
  position: absolute;
  top: -11px;
  left: 0;
}
.tabs .tab-title.opened:before,
.tabs .wpb_tabs_nav li.ui-state-active a:before {
  content: '';
  display: block;
  bottom: -18px;
  left: 50%;
  margin-left: -4px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #000;
  border-width: 4px 4px;
}
.tabs .tab-content {
  padding: 0 !important;
  border: none !important;
  margin-top: 35px !important;
}
.tabs .tab-content h2 {
  font-size: 18px;
}
.tabs .tab-content p {
  font: 13px/24px 'Open Sans', Georgia, Helvetica, sans-serif;
  color: #767676;
}
.tabs .tab-content .list li {
  display: list-item;
}
.vc_tta-color-grey.vc_tta-style-outline .vc_tta-tab > a {
  color: #767676 !important;
}
.vc_tta-color-grey.vc_tta-style-outline .vc_tta-tab.vc_active > a,
.vc_tta-color-grey.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title > a {
  color: #000 !important;
}
.vc_tta.vc_tta-style-outline .vc_tta-panel-body,
.vc_tta.vc_tta-style-outline .vc_tta-panel-heading,
.vc_tta.vc_tta-style-outline .vc_tta-tab > a {
  border-width: 1px !important;
}
.vc_tta.vc_general .vc_tta-panel-body > :last-child {
  margin-bottom: 0 !important;
}
.vc_tta.vc_tta-style-outline .vc_tta-tabs-list {
  padding-left: 1px !important;
  padding-top: 1px !important;
}
.vc_tta.vc_tta-style-outline .vc_tta-tab {
  margin-left: -1px !important;
  margin-top: -1px !important;
}
.vc_tta.vc_tta-style-outline .vc_tta-panel.vc_active + .vc_tta-panel .vc_tta-panel-heading,
.vc_tta.vc_tta-style-outline .vc_tta-panel:not(:first-child) .vc_tta-panel-heading {
  margin-top: -1px !important;
}
.vc_tta.vc_tta-style-outline .vc_tta-panel.vc_active .vc_tta-panel-heading,
.vc_tta.vc_tta-style-outline .vc_tta-panel:not(:last-child) .vc_tta-panel-heading {
  margin-bottom: -1px !important;
}
@media only screen and (min-width: 768px) {
  .vc_tta.vc_tta-style-outline.vc_tta-tabs .vc_tta-panels {
    border-width: 1px !important;
  }
}
.products-tabs {
  border-top: 1px solid #000;
  text-align: center;
  padding: 20px 0;
  position: relative;
}
.products-tabs .wpb_tabs_nav {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}
.products-tabs .wpb_tabs_nav li {
  float: none !important;
  display: inline-block;
  background-color: transparent !important;
}
.products-tabs .wpb_tabs_nav li:last-child a:after {
  display: none;
}
.products-tabs .tab-title,
.products-tabs .wpb_tabs_nav li a {
  margin: 0 20px !important;
  font-size: 16px;
  padding: 20px 0 !important;
  color: #252525;
  text-transform: uppercase;
  font-family: 'Roboto';
  font-weight: 400;
  letter-spacing: 2px;
  position: relative;
}
.products-tabs .tab-title:before,
.products-tabs .wpb_tabs_nav li a:before {
  display: none !important;
}
.products-tabs .tab-title:after,
.products-tabs .wpb_tabs_nav li a:after {
  content: '';
  border: none !important;
  display: block;
  width: 5px !important;
  height: 5px !important;
  position: absolute;
  top: 50% !important;
  right: -22px;
  margin-top: -3px;
  left: auto !important;
  background-color: #000;
}
.products-tabs .tab-title.opened,
.products-tabs .wpb_tabs_nav li a.opened {
  color: #cda85c;
}
.products-tabs .tab-title.opened:after,
.products-tabs .wpb_tabs_nav li a.opened:after {
  border: none !important;
}
.products-tabs .tab-title:nth-child(3):after,
.products-tabs .wpb_tabs_nav li a:nth-child(3):after {
  display: none !important;
}
.products-tabs .tab-title:first-child,
.products-tabs .wpb_tabs_nav li a:first-child {
  margin-left: 20px !important;
}
.products-tabs .wpb_tab {
  padding: 0 !important;
  background-color: transparent !important;
}
.shop_attributes th {
  font-size: 14px;
}
.shop_attributes td p {
  margin-bottom: 0;
  padding-left: 10px;
}
.statick-banner {
  position: relative;
}
.statick-banner .sm-title-banner {
  width: 80%;
  margin-left: -40%;
}
.statick-banner .ov_hidden {
  overflow: hidden;
}
.etheme_widget_qr_code {
  border: 1px solid #ebebeb;
  padding: 15px 0 0 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  line-height: 20px;
  min-height: 120px;
  color: #767676;
}
.etheme_widget_qr_code span {
  background: transparent;
}
.etheme_widget_qr_code h4 {
  margin-bottom: 10px !important;
  color: #252525;
}
.etheme_widget_qr_code .widget-title {
  border: none;
  padding: 0;
  float: left;
  width: 100%;
  position: static;
  text-align: left;
  font-size: 16px !important;
  font-family: 'Roboto';
  font-weight: 500;
}
.etheme_widget_qr_code .widget-title:after {
  content: "";
  height: 0;
}
.etheme_widget_qr_code .qr-lighbox {
  font-size: 0;
  width: 85px;
  height: 118px;
  display: block;
  float: right;
  margin-top: -41px;
  background-image: url(https://terminal21.co.th/images/qr-bg.png);
  opacity: 0.6;
  -webkit-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.etheme_widget_qr_code .qr-lighbox:hover {
  text-decoration: none;
}
.meta-title {
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #252525;
  line-height: 16px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  float: left;
  width: 100%;
}
.meta-title span {
  padding: 0 6px 0 0;
  background: #fff;
  position: relative;
  z-index: 2;
}
.meta-title:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
}
.product-information .yith-wcwl-add-to-wishlist,
.product-images .yith-wcwl-add-to-wishlist {
  margin-right: 20px;
}
.product-information .yith-wcwl-add-to-wishlist .feedback,
.product-images .yith-wcwl-add-to-wishlist .feedback {
  display: none;
}
.product-information .yith-wcwl-add-to-wishlist a,
.product-images .yith-wcwl-add-to-wishlist a {
  position: relative;
  padding-left: 20px;
  line-height: 1.3;
}
.product-information .yith-wcwl-add-to-wishlist a:before,
.product-images .yith-wcwl-add-to-wishlist a:before {
  content: "\f08a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #252525;
  font-style: normal;
  position: absolute;
  left: -10px;
  top: 0;
}
.product-information .yith-wcwl-add-to-wishlist + .clear,
.product-images .yith-wcwl-add-to-wishlist + .clear {
  display: none;
}
.product-information .email-link,
.product-images .email-link {
  position: relative;
  padding-left: 25px;
  line-height: 20px;
}
.product-information .email-link:before,
.product-images .email-link:before {
  content: "\f003";
  font-family: FontAwesome;
  font-size: 16px;
  font-style: normal;
  color: #252525;
  position: absolute;
  left: 0;
  top: -1px;
}
.product-images .yith-wcwl-add-to-wishlist {
  width: 100%;
  margin-top: 20px;
}
.product-lightbox-btn {
  position: absolute;
  left: 17px;
  bottom: 17px;
  font-size: 0;
  z-index: 10000;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #3a3a3a;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.product-lightbox-btn:before {
  content: "\f065";
  font-family: FontAwesome;
  font-size: 16px;
  color: #3a3a3a;
  line-height: 39px;
}
.product-lightbox-btn:hover {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.categoriesCarousel .slide-item {
  position: relative;
}
.categoriesCarousel .slide-item a {
  display: block;
  padding: 0 10px;
}
.categories-mask {
  position: absolute;
  width: 100%;
  bottom: 35px;
  padding: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  right: 0;
  text-align: center;
}
.categories-mask span {
  font: 12px/15px 'Roboto', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #cda85c;
  display: block;
  margin-bottom: 5px;
}
.categories-mask h4 {
  font-size: 26px;
  color: #000 !important;
}
.categories-mask span.more {
  font: 12px/15px 'Roboto', Arial, Helvetica, sans-serif;
  color: #252525;
  font-size: 12px;
  margin-top: 20px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.categories-mask span.more:before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -12px;
  width: 25px;
  height: 2px;
  background-color: #a6a5a4;
  top: -10px;
}
.product-category:hover .categories-mask {
  background-color: rgba(255, 255, 255, 0.95);
}
.product-category:hover .categories-mask h4 {
  color: #000;
}
.product-category:hover .categories-mask span.more {
  color: #cda85c;
}
.content-product .added_to_cart {
  display: none;
}
.slide-item .product .product-excerpt {
  display: none;
  font: 13px/24px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #767676;
  margin-bottom: 26px;
}
.slide-item .product.content-product-advanced .product-excerpt {
  display: block;
}
.slide-item .product.content-product-advanced .product-details {
  text-align: left !important;
}
.slide-item .product.content-product-advanced .products-page-cats {
  margin-left: 0;
}
.slide-item .product .product-details {
  padding-bottom: 1px;
}
.recentCarousel .slide-item {
  margin: 0 15px;
}
.recentCarousel .slide-item .caption h3 {
  font: 16px/24px 'Roboto', Helvetica, sans-serif;
  font-weight: 400;
}
.recentCarousel .slide-item .caption h2 {
  font: 20px/24px 'Roboto', Helvetica, sans-serif;
  font-weight: 400;
}
.recentCarousel .slide-item .caption .zxmedium-h {
  position: relative;
}
.recentCarousel .slide-item .caption .zxmedium-h:after {
  content: '';
  display: block;
  width: 70px;
  height: 3px;
  background: #E5E5E5;
  position: absolute;
  bottom: -15px;
  left: 0;
}
.recentCarousel .slide-item .caption p {
  font: 13px/24px 'Open Sans', Helvetica, sans-serif;
  color: #767676;
  margin: 0 0 10px;
}
.recent-categ {
  margin-bottom: 25px;
}
.all-pages {
  font-size: 12px;
  color: #252525;
  letter-spacing: 1px;
  line-height: 33px;
}
.simple-list {
  list-style: none;
  margin: 30px 0 5px;
  padding: 0;
  font: 13px/55px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #767676;
}
.simple-list li {
  border-top: 1px solid #ddd;
}
.simple-list li.lastItem {
  border-bottom: 1px solid #ddd;
}
.simple-list strong {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #515151;
}
.project-navigation {
  margin-top: 20px;
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
.project-navigation + .title-alt {
  margin-top: 0;
}
.project-navigation .next-project,
.project-navigation .prev-project {
  position: relative;
}
.project-navigation .next-project a,
.project-navigation .prev-project a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.project-navigation .next-project .hide-info,
.project-navigation .prev-project .hide-info {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 250px;
  top: -76px;
  left: auto;
  background-color: #FFF;
  border: 1px solid #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 100;
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
  display: table;
}
.project-navigation .next-project .hide-info:before,
.project-navigation .prev-project .hide-info:before {
  bottom: -22px;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 213, 213, 0);
  border-top-color: #D5D5D5;
  border-width: 11px;
  margin-left: 44px;
}
.project-navigation .next-project .hide-info:after,
.project-navigation .prev-project .hide-info:after {
  bottom: -20px;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 10px;
  margin-left: 45px;
}
.project-navigation .next-project .hide-info img,
.project-navigation .prev-project .hide-info img {
  float: left;
  margin-right: 10px;
  height: auto;
  max-width: 125px;
  max-height: 60px;
}
.project-navigation .next-project .hide-info span,
.project-navigation .prev-project .hide-info span {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12px;
  display: block;
  margin: 0 !important;
  color: #cda85c;
  text-transform: uppercase;
  display: table-cell;
  vertical-align: middle;
}
.project-navigation .next-project .hide-info span.price,
.project-navigation .prev-project .hide-info span.price {
  font-family: 'Roboto';
  font-weight: 500;
  color: #252525;
  margin: 0 !important;
  line-height: 15px;
  text-transform: uppercase;
  text-align: center;
}
.project-navigation .next-project:hover .hide-info,
.project-navigation .prev-project:hover .hide-info {
  opacity: 1;
  visibility: visible;
}
.project-navigation .prev-project .hide-info {
  left: 0;
  right: auto;
  padding-right: 10px;
}
.project-navigation .prev-project .hide-info img {
  float: left;
  margin-right: 10px;
  margin-left: 0;
}
.project-navigation .prev-project .hide-info span {
  margin-right: 10px;
  right: 0;
}
.project-navigation .prev-project .hide-info:before,
.project-navigation .prev-project .hide-info:after {
  left: 0;
  right: auto;
}
.project-navigation .next-project .hide-info {
  right: 0;
  left: auto;
  padding-left: 10px;
}
.project-navigation .next-project .hide-info:before,
.project-navigation .next-project .hide-info:after {
  left: 160px;
  right: 20px;
}
.project-navigation .next-project img {
  float: right !important;
  margin-right: 0 !important;
  margin-left: 10px;
}
.project-navigation .next-project span {
  text-align: left;
  margin-left: 10px;
  left: 0;
}
.swiper-slide {
  position: relative;
}
.thumbnails-x .post-news {
  margin-bottom: 18px;
}
.thumbnails-x .post-news:hover .zoom {
  opacity: 1;
}
.thumbnails-x .zoom {
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  opacity: 0;
}
.thumbnails-x .zoom > i {
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background: #cda85c;
  opacity: 0.6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.thumbnails-x .style-paragraph {
  margin-top: 20px;
}
.thumbnails-x .zmedium-h {
  margin-bottom: 5px;
}
.zeroing-mar {
  margin: 0 !important;
}
.post-news {
  position: relative;
}
.post-news img {
  width: 100%;
  text-align: center;
}
.caption .zxmedium-h a:hover {
  color: #767676;
}
.caption .zmedium-h a {
  color: #cda85c;
}
.caption .zmedium-h a:hover {
  color: #767676;
}
.filter-page {
  margin-top: 20px;
  margin-bottom: 80px;
}
.large-banner {
  margin-bottom: 15px;
  text-align: center;
}
.large-banner .xxlarge-h {
  margin-top: 0;
  text-shadow: 0 0 13px rgba(0, 0, 0, 0.9);
}
.large-banner .xxlarge-h:before {
  display: none;
}
.ico-grid {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -190px -49px;
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 9px 0;
}
.ico-next-page {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -223px -34px;
  width: 9px;
  height: 9px;
  display: block;
  top: -2px;
  position: relative;
}
.ico-prev-page {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -209px -34px;
  width: 9px;
  height: 9px;
  display: block;
  top: -2px;
  position: relative;
}
.pagination-cubic .siblings-li {
  border-right: none;
}
.pagination-cubic {
  margin: 3px 0 14px 0;
  padding: 0;
  list-style: none;
  float: right;
  display: inline-block;
}
.pagination-cubic .page-numbers {
  text-align: right;
}
.pagination-cubic li {
  display: inline-block;
  padding: 0 3px 0 3px;
  border-right: 1px solid #D5D5D5;
  float: left;
}
.pagination-cubic li.lastItem,
.pagination-cubic li.firstItem {
  border-left: none;
}
.pagination-cubic li a {
  font: 14px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
  display: block;
  padding: 6px 10px;
  float: left;
  -webkit-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.pagination-cubic li a:hover {
  background: #000;
  color: #fff;
  text-decoration: none;
}
.pagination-cubic li a:hover.next-page,
.pagination-cubic li a:hover.prev-page {
  background: transparent;
}
.pagination-cubic li.active {
  border-right: none;
}
.pagination-cubic li.active a {
  background: #000;
  color: #fff;
}
.pagination-cubic li span.page-numbers.current {
  padding: 6px 10px;
  font: 14px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #FFF;
  background-color: #000;
  border-right: 1px solid #D5D5D5;
  float: left;
}
.pagination-cubic .dots {
  display: block;
  padding: 4px 4px 5px;
  float: left;
}
.next-page,
.prev-page {
  margin-bottom: -8px;
}
.p-filters {
  margin-bottom: 13px;
}
.ui-range {
  margin-bottom: 21px;
}
.price_slider.ui-corner-all {
  height: 3px;
  margin-top: -1px;
  position: relative;
  background-color: #ddd;
  top: 0;
  box-sizing: border-box;
}
.ui-slider .ui-slider-handle {
  border: none;
  background-color: #cda85c;
  cursor: pointer;
  height: 10px;
  outline: none;
  position: absolute;
  top: -3px;
  margin-left: -1px;
  width: 10px;
  z-index: 2;
}
.ui-slider .ui-slider-handle:hover {
  background: #bc923a;
}
.ui-slider .ui-slider-range {
  background: #d9bd83;
}
.amount-text {
  font: 14px/15px 'Roboto', Georgia, sans-serif;
  color: #767676;
  margin-top: 10px;
}
.amount-text .slider-amount {
  color: #252525;
  font-family: 'Roboto', Georgia, sans-serif;
}
.product {
  margin-bottom: 40px;
}
.two-column-search .pm-image {
  max-width: 270px;
  position: relative;
}
.two-column-search .products-page-cats {
  margin: 6px 0 2px 0;
}
.custom-checkbox {
  padding: 0;
  margin: 0;
  list-style: none;
}
.custom-checkbox a {
  font: 13px/18px 'Roboto', Georgia, Helvetica, sans-serif;
  padding: 15px 0 15px 36px;
  text-transform: uppercase;
  color: #767676;
  display: block;
}
.custom-checkbox a:focus {
  text-decoration: none;
}
.custom-checkbox li {
  border-bottom: 1px solid #E9E9E9;
  position: relative;
}
.custom-checkbox li:hover a,
.custom-checkbox li:hover .count {
  text-decoration: none;
  color: #000;
}
.custom-checkbox li.lastItem {
  border: none;
}
.custom-checkbox .count {
  font: 13px/15px 'Roboto', Georgia, Helvetica, sans-serif;
  color: #767676;
  display: block;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 50%;
  margin-top: -7px;
  cursor: pointer;
}
.ffCheckboxWrapper input[type="checkbox"] {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 10px;
  margin-top: -6px;
  border: 1px solid #D9D9D9;
  display: inline-block;
}
.toggle-block {
  margin-bottom: 5px;
}
.toggle-block .toggle-element .toggle-content {
  display: none;
  padding-top: 5px;
}
.toggle-block .toggle-element > a {
  border-bottom: 1px solid #E9E9E9;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  overflow: visible;
  display: block;
  padding: 17px 0;
  font: 13px/15px 'Roboto', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  position: relative;
}
.toggle-block .toggle-element > a:hover,
.toggle-block .toggle-element > a:focus {
  text-decoration: none;
}
.toggle-block .collapsed .disable {
  width: 9px;
  height: 9px;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -5px;
}
.toggle-block .open-this {
  width: 9px;
  height: 1px;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -8px;
}
.toggle-block .panel-body {
  padding: 0;
  border-top: none;
  position: relative;
}
.toggle-block .panel-body:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: -1px;
}
.toggle-block .panel-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.toggle-block .panel-body ul li {
  border-bottom: 1px solid #E9E9E9;
}
.toggle-block .panel-body ul li.lastItem {
  border-bottom: none;
}
.toggle-block .panel-body ul a {
  font: 13px/15px 'Roboto', Arial, Georgia, sans-serif;
  color: #767676;
  text-transform: uppercase;
  position: relative;
  display: block;
  padding: 17px 0 17px 20px;
}
.toggle-block .panel-body ul a:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -4px;
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -212px -51px;
  width: 5px;
  height: 8px;
}
.toggle-block .panel-body ul a:hover {
  color: #434343;
  text-decoration: none;
}
.toggle-block.bordered .toggle-element {
  padding: 0 0 15px;
}
.toggle-block.bordered .toggle-element .toggle-content {
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 15px 22px;
  font: 13px/24px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #767676;
  border: 1px solid #d5d5d5;
}
.toggle-block.bordered .panel {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  margin-top: 0 !important;
}
.toggle-block.bordered .panel:last-child .reference {
  margin-bottom: 10px;
}
.toggle-block.bordered .reference {
  margin-bottom: 25px;
  padding: 15px 22px;
  font: 13px/24px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #767676;
}
.toggle-block.bordered .toggle-element > a {
  font: 14px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
  display: block;
  padding: 10px 25px 10px 30px;
  border: 1px solid #D5D5D5;
  position: relative;
}
.toggle-block.bordered .toggle-element > a:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  width: 7px;
  height: 7px;
  background: #cda85c;
  margin-top: -4px;
  left: 12px;
}
.toggle-block.bordered .toggle-element > a:hover,
.toggle-block.bordered .toggle-element > a:focus {
  text-decoration: none;
}
.toggle-block.bordered .toggle-element > a .open-this {
  width: 9px;
  height: 9px;
  display: block;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -8px;
}
.one-column-search {
  margin-top: 16px;
}
.one-column-search > div {
  margin-bottom: 0;
}
.one-column-search > div .wrap-p {
  border-bottom: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 20px;
}
.one-column-search > div:first-child .wrap-p {
  padding-top: 0;
}
.one-column-search > div:last-child {
  border-bottom: none;
}
.one-column-search .pm-image {
  max-width: 270px;
  position: relative;
}
.one-column-search .products-page-cats {
  margin: 6px 0 2px 0;
}
.products-list .pm-details {
  width: auto;
  max-width: 600px;
}
.xlarge-banner {
  margin-bottom: 20px;
  text-align: center;
}
.xlarge-banner .xxlarge-h {
  margin-top: 0;
  text-shadow: 0 0 13px rgba(0, 0, 0, 0.9);
}
.xlarge-banner .xxlarge-h:before {
  display: none;
}
.c-mar {
  margin-left: 0;
  margin-right: 17px;
}
.offset-x1 {
  margin-bottom: 10px;
}
.offset-x2 {
  margin-bottom: 20px;
}
.offset-x25 {
  margin-bottom: 25px;
}
.offset-x3 {
  margin-bottom: 30px;
}
.offset-4 {
  margin-bottom: 40px;
}
.neg-offset-x0 {
  margin-top: 0;
}
.neg-offset-x1 {
  margin-top: -10px;
}
.neg-offset-x15 {
  margin-top: -15px;
}
.neg-offset-x2 {
  margin-top: -20px;
}
.neg-offset-x25 {
  margin-top: -25px;
}
.neg-offset-x3 {
  margin-top: -30px;
}
.neg-offset-4 {
  margin-top: -40px;
}
/*---------------------------------------------------------------*/
/* Shopping Cart Page / #shopping-cart
/*---------------------------------------------------------------*/
.shop-table {
  float: left;
  width: 100%;
}
.shop-table .table-bordered {
  text-align: center;
  border-width: 1px 0 1px 0;
}
.shop-table .table-bordered tbody tr td {
  padding: 25px 16px;
  color: #000;
  text-align: center;
}
.shop-table .table-bordered > thead > tr > th:first-child,
.shop-table .table-bordered > tbody > tr > td:first-child {
  border-left: none;
}
.shop-table .table-bordered > thead > tr > th:last-child,
.shop-table .table-bordered > tbody > tr > td:last-child {
  border-right: none;
}
.shop-table .table-bordered > thead > tr > th {
  padding: 15px !important;
}
.shop-table .table-bordered > tbody > tr > td {
  padding: 25px  16px;
  vertical-align: middle;
}
.shop-table .table-bordered td.product-name {
  text-align: left;
}
.shop-table .table-bordered td.product-name img {
  margin-right: 10px;
}
.shop-table .table-bordered td.product-name a {
  font: 14px/22px 'Roboto', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.shop-table .table-bordered td.product-name .product-thumbnail {
  display: table-cell;
  vertical-align: middle;
  max-width: 110px;
  padding-right: 10px;
}
.shop-table .table-bordered td.product-name .cart-item-details {
  display: table-cell;
  vertical-align: middle;
}
.shop-table .table-bordered td.product-price span,
.shop-table .table-bordered td.product-subtotal span {
  font: 20px/20px 'Roboto', Arial, sans-serif;
  font-weight: 700;
  color: #000;
}
.shop-table .table-bordered td.product-quantity .quantity {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  border: none;
}
.shop-table .table-bordered td.product-quantity .quantity input[type="number"] {
  width: 50px !important;
  padding-right: 9px;
  border: 1px solid #000;
}
.shop-table .table-bordered td.product-subtotal,
.shop-table .table-bordered th.product-subtotal {
  text-align: center;
}
.shop-table .table-bordered .remove-item {
  height: 27px;
  border-color: #000;
  font: 10px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #000;
  border-radius: 0;
  line-height: 27px;
  width: 20px;
  margin: 0 auto;
  display: block;
  padding: 0;
  -webkit-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.shop-table .table-bordered .remove-item:hover {
  background: #000;
  color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.shop-table .table-bordered .remove-item:focus {
  outline: none;
}
.shop-table thead tr th {
  font: 15px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;
  text-align: center;
  border-bottom: none;
  text-transform: uppercase;
}
.shop-table .count-p {
  display: inline-block;
  float: none;
  min-width: 66px;
}
.actions {
  margin-bottom: 40px;
  text-align: right;
}
.actions input.btn {
  margin-left: 10px;
}
.coupon input[type="text"] {
  font-family: 'Roboto', Arial, Georgia, sans-serif;
  font-size: 12px;
}
.coupon .button {
  float: right;
}
.sidebar-position-left .coupon input[type="text"],
.sidebar-position-right .coupon input[type="text"] {
  width: 225px;
}
.col-ct-9 {
  width: 75%;
  float: left;
}
.col-ct-8 {
  width: 70%;
  float: left;
}
.col-ct-4 {
  width: 30%;
  float: left;
}
.col-ct-3 {
  width: 25%;
  float: left;
}
.empty-cart-block {
  text-align: center;
}
.reference {
  padding: 30px 20px 3px;
  border: 1px solid #D5D5D5;
  font: 13px/22px 'OpenSans_Italic', Arial, Georgia, sans-serif;
  position: relative;
  text-align: center;
}
.reference:after,
.reference:before {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.reference:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
}
.reference:before {
  border-color: rgba(213, 213, 213, 0);
  border-bottom-color: #D5D5D5;
  border-width: 11px;
  margin-left: -11px;
}
.accordion-info .reference:after,
.accordion-info .reference:before {
  display: none;
}
.woocommerce-shipping-calculator a {
  font-size: 14px;
}
.woocommerce-shipping-calculator p {
  margin-bottom: 5px;
}
.woocommerce-shipping-calculator button {
  line-height: 40px !important;
  height: 40px;
  padding: 0 28px !important;
  font-size: 13px !important;
  letter-spacing: 1px;
}
.cart-collaterals .cart_totals h2 {
  font: 15px/16px 'Roboto', Georgia, Helvetica, sans-serif;
  color: #000;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  text-decoration: none;
}
.cart-collaterals table {
  margin-bottom: 20px;
}
.cart-collaterals table th,
.cart-collaterals table td {
  padding: 0 0 8px 0;
}
.cart-collaterals table th {
  color: #767676;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: bottom;
  font-size: 14px;
}
.cart-collaterals table tr.shipping td {
  padding-bottom: 0;
}
.cart-collaterals li {
  list-style: none !important;
}
.cart-collaterals .cart-subtotal > th,
.cart-collaterals .cart-subtotal > td {
  border-top: none;
}
.cart-collaterals .cart-subtotal td {
  padding: 30px 0 4px;
}
.cart-collaterals .cart-subtotal td span {
  font-size: 16px;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.cart-collaterals .cart-discount td {
  padding: 30px 0 4px;
}
.cart-collaterals .cart-discount td span {
  font-size: 16px;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.cart-collaterals .shipping td,
.cart-collaterals .order-discount td,
.cart-collaterals .order-discount th {
  padding: 30px 0 8px;
  vertical-align: bottom;
}
.cart-collaterals .shipping td li,
.cart-collaterals .order-discount td li,
.cart-collaterals .order-discount th li {
  list-style: none !important;
}
.cart-collaterals #shipping_method {
  margin-bottom: 0;
}
.cart-collaterals .shipping th {
  vertical-align: bottom !important;
}
.cart-collaterals .checkout-button {
  line-height: 40px !important;
  height: 40px;
  padding: 0 28px !important;
  font-size: 13px !important;
  letter-spacing: 1px;
  float: right;
  border: none;
  margin-bottom: 20px;
}
.cart-collaterals .order-discount th {
  text-transform: uppercase;
}
.cart-collaterals .order-discount td .amount {
  font-size: 16px;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.cart-collaterals .order-total td,
.cart-collaterals .tax-rate td {
  padding: 30px 0 4px;
  border-bottom: 1px solid #ddd;
}
.cart-collaterals .tax-rate .amount {
  font-size: 16px;
  line-height: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.cart-collaterals .order-total th {
  border-bottom: 1px solid #ddd;
}
.cart-collaterals .order-total strong span {
  font-size: 24px;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.cart-collaterals .btn-footer td {
  padding: 18px 0;
}
.cart-collaterals .btn-footer td .btn {
  width: 100%;
  font-size: 12px;
  padding: 0;
  margin-bottom: 10px;
}
.cart-collaterals tbody > tr > td:first-child {
  text-align: left;
}
.cart-collaterals tbody > tr > td:last-child {
  text-align: right;
}
.shipping_calculator {
  margin-top: 30px;
}
.shipping-calculator-form select {
  width: 100%;
}
.small-coast {
  font: 16px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
}
.ship-title {
  font: 13px/15px 'Merriweather_Italic', Arial, Georgia, sans-serif;
  color: #cda85c;
}
.xlarge-coast {
  font: 24px/20px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
}
.select2-container .select2-choice {
  height: 40px;
  line-height: 40px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.select2-container .select2-choice:hover {
  height: 40px;
  line-height: 40px;
}
/*---------------------------------------------------------------*/
/* Wishlist Table
/*---------------------------------------------------------------*/
.shop_table.wishlist_table td,
.shop_table.wishlist_table th {
  vertical-align: middle !important;
  padding: 25px 16px;
  text-align: center;
}
.shop_table.wishlist_table td.product-name,
.shop_table.wishlist_table th.product-name {
  padding: 25px 16px;
}
.shop_table.wishlist_table td.product-name {
  width: 300px;
  font: 14px/22px 'Roboto', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.shop_table.wishlist_table td.product-name img {
  margin-right: 10px;
}
.shop_table.wishlist_table td.product-add-to-cart {
  width: 190px;
  text-align: center;
}
.shop_table.wishlist_table.empty-table th.product-remove,
.shop_table.wishlist_table.empty-table th.product-thumbnail,
.shop_table.wishlist_table.empty-table th:last-child {
  display: none;
}
.shop_table.wishlist_table.empty-table .wishlist-empty {
  text-align: center !important;
}
.shop_table.wishlist_table .yith-wcwl-share {
  text-align: left;
}
.shop_table.wishlist_table .yith-wcwl-share ul {
  padding-left: 0;
}
.address {
  padding: 45px 15px 40px;
}
.address .col-ct-6 {
  padding-left: 15px;
  padding-right: 15px;
  text-align: start;
  display: inline-block;
}
.address .btn {
  font: 11px/15px 'Roboto', Verdana;
  font-weight: 500;
  margin-top: 10px;
}
.mailto-company {
  color: #cda85c;
}
.mailto-company i.fa {
  font-size: 16px;
  padding-right: 10px;
}
.mailto-company i.fa.fa-envelope-o {
  padding-right: 6px;
}
.wp-picture:hover .zoom,
.swiper-slide:hover .zoom,
.portfolio-image:hover .zoom,
.thumbnails-x:hover .zoom {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
}
.wp-picture .btn_group,
.swiper-slide .btn_group,
.portfolio-image .btn_group,
.thumbnails-x .btn_group {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin: -20px 0 0 0;
  z-index: 2;
  text-align: center;
}
.wp-picture .btn_group a,
.swiper-slide .btn_group a,
.portfolio-image .btn_group a,
.thumbnails-x .btn_group a {
  background: #262626;
  opacity: 0.9;
  color: #FFF !important;
  margin: 0 8px;
  position: relative;
  border: none;
  padding: 13px 15px !important;
  -webkit-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.wp-picture .btn_group a:focus,
.swiper-slide .btn_group a:focus,
.portfolio-image .btn_group a:focus,
.thumbnails-x .btn_group a:focus {
  outline: none;
}
.wp-picture .btn_group a:after,
.swiper-slide .btn_group a:after,
.portfolio-image .btn_group a:after,
.thumbnails-x .btn_group a:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -2px;
  left: -2px;
  -webkit-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.wp-picture .btn_group a:before,
.swiper-slide .btn_group a:before,
.portfolio-image .btn_group a:before,
.thumbnails-x .btn_group a:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -2px;
  right: -2px;
  -webkit-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.wp-picture .btn_group a:hover,
.swiper-slide .btn_group a:hover,
.portfolio-image .btn_group a:hover,
.thumbnails-x .btn_group a:hover {
  opacity: 1;
}
.wp-picture .btn_group a span,
.swiper-slide .btn_group a span,
.portfolio-image .btn_group a span,
.thumbnails-x .btn_group a span {
  text-transform: uppercase;
}
.wp-picture .btn_group a span i,
.swiper-slide .btn_group a span i,
.portfolio-image .btn_group a span i,
.thumbnails-x .btn_group a span i {
  font-size: 16px;
  position: relative;
  left: 1px;
  right: 1px;
}
.wp-picture .btn_group a span:after,
.swiper-slide .btn_group a span:after,
.portfolio-image .btn_group a span:after,
.thumbnails-x .btn_group a span:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -2px;
  left: -2px;
  -webkit-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.wp-picture .btn_group a span:before,
.swiper-slide .btn_group a span:before,
.portfolio-image .btn_group a span:before,
.thumbnails-x .btn_group a span:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  -webkit-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.wp-picture .btn_group a:hover:before,
.swiper-slide .btn_group a:hover:before,
.portfolio-image .btn_group a:hover:before,
.thumbnails-x .btn_group a:hover:before {
  -webkit-box-shadow: 1px -1px 0 1px rgba(0, 0, 0, 0.6);
  box-shadow: 1px -1px 0 1px rgba(0, 0, 0, 0.6);
}
.wp-picture .btn_group a:hover:after,
.swiper-slide .btn_group a:hover:after,
.portfolio-image .btn_group a:hover:after,
.thumbnails-x .btn_group a:hover:after {
  -webkit-box-shadow: -1px -1px 0 1px rgba(0, 0, 0, 0.6);
  box-shadow: -1px -1px 0 1px rgba(0, 0, 0, 0.6);
}
.wp-picture .btn_group a:hover span:before,
.swiper-slide .btn_group a:hover span:before,
.portfolio-image .btn_group a:hover span:before,
.thumbnails-x .btn_group a:hover span:before {
  -webkit-box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.6);
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.6);
}
.wp-picture .btn_group a:hover span:after,
.swiper-slide .btn_group a:hover span:after,
.portfolio-image .btn_group a:hover span:after,
.thumbnails-x .btn_group a:hover span:after {
  -webkit-box-shadow: -1px 1px 0 1px rgba(0, 0, 0, 0.6);
  box-shadow: -1px 1px 0 1px rgba(0, 0, 0, 0.6);
}
.wp-picture .zoom,
.swiper-slide .zoom,
.portfolio-image .zoom,
.thumbnails-x .zoom {
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  opacity: 0;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.wp-picture .zoom > i,
.swiper-slide .zoom > i,
.portfolio-image .zoom > i,
.thumbnails-x .zoom > i {
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: #cda85c;
  opacity: 0.6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.blog-full-width {
  margin-top: 30px;
}
.blog-full-width .row {
  margin-right: 0;
  margin-left: 0;
}
.blog-full-width .row .content {
  padding: 0;
}
.blog-full-width .row .content article {
  margin-left: -1px;
}
.post-slide .btn_group {
  margin-top: -10px;
}
.post-slide .btn_group a {
  padding: 8px 9px;
  font-size: 12px;
}
.blog-post {
  margin: 0 0 60px;
  padding: 0;
  list-style: none;
  float: left;
  width: 100%;
}
.blog-post .content-article p {
  margin-bottom: 10px;
}
.blog-post .content-article h2 {
  font-size: 30px;
  color: #767676;
}
.blog-post .content-article li {
  list-style: inherit;
}
.blog-post .content-article ul,
.blog-post .content-article ol {
  padding-left: 20px;
}
.blog-post ul ul {
  padding-left: 20px;
}
.blog-post .wp-picture {
  margin-bottom: 0;
}
.blog-post h6.active {
  margin-top: 30px;
}
.blog-post .zmedium-h {
  margin-bottom: 5px;
  font: 12px/15px 'Roboto', Georgia, Helvetica, sans-serif;
  font-weight: 700;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.blog-post .zmedium-h a {
  color: #cda85c;
}
.blog-post .zmedium-h a:hover {
  color: #767676;
}
.blog-post .zxmedium-h a:hover {
  color: #767676;
}
.post-default .zmedium-h {
  margin-bottom: 5px;
}
.post-default .zmedium-h a {
  color: #cda85c;
}
.post-default .zmedium-h a:hover {
  color: #767676;
}
.post-default .zxmedium-h a:hover {
  color: #767676;
}
.post-default .wp-picture {
  margin-bottom: 30px;
}
.pag-wrap {
  border-bottom: 1px solid #DDDDDD;
  border-top: 1px solid #000000;
  float: left;
  padding: 18px 0;
  position: relative;
  width: 100%;
  margin-bottom: 90px;
}
.pag-wrap .text-result {
  margin-left: 0;
  float: left !important;
}
.pag-wrap .pagination-cubic {
  margin-bottom: 0;
  text-align: right;
}
@media (min-width: 768px) {
  article.content-small .wp-picture {
    width: 40%;
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  article.content-small .meta-post {
    margin-bottom: 15px;
  }
  article.content-small .meta-post:after {
    display: none;
  }
  article.content-small .more-link {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
  article.content-small .more-link .read-more {
    float: none !important;
  }
  article.content-small h6.active {
    margin-top: 0;
  }
  .col-md-12 article.content-small .wp-picture {
    width: 50%;
  }
}
article.content-mosaic > div {
  border: 1px solid #e5e5e5;
}
article.content-mosaic .more-link {
  display: none;
}
article.content-mosaic .content-article,
article.content-mosaic .meta-post,
article.content-mosaic h2,
article.content-mosaic h6 {
  padding: 0 25px;
}
article.content-mosaic .meta-post:after {
  left: 25px;
}
article.content-timeline {
  position: relative;
  margin-bottom: 35px;
  padding: 70px 0 0;
}
article.content-timeline > div {
  border: 1px solid #e5e5e5;
  padding-bottom: 30px;
}
article.content-timeline > div .content-article,
article.content-timeline > div .meta-post,
article.content-timeline > div h2,
article.content-timeline > div h6 {
  padding: 0 25px;
}
article.content-timeline > div .meta-post:after {
  left: 25px;
}
article.content-timeline > div:after {
  content: "";
  position: absolute;
  width: 1px;
  left: 10px;
  height: 37px;
  bottom: -37px;
  z-index: 0;
  background-color: #e5e5e5;
}
article.content-timeline > div .wp-picture {
  margin-bottom: 0;
}
article.content-timeline > div h6 {
  margin-top: 30px;
}
article.content-timeline div.meta-post-timeline {
  border: none;
  position: absolute;
  z-index: 1;
  top: 0px;
  float: left;
  padding: 10px 30px;
  font-weight: 700;
  color: #FFF;
  background-color: #898989;
}
article.content-timeline div.meta-post-timeline a {
  color: #FFF;
}
article.content-timeline div.meta-post-timeline:after {
  content: "";
  position: absolute;
  width: 1px;
  left: 10px;
  height: 33px;
  top: 38px;
  z-index: 0;
  background-color: #e5e5e5;
}
article.content-timeline .more-link {
  display: none;
}
.login-page {
  margin-bottom: 60px;
}
.login-page h3 {
  margin-bottom: 10px;
}
.login-form,
.register-form {
  margin-bottom: 55px;
}
.login-form .form-group,
.register-form .form-group {
  margin-top: 15px;
}
.login-form .btn,
.register-form .btn {
  font-size: 12px;
  margin-right: 8px;
  width: 170px;
}
.login-form label.inline,
.register-form label.inline {
  font-weight: 400;
  line-height: 12px;
  display: inline-block;
  width: 100%;
}
.login-form label.inline input,
.register-form label.inline input {
  margin-top: 0px;
}
.lost-password {
  color: #767676;
  line-height: 40px;
  margin-left: 10px;
}
.lost_reset_password .button {
  line-height: 40px !important;
  height: 40px;
  padding: 0 28px !important;
  font-size: 13px !important;
  letter-spacing: 1px;
}
.info-group {
  text-align: center;
  font-family: 'OpenSans_Italic';
  padding: 23px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  position: relative;
}
.info-group:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 8px;
  left: 35px;
  top: -8px;
  background-image: url(https://terminal21.co.th/images/white-ribbon.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.terms-checkbox {
  line-height: 40px;
  margin-left: 15px;
  float: left;
}
.terms-checkbox input {
  margin-top: 14px;
}
.register .button {
  margin-top: 0;
  width: 170px;
}
.addresses .address .title {
  width: 100%;
  display: inline-block;
}
.addresses .address .title h3 {
  float: left;
  margin-bottom: 0;
}
.addresses .address .title a {
  float: right;
  font-size: 16px;
  line-height: 30px;
}
.step-nav {
  margin: 22px 0 28px;
  padding: 10px 0 0;
  list-style: none;
  display: inline-block;
  border: 1px solid #000;
}
.step-nav li {
  display: inline-block;
  padding: 4px 10px 0;
  margin-bottom: 12px;
  border-right: 1px solid #C7C7C7;
  text-transform: uppercase;
  font: 15px/15px 'Roboto', Arial, Georgia, sans-serif;
  font-weight: 500;
  color: #c2c2c2;
  letter-spacing: 1px;
}
.step-nav li.lastItem {
  border-right: 0;
}
.step-nav li.active {
  color: #000;
}
.step-nav li a {
  color: #c2c2c2;
}
.step-nav li a:hover {
  text-decoration: none;
  color: #000;
}
.before-checkout-form {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.before-checkout-form .showlogin,
.before-checkout-form .showcoupon {
  color: #cda85c;
}
.before-checkout-form .showlogin:hover,
.before-checkout-form .showcoupon:hover {
  color: #767676;
}
.before-checkout-form .login {
  padding: 15px 0;
  float: left;
}
.before-checkout-form .login .button {
  float: left;
  margin-right: 15px;
}
.before-checkout-form .login label[for="rememberme"] {
  position: relative;
  top: 4px;
}
.before-checkout-form .checkout_coupon {
  margin-top: 10px;
  float: left;
}
.before-checkout-form .checkout_coupon .button {
  height: 40px;
}
.woocommerce-checkout .form-row .chosen-container-single .chosen-single {
  height: 28px !important;
  line-height: 29px !important;
}
.checkout-form {
  margin-top: 20px;
}
.checkout-form label {
  margin-bottom: 10px;
}
.checkout-form textarea {
  resize: none;
  height: 115px;
}
.country_to_state,
.shipping-calculator-form select {
  width: 100%;
  background-position: 98% 50%;
}
.text-warning {
  color: #ff0000;
}
.checkout-form .form-group {
  margin-bottom: 20px;
}
.cust-checkbox a {
  font: 13px/22px 'Roboto', Arial, sans-serif;
  color: #767676;
}
.cust-checkbox a:hover,
.cust-checkbox a:focus {
  text-decoration: none;
}
.cust-checkbox .ffCheckboxWrapper {
  position: relative;
  padding-left: 37px;
  margin-bottom: 20px;
}
.payment_methods li label img {
  max-width: 140px;
  margin: 0 10px;
}
.payment_method_paypal a {
  display: block;
}
.payment_method_paypal input[type=radio] {
  top: -25px;
}
.order-review {
  margin-top: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 32px 22px;
  border: 2px solid #000;
  background: url(https://terminal21.co.th/images/bg-light-strippped.png) repeat;
}
.order-review .step-title {
  margin-bottom: 15px;
  float: left;
  width: 100%;
}
.order-review .shop_table td {
  text-align: right !important;
}
.order-review .shop_table td.product-name {
  text-align: left !important;
}
.order-review #payment .terms {
  position: relative;
}
.order-review #payment .terms input[type="checkbox"] {
  position: absolute;
  top: 1px;
}
.order-review input[type="submit"] {
  line-height: 40px !important;
  height: 40px;
  padding: 0 28px !important;
  font-size: 13px !important;
  letter-spacing: 1px;
  border: none;
}
.shop_table {
  width: 100%;
  margin: 0 0 20px;
  float: left;
}
.shop_table tr > td {
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #dddddd;
  font-size: 15px;
  font-family: 'Roboto';
  vertical-align: middle;
}
.shop_table tr > td.product-name {
  padding: 22px 0;
}
.shop_table tr li {
  list-style: none !important;
}
.shop_table th {
  font: 14px/22px 'Roboto', Arial, Georgia, sans-serif;
  text-transform: uppercase;
  color: #252525;
  vertical-align: middle;
  letter-spacing: 1px;
  padding: 22px 0 !important;
  line-height: 1.42857143;
  font-weight: 500;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}
.shop_table .header td {
  border-top: none;
  padding-bottom: 5px !important;
}
.shop_table .header + tr td {
  border-top-width: 2px;
}
.shop_table .footer td {
  border-bottom: 2px solid #ddd;
  padding: 15px 0 !important;
}
.shop_table thead > tr > td:first-child {
  text-align: left;
}
.shop_table tbody > tr > td:last-child {
  text-align: right;
}
.shop_table td.product-name {
  font: 14px/22px 'Roboto', Arial, Georgia, sans-serif;
}
.shop_table thead tr > th {
  border-top: none;
}
.shop_table thead tr > th:last-child {
  text-align: right;
}
.shop_table .shipping th {
  width: 150px;
}
.shop_table .shipping td {
  font-size: 13px;
}
.shop_table .order-total th {
  line-height: 22px;
}
.shop_table .order-total td {
  font-size: 15px;
  font-family: 'Roboto';
  color: #252525;
}
.order_details thead tr > th:last-child,
.order_details tbody > tr > td:last-child {
  text-align: left;
  padding-left: 0;
}
.order_details tr > td {
  padding-left: 0;
}
.woocommerce-order-received .woocommerce header {
  display: inline-block;
  width: 100%;
}
.woocommerce-order-received .woocommerce h2 {
  border-bottom: 2px solid #d5d5d5;
  text-transform: uppercase;
  font-size: 18px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.woocommerce-order-received .woocommerce > h3 {
  font-size: 16px;
  margin-top: 22px;
  display: inline-block;
}
.woocommerce-order-received .woocommerce h3 {
  margin-bottom: 0;
}
.woocommerce-order-received .woocommerce .customer_details tr:first-child th,
.woocommerce-order-received .woocommerce .customer_details tr:first-child td {
  border-top: none;
}
.woocommerce-order-received .woocommerce .variation {
  margin-bottom: 0;
}
.woocommerce-order-received .woocommerce .variation dt {
  width: auto;
  float: left;
  line-height: 23px;
  padding-right: 5px;
}
.woocommerce-order-received .woocommerce .variation dd p {
  margin-bottom: 0;
}
.woocommerce-order-received .woocommerce ul.order_details {
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
  line-height: 24px;
}
.woocommerce-order-received .woocommerce ul.order_details .total {
  font-weight: 400;
}
.woocommerce-order-received .woocommerce ul.order_details .total strong {
  font-weight: 500;
}
.xmedium-coast {
  font: 20px/15px 'Roboto', Arial, Georgia, sans-serif;
  font-weight: 500;
}
.cart-subtotal th,
.shipping th,
.order-total th {
  font: 14px/15px 'Roboto', Arial, Georgia, sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: bottom !important;
}
.td-xsmall {
  font: 15px/15px 'Roboto', Arial, Georgia, sans-serif;
  color: #252525;
}
.grey-text {
  color: #767676;
}
.payment_methods {
  margin: 0 0 25px;
  padding: 0;
  padding-left: 0 !important;
  list-style: none;
}
.payment_methods p {
  margin-left: 37px;
  color: #767676;
}
.payment_methods li {
  padding: 17px 0;
  border-bottom: 1px solid #ddd;
}
.payment_methods li.paypal-li {
  padding: 5px 0;
}
.payment_methods li label {
  font: 13px/22px 'Roboto', Arial, sans-serif;
  color: #767676;
  padding-left: 21px;
}
.payment_methods .ffCheckboxWrapper {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 37px;
}
.paypal-meth {
  margin-left: 15px;
}
.step-title {
  font: 15px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #252525;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 40px;
  letter-spacing: 1px;
  margin-bottom: 25px;
}
.step-title span {
  background: #fff;
  position: relative;
  z-index: 2;
  padding-right: 7px;
}
.step-title:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  position: absolute;
  background: #000;
  z-index: 1;
}
form .form-row {
  margin-bottom: 20px;
}
form .form-row-first,
form .form-row-last {
  float: left;
  overflow: visible;
  width: 47%;
}
form .form-row-last {
  float: right;
}
form .form-row-wide {
  width: 100%;
}
form .form-row input.input-text,
form .form-row textarea {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  width: 100%;
}
form .form-row textarea {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #d5d5d5;
  box-shadow: none;
  display: block;
  height: 120px;
  line-height: 1.5em;
}
form .form-row label {
  font: 14px/22px 'Roboto', Georgia, Helvetica, sans-serif;
  color: #767676;
}
form .form-row label abbr {
  color: #ff0000;
  cursor: default;
  border: none;
}
.desc-contact p {
  line-height: 22px;
}
.fa-map-marker {
  margin-right: 6px;
  position: relative;
  z-index: 2;
}
.sot {
  font-size: 12px;
}
.desc-contact:hover .pulse {
  opacity: 1;
}
.pulse {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  height: 8px;
  width: 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  opacity: 0;
  margin: 6px 0px 0px -3px;
  -webkit-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 26px;
  width: 26px;
  position: absolute;
  margin: -10px 0 0 -10px;
  opacity: 0;
  -webkit-box-shadow: 0 0 1px 2px #000000;
  box-shadow: 0 0 1px 2px #000000;
  -webkit-animation: pulsate 1s ease-out infinite 1.1s;
  -ms-animation: pulsate 1s ease-out infinite 1.1s;
  -o-animation: pulsate 1s ease-out infinite 1.1s;
  animation: pulsate 1s ease-out infinite 1.1s;
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8, 0.8);
    -o-transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
    opacity: 0;
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8, 0.8);
    -o-transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
    opacity: 0;
  }
}
.contact-form textarea {
  height: 140px;
  resize: none;
}
.contact-form .form-group {
  margin-bottom: 12px;
}
.contact-form label {
  margin-bottom: 10px;
}
.contact-form .submit-group {
  text-align: right;
  margin-top: 20px;
}
.contact-form .submit-group .btn {
  font-size: 12px;
}
.contact-form .button {
  margin-top: 8px;
}
.contact-form.compressed label[for="contact-website"],
.contact-form.compressed #contact-website {
  display: none;
}
.info-book {
  padding-top: 100px;
}
.lookBook-1 .swiper-slide {
  float: left;
  text-align: center;
  cursor: pointer;
  opacity: 0.4;
  max-width: 100%;
  -webkit-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.lookBook-1 .swiper-slide .bott-tools {
  text-align: right;
  padding: 15px 0 12px;
}
.lookBook-1 .swiper-slide .bott-tools ul {
  display: inline-block;
  margin: 0 0 0 15px;
}
.lookBook-1 .swiper-slide:hover {
  opacity: 1;
}
.lookBook-1 .swiper-slide .btn_group {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 92%;
  left: 0;
}
.lookBook-1 .swiper-slide-active {
  opacity: 1;
}
.lookbook-share {
  padding: 15px 0 12px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font: 15px/24px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
  display: inline-block;
  width: 100%;
}
.lookbook-share ul {
  display: inline-block;
  margin: 0 0 0 15px;
  float: right;
}
.lookbook-share ul li {
  margin: 0 8px;
}
.lookbook-share ul li:after {
  display: none;
}
.et-swiper-container {
  margin: 18px auto 70px;
  height: auto;
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  -webkit-transition-property: -webkit-transform, left, top;
  -webkit-transition-duration: 0s;
  -webkit-transform: translate3d(0px, 0, 0);
  -webkit-transition-timing-function: ease;
  -moz-transition-property: -moz-transform, left, top;
  -moz-transition-duration: 0s;
  -moz-transform: translate3d(0px, 0, 0);
  -moz-transition-timing-function: ease;
  -o-transition-property: -o-transform, left, top;
  -o-transition-duration: 0s;
  -o-transform: translate3d(0px, 0, 0);
  -o-transition-timing-function: ease;
  -o-transform: translate(0px, 0px);
  -ms-transition-property: -ms-transform, left, top;
  -ms-transition-duration: 0s;
  -ms-transform: translate3d(0px, 0, 0);
  -ms-transition-timing-function: ease;
  transition-property: transform, left, top;
  transition-duration: 0s;
  transform: translate3d(0px, 0, 0);
  transition-timing-function: ease;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.quote-border {
  font: 13px/24px 'OpenSans_Italic', Arial, Verdana;
  color: #767676;
  background: url(https://terminal21.co.th/images/bg-light-strippped.png) repeat;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 25px 0 85px;
  padding: 25px 25px;
  position: relative;
}
.quote-border:before {
  content: "\f10d";
  font-family: FontAwesome;
  font-size: 40px;
  color: #767676;
  position: absolute;
  z-index: -1;
  left: 26px;
  top: 16px;
  opacity: 0.1;
}
/*========================================*/
/*========================================*/
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 20px;
}
table th,
table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: middle;
  border-top: 1px solid #efefef;
}
table th {
  font-weight: bold;
}
table thead th {
  vertical-align: bottom;
}
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 0;
}
table tbody + tbody {
  border-top: 1px solid #dddddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}
.table-dashed,
.table-dashed > thead > tr > th,
.table-dashed > tbody > tr > th,
.table-dashed > tfoot > tr > th,
.table-dashed > thead > tr > td,
.table-dashed > tbody > tr > td,
.table-dashed > tfoot > tr > td {
  border-width: 1px;
  border-color: #ddd;
}
.woocommerce-message,
.alert-success,
.success,
.woocommerce-error,
.alert-error,
.error,
.woocommerce-info,
.alert-info,
.info,
.woocommerce-warning,
.alert-warning,
.warning {
  border: 1px solid transparent;
  font-size: 13px;
  color: #767676;
  padding: 15px;
  float: left;
  width: 100%;
  position: relative;
  padding-left: 60px !important;
  line-height: 24px;
  margin-bottom: 20px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.woocommerce-message li,
.alert-success li,
.success li,
.woocommerce-error li,
.alert-error li,
.error li,
.woocommerce-info li,
.alert-info li,
.info li,
.woocommerce-warning li,
.alert-warning li,
.warning li {
  list-style: none !important;
}
.woocommerce-message:before,
.alert-success:before,
.success:before,
.woocommerce-error:before,
.alert-error:before,
.error:before,
.woocommerce-info:before,
.alert-info:before,
.info:before,
.woocommerce-warning:before,
.alert-warning:before,
.warning:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 25px;
  margin-top: -11px;
  line-height: 1;
  font-size: 24px;
  font-family: FontAwesome;
}
.woocommerce-message .button,
.alert-success .button,
.success .button,
.woocommerce-error .button,
.alert-error .button,
.error .button,
.woocommerce-info .button,
.alert-info .button,
.info .button,
.woocommerce-warning .button,
.alert-warning .button,
.warning .button {
  float: right;
}
.woocommerce-message,
.alert-success,
.success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.woocommerce-message:before,
.alert-success:before,
.success:before {
  content: "\f00c";
}
.woocommerce-error,
.alert-error,
.error {
  background-color: #f2dede;
  border-color: #ebccd1;
}
.woocommerce-error:before,
.alert-error:before,
.error:before {
  content: "\f00d";
}
.woocommerce-info,
.alert-info,
.info,
.info {
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.woocommerce-info:before,
.alert-info:before,
.info:before,
.info:before {
  content: "\f129";
}
.woocommerce-warning,
.alert-warning,
.warning,
.warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.woocommerce-warning:before,
.alert-warning:before,
.warning:before,
.warning:before {
  content: "\f12a";
}
.widget_nav_menu {
  display: inline-block;
  width: 100%;
}
.widget_nav_menu .widget-title {
  margin-bottom: -1px;
}
.widget_nav_menu .widget-title span {
  background: none;
}
.widget_nav_menu .widget-title:after {
  display: none;
}
.widget_nav_menu li {
  border-bottom: 1px solid #ddd;
  position: relative;
  display: inline-block;
  width: 100%;
}
.widget_nav_menu li a {
  font: 14px/15px 'Roboto', Georgia, Helvetica, sans-serif;
  padding: 15px 0;
  color: #767676 !important;
  display: inherit;
  text-transform: none;
}
.widget_nav_menu li a:after {
  display: none;
}
.widget_nav_menu li a .list-group-item-heading {
  text-transform: none;
}
.widget_nav_menu li a:hover {
  color: #000 !important;
}
.widget_nav_menu li.current-menu-item a {
  background-color: #fff;
  color: #cda85c;
}
.widget_nav_menu li.current-menu-item a:before {
  color: #cda85c;
}
.widget_nav_menu li .sub-menu li:first-child {
  border-top: 1px solid #ddd;
}
.widget_nav_menu li .sub-menu li:last-child {
  border-bottom: none;
}
.widget_nav_menu li .sub-menu li a:before {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  content: "\f105";
  -webkit-font-smoothing: antialiased;
  float: left;
  line-height: 16px;
  font-size: 16px;
  margin-right: 10px;
  padding-left: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.widget_archive li,
.widget_recent_comments li {
  line-height: 1.8;
}
.widget_archive select,
.widget_recent_comments select {
  width: 100%;
}
#calendar_wrap table {
  width: 100%;
}
#calendar_wrap caption {
  font-size: 14px;
  margin-bottom: 10px;
}
.widget_pages li,
.widget_rss li,
.widget_meta li {
  line-height: 1.8;
}
.widget_rss li {
  margin-bottom: 10px;
}
.sidebar-widget select {
  max-width: 100%;
}
/* Wordpress gallery */
.et-gallery {
  margin-bottom: 30px;
}
.et-gallery .gallery-preview {
  cursor: pointer;
  margin-bottom: 10px;
}
.et-gallery .gallery {
  margin: 0 -5px !important;
}
.et-gallery .gallery-item {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0 5px 0 5px;
}
.et-gallery .gallery-item.last-child {
  padding-right: 0;
}
.et-gallery .gallery-item:hover {
  opacity: 0.8;
}
.wpb_images_grid_ul .gallery-item {
  display: inline-block;
  padding: 1px !important;
}
.body-shortcode p {
  color: #767676;
  font-family: 'Open Sans', Arial, sans-serif;
}
.body-shortcode ul {
  color: #767676;
  font-family: 'Open Sans', Arial, sans-serif;
  line-height: 1.5;
  font-size: 13px;
}
.body-shortcode strong {
  color: #000;
}
/* alert */
.alert {
  overflow: auto;
}
.alert i {
  float: left;
  margin-right: 10px;
  font-size: 30px;
  margin-top: 5px;
}
.alert .body-alert {
  margin-left: 45px;
  display: block;
}
.alert .fa-exclamation {
  margin: 7px;
}
/*  dropcap */
.dropcap {
  background: none repeat scroll 0 0 #C4C4C4;
  color: #fff;
  display: inline-block;
  float: left;
  font-family: Georgia;
  font-size: 36px;
  font-weight: normal;
  line-height: 1.2;
  margin: 3px 9px 0 0;
  text-align: center;
  width: 43px;
}
.dropcap.light {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  color: #000000;
}
.cta-block {
  -moz-box-sizing: border-box;
  float: left;
  margin-bottom: 0;
  padding: 20px 40px;
  transition: all 0.3s ease-in-out 0s;
  width: 100%;
}
.cta-block h1,
.cta-block h2,
.cta-block h3,
.cta-block h4,
.cta-block h5,
.cta-block h6 {
  margin-bottom: 5px;
}
.cta-block .button-left {
  padding-right: 40px;
  width: 25%;
}
.cta-block .button-right {
  text-align: right;
  width: 25%;
}
.cta-block.style-without-border {
  border: medium none;
  padding: 0;
}
.cta-block.style-filled {
  background-color: #FCFCFC;
  border-bottom-color: #DBDBDB;
  border-left-width: 3px;
  border-right-color: #DBDBDB;
  border-top-color: #DBDBDB;
}
.cta-block.style-dark {
  background: none repeat scroll 0 0 #404040;
  border: medium none;
  color: #8B8B8B;
}
.cta-block.style-dark .btn {
  border: 2px solid #fff;
}
.cta-block.style-dark h1,
.cta-block.style-dark h2,
.cta-block.style-dark h3,
.cta-block.style-dark h4,
.cta-block.style-dark h5,
.cta-block.style-dark h6,
.cta-block.style-dark a {
  color: #FFFFFF;
}
.cta-block.style-fullwidth {
  position: relative;
  background: #2E2E2E;
  width: 100%;
  padding: 40px 25px;
  margin-left: 15px;
  border: none;
  text-align: center;
  -webkit-transition: backcground-color 0.2s ease-in-out;
  -moz-transition: backcground-color 0.2s ease-in-out;
  -ms-transition: backcground-color 0.2s ease-in-out;
  -o-transition: backcground-color 0.2s ease-in-out;
  transition: backcground-color 0.2s ease-in-out;
}
.cta-block.style-fullwidth h1 {
  letter-spacing: 1px;
}
.cta-block.style-fullwidth:hover {
  cursor: pointer;
  background-color: #4f4f4f;
}
.cta-block.style-fullwidth:hover i {
  color: #a5a5a5;
}
.footer-top-1 .cta-block.style-fullwidth {
  display: none;
  background-color: #cda85c;
}
.footer-top-1 .cta-block.style-fullwidth .fa-star-o {
  color: #FFF;
}
.footer-top-1 .wpb_widgetised_column_heading {
  border-bottom: 1px solid #000;
  color: #000;
}
.footer-top-1 .carousel-area .title {
  border-top: none;
}
footer .etheme_widget_recent_entries .owl-controls {
  display: none !important;
}
.footer-top .margin0 .cta-block.style-fullwidth {
  margin-left: 0;
}
.table-row {
  display: table;
  width: 100%;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
}
.cta-block .active {
  color: #cda85c;
}
pre {
  white-space: pre-line;
}
.toCall {
  width: 50px;
  height: 50px;
  display: inline-block;
}
.all-fontAwesome .fa-hover a {
  border-radius: 4px;
  color: #222222;
  display: block;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  -webkit-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.all-fontAwesome .fa-hover a:hover,
.all-fontAwesome .fa-hover a:hover span {
  background-color: #cda85c;
  color: #fff;
  text-decoration: none;
}
.list ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 15px;
}
.list li {
  display: inline-block;
  padding: 5px 0 5px 5px;
}
.list li:before {
  content: "\f105";
  float: left;
  font-family: "FontAwesome";
  font-size: 12px;
  color: #cda85c;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  margin-right: 10px;
  text-decoration: inherit;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.list.list-circle li:before {
  content: "\f111";
  font-size: 9px;
  margin-top: 3px;
}
.list.list-star li:before {
  content: "\f005";
}
.list.list-square li:before {
  content: "\f0c8";
  font-size: 6px;
  margin-top: 1px;
}
.list.list-dash li:before {
  content: "\f068";
  margin-top: 3px;
}
.pricing-table table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 30px;
}
.pricing-table table .plan-price {
  background: none repeat scroll 0 0 #FAFAFA;
  color: #999999;
  font-family: Georgia;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.2;
}
.pricing-table table.table th,
.pricing-table table.table td {
  border-left: 1px solid #DDDDDD;
  padding: 15px;
  text-align: center;
}
.pricing-table table.table th:last-child,
.pricing-table table.table td:last-child {
  border-right: 1px solid #DDDDDD;
}
.pricing-table table.table th.selected,
.pricing-table table.table td.selected {
  background: none repeat scroll 0 0 #FCFCFC;
}
.pricing-table table.table thead:first-child tr:first-child th {
  border-top: 1px solid #DDDDDD;
  color: #292D33;
  font-family: Georgia;
  font-size: 18px;
  text-transform: uppercase;
}
.pricing-table table.table tfoot tr:last-child td,
.pricing-table table.table tfoot tr:last-child th {
  border-bottom: 1px solid #DDDDDD;
}
.pricing-table.style3 table .plan-price,
.pricing-table.style2 table .plan-price {
  background: none repeat scroll 0 0 #333333;
  border-color: #333333 !important;
  color: #FFFFFF;
  font-size: 48px;
}
.pricing-table.style3 table .plan-price sup,
.pricing-table.style2 table .plan-price sup {
  color: #BABABA;
  font-family: Georgia;
  font-size: 18px;
  font-weight: normal;
  margin: 0 4px;
  top: -0.7em;
}
.pricing-table.style3 table .plan-price sub,
.pricing-table.style2 table .plan-price sub {
  bottom: 0;
  color: #6A6A6A;
  font-family: "Open Sans", Arial;
  font-size: 12px;
  margin: 0 4px 0 -24px;
}
.pricing-table.style3 table .plan-price {
  background: none repeat scroll 0 0 #FAFAFA;
  border-color: #DDDDDD !important;
  color: #6F6F6F;
}
.pricing-table.style3 table .plan-price sup {
  color: #6F6F6F;
}
.pricing-table.style3 table .plan-price sub {
  color: #6F6F6F;
}
.pricing-table ul {
  -moz-box-sizing: border-box;
  float: left;
  list-style: none outside none;
  margin: 0 0 30px -1px;
  padding: 0;
}
.pricing-table ul li {
  background-color: #FFFFFF;
  border-bottom: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  border-right: 1px solid #EBEBEB;
  padding: 20px;
  text-align: center;
}
.pricing-table ul li.row-title {
  border-top: 1px solid #EBEBEB;
  color: #000000;
  font-family: Georgia;
  font-size: 20px;
  text-transform: uppercase;
}
.pricing-table ul li.row-price {
  background: none repeat scroll 0 0 #FAFAFA;
  color: #cda85c;
  font-family: Georgia;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.2;
  padding: 10px;
}
.pricing-table ul.selected-column {
  position: relative;
  top: -20px;
}
.pricing-table ul.selected-column li {
  background: none repeat scroll 0 0 #FCFCFC;
}
.pricing-table ul.selected-column li.row-title {
  padding: 30px 20px;
}
.pricing-table ul.selected-column li.row-price {
  padding: 10px;
}
.pricing-table ul.selected-column li:last-child {
  padding: 30px 20px;
}
.pricing-table ul:first-child {
  margin-left: 0;
}
.pricing-table.style2 ul li.row-price {
  background: none repeat scroll 0 0 #333333;
  border-color: #464646;
  color: #FFFFFF;
  font-size: 30px;
}
.pricing-table.style2 ul li.row-price sup {
  color: #BABABA;
  font-family: Georgia;
  font-size: 18px;
  font-weight: normal;
  margin: 0 4px;
  top: -0.4em;
}
.pricing-table.style2 ul li.row-price sub {
  bottom: -5px;
  color: #6A6A6A;
  font-family: "Open Sans", Arial;
  font-size: 12px;
  margin: 0 4px 0 -24px;
}
.pricing-table.style2 ul.selected-column li {
  background: none repeat scroll 0 0 #333333;
  border-color: #464646;
  color: #FFFFFF;
}
.pricing-table.style2 ul.selected-column li.row-price {
  background: none repeat scroll 0 0 #1F1F1F;
  border-color: #1F1F1F;
}
.pricing-table.style3 ul li.row-price {
  background: none repeat scroll 0 0 #FAFAFA;
  border-color: #DDDDDD;
  color: #cda85c;
  font-size: 30px;
}
.pricing-table.style3 ul li.row-price sup {
  color: #cda85c;
  font-family: Georgia;
  font-size: 18px;
  font-weight: normal;
  margin: 0 4px;
  top: -0.4em;
}
.pricing-table sup,
.pricing-table sub {
  float: none !important;
}
.pricing-table.style3 ul li.row-price sub {
  bottom: -5px;
  color: #cda85c;
  font-family: "Open Sans", Arial;
  font-size: 12px;
  margin: 0 4px 0 -24px;
}
.smile_icon_list.no_bg .icon_list_icon {
  background: rgba(189, 22, 22, 0.79) !important;
}
.ult-just-icon-wrapper {
  float: none;
  display: inline-block;
  margin: 0 7px;
  text-align: center;
}
.pricing-table.columns2 > ul {
  width: 50%;
}
.pricing-table.columns3 > ul {
  width: 33%;
}
.pricing-table.columns4 > ul {
  width: 25%;
}
.pricing-table.columns5 > ul {
  width: 20%;
}
.tabs {
  margin-bottom: 20px;
  position: relative;
}
.tabs .tab-title:hover {
  text-decoration: underline;
}
.tabs .tab-title:first-child {
  margin-left: 20px;
}
.tabs .tab-title.active {
  border-bottom: 1px solid #FFFFFF;
  color: #000000;
}
.tabs .tab-title > i {
  margin-right: 10px;
}
.custom-tabs a:hover {
  text-decoration: none !important;
  background: #fff !important;
}
.tabs .tab-content {
  -moz-box-sizing: border-box;
  border: 1px solid #E9E9E9;
  display: none;
  float: right;
  margin-bottom: 30px;
  margin-top: -1px;
  padding: 30px;
  width: 100%;
}
.tab-content .list li {
  color: #767676;
  line-height: 20px;
}
.tab-content .list li:before {
  line-height: 20px;
}
.tabs.accordion {
  border: none;
  width: 100%;
}
.tabs.accordion .comment-reply-link {
  top: auto;
  bottom: 20px;
}
.tabs.accordion .tabs-nav,
.tabs.accordion .wpb_tabs_nav {
  border-top: none;
  border-bottom: none;
  background-color: transparent !important;
}
.tabs.accordion .tabs-nav li,
.tabs.accordion .wpb_tabs_nav li {
  width: 100%;
}
.tabs.accordion .wpb_tab {
  background-color: transparent !important;
  padding: 0 !important;
}
@media only screen and (min-width: 992px) {
  .tabs.accordion .tabs-nav li {
    float: left;
  }
}
.tabs.accordion .tab-title,
.tabs.accordion .wpb_tabs_nav li a {
  font: 14px/15px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
  display: block;
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #D5D5D5;
  position: relative;
  border-left: none;
  border-right: none;
  padding: 13px 0 !important;
  padding-left: 0;
  margin: 0;
  margin-top: -1px;
}
.tabs.accordion .tab-title:hover,
.tabs.accordion .wpb_tabs_nav li a:hover,
.tabs.accordion .tab-title:focus,
.tabs.accordion .wpb_tabs_nav li a:focus {
  text-decoration: none !important;
  color: #cda85c;
}
.tabs.accordion .tab-title:after,
.tabs.accordion .wpb_tabs_nav li a:after {
  display: none;
}
.tabs.accordion .tab-title.opened:before,
.tabs.accordion .wpb_tabs_nav li a.opened:before {
  position: static;
  border-width: 0px !important;
}
.tabs.accordion .wpb_tabs_nav li {
  margin: 10px 0;
  background-color: transparent !important;
}
.tabs.accordion .tab-title:first-child {
  margin-left: 0;
  margin-right: 0;
}
.tabs.accordion .active a {
  background: #F7F7F7;
  cursor: default;
}
.tabs.accordion .tab-content {
  margin-bottom: 0;
  margin-top: 0 !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none !important;
  padding: 0 !important;
}
.tabs.accordion .tab-content .tab-content-inner {
  padding: 30px 0;
}
.tabs-after_image .tabs .tab-title:before {
  content: none;
}
.tabs.accordion .tab-title.opened {
  border: 1px solid #000;
  border-left: none;
  border-right: none;
}
.tabs.accordion .tab-title.opened:before {
  content: '';
  display: block;
  bottom: -9px;
  left: 50%;
  margin-left: -4px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #000;
  border-width: 4px 4px !important;
  background-color: transparent;
}
.tabs-after_image .tabs.accordion .tab-content {
  border: none !important;
  padding: 0 !important;
}
.tabs-after_image .tabs.accordion .tab-content .tab-content-inner {
  padding: 30px 0;
}
.left-bar,
.right-bar {
  float: left;
  border: 1px solid #ddd !important;
  width: 100%;
}
.left-bar .left-titles,
.right-bar .left-titles {
  float: left;
  height: 100%;
  border-right: 1px solid #ddd;
  width: 20%;
}
.left-bar .left-titles .tab-title-left,
.right-bar .left-titles .tab-title-left {
  display: block;
  padding: 20px 30px;
  border-style: solid;
  border-color: #ddd;
  border-width: 1px 1px 0 0;
  margin-right: -1px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.left-bar .left-titles .tab-title-left:hover,
.right-bar .left-titles .tab-title-left:hover {
  text-decoration: underline;
  background-color: #FFF;
}
.left-bar .left-titles .tab-title-left.opened,
.right-bar .left-titles .tab-title-left.opened {
  background-color: #FFF !important;
  border-right: none;
}
.left-bar .left-titles .tab-title-left.opened:after,
.right-bar .left-titles .tab-title-left.opened:after {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0px;
  left: -1px;
}
.left-bar .left-titles .tab-title-left.opened:before,
.right-bar .left-titles .tab-title-left.opened:before {
  content: '';
  display: block;
  bottom: 50%;
  left: 0;
  margin-bottom: -4px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #000;
  border-width: 4px 4px;
}
.left-bar .left-titles .tab-title-left:first-child,
.right-bar .left-titles .tab-title-left:first-child {
  border-top: none;
}
.left-bar .left-titles .tab-title-left:last-child,
.right-bar .left-titles .tab-title-left:last-child {
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
}
.left-bar .wpb_tabs_nav,
.right-bar .wpb_tabs_nav,
.left-bar .tabs-nav,
.right-bar .tabs-nav {
  width: 30%;
  float: left;
  margin-bottom: 0;
  border-bottom: none;
  border-top: none;
}
.left-bar .wpb_tabs_nav li,
.right-bar .wpb_tabs_nav li,
.left-bar .tabs-nav li,
.right-bar .tabs-nav li {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  border-bottom: 1px solid #e6e6e6;
  border-top: none;
  display: block;
}
.left-bar .wpb_tabs_nav li.ui-tabs-active,
.right-bar .wpb_tabs_nav li.ui-tabs-active,
.left-bar .tabs-nav li.ui-tabs-active,
.right-bar .tabs-nav li.ui-tabs-active {
  background-color: #FFF !important;
}
.left-bar .wpb_tabs_nav li.ui-tabs-active a,
.right-bar .wpb_tabs_nav li.ui-tabs-active a,
.left-bar .tabs-nav li.ui-tabs-active a,
.right-bar .tabs-nav li.ui-tabs-active a {
  border-right-color: #fff;
}
.left-bar .wpb_tabs_nav li.ui-tabs-active a:after,
.right-bar .wpb_tabs_nav li.ui-tabs-active a:after,
.left-bar .tabs-nav li.ui-tabs-active a:after,
.right-bar .tabs-nav li.ui-tabs-active a:after {
  display: none;
}
.left-bar .wpb_tabs_nav li:last-child,
.right-bar .wpb_tabs_nav li:last-child,
.left-bar .tabs-nav li:last-child,
.right-bar .tabs-nav li:last-child {
  margin-bottom: -1px;
}
.left-bar .wpb_tabs_nav li a,
.right-bar .wpb_tabs_nav li a,
.left-bar .tabs-nav li a,
.right-bar .tabs-nav li a {
  display: block;
  padding: 20px 30px !important;
  border-style: solid;
  border-color: #ddd;
  border-width: 1px 1px 0 0;
  margin-right: -1px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  margin-top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.left-bar .wpb_tabs_nav li a:hover,
.right-bar .wpb_tabs_nav li a:hover,
.left-bar .tabs-nav li a:hover,
.right-bar .tabs-nav li a:hover {
  text-decoration: underline;
  background-color: #FFF;
}
.left-bar .wpb_tabs_nav li a.opened,
.right-bar .wpb_tabs_nav li a.opened,
.left-bar .tabs-nav li a.opened,
.right-bar .tabs-nav li a.opened {
  background-color: #FFF !important;
  border-right: none;
}
.left-bar .wpb_tabs_nav li a.opened:after,
.right-bar .wpb_tabs_nav li a.opened:after,
.left-bar .tabs-nav li a.opened:after,
.right-bar .tabs-nav li a.opened:after {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0px;
  left: -1px;
}
.left-bar .wpb_tabs_nav li a.opened:before,
.right-bar .wpb_tabs_nav li a.opened:before,
.left-bar .tabs-nav li a.opened:before,
.right-bar .tabs-nav li a.opened:before {
  content: '';
  display: block;
  bottom: 50%;
  left: 0;
  margin-bottom: -4px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #000;
  border-width: 4px 4px;
}
.left-bar .wpb_tabs_nav li a:first-child,
.right-bar .wpb_tabs_nav li a:first-child,
.left-bar .tabs-nav li a:first-child,
.right-bar .tabs-nav li a:first-child {
  border-top: none;
}
.left-bar .wpb_tabs_nav li a:last-child,
.right-bar .wpb_tabs_nav li a:last-child,
.left-bar .tabs-nav li a:last-child,
.right-bar .tabs-nav li a:last-child {
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
}
.left-bar .tab-content,
.right-bar .tab-content,
.left-bar .wpb_tab,
.right-bar .wpb_tab {
  float: right!important;
  width: 70%;
  margin: 0 0 0 0;
  position: relative;
  padding-left: 20px !important;
  padding-right: 20px;
  min-height: 1px !important;
  border-left: 1px solid #ddd !important;
  background-color: transparent !important;
}
.right-bar {
  float: left;
  border: 1px solid #ddd !important;
  width: 100%;
}
.right-bar .left-titles {
  float: right;
  margin-left: -1px;
  border-left: 1px solid #ddd;
  width: 20%;
  position: relative;
  z-index: 1;
}
.right-bar .wpb_tabs_nav,
.right-bar .tabs-nav {
  float: right;
}
.right-bar .wpb_tabs_nav li.ui-tabs-active,
.right-bar .tabs-nav li.ui-tabs-active {
  background-color: #FFF !important;
}
.right-bar .wpb_tabs_nav li.ui-tabs-active a,
.right-bar .tabs-nav li.ui-tabs-active a {
  border-right-color: #ddd;
  border-left-color: #FFF;
}
.right-bar .wpb_tabs_nav li.ui-tabs-active a:after,
.right-bar .tabs-nav li.ui-tabs-active a:after {
  display: none;
}
.right-bar .wpb_tabs_nav li a,
.right-bar .tabs-nav li a {
  display: block;
  padding: 20px 30px;
  border-style: solid;
  border-color: #ddd;
  border-width: 1px 1px 0 1px;
  margin-left: 0;
  text-transform: uppercase;
  position: relative;
}
.right-bar .wpb_tabs_nav li a:hover,
.right-bar .tabs-nav li a:hover {
  text-decoration: underline;
  background-color: #FFF;
}
.right-bar .wpb_tabs_nav li a.opened,
.right-bar .tabs-nav li a.opened {
  background-color: #FFF !important;
  border-left-color: #fff;
}
.right-bar .wpb_tabs_nav li a.opened:after,
.right-bar .tabs-nav li a.opened:after {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0px;
  right: -1px;
  left: auto;
}
.right-bar .wpb_tabs_nav li a.opened:before,
.right-bar .tabs-nav li a.opened:before {
  content: '';
  display: block;
  bottom: 50%;
  right: 0;
  left: auto;
  margin-bottom: -4px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #000;
  border-width: 4px 4px;
}
.right-bar .wpb_tabs_nav li a:first-child,
.right-bar .tabs-nav li a:first-child {
  border-top: none;
}
.right-bar .wpb_tabs_nav li a:last-child,
.right-bar .tabs-nav li a:last-child {
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
}
.right-bar .tab-content,
.right-bar .wpb_tab {
  float: left !important;
  margin: 0;
  padding: 20px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-left: none !important;
  border-right: 1px solid #e9e9e9 !important;
  width: 70% !important;
  min-height: 1px !important;
  position: relative;
  right: -1px;
}
.left-titles a:hover,
.tab-title-left:hover {
  text-decoration: none !important;
  color: #cda85c;
  background: none !important;
}
.progress-bar {
  background: #cda85c;
  height: 28px;
  margin-bottom: 10px;
  position: relative;
  color: #fff;
}
.progress-bar > div {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
.progress-bar > span {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 300;
  left: 0;
  line-height: 28px;
  margin-left: 15px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}
.ico-tab {
  font-size: 40px;
  color: #ccc;
  float: left;
}
.body-text-tab {
  margin-left: 60px;
  padding-top: 10px;
}
.table-dashed,
.table-dashed > thead > tr > th,
.table-dashed > tbody > tr > th,
.table-dashed > tfoot > tr > th,
.table-dashed > thead > tr > td,
.table-dashed > tbody > tr > td,
.table-dashed > tfoot > tr > td {
  border-style: dashed !important;
}
.team-member {
  text-align: center;
}
.team-member .member-image {
  margin-bottom: 20px;
}
.team-member .member-image img:hover {
  opacity: 0.8;
}
.team-member .member-details {
  color: #767676;
}
.team-member .member-details h4 {
  font-size: 16px;
  color: #252525;
}
.team-member .member-details h5 {
  text-transform: none;
  font-size: 12px;
  text-transform: uppercase;
  color: #cda85c;
}
.team-member .member-details h5:hover {
  color: #767676;
}
.team-member .member-details .menu-social-icons {
  width: 100%;
  margin: 10px 0 !important;
}
.team-member .member-details .menu-social-icons li {
  float: none;
  display: inline-block;
}
.team-member.member-type-2 {
  text-align: left !important;
}
.team-member.member-type-2 .member-details {
  color: #767676;
}
.team-member.member-type-2 .member-details .menu-social-icons {
  text-align: left;
}
.team-member.member-type-2 .member-details h4 {
  color: #333;
}
.team-member.member-type-2 .member-details p.member-email {
  margin-bottom: 10px !important;
}
/*---------------------------------------------------------------*/
/* Blockquote
/*---------------------------------------------------------------*/
blockquote {
  font: 13px/24px 'OpenSans_Italic', Helvetica, sans-serif;
  color: #767676;
  padding: 20px;
  padding-left: 56px;
  position: relative;
  border: none;
  margin: 20px 0 20px;
}
blockquote:before {
  content: "\f10d";
  font-family: FontAwesome;
  color: #767676;
  font-size: 40px;
  position: absolute;
  top: 0;
  left: 0;
}
blockquote.style2 {
  background: #fcfcfc no-repeat 10px 10px;
  min-height: 50px;
}
blockquote.style2:before {
  opacity: 0.1;
  left: 15px;
  top: 15px;
}
.format-quote blockquote {
  background: url(https://terminal21.co.th/images/pattern.jpg) repeat;
  text-align: center;
  font: 24px/36px 'Roboto_Light', sans-serif;
  color: #292929;
  font-weight: 300;
  padding: 55px 40px;
}
.format-quote blockquote p {
  position: relative;
  z-index: 1;
}
.format-quote blockquote:before {
  content: "";
  background-image: url(https://terminal21.co.th/images/quotes-white.png);
  background-repeat: no-repeat;
  width: 118px;
  height: 102px;
  right: 0;
  left: auto;
}
.format-quote blockquote footer {
  font-size: 14px;
}
.format-quote blockquote footer:before {
  display: none;
}
.format-gallery .post-gallery-slider {
  margin-bottom: 30px;
}
.format-gallery .post-gallery-slider .owl-controls {
  width: 100%;
  top: 50%;
  margin-top: -23px;
}
.format-gallery .post-gallery-slider .owl-controls .owl-buttons div {
  background-image: none;
  width: 45px;
  height: 45px;
  position: absolute;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #eee;
  text-align: center;
  font-size: 0;
  text-indent: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.format-gallery .post-gallery-slider .owl-controls .owl-buttons div:before {
  font-family: FontAwesome;
  font-size: 30px;
  position: absolute;
  color: #fff;
  line-height: 41px;
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.format-gallery .post-gallery-slider .owl-controls .owl-buttons div:hover {
  background-color: #fff !important;
  opacity: 1;
  border-color: #fff;
}
.format-gallery .post-gallery-slider .owl-controls .owl-buttons div:hover:before {
  color: #252525 !important;
}
.format-gallery .post-gallery-slider .owl-controls .owl-buttons .owl-prev {
  left: -20px;
}
.format-gallery .post-gallery-slider .owl-controls .owl-buttons .owl-prev:before {
  content: "\f104";
  left: -1px;
}
.format-gallery .post-gallery-slider .owl-controls .owl-buttons .owl-next {
  right: -20px;
}
.format-gallery .post-gallery-slider .owl-controls .owl-buttons .owl-next:before {
  content: "\f105";
  left: 1px;
}
.format-gallery .post-gallery-slider .owl-item {
  text-align: center;
}
.format-gallery .post-gallery-slider:hover .owl-buttons div {
  opacity: 1;
}
.format-gallery .post-gallery-slider:hover .owl-buttons .owl-next {
  right: 20px;
}
.format-gallery .post-gallery-slider:hover .owl-buttons .owl-prev {
  left: 20px;
}
.wpb_gallery .wpb_gallery_slides .owl-controls {
  width: 100%;
  top: 50%;
  margin-top: -23px;
}
.wpb_gallery .wpb_gallery_slides .owl-controls .owl-buttons div {
  background-image: none;
  width: 45px;
  height: 45px;
  position: absolute;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #fff;
  text-align: center;
  font-size: 0;
  text-indent: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.wpb_gallery .wpb_gallery_slides .owl-controls .owl-buttons div:before {
  font-family: FontAwesome;
  font-size: 30px;
  position: absolute;
  color: #fff;
  line-height: 41px;
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.wpb_gallery .wpb_gallery_slides .owl-controls .owl-buttons div:hover {
  background-color: #fff !important;
  opacity: 1;
}
.wpb_gallery .wpb_gallery_slides .owl-controls .owl-buttons div:hover:before {
  color: #252525 !important;
}
.wpb_gallery .wpb_gallery_slides .owl-controls .owl-buttons .owl-prev {
  left: -20px;
}
.wpb_gallery .wpb_gallery_slides .owl-controls .owl-buttons .owl-prev:before {
  content: "\f104";
  left: -1px;
}
.wpb_gallery .wpb_gallery_slides .owl-controls .owl-buttons .owl-next {
  right: -20px;
}
.wpb_gallery .wpb_gallery_slides .owl-controls .owl-buttons .owl-next:before {
  content: "\f105";
  left: 1px;
}
.wpb_gallery .wpb_gallery_slides:hover .owl-buttons div {
  opacity: 1;
}
.wpb_gallery .wpb_gallery_slides:hover .owl-buttons .owl-next {
  right: 20px;
}
.wpb_gallery .wpb_gallery_slides:hover .owl-buttons .owl-prev {
  left: 20px;
}
/*---------------------------------------------------------------*/
/* Testimonials Slider
/*---------------------------------------------------------------*/
blockquote.testimonials-text {
  background-color: #FFF;
  border: 1px solid #d5d5d5;
  color: #767676;
  font-size: 13px;
  position: relative;
  padding: 20px;
}
blockquote.testimonials-text:before {
  bottom: -32px;
  top: auto;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 213, 213, 0);
  border-top-color: #D5D5D5;
  border-width: 16px;
  margin-left: 0;
}
blockquote.testimonials-text:after {
  bottom: -31px;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 16px;
  margin-left: 0;
}
.testimonials-slider .owl-controls {
  top: auto;
  bottom: -8px;
  background-color: #FFF;
}
/*---------------------------------------------------------------*/
/* Testimonials Grid
/*---------------------------------------------------------------*/
.testimonial-grid .item {
  margin-bottom: 30px;
}
.testimonial-info {
  display: table-row;
  margin-top: 2px;
}
.testimonial-info .testimonial-author {
  display: table-cell;
  vertical-align: middle;
}
.testimonial-info .testimonial-author .url a {
  color: #cda85c;
}
.testimonial-info .testimonial-author .url a:hover {
  color: #767676;
}
.testimonial-info .avatar-link {
  margin-right: 15px;
  display: block;
}
/*================================================*/
/* Footer / #footer */
/*================================================*/
.main-footer {
  background: #222;
  padding-top: 60px;
}
.main-footer a {
  color: #787878;
}
.main-footer h5 {
  font: 12px/15px 'Roboto', Arial, Helvetica, sans-serif;
  color: #aaa;
  text-transform: uppercase;
  margin: 0 0 10px;
  letter-spacing: 1px;
}
.main-footer p {
  font: 11px/18px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #767676;
}
.main-footer .product_list_widget li {
  border-bottom: 1px solid #3f3f3f;
}
.main-footer .product_list_widget .pull-left {
  border: 1px solid #3f3f3f;
}
.main-footer .product_list_widget .media-heading a {
  color: #fff;
}
.main-footer .product_list_widget .media-heading a:hover {
  color: #767676;
}
.main-footer .product_list_widget .small-coast del .amount {
  color: #767676;
}
.main-footer .blog-post-list li {
  padding: 22px 0 23px;
  border-bottom: 1px solid #3f3f3f;
}
.main-footer .blog-post-list li .media-body strong {
  color: #fff;
}
.main-footer .blog-post-list li:last-child {
  border-bottom: none !important;
}
.main-footer .wpb_widgetised_column_heading {
  font-size: 18px;
  line-height: 20px;
  color: #fff !important;
  padding: 0 0 14px;
  border-bottom: 1px solid #3F3F3F;
  border-top: none;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: left;
}
.main-footer .container {
  position: relative;
}
.main-footer .container .hidden-tooltip {
  position: absolute;
  top: -10px;
  right: 10px;
}
.main-footer .container .hidden-tooltip .tooltip-content {
  background-color: #111;
  right: 100%;
}
.main-footer .container .hidden-tooltip .tooltip-content .btn-close i {
  border: none !important;
}
.main-footer .container .hidden-tooltip .tooltip-content .btn-medium {
  color: #FFF;
  border: 1px solid #fff;
}
.main-footer .container .hidden-tooltip .open-tooltip i {
  -webkit-animation: tooltip-color-white 1.5s ease-in infinite;
  -moz-animation: tooltip-color-white 1.5s ease-in infinite;
  -ms-animation: tooltip-color-white 1.5s ease-in infinite;
  animation: tooltip-color-white 1.5s ease-in infinite;
}
.main-footer .container .hidden-tooltip i {
  border: 1px solid #fff;
}
.main-footer .container .hidden-tooltip i:hover {
  border: 1px solid #fff !important;
  color: #FFF;
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}
.main-footer .container .widget_icl_lang_sel_widget {
  float: left;
  width: 100%;
  text-align: left;
}
.main-footer .twitter-list li {
  border-bottom: 1px solid #3f3f3f;
}
.main-footer .vc_separator {
  font: 18px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #fff !important;
  padding: 0 0 18px;
  border-bottom: 1px solid #3F3F3F;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: left;
}
.main-footer .vc_separator.vc_sep_color_black .vc_sep_line {
  border-color: #3f3f3f;
}
.main-footer .menu-social-icons {
  text-align: center;
}
.main-footer .menu-social-icons li {
  margin: 0 5px;
  float: none;
  display: inline-block;
}
.main-footer .menu-social-icons li:hover {
  opacity: 0.8;
}
.main-footer .menu-social-icons svg {
  display: none;
}
.main-footer .menu-social-icons i {
  background-color: #464646;
  width: 23px;
  height: 23px;
  font-size: 14px;
  line-height: 23px;
  color: #222;
}
.main-footer .menu-social-icons i:hover {
  color: #fff;
}
.main-footer .widget-title {
  font: 18px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #fff !important;
  padding: 0 0 14px;
  border-bottom: 1px solid #3F3F3F;
  border-top: none;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: left;
}
.main-footer .widget-title span {
  background-color: transparent;
  padding: 0;
}
.main-footer .widget-title:after {
  content: "";
  height: 0;
}
.main-footer .blog-post-list .media-heading a {
  color: #FFF;
}
.main-footer .blog-post-list .media-heading a {
  color: #787878;
}
.vc_row + p {
  margin: 0;
}
.main-footer-1 {
  background-color: #fff;
  padding-top: 30px;
}
.main-footer-1 .widget-title {
  color: #000 !important;
}
.main-footer-1 a {
  color: #787878;
}
.main-footer-1 a:hover {
  color: #000;
}
.main-footer-1 .white-text,
.main-footer-1 a.white-text {
  color: #000;
}
.main-footer-1 .list-unstyled a:hover {
  color: #000;
}
.main-footer-1 .tagcloud a {
  border: 1px solid #ddd;
}
.main-footer-1 .tagcloud a:hover {
  border: 1px solid #000;
  color: #000;
}
.main-footer-1 .blog-post-list .media-heading a {
  color: #787878;
}
.main-footer-1 .blog-post-list .media-heading a:hover {
  color: #cda85c;
}
.main-footer-1 .blog-post-list li {
  border-bottom: 1px solid #ddd;
}
.main-footer-1 .blog-post-list li .date-event {
  background-color: #cda85c;
  outline-color: #fff;
  border-color: #cda85c;
}
.main-footer-1 .blog-post-list li .media-body strong {
  color: #6e6e6e;
}
.main-footer-1 .blog-post-list li:last-child {
  border-bottom: none;
}
.main-footer-1 .address-company {
  border: 1px solid #ddd;
}
.main-footer-1 .product_list_widget li {
  border-bottom: 1px solid #ddd;
}
.main-footer-1 .product_list_widget li .media-heading a {
  color: #000;
}
.main-footer-1 .product_list_widget li .pull-left {
  border: 1px solid #ddd;
}
.main-footer-1 .twitter-list li {
  border-bottom: 1px solid #ddd;
}
.main-footer-1 .vc_separator h4 {
  color: #000;
}
.main-footer-1.text-color-default .wpb_heading {
  font: 18px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #000 !important;
  padding: 0 0 14px;
  border-bottom: 1px solid #3F3F3F;
  border-top: none;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: left;
}
.main-footer-1.text-color-dark .wpb_heading {
  font: 18px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #222 !important;
  padding: 0 0 14px;
  border-bottom: 1px solid #3F3F3F;
  border-top: none;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: left;
}
.copyright-1 {
  background-color: #fff;
}
.about-company > .pull-left {
  margin-right: 18px;
  max-width: 100%;
}
#lang_sel_footer,
.widget_icl_lang_sel_widget {
  position: relative;
  z-index: 10;
}
.white-text,
a.white-text {
  color: white;
}
.address-company {
  margin: 19px 0 0;
  text-align: center;
  padding: 16px 10px 10px;
  border: 1px solid #3F3F3F;
  font: 12px/22px 'Roboto', Arial, Helvetica, sans-serif;
  color: #767676;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.social-link-type-2 {
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
}
.social-link-type-2 li {
  display: inline-block;
  margin: 0 4px;
}
.social-link-type-2 a:hover i {
  opacity: 0.8;
}
.social-link-type-2 i {
  display: block;
}
.social-link-type-2 .ico-twitter {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -143px 0;
  width: 22px;
  height: 23px;
}
.social-link-type-2 .ico-facebook {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -166px -1px;
  width: 22px;
  height: 23px;
}
.social-link-type-2 .ico-p {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -190px -1px;
  width: 22px;
  height: 23px;
}
.social-link-type-2 .ico-google {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -214px -1px;
  width: 22px;
  height: 23px;
}
.social-link-type-2 .ico-rss {
  background: url('https://terminal21.co.th/images/sprite/icons.png') no-repeat -238px -2px;
  width: 22px;
  height: 23px;
}
.letter-offset {
  letter-spacing: 4px;
}
.flickr_badge_image {
  margin: 0 5px 10px !important;
  width: 28% !important;
  height: auto !important;
  float: left;
}
.flickr_badge_image img {
  height: auto !important;
}
[class*=col-ct-] {
  float: left;
  overflow: hidden;
}
.col-ct-6 {
  width: 50%;
}
.list-unstyled {
  font-size: 13px;
  line-height: 30px;
}
.list-unstyled a:hover {
  color: #fff;
  text-decoration: none;
}
.tagcloud {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.tagcloud a {
  display: inline-block;
  font-size: 13px !important;
  line-height: 15px;
  margin: 0 7px 11px 0;
  padding: 6px 12px;
  border: 1px solid #3F3F3F;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.tagcloud a:hover {
  text-decoration: none;
  color: #fff;
  border-color: #fff;
}
.blog-post-list .media-heading a {
  font: 12px/11px 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
}
.blog-post-list .media-heading a:hover {
  color: #bbb;
}
.blog-post-list .media {
  padding: 0;
}
.blog-post-list .media > .pull-left {
  margin-right: 21px;
}
.blog-post-list li {
  padding: 14px 0 14px;
  border-bottom: 1px solid #3F3F3F;
}
.blog-post-list .media-body {
  font: 13px/20px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #6e6e6e;
}
.blog-post-list .date-event {
  background: #3B3B3B;
  outline-color: #626262;
  border-color: #3B3B3B;
}
.footer-column {
  margin-bottom: 54px;
  overflow: hidden;
}
.copyright-1,
.copyright-2,
.copyright-3,
.copyright {
  background: #222;
  font-size: 12px;
  padding-top: 20px;
}
.copyright-1 .container .row-copyrights,
.copyright-2 .container .row-copyrights,
.copyright-3 .container .row-copyrights,
.copyright .container .row-copyrights {
  padding-top: 30px;
  padding-bottom: 30px;
  display: inline-block;
  width: 100%;
  border-top: 1px solid #2B2B2B;
}
.copyright-1 .textwidget,
.copyright-2 .textwidget,
.copyright-3 .textwidget,
.copyright .textwidget {
  color: #787878;
  margin: 3px 0 0;
}
.copyright-1 .textwidget a:hover,
.copyright-2 .textwidget a:hover,
.copyright-3 .textwidget a:hover,
.copyright .textwidget a:hover {
  text-decoration: underline;
}
.copyright-1 .textwidget .active,
.copyright-2 .textwidget .active,
.copyright-3 .textwidget .active,
.copyright .textwidget .active {
  color: #cda85c;
}
.copyright-1 .textwidget p,
.copyright-2 .textwidget p,
.copyright-3 .textwidget p,
.copyright .textwidget p {
  margin-bottom: 5px;
  margin-top: 5px;
}
.copyright-1 .copyright-payment,
.copyright-2 .copyright-payment,
.copyright-3 .copyright-payment,
.copyright .copyright-payment {
  margin-bottom: 0;
}
.copyright-1 .copyright-payment li,
.copyright-2 .copyright-payment li,
.copyright-3 .copyright-payment li,
.copyright .copyright-payment li {
  display: inline-block;
  text-align: center;
  width: 45px;
  margin: 0 5px;
}
.copyright-1 .copyright-payment li a,
.copyright-2 .copyright-payment li a,
.copyright-3 .copyright-payment li a,
.copyright .copyright-payment li a {
  padding: 0 5px;
}
.copyright-1 .copyright-payment li a img,
.copyright-2 .copyright-payment li a img,
.copyright-3 .copyright-payment li a img,
.copyright .copyright-payment li a img {
  width: 100%;
  height: auto;
}
.copyright-1 {
  background-color: #fff;
}
.copyright-1 .container .row-copyrights {
  border-top: 1px solid #ddd;
}
.footer-top-2 {
  background-color: #222;
  padding-top: 40px;
}
.footer-top-2 .widget-title {
  color: #fff;
}
.footer-top-2 .widget-title:after {
  background: #3f3f3f;
}
.footer-top-2 .widget-title span {
  background: #222 !important;
  color: #FFF;
}
.footer-top-2 a,
.footer-top-2 .wpb_heading,
.footer-top-2 .media-heading,
.footer-top-2 .sidebar-widget .blog-post-list .media-body strong,
.footer-top-2 .sidebar-slider .blog-post-list .media-body strong {
  color: #fff;
}
.footer-top-2 .wpb_widgetised_column_heading,
.footer-top-2 .sidebar-widget .widget-title,
.footer-top-2 .sidebar-slider .widget-title {
  border-color: #3F3F3F;
}
.footer-top-2 .media-heading a,
.footer-top-2 .wpb_heading,
.footer-top-2 .wpb_flickr_widget h2 {
  color: #fff !important;
}
.footer-top-2 .vc_separator h4 {
  background-color: #222;
  color: #FFF;
}
.footer-top-2 .vc_separator.vc_sep_color_black .vc_sep_line {
  border-color: #3f3f3f;
}
.footer-top-2 .product-categories > li,
.footer-top-2 .sidebar-widget .blog-post-list li,
.footer-top-2 .sidebar-slider .blog-post-list li {
  border-color: #3F3F3F;
}
.footer-top-2 .product_list_widget li {
  border-bottom: 1px solid #3f3f3f;
}
.footer-top-2 .product_list_widget li .media-heading a {
  color: #FFF;
}
.footer-top-2 .product_list_widget li .media-heading a:hover {
  color: #787878;
}
.footer-top-2 .product_list_widget li .media > .pull-left {
  border: 1px solid #3f3f3f;
}
.footer-top-2 .product_list_widget li .small-coast {
  color: #787878;
}
.footer-top-2 .product_list_widget li .small-coast del .amount {
  color: #787878;
}
.footer-top-2 .twitter-list li {
  border-bottom: 1px solid #3f3f3f;
}
.footer-top-2 .wpb_heading:before {
  background: #3F3F3F !important;
}
.footer-top-2 .wpb_heading:after {
  background: #3F3F3F !important;
}
.footer-top-2 .widget-title span,
.footer-top-2 .title span {
  background: #222;
  color: #fff;
}
.footer-top-2 .title {
  border-top: none;
  border-color: #3F3F3F;
}
.footer-top-2 .widget-title:after,
.footer-top-2 .title:after {
  background: #3F3F3F;
}
.main-footer-2.text-color-default .wpb_heading {
  font: 18px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #fff !important;
  padding: 0 0 14px;
  border-bottom: 1px solid #3F3F3F;
  border-top: none;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: left;
}
.main-footer-2.text-color-default .menu-social-icons i {
  background-color: #464646 !important;
}
.main-footer-2.text-color-dark .blog-post-list .media-heading a,
.main-footer-2.text-color-dark .blog-post-list strong {
  color: #252525 !important;
}
.woocommerce-variation-add-to-cart-disabled .disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.footer-top .wpb_heading {
  text-align: left !important;
  border: none;
  padding-bottom: 14px !important;
  font-size: 16px;
  border-bottom: 1px solid #3F3F3F;
  color: #000;
}
.footer-top .title {
  border: none;
  position: relative;
}
.footer-top .carousel-area .title:after {
  top: 33px;
}
.articles-nav {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  margin: 20px 0;
}
.articles-nav .left {
  float: left;
}
.articles-nav .right {
  float: right;
}
.fa-heart {
  font-size: 12px;
}
.footer-top .widget-container.flickr .widget-title {
  margin-bottom: 30px;
}
.footer-top .product_list_widget .amount {
  color: #000;
}
/* Color scheme for footer / #footer-color */
.main-footer.text-color-dark,
.copyright.text-color-dark {
  color: #222;
}
.main-footer.text-color-dark a,
.copyright.text-color-dark a {
  color: #222;
}
.main-footer.text-color-dark a:hover,
.copyright.text-color-dark a:hover {
  text-decoration: underline;
  color: #222 !important;
}
.main-footer.text-color-dark .widget-title,
.copyright.text-color-dark .widget-title,
.main-footer.text-color-dark .address-company,
.copyright.text-color-dark .address-company {
  color: #222 !important;
}
.main-footer.text-color-dark .widget-title .white-text,
.copyright.text-color-dark .widget-title .white-text,
.main-footer.text-color-dark .address-company .white-text,
.copyright.text-color-dark .address-company .white-text {
  color: #222;
}
.main-footer.text-color-dark .wpb_widgetised_column_heading,
.copyright.text-color-dark .wpb_widgetised_column_heading {
  color: #222 !important;
}
.main-footer.text-color-dark .textwidget,
.copyright.text-color-dark .textwidget,
.main-footer.text-color-dark p,
.copyright.text-color-dark p,
.main-footer.text-color-dark h5,
.copyright.text-color-dark h5,
.main-footer.text-color-dark h5 span,
.copyright.text-color-dark h5 span,
.main-footer.text-color-dark .blog-post-list .media-body,
.copyright.text-color-dark .blog-post-list .media-body {
  color: #222;
}
.main-footer .product-categories > li,
.copyright .product-categories > li {
  border-bottom: 1px solid #3F3F3F;
}
.main-footer.text-color-default .menu-social-icons i,
.copyright.text-color-default .menu-social-icons i {
  background-color: #C6C6C6;
  color: #fff;
}
.main-footer.text-color-default .wpb_widgetised_column_heading,
.copyright.text-color-default .wpb_widgetised_column_heading {
  color: #222 !important;
}
.main-footer-3.text-color-default .wpb_widgetised_column_heading {
  color: #fff !important;
}
.main-footer-3.text-color-default .wpb_heading {
  font: 18px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #fff !important;
  padding: 0 0 14px;
  border-bottom: 1px solid #3F3F3F;
  border-top: none;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: left;
}
.main-footer-3.text-color-default .menu-social-icons i {
  background-color: #353535;
  color: #fff;
}
.main-footer-3.text-color-dark .blog-post-list .media-heading a,
.main-footer-3.text-color-dark .blog-post-list strong {
  color: #252525 !important;
}
.main-footer.text-color-light,
.copyright.text-color-light {
  color: #fff;
}
.main-footer.text-color-light a,
.copyright.text-color-light a {
  color: #fff;
}
.main-footer.text-color-light a:hover,
.copyright.text-color-light a:hover {
  text-decoration: underline;
  color: #fff !important;
}
.main-footer.text-color-light .widget-title,
.copyright.text-color-light .widget-title,
.main-footer.text-color-light .address-company,
.copyright.text-color-light .address-company {
  color: #fff !important;
}
.main-footer.text-color-light .widget-title .white-text,
.copyright.text-color-light .widget-title .white-text,
.main-footer.text-color-light .address-company .white-text,
.copyright.text-color-light .address-company .white-text {
  color: #fff;
}
.main-footer.text-color-light .wpb_heading,
.copyright.text-color-light .wpb_heading {
  border-bottom: 1px solid #fff;
  color: #fff !important;
}
.main-footer.text-color-light .textwidget,
.copyright.text-color-light .textwidget,
.main-footer.text-color-light p,
.copyright.text-color-light p,
.main-footer.text-color-light h5,
.copyright.text-color-light h5,
.main-footer.text-color-light h5 span,
.copyright.text-color-light h5 span,
.main-footer.text-color-light .blog-post-list .media-body,
.copyright.text-color-light .blog-post-list .media-body,
.main-footer.text-color-light .blog-post-list .media-heading a,
.copyright.text-color-light .blog-post-list .media-heading a {
  color: #fff;
}
.main-footer.text-color-light .tagcloud a,
.copyright.text-color-light .tagcloud a,
.main-footer.text-color-light .address-company,
.copyright.text-color-light .address-company {
  border: 1px solid #fff;
}
.main-footer.text-color-light .blog-post-list li,
.copyright.text-color-light .blog-post-list li,
.main-footer.text-color-light .product-categories > li,
.copyright.text-color-light .product-categories > li,
.main-footer.text-color-light .product_list_widget li,
.copyright.text-color-light .product_list_widget li {
  border-bottom: 1px solid #fff;
}
.main-footer.text-color-light .row-copyrights,
.copyright.text-color-light .row-copyrights {
  border-top: 1px solid #fff;
}
.main-footer.text-color-light .widget-title,
.copyright.text-color-light .widget-title {
  border-bottom: 1px solid #fff;
}
.main-footer.text-color-light .blog-post-list .date-event,
.copyright.text-color-light .blog-post-list .date-event {
  background: #bcbcbc;
  border-color: #9a9a9a;
  outline-color: #9a9a9a;
}
.main-footer.text-color-light .menu-social-icons li a:hover,
.copyright.text-color-light .menu-social-icons li a:hover {
  text-decoration: none;
}
.main-footer.text-color-light .menu-social-icons li a i,
.copyright.text-color-light .menu-social-icons li a i {
  background-color: #9a9a9a;
}
.main-footer .etheme_widget_recent_entries .widget-title,
.copyright .etheme_widget_recent_entries .widget-title {
  margin-bottom: 0;
}
.main-footer .etheme_widget_recent_entries .blog-post-list li,
.copyright .etheme_widget_recent_entries .blog-post-list li {
  padding: 16px 0 23px;
}
.back-top {
  position: fixed;
  z-index: 10000;
  right: 25px;
  bottom: 50px;
  visibility: hidden;
}
.back-top a {
  padding: 17px 13px 15px;
  display: block;
  line-height: 33px;
  border: 2px solid #565656;
  background-color: #565656;
  -webkit-border-radius: 100%;
  text-align: center;
  border-radius: 100%;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.back-top a:focus {
  outline: none;
  text-decoration: none;
}
.back-top a:hover {
  background-color: #252525;
  border-color: #252525;
  color: #FFF;
  text-decoration: none;
}
.back-top span {
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: 15px;
  height: 9px;
  display: block;
  font-style: normal;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.back-top span:before {
  content: "\f106";
  color: #fff;
  font-size: 24px;
  position: relative;
  top: -15px;
  font-family: FontAwesome;
}
.back-top:hover span:before {
  color: #FFF;
}
.back-top.bounceIn {
  visibility: visible;
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    visibility: visible !important;
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    visibility: visible !important;
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -ms-transform: scale(0.3);
    transform: scale(0.3);
    visibility: visible !important;
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    visibility: visible !important;
  }
}
.bounceIn {
  -webkit-animation: bounceIn 0.4s linear forwards;
  -ms-animation: bounceIn 0.4s linear forwards;
  -o-animation: bounceIn 0.4s linear forwards;
  animation: bounceIn 0.4s linear forwards;
}
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    visibility: hidden;
  }
}
@-o-keyframes bounceOut {
  0% {
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -o-transform: scale(0.94);
    transform: scale(0.94);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -o-transform: scale(0.3);
    transform: scale(0.3);
    visibility: hidden;
  }
}
@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -ms-transform: scale(0.3);
    transform: scale(0.3);
    visibility: hidden;
  }
}
.bounceOut {
  -webkit-animation: bounceOut 0.4s linear forwards;
  -ms-animation: bounceOut 0.4s linear forwards;
  -o-animation: bounceOut 0.4s linear forwards;
  animation: bounceOut 0.4s linear forwards;
}
.responsive-switcher {
  padding-top: 40px;
  text-align: center;
  color: #fff;
  font-size: 14px;
}
.responsive-switcher i {
  font-size: 18px;
  margin: 0 5px 0 0;
}
/*===========================*/
/* Hidden Menu
/*==========================*/
.st-container,
.st-pusher,
.st-content {
  height: 100%;
}
.st-content {
  background: #fff;
}
.st-content,
.st-content-inner {
  position: relative;
}
.st-container {
  position: relative;
  overflow: hidden;
}
.st-pusher {
  left: 0;
  z-index: 99;
  height: 100%;
  background-color: #FFF;
  -webkit-transition: all 300ms ease 0;
  -moz-transition: all 300ms ease 0;
  -o-transition: all 300ms ease 0;
  transition: all 300ms ease 0;
}
.st-pusher::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}
.st-menu-open .st-pusher::after {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
html.st-menu-open body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.st-menu {
  position: fixed;
  top: 0;
  -webkit-transform: translateX(-250px);
  -moz-transform: translateX(-250px);
  -ms-transform: translateX(-250px);
  -o-transform: translateX(-250px);
  transform: translateX(-250px);
  overflow-y: auto;
  width: 250px;
  height: 100%;
  background: #1a1a1a;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.st-menu .nav-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.st-menu .nav-wrapper .st-menu-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden !important;
}
.st-menu-open .mobile-menu-block > .mobile-menu-block.st-menu {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.st-container .st-pusher {
  overflow-x: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.st-menu-open .st-container .st-pusher {
  -webkit-transform: translateX(250px);
  -moz-transform: translateX(250px);
  -ms-transform: translateX(250px);
  -o-transform: translateX(250px);
  transform: translateX(250px);
}
.st-menu::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.st-menu-open .st-menu::after {
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}
/* Mobile Menu Styles */
.menu-icon {
  font-size: 0;
  -webkit-appearance: none;
  -moz-apearance: none;
  position: relative;
  border: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-style: normal;
  background-color: transparent !important;
}
.menu-icon:before {
  content: "\f039";
  font-size: 26px;
  color: #a5a5a5;
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  top: 0;
}
#st-trigger-effects {
  display: block;
}
.navbar #st-trigger-effects {
  display: none;
}
@media (min-width: 1100px) {
  .boxed .st-menu {
    display: none;
  }
  .boxed .st-menu.hide-filters-block {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    display: block;
  }
}
.st-menu-open .boxed .st-menu {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.mobile-menu-block.st-menu::after {
  display: none;
}
/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
.no-csstransforms3d .st-pusher,
.no-js .st-pusher {
  margin-left: 0;
}
.mobile-nav-heading {
  color: #FFF;
  font-family: 'Roboto';
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 30px 15px 10px;
  letter-spacing: 1px;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-nav-heading.close-mobile-nav {
  font-size: 18px;
  width: 250px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 30px;
  margin-bottom: 20px;
  background-color: #1e1e1e;
  border-bottom: none;
  margin-top: 0;
}
.mobile-nav-heading a {
  color: #FFF;
}
.mobile-nav-heading a:hover {
  color: #cda85c;
}
.mobile-nav-heading i {
  margin-right: 10px;
  font-size: 16px;
}
.mobile-nav {
  float: left;
  width: 100%;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-nav .register-link:hover .register-popup,
.mobile-nav .register-link:hover .login-popup,
.mobile-nav .login-link:hover .register-popup,
.mobile-nav .login-link:hover .login-popup {
  display: none !important;
}
.mobile-nav .register-link a:hover,
.mobile-nav .login-link a:hover {
  text-decoration: none;
}
.mobile-nav .menu-social-icons li {
  margin-right: 3px;
}
.mobile-nav ul.wcml_currency_switcher {
  margin: 10px 0 !important;
  display: inline-block;
}
.mobile-nav ul.wcml_currency_switcher li {
  border: none;
  color: #FFF;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobile-nav ul.wcml_currency_switcher li:hover {
  color: #cda85c;
}
.mobile-nav .widget_currency_sel_widget {
  margin: 10px 0 !important;
  display: inline-block;
  margin-left: 10px !important;
}
.mobile-nav .widget_currency_sel_widget select {
  min-width: 70px;
  height: 26px;
  line-height: 20px;
}
.mobile-nav #lang_sel_list {
  display: inline-block;
  margin: 5px 0 !important;
}
.mobile-nav #lang_sel_list a {
  color: #fff !important;
  background-color: transparent !important;
  font-size: 12px;
  padding: 0 4px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobile-nav #lang_sel_list a:hover {
  color: #cda85c;
}
.mobile-nav #lang_sel {
  display: inline-block;
  margin: 10px 0 !important;
  margin-left: 10px !important;
}
.mobile-nav #lang_sel li {
  z-index: 1;
}
.mobile-nav #lang_sel a.lang_sel_sel {
  background-image: url(https://terminal21.co.th/images/select-arrow.jpg);
  background-position: 97% 50%;
  background-repeat: no-repeat;
}
.mobile-nav .menu-social-icons {
  border-top: 1px solid #262626;
  display: inline-block;
  padding-top: 30px;
}
.mobile-nav .menu-social-icons li a .circle circle {
  display: none;
}
.mobile-nav .menu-social-icons li a:hover {
  opacity: 0.9;
}
.mobile-nav .links {
  padding-left: 0;
  padding-top: 20px;
  margin: 0;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  border-top: 1px solid #262626;
}
.mobile-nav .links li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  border: none !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-nav .links li a {
  display: block;
  color: #FFF;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Roboto';
  text-transform: uppercase;
  padding: 15px 0 15px 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobile-nav .links li a:hover {
  color: #cda85c;
}
.mobile-nav .links li:after {
  display: none;
}
/**
 *
 * MyAccount
 *
 */
.woocommerce-MyAccount-content {
  width: 73%;
  float: left;
}
.woocommerce-MyAccount-navigation {
  width: 25%;
  float: left;
  margin-right: 20px;
}
.woocommerce-MyAccount-navigation ul {
  padding-left: 0;
}
.woocommerce-MyAccount-navigation li {
  list-style: none !important;
  border-bottom: 1px solid #E9E9E9;
}
.woocommerce-MyAccount-navigation li a {
  font: 13px/18px 'Raleway' !important;
  display: inline;
  padding-left: 10px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 15px 0;
}
.mobile-nav ul {
  padding-left: 0;
  float: left;
}
.mobile-nav li {
  display: block;
  position: relative;
  float: left !important;
  width: 100%;
  border: none !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-nav li a {
  display: block;
  color: #FFF;
  text-align: left;
  font-size: 13px;
  line-height: 20px;
  font-family: 'Roboto';
  text-transform: uppercase;
  padding: 15px 0 15px 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobile-nav li a:hover {
  color: #cda85c;
}
.mobile-nav li a:after {
  display: none;
}
.mobile-nav li .open-child {
  float: right;
  font-size: 0;
  width: 50px;
  height: 50px;
  line-height: 20px;
  display: block;
  z-index: 1;
  cursor: pointer;
  right: -20px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #FFF;
}
.mobile-nav li .open-child:hover {
  color: #cda85c;
}
.mobile-nav li .open-child:after {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 18px;
  left: 50%;
  top: 14px;
  position: relative;
  margin-left: -6px;
}
.mobile-nav li.over > .open-child {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transform-origin: 50% 50%;
}
.mobile-nav li > ul {
  display: none;
  background-color: #2c2c2c;
  position: relative;
  float: left;
  width: 250px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px 20px 15px 0;
  margin: 15px -20px;
}
.mobile-nav li > ul:before {
  content: '';
  display: block;
  left: 30px;
  top: -20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #2c2c2c;
  border-width: 10px 10px;
}
.mobile-nav li > ul > li a {
  color: #767676;
  font-size: 12px;
  padding: 10px 0 10px 35px;
  display: inline-block;
}
.mobile-nav li > ul > li .open-child {
  width: 50px;
  height: 37px;
  -webkit-transform-origin: 50% 50%;
}
.mobile-nav li > ul > li .open-child:after {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 18px;
  left: 50%;
  top: 10px;
  position: relative;
  margin-left: -6px;
}
.mobile-nav li > ul > li ul {
  padding: 15px 0;
  margin: 15px 0;
  background-color: #333;
}
.mobile-nav li > ul > li ul:before {
  border-bottom-color: #333;
}
.mobile-nav li > ul > li ul > li ul {
  padding: 0;
  margin: 0;
  background-color: #333;
}
.mobile-nav li > ul > li ul > li ul:before {
  border-bottom-color: #333;
}
.mobile-nav li > ul > li ul > li ul > li ul {
  background-color: #333;
}
.mobile-nav li > ul > li ul > li ul > li ul:before {
  border-bottom-color: #333;
}
.mobile-nav > li > .open-child {
  right: -20px;
}
.mobile-nav.line-items li {
  float: left;
  width: auto;
}
.mobile-nav.line-items li a {
  padding-left: 20px;
}
.mobile-nav.line-items li:first-child a {
  padding-left: 10px;
}
.mobile-nav.line-items li.active a {
  color: #cda85c;
}
.mobile-nav .links li a {
  font-size: 13px;
}
.mobile-nav .links li:after {
  display: none;
}
/*===========================*/
/* Register & Login Blocks
/*==========================*/
.register-link,
.login-link {
  position: relative;
}
.register-link .register-popup,
.login-link .register-popup,
.register-link .login-popup,
.login-link .login-popup {
  visibility: hidden;
  opacity: 0;
  z-index: 10000;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease 0.25s;
  -ms-transition: all 0.3s ease 0.25s;
  -o-transition: all 0.3s ease 0.25s;
  transition: all 0.3s ease 0.25s;
  padding: 20px 25px 10px;
  width: 300px;
  background: #fff;
  border: 2px solid #E6E6E6;
  position: absolute;
  top: 51px;
  left: -180px;
  color: #767676;
  font-family: 'Roboto';
  -webkit-box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.07);
}
.register-link .register-popup:before,
.login-link .register-popup:before,
.register-link .login-popup:before,
.login-link .login-popup:before {
  content: ' ';
  display: block;
  top: -15px;
  left: 149px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #E6E6E6;
  border-width: 7px 8px;
  margin-left: -6px;
}
.register-link .register-popup .popup-title,
.login-link .register-popup .popup-title,
.register-link .login-popup .popup-title,
.login-link .login-popup .popup-title {
  float: left;
  width: 100%;
  margin: -10px 0 20px;
  border-bottom: 1px solid #252525;
}
.register-link .register-popup .popup-title span,
.login-link .register-popup .popup-title span,
.register-link .login-popup .popup-title span,
.login-link .login-popup .popup-title span {
  font-size: 15px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #252525;
  background-color: #FFF;
  padding-right: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  top: 10px;
}
.register-link .register-popup .login-remember,
.login-link .register-popup .login-remember,
.register-link .login-popup .login-remember,
.login-link .login-popup .login-remember {
  margin-bottom: 0;
}
.register-link .register-popup .login-remember label,
.login-link .register-popup .login-remember label,
.register-link .login-popup .login-remember label,
.login-link .login-popup .login-remember label {
  margin-top: 5px;
}
.register-link .register-popup p,
.login-link .register-popup p,
.register-link .login-popup p,
.login-link .login-popup p {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.register-link .register-popup label,
.login-link .register-popup label,
.register-link .login-popup label,
.login-link .login-popup label {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}
.register-link .register-popup input,
.login-link .register-popup input,
.register-link .login-popup input,
.login-link .login-popup input {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  height: 40px;
  padding: 10px;
  margin-bottom: 5px;
}
.register-link .register-popup input:focus,
.login-link .register-popup input:focus,
.register-link .login-popup input:focus,
.login-link .login-popup input:focus {
  outline: none;
  border-color: #252525;
}
.register-link .register-popup input[type=submit],
.login-link .register-popup input[type=submit],
.register-link .login-popup input[type=submit],
.login-link .login-popup input[type=submit] {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Open Sans';
  font-size: 13px !important;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 10px;
  padding: 10px 22px;
}
.register-link .register-popup a.border-grey,
.login-link .register-popup a.border-grey,
.register-link .login-popup a.border-grey,
.login-link .login-popup a.border-grey {
  background-color: #B8B8B8;
  border-color: #B8B8B8;
  padding: 10px 22px;
  margin-top: 10px;
  font-family: 'Open Sans';
  font-size: 13px !important;
  float: right;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.register-link .register-popup a.border-grey:hover,
.login-link .register-popup a.border-grey:hover,
.register-link .login-popup a.border-grey:hover,
.login-link .login-popup a.border-grey:hover {
  background-color: #262626;
  border-color: #262626;
  color: #FFF;
}
.register-link .register-popup .popup-terms,
.login-link .register-popup .popup-terms,
.register-link .login-popup .popup-terms,
.login-link .login-popup .popup-terms {
  font-size: 14px !important;
  margin-bottom: 0px;
}
.register-link .register-popup .popup-terms a,
.login-link .register-popup .popup-terms a,
.register-link .login-popup .popup-terms a,
.login-link .login-popup .popup-terms a {
  color: #cda85c;
  text-transform: none;
  font-size: 14px;
}
.register-link .register-popup .popup-terms a:hover,
.login-link .register-popup .popup-terms a:hover,
.register-link .login-popup .popup-terms a:hover,
.login-link .login-popup .popup-terms a:hover {
  color: #252525;
}
.register-link .register-popup .form-row.right,
.login-link .register-popup .form-row.right,
.register-link .login-popup .form-row.right,
.login-link .login-popup .form-row.right {
  margin-bottom: 10px;
}
.register-link .register-popup a.btn,
.login-link .register-popup a.btn,
.register-link .login-popup a.btn,
.login-link .login-popup a.btn {
  font-family: 'Open Sans', sans-serif;
}
.register-link .register-popup,
.login-link .register-popup {
  left: -250px;
}
.register-link .register-popup:before,
.login-link .register-popup:before {
  left: 260px;
}
.register-link:hover .register-popup,
.login-link:hover .register-popup,
.register-link:hover .login-popup,
.login-link:hover .login-popup {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  top: 36px;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.et-register-form {
  display: inline-block;
  width: 100%;
}
.et-register-form .form-row.right {
  display: inline-block;
  float: right;
}
.et-register-form .form-row.right .btn {
  float: right;
}
.woocommerce-account .sidebar {
  display: none !important;
}
.woocommerce-account .content {
  width: 100% !important;
}
.woocommerce-account.logged-in .sidebar.sidebar-left {
  display: block !important;
}
.woocommerce-account.logged-in .content {
  width: 75% !important;
}
.woocommerce-account.logged-in .sidebar-position-without .content {
  width: 100% !important;
}
.addresses {
  display: inline-block;
  width: 100%;
}
.addresses .col-1,
.addresses .col-2 {
  display: block;
  width: 50%;
  float: left;
  padding: 0 15px;
}
.addresses > div:first-child {
  padding-left: 0;
}
.addresses > div:last-child {
  padding-right: 0;
}
.register-link .btn-black {
  width: 100% !important;
  color: #fff;
}
.captcha-block {
  float: left;
  width: auto;
  margin-bottom: 20px;
}
.captcha-block img {
  float: left;
  margin-top: 8px;
}
.captcha-block input {
  float: left !important;
  width: 70px !important;
  margin-top: 8px;
  height: 24px !important;
  padding: 2px 10px !important;
  margin-left: 20px;
}
.spinner {
  display: none;
}
/*===========================*/
/* Hidden Filters
/*==========================*/
.hide-filters-block {
  background-color: #FFF;
}
.hide-filters-block .st-menu-content {
  padding: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.hide-filters-block .st-menu-content .sidebar {
  width: 100%;
  right: 0;
}
.st-menu.hide-filters-block {
  width: 350px;
  -webkit-transform: translateX(-350px);
  -moz-transform: translateX(-350px);
  -ms-transform: translateX(-350px);
  -o-transform: translateX(-350px);
  transform: translateX(-350px);
}
.st-menu-open .hide-filters-block .st-pusher {
  -webkit-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0);
}
.hide-filters-block.st-menu {
  z-index: -1;
  visibility: hidden;
}
.hide-filters-block > .hide-filters-block {
  z-index: 1;
  visibility: visible;
}
.st-menu-open .hide-filters-block > .hide-filters-block.st-menu {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.hide-filters-block.st-menu::after {
  display: none;
}
.filters-wrapper {
  float: left;
  width: 100%;
  padding: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.active-filters {
  padding-left: 0;
  float: left;
  width: 100%;
}
.active-filters li {
  float: left;
}
.active-filters li a {
  display: block;
  color: #bbb;
  padding: 6px 8px 6px 12px;
  margin: 6px 6px 0 0;
  background-color: #bbb;
  color: #FFF;
  font-family: 'Roboto';
  font-size: 12px;
}
.active-filters li a span.cross {
  background-image: url(https://terminal21.co.th/images/delete.png);
  background-repeat: no-repeat;
  background-position: center center;
  padding: 6px 10px 6px;
  background-color: #adadad;
  position: relative;
  right: -8px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.active-filters li a span.cross:hover {
  background-color: #333;
}
.product-categories {
  padding-left: 0 !important;
}
.product-categories > li {
  list-style: none;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #E9E9E9;
}
.product-categories > li.firstItem {
  padding-top: 0;
}
.product-categories > li > a {
  font: 13px/18px 'Roboto';
  display: inline;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 17px 0;
}
.product-categories > li > ul {
  padding: 0;
}
.product-categories > li > ul.children li {
  border-top: 1px solid #e9e9e9;
  list-style: none;
}
.product-categories > li > ul.children li > a {
  font: 13px/15px 'Roboto';
  color: #767676;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-left: 10px;
  padding-bottom: 17px;
  padding-top: 17px;
}
.product-categories > li > ul.children li > a:hover {
  color: #cda85c;
}
.product-categories > li > ul.children li.current-cat > a {
  color: #333;
}
.product-categories > li > ul.children li.opened > .open-this {
  background-image: url(https://terminal21.co.th/images/small-minus.png);
}
.product-categories > li > ul a:before {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  content: "\f105";
  -webkit-font-smoothing: antialiased;
  float: left;
  line-height: 16px;
  font-size: 16px;
  margin-right: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.product-categories > li.opened > .open-this {
  background-image: url(https://terminal21.co.th/images/small-minus.png);
}
.product-categories > li.current-cat {
  color: #cda85c;
}
.product-categories > li.current-cat a {
  color: #cda85c;
}
.product-categories > li.current-cat span {
  color: #cda85c;
}
.product-categories > li.current-cat .children span.count {
  color: #333 !important;
}
.product-categories > li span {
  cursor: pointer;
}
.product-categories > li span:hover {
  color: #cda85c;
}
.product-categories .open-this {
  cursor: pointer;
  float: right;
  height: 26px;
  position: relative;
  top: 12px;
  z-index: 1;
  font-size: 0;
  width: 26px;
  display: block;
  margin: 0;
  background-image: url(https://terminal21.co.th/images/small-plus.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.product-categories.with-accordion ul.children {
  display: none;
}
.product-categories.with-accordion ul.children li.over > .open-this {
  background-image: url(https://terminal21.co.th/images/small-minus.png);
}
.product-categories.with-accordion ul.children li a:hover {
  color: #cda85c;
}
.product-categories.with-accordion ul.children li ul li a {
  padding-left: 20px;
}
.product-categories > li > ul > li > a:hover,
.product-categories > li > ul > li > a:hover + span,
.product-categories ul.children li > a:hover,
.product-categories ul.children li > a:hover + span {
  text-decoration: none !important;
}
.sidebar-widger .wrap-h {
  margin-bottom: 0;
}
/*===========================*/
/* Social Icons Shortcode
/*==========================*/
.menu-social-icons {
  display: inline-block;
}
.menu-social-icons li {
  float: left;
  width: auto;
}
.main-footer .menu-social-icons {
  margin-top: 10px;
}
.main-footer.pull-right .menu-social-icons {
  margin-top: 0;
}
/*---------------------------------------------------------------*/
/* Portfolio
/*---------------------------------------------------------------*/
.portfolio-item {
  margin-bottom: 20px;
}
.portfolio-item .portfolio-image {
  position: relative;
  margin-bottom: 15px;
}
.portfolio-item .portfolio-image .portfolio-mask {
  right: 1px;
  margin-right: -2px;
  width: auto;
}
.portfolio-item .portfolio-image:hover .portfolio-mask {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.6);
}
.portfolio-item .post-info {
  margin-bottom: 20px;
}
.portfolio-item h3 {
  font-size: 20px;
  text-transform: none;
  font-weight: 300;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.portfolio-item.columns-count-2 .portfolio-descr {
  text-align: left;
}
.portfolio.with-transition {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}
.portfolio-item.with-transition {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.portfolio-filters {
  padding-left: 0;
  text-align: center;
  margin-bottom: 40px;
}
.portfolio-filters li {
  list-style: none;
  display: inline-block;
  margin: 0 2px 20px 2px;
}
.portfolio-filters li .btn {
  line-height: 32px !important;
  height: 34px;
  border: 1px solid #CBCBCB;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.portfolio-filters li .btn.active {
  color: #FFF;
  background-color: #cda85c;
  border-color: transparent !important;
}
.portfolio-filters li .btn:hover {
  border-color: #000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.portfolio-pagination {
  float: right;
}
.portfolio-pagination li {
  margin: 0 2px 0 2px;
}
.portfolio-descr .posted-in {
  color: #cda85c;
  font: 12px/15px 'Roboto', Georgia, Helvetica, sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.portfolio-descr a:hover {
  color: #767676;
}
.portfolio-descr h3 {
  margin: 10px 0;
}
.portfolio-descr p {
  color: #767676;
  font: 13px/20px 'Open Sans', Arial, Helvetica, sans-serif;
}
.main-footer .wpb_flickr_widget h2,
.footer-top .wpb_flickr_widget h2 {
  font: 16px/20px 'Roboto', Arial, Helvetica, sans-serif;
  color: #222;
  padding: 0 0 14px;
  border-top: none;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: center;
}
@media (min-width: 980px) {
  .portfolio-item.columns-count-4 {
    width: 24.8%;
  }
  .portfolio-item.columns-count-4 .portfolio-image .btn_group {
    margin-top: -42px;
  }
  .portfolio-item.columns-count-4 .portfolio-image .btn_group a {
    margin-bottom: 10px;
  }
}
.royal-post-grid .vc_btn3-style-classic {
  background: #262626;
  opacity: 0.9;
  color: #FFF !important;
  margin: 0 8px;
  position: relative;
  border: none;
  border-radius: 0;
  border: none !important;
  text-transform: uppercase;
  font-size: 13px;
  padding: 13px 15px !important;
  -webkit-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.royal-post-grid .vc_btn3-style-classic:hover {
  background-color: #cda85c;
}
/*---------------------------------------------------------------*/
/* Progress Bar
/*---------------------------------------------------------------*/
.progress-bars .progress-bar {
  background: #f5f5f5;
  height: 28px;
  position: relative;
  margin-bottom: 10px!important;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.progress-bar > div {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #cda85c;
}
.progress-bar > span {
  color: white;
  font-size: 13px;
  margin-left: 15px;
  line-height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  font-weight: 300;
}
.wpb_column > .wpb_wrapper .progress-bars .progress-bar {
  margin-bottom: 10px !important;
}
/*---------------------------------------------------------------*/
/*
/* Styles for social widget.
/*
 */
/*---------------------------------------------------------------*/
.et-follow-buttons a {
  display: inline-block;
  padding: 0 6px;
  text-align: center;
  font-size: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.et-follow-buttons.buttons-size-normal a {
  font-size: 18px;
  padding: 0 7px;
}
.et-follow-buttons.buttons-size-large a {
  display: inline-block;
  width: 44px;
  height: 44px;
  color: #999 !important;
  margin: 0 4px 8px 4px;
  line-height: 45px;
  background-color: #f2f2f2;
  font-size: 16px;
  padding: 0;
}
.et-follow-buttons.buttons-size-large a:hover {
  color: #fff!important;
}
.et-follow-buttons a:first-child {
  padding-left: 0;
}
.et-follow-buttons a:last-child {
  padding-right: 0;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-facebook:hover {
  background-color: #3a5795;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-twitter:hover {
  background-color: #55ACF2;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-instagram:hover {
  background-color: #2d5a82;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-google:hover {
  background-color: #dd4c3e;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-pinterest:hover {
  background-color: #bd2126;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-linkedin:hover {
  background-color: #1898b9;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-tumblr:hover {
  background-color: #35465c;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-youtube:hover {
  background-color: #e2282e;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-vimeo:hover {
  background-color: #2ab8e8;
}
.et-follow-buttons.icons-colorfull.buttons-size-large .follow-rss:hover {
  background-color: #f97636;
}
.et-follow-buttons.icons-colorfull.buttons-size-large a:hover {
  color: #fff !important;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-facebook:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-facebook:hover {
  color: #3a5795;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-twitter:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-twitter:hover {
  color: #55ACF2;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-instagram:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-instagram:hover {
  color: #2d5a82;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-google:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-google:hover {
  color: #dd4c3e;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-pinterest:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-pinterest:hover {
  color: #bd2126;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-linkedin:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-linkedin:hover {
  color: #1898b9;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-tumblr:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-tumblr:hover {
  color: #35465c;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-youtube:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-youtube:hover {
  color: #e2282e;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-vimeo:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-vimeo:hover {
  color: #2ab8e8;
}
.et-follow-buttons.icons-colorfull.buttons-size-normal .follow-rss:hover,
.et-follow-buttons.icons-colorfull.buttons-size-small .follow-rss:hover {
  color: #f97636;
}
.header-wrapper.header-inline .et-follow-buttons a {
  padding-right: 0;
}
.header-wrapper.header-inline .et-follow-buttons a i {
  color: #fff;
  font-size: 16px;
  width: 23px;
}
.header-wrapper.header-top-menu .et-follow-buttons {
  margin-right: 15px;
  padding: 16px 0;
  margin-bottom: 0;
  top: 0;
  position: static;
}
.header-wrapper.header-top-menu .et-follow-buttons li,
.header-wrapper.header-top-menu .et-follow-buttons > a {
  float: none;
  margin-left: 0;
  display: inline-block;
  margin-right: 0;
}
.header-wrapper.header-top-menu .menu-social-icons li i,
.header-wrapper.header-top-menu .et-follow-buttons li i,
.header-wrapper.header-top-menu .menu-social-icons > a i,
.header-wrapper.header-top-menu .et-follow-buttons > a i {
  color: #fff;
  font-size: 16px;
  width: 22px;
  background-color: transparent !important;
}
.header-wrapper.header-top-menu .menu-social-icons li i:hover,
.header-wrapper.header-top-menu .et-follow-buttons li i:hover,
.header-wrapper.header-top-menu .menu-social-icons > a i:hover,
.header-wrapper.header-top-menu .et-follow-buttons > a i:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 992px) {
  .footer .etheme_widget_socials .et-follow-buttons.buttons-size-large a {
    width: 28px;
    height: 28px;
    font-size: 16px;
    line-height: 28px;
  }
  .mobile-sidebar-widget .widget-title {
    text-transform: none;
    font-size: 16px;
  }
  .mobile-sidebar-widget.etheme_widget_socials .et-follow-buttons {
    text-align: left;
  }
  .mobile-sidebar-widget.etheme_widget_socials .et-follow-buttons a {
    font-size: 22px;
    color: #999;
    padding: 0 8px;
  }
}
.portfolio-item .portfolio-descr:hover {
  color: #cda85c;
}
.mobile-price {
  display: none;
}
.portfolio-descr h3 {
  color: #000;
}
.portfolio-descr h3 a {
  color: #000;
}
.portfolio-descr h3 a:hover {
  color: #cda85c;
}
.content .etheme_widget_recent_entries {
  float: none !important;
}
.login-popup {
  visibility: hidden !important;
  opacity: 0 !important;
  z-index: 10000 !important;
  filter: alpha(opacity=0) !important;
  -webkit-transition: all 0.3s ease 0.25s;
  -ms-transition: all 0.3s ease 0.25s;
  -o-transition: all 0.3s ease 0.25s;
  transition: all 0.3s ease 0.25s;
}
.login-popup.hover_login {
  visibility: visible !important;
  opacity: 1 !important;
  filter: alpha(opacity=100);
  top: 36px !important;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
}