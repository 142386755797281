$tiny-bar-height: 20px;

.tiny-bar {
  width: 100%;
  min-height: $tiny-bar-height;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 100%;
  }
}