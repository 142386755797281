.shop-cover {
    width: 185px;

    &-header {
        position: relative;
        width: 185px;

        .shop-logo {
            position: relative;
            float: left;
            flex: 0 0 50%;
            max-width: 30px;
        }

        .shop-name {
            position: relative;
            float: right;
            font-size: $size-4;
        }

        .shop-category {
            position: absolute;
            margin-top: 20px;
            right: 0;
            font-size: $size-7;
            text-transform: uppercase;
            opacity: 0.8;
        }
    }

    .shop-img {
        position: relative;
        bottom: 0;
        width: 185px;
        padding-top: 40px;
    }
}