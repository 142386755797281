/*
 Theme Name:   Royal Child
 Theme URI:    https://8theme.com/
 Description:  Royal Child Theme
 Author:       8theme
 Author URI:   https://8theme.com
 Template:     royal
 Version:      0.0.1
 Text Domain:  royal-child
*/

#customise {
	.main-header > .container {
		background: url("https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/top-line-rainbow.png") repeat-x top
			left;
		position: relative;
		min-height: 100%;
		padding: 5px 10px;
	}
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: "DB-Helvethaica-X";
	}

	p,
	a {
		font-weight: 400;
	}
	b,
	strong {
		font-weight: 700 !important;
	}
	body {
		font-family: "roboto";
	}
	table th,
	table td {
		border-top: 0px solid #efefef;
		padding: 5px;
	}
	.header-wrapper {
		background-color: #fff;
	}
	.admin-bar .header-wrapper {
		top: 32px;
	}
	a:focus {
		text-decoration: none;
	}
	.container.content-page {
		/*padding-top: 195px;*/
		padding-top: 195px;
	}
	.page-heading {
		padding-top: 210px;
	}
	.blog-post {
		/*padding-top: 55px;*/
		padding-top: 210px;
		margin-bottom: 0px;
	}
	.blog-post h6.active {
		display: none;
	}
	h2.entry-title {
		display: none;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li {
		width: 140px;
	}
	.page-content {
		margin-top: 40px;
		/*padding-top: 60px;*/
	}
	.wpb_revslider_element.wpb_content_element {
		display: none;
	}
	.header-logo img {
		max-width: 250px;
	}
	.navbar {
		padding: 15px 0;
	}
	.header-type-8 .header-logo,
	.header-type-8 .navbar-header {
		left: 25px !important;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li > a {
		border: 0px solid #ebebeb !important;
		color: #000;
		height: 50px;
		padding: 0px;
		width: 100%;
		text-align: center;
		line-height: 3.5;
		/*font-weight: 500;*/
		/*font-family: 'DB-Helvethaica-X';*/
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:first-child {
		background-color: #fff200;
		width: 100px !important;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:first-child > a {
		padding-left: 10px;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:first-child > a:hover {
		padding-left: 5px;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:first-child > a::before {
		/* background: url(/korat/wp-content/uploads/2016/09/home_icon.png) no-repeat top left; */
		position: absolute;
		width: 40px;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/12/home-page-icon-1.png) no-repeat top
			left;
		position: absolute;
		content: " ";
		/*left: 10px;*/
		left: 10px;
		height: 40px;
		/*top: 13px;*/
		top: 15px;
		background-size: 16px;
		/*content: "\f015";
font: normal normal normal 20px/1 FontAwesome;
color: #000000;*/
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(2) {
		background: #bdd73c;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(2):hover {
		background: #d4eb5e;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(3) {
		background: #25aae1;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(3):hover {
		background: #4abeef;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(4) {
		background: #d91b5c;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(4):hover {
		background: #e8407b;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(5) {
		background: #ef519e;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(5):hover {
		background: #f675b4;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(6) {
		background: #fff200;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(6):hover {
		background: #faf363;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(7) {
		background: #bdd73c;
	}
	.header-type-8 .menu-wrapper .menu-main-container > ul > li:nth-child(7):hover {
		background: #d4eb5e;
	}
	header.header .menu-wrapper {
		border-top: 0px solid #ebebeb;
		border-bottom: 0px solid #ebebeb;
	}
	.textwidget {
		font-size: 10px;
	}
	#text-3 a {
		/*width: 110px;*/
		display: inline-block;
		text-align: center;
		/*padding-top: 18px;*/
	}
	#text-3 a.link_top:hover {
		padding-top: 18px;
	}
	a.link_top {
		/*width: 100px;*/
		width: 140px;
		padding-top: 18px;
	}
	a.link_top img {
		margin-left: 10px;
	}
	.header-type-8 .menu-wrapper .languages-area {
		float: right;
		padding: 0px 0 0;
		background: #f3f3f3;
		display: inline-block;
		width: 20.2%;
		height: 50px;
	}
	.container::before {
		background: url("https://terminal21.co.th/korat/wp-content/uploads/2016/09/lasso-border.png") repeat-y top left;
		content: "";
		position: absolute;
		top: -5%;
		left: 0;
		width: 10px;
		height: 105%;
		display: block !important;
		opacity: 0.5;
		z-index: 10000;
	}
	.container::after {
		background: url("https://terminal21.co.th/korat/wp-content/uploads/2016/09/lasso-border.png") repeat-y top right;
		content: "";
		position: absolute;
		top: -5%;
		right: 0;
		width: 10px;
		height: 105%;
		display: block !important;
		opacity: 0.5;
		z-index: 10000;
	}
	.main-header > .container::before,
	.main-header > .container::after {
		opacity: 0;
	}
	.boxed .st-container {
		box-shadow: 0 0px 0px !important;
		-webkit-box-shadow: 0 0px 0px !important;
	}
	div#text-4 {
		position: absolute;
		top: -70px;
		right: 20px;
	}
	.lang {
		display: inline;
		float: right;
		font-size: 24px;
		font-weight: bold;
		margin-right: 10px;
	}
	.lang > a {
		width: 37px;
		height: 33px;
		background-size: 100% auto;
		background-repeat: no-repeat;
		/*margin: 10px 5px 0px 5px;*/
		-ms-transform: translate(0, 25%);
		-webkit-transform: translate(0, 25%);
		transform: translate(0px, 25%);
	}
	.lang > a:nth-child(1) {
		background-image: url("/korat/wp-content/uploads/2016/09/lan-th-0.png");
	}
	.lang > a:nth-child(2) {
		background-image: url("/korat/wp-content/uploads/2016/09/lan-en-1.png");
	}
	div#text-3 {
		display: inline-block;
	}
	.widget_polylang {
		display: inline;
		float: right;
		/*padding-top: 10px;*/
		font-size: 24px;
		font-weight: bold;
		margin-right: 10px;
	}
	.widget_polylang li {
		display: inline;
	}
	.widget_polylang a {
		width: 37px;
		height: 33px;
		background-size: 100% auto;
		background-repeat: no-repeat;
		/*margin: 10px 5px 0px 5px;*/
		-ms-transform: translate(0, 25%);
		-webkit-transform: translate(0, 25%);
		transform: translate(0px, 25%);
		display: inline-block;
		text-align: center;
	}
	:lang(th) .widget_polylang .lang-item-th a {
		background-image: url("/korat/wp-content/uploads/2016/09/lan-th-1.png");
		margin-right: 3px;
	}
	:lang(en) .widget_polylang .lang-item-th a {
		background-image: url("/korat/wp-content/uploads/2016/09/lan-th-0.png");
		margin-right: 3px;
	}
	:lang(en) .widget_polylang .lang-item-th a:hover {
		background-image: url("/korat/wp-content/uploads/2016/09/lan-th-1.png");
		margin-right: 3px;
	}
	:lang(th) .widget_polylang .lang-item-en a {
		background-image: url("/korat/wp-content/uploads/2016/09/lan-en-0.png");
		margin-left: 3px;
	}
	:lang(th) .widget_polylang .lang-item-en a:hover {
		background-image: url("/korat/wp-content/uploads/2016/09/lan-en-1.png");
		margin-left: 3px;
	}
	:lang(en) .widget_polylang .lang-item-en a {
		background-image: url("/korat/wp-content/uploads/2016/09/lan-en-1.png");
		margin-left: 3px;
	}
	.widget_polylang img {
		opacity: 0;
	}
	li.lang-item-th:after {
		content: ":";
		font-size: 24px;
		font-weight: bold;
		padding-top: 5px;
		position: absolute;
	}
	.main-footer {
		background: TRANSPARENT;
		padding-top: 0px;
	}
	.main-footer p {
		font: 12px/16px "roboto", Helvetica, Arial, sans-serif;
		color: #455560;
	}
	.width20 {
		width: 25%;
		float: left;
		text-align: center;
	}
	.width20 a {
		font-family: "roboto", Helvetica, Arial, sans-serif;
		color: #455560;
	}
	:lang(th) .width20 a {
		color: #455565;
		font-family: "DB-Helvethaica-X";
		font-size: 18px;
	}
	.copyright-1,
	.copyright-2,
	.copyright-3,
	.copyright {
		display: none;
	}
	.main-footer hr.divider {
		border-bottom: 1px solid #bfc3c6;
		margin-top: 5px;
		margin-bottom: 5px;
		width: 93%;
		margin-left: 3.5%;
	}
	.bg_footer {
		background: url(https://terminal21.co.th/korat/wp-content/uploads/2016/09/fotter_bg_2.png) repeat-x top left;
		position: relative;
		min-height: 100%;
		padding: 90px 0px 0px 0px;
		margin-left: 1px !important;
		margin-right: 1px !important;
		background-size: 265px;
	}
	.social-icon {
		float: right;
	}
	.social-icon a {
		/*float: left;*/
		margin-left: 1px;
	}
	.width60 {
		width: 50%;
	}
	.main-footer .wpb_single_image.vc_align_left {
		text-align: left;
		margin-bottom: 15px;
	}
	.mc4wp-form {
		float: left;
		width: 250px;
		margin: -26px 5px 0px 0px;
	}
	.mc4wp-form input[type="email"] {
		width: 55%;
		margin-top: 0px;
		padding: 5px 10px;
		float: left;
		height: 35px;
		border-radius: 3px 0 0 3px !important;
		border-right: transparent 1px solid;
		height: 33px;
	}
	.mc4wp-form input[type="submit"] {
		width: 45%;
		background-color: #f3f4f4 !important;
		color: #000;
		border-radius: 3px;
		border: 1px solid #484848;
		border-left: 1px solid transparent;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		padding: 9px 10px 9px 10px;
		font-size: 12px;
		background-image: url("https://terminal21.co.th/korat/wp-content/uploads/2016/11/icon-plane.png");
		background-repeat: no-repeat;
		background-position: 93% 50%;
		text-align: left;
		text-transform: none;
		height: 33px;
		background-size: 32%;
	}
	.mc4wp-form-fields label {
		font-weight: 400;
		font-size: 12px;
		display: inherit;
		text-align: right;
		margin-right: 10px;
	}
	/*.mc4wp-form::after {
width: 1px;height: 24px;content: "";
position: absolute;
top: 4px;
right: 198px;
z-index: 200;   
background: #484848;
}*/
	.mc4wp-form-fields::after {
		width: 1px;
		height: 24px;
		content: "";
		position: absolute;
		top: 4px;
		left: 0;
		z-index: 200;
		background: #484848;
		-ms-transform: translate(138px, 0%);
		-webkit-transform: translate(138px, 0%);
		transform: translate(138px, 0%);
	}
	.promo_date {
		display: inline-block;
		float: none;
	}
	.promo_txt p,
	.promo_date p,
	div.promo_date {
		color: #fff;
		font-family: "DB-Helvethaica-X";
		font-size: 18px;
		line-height: 1.5;
	}
	.promo_date p,
	div.promo_date {
		font-size: 18px;
		color: #000;
		margin-bottom: 0px !important;
		font-family: "DB-Helvethaica-X";
	}
	.promo_title h4 {
		color: #abca11;
		font-size: 26px;
		margin-bottom: 0px;
		font-family: "DB-Helvethaica-X";
	}
	.young_bander::before {
		background: #f2f3f3 none repeat scroll 0 0;
		content: "";
		height: 45%;
		height: 60px;
		position: absolute;
		left: 13px;
		top: 60%;
		-ms-transform: translate(0, -65%);
		-webkit-transform: translate(0, -65%);
		transform: translate(-13px, -65%);
		width: 2px;
	}
	.left15 {
		left: -15px !important;
	}
	.footermenu {
		left: -7px;
		position: relative;
	}
	/************************************ ICON FLOOR ***************************************/
	.width14 {
		width: 14.28% !important;
		background-color: #efefef;
		border: 2px solid #fff;
	}
	.width14.floor5:hover {
		background-color: #d91b5c;
		border: 2px solid #fff;
		/*transform: scale(1.1);*/
		z-index: 1;
	}
	.width14.floor5:hover .floor p {
		color: #d91b5c !important;
	}
	.width14.floor4:hover {
		background-color: #bdd73c;
		border: 2px solid #fff;
		/*transform: scale(1.1);*/
		z-index: 1;
	}
	.width14.floor4:hover .floor p {
		color: #bdd73c !important;
	}
	.width14.floor3:hover {
		background-color: #25aae1;
		border: 2px solid #fff;
		/*transform: scale(1.1);*/
		z-index: 1;
	}
	.width14.floor3:hover .floor p {
		color: #25aae1 !important;
	}
	.width14.floor2:hover {
		background-color: #ef519e;
		border: 2px solid #fff;
		/*transform: scale(1.1);*/
		z-index: 1;
	}
	.width14.floor2:hover .floor p {
		color: #ef519e !important;
	}
	.width14.floor1:hover {
		background-color: #d91b5c;
		border: 2px solid #fff;
		/*transform: scale(1.1);*/
		z-index: 1;
	}
	.width14.floor1:hover .floor p {
		color: #d91b5c !important;
	}
	.width14.floorg:hover {
		background-color: #bdd73c;
		border: 2px solid #fff;
		/*transform: scale(1.1);*/
		z-index: 1;
	}
	.width14.floorg:hover .floor p {
		color: #bdd73c !important;
	}
	.width14.floorl:hover {
		background-color: #25aae1;
		border: 2px solid #fff;
		/*transform: scale(1.1);*/
		z-index: 1;
	}
	.width14.floorl:hover .floor p {
		color: #25aae1 !important;
	}
	.width14 .ult-just-icon-wrapper {
		text-align: center;
	}
	.ult-just-icon-wrapper {
		margin: 0px !important;
		display: inline;
	}
	.aio-icon-img.uavc-circle {
		padding: 0.2em;
	}
	.floor {
		margin-bottom: 0px !important;
		border: 2px solid #959595;
		margin-top: 5px;
		width: 27px;
		text-align: center;
		z-index: 10;
		position: relative;
	}
	.floor p {
		color: #959595 !important;
		/*font-size: 12px;*/
		font-size: 14px;
		font-weight: bold;
	}
	.floor_name {
		margin-bottom: 5px !important;
		margin-top: 4px;
	}
	.floor_name p {
		font-size: 12px;
		text-align: center;
		color: #000;
	}
	.width14 .vc_column-inner {
		padding: 2px 5px !important;
	}
	.width14:hover .floor {
		border: 2px solid #000;
		background-color: #000;
	}
	.width14:hover .floor p {
		color: #d91b5c !important;
		font-weight: bold;
	}
	.width14:hover .floor_name p {
		color: #fff !important;
	}
	.width14:hover .aio-icon-img.uavc-circle {
		background: transparent !important;
	}
	.width14:hover .floor5 {
		background-image: url("https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/5f_icon_w.png");
		background-size: 100% auto;
	}
	.width14:hover .aio-icon-img.uavc-circle {
		background-image: url("https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/5f_icon_w.png");
		background-size: 100% auto;
	}
	.width14 .banner.align-center {
		text-align: center;
		background-color: #f3f4f5;
		height: 100px;
		border-radius: 100%;
		width: 100px;
		margin-bottom: 0px;
		vertical-align: middle;
		left: 50%;
		-ms-transform: translate(-50%, 0%);
		-webkit-transform: translate(-50%, 0%);
		transform: translate(-50%, 0%);
	}
	.width14:hover .banner.align-center {
		background-color: transparent;
	}
	.width14:hover .banner img {
		opacity: 0;
	}
	.width14 .banner img {
		-webkit-transition: all 0.1s ease-in-out;
		-ms-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
		transition: all 0.1s ease-in-out;
		height: auto;
		width: 70px;
		margin-top: 50%;
		-ms-transform: translate(0%, -50%);
		-webkit-transform: translate(0%, -50%);
		transform: translate(0%, -50%);
	}
	.floor5:hover .banner-content {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/5f_icon_w-1.png) !important;
		background-size: 70px !important;
		background-position: 50% 50% !important;
		background-repeat: no-repeat !important;
	}
	.floor4:hover .banner-content {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/4f_icon_nw.png) !important;
		background-size: 70px !important;
		background-position: 50% 50% !important;
		background-repeat: no-repeat !important;
	}
	.floor3:hover .banner-content {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/3f_icon_w-1.png) !important;
		background-size: 70px !important;
		background-position: 50% 50% !important;
		background-repeat: no-repeat !important;
	}
	.floor2:hover .banner-content {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/2f_icon_w-1.png) !important;
		background-size: 70px !important;
		background-position: 50% 50% !important;
		background-repeat: no-repeat !important;
	}
	.floor1:hover .banner-content {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/1f_icon_w-1.png) !important;
		background-size: 70px !important;
		background-position: 50% 50% !important;
		background-repeat: no-repeat !important;
	}
	.floorg:hover .banner-content {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/gf_icon_w-1.png) !important;
		background-size: 70px !important;
		background-position: 50% 50% !important;
		background-repeat: no-repeat !important;
	}
	.floorl:hover .banner-content {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/lf_icon_w-1.png) !important;
		background-size: 70px !important;
		background-position: 50% 50% !important;
		background-repeat: no-repeat !important;
	}

	/********************************** END ICON FLOOR *************************************/

	/************************************* SHOP LOGO ***************************************/
	.vc_grid-container-wrapper.vc_clearfix {
		float: left;
		width: 100%;
	}
	.shop_all .vc_grid-item:nth-child(1n + 1) .vc_gitem-animated-block {
		border: 2px solid #25aae1;
	}
	.shop_all .vc_grid-item:nth-child(2n + 1) .vc_gitem-animated-block {
		border: 2px solid #db2866;
	}
	.shop_all .vc_grid-item:nth-child(3n + 1) .vc_gitem-animated-block {
		border: 2px solid #ef519e;
	}
	.shop_all .vc_grid-item:nth-child(4n + 1) .vc_gitem-animated-block {
		border: 2px solid #bdd73c;
	}
	.shop_all .vc_gitem-animated-block {
		padding: 20px;
	}

	.shoplogo {
		background-size: contain !important;
	}
	span.location {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/location_wbd_bg.png) !important;
		background-size: contain;
		background-position: 50% 50% !important;
		background-repeat: no-repeat !important;
		display: block;
		/*width: 140px;*/
		height: 25px;
		padding: 3px 5px 3px 4px;
		color: #000;
		font-size: 17px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	span.location.long {
		font-size: 14px;
		line-height: 1.5;
	}
	.shop_all .vc_gitem-post-data-source-post_title h4 {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-bottom: 0px;
	}
	.shop_all .vc_gitem-post-data-source-post_title {
		margin-bottom: 0px;
	}
	.shop_all .vc_grid-item:nth-child(1n + 1):hover span.location {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/location_b_bg.png) !important;
	}
	.shop_all .vc_grid-item:nth-child(2n + 1):hover span.location {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/location_pb_bg.png) !important;
	}
	.shop_all .vc_grid-item:nth-child(3n + 1):hover span.location {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/location_pw_bg.png) !important;
	}
	.shop_all .vc_grid-item:nth-child(4n + 1):hover span.location {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/location_g_bg.png) !important;
	}
	.shop_all .vc_grid-item.vc_col-sm-2 {
		width: 14.28% !important;
	}
	.shop_all .vc_arrow-icon-navicon:before {
		content: "\f0d8";
		font-family: FontAwesome;
	}
	.shop_all .vc_arrow-icon-navicon:after {
		content: "\f0d7";
		font-family: FontAwesome;
		position: absolute;
		bottom: 4px;
		left: 35%;
	}
	.shop_all .vc_gitem_row .vc_gitem-col {
		padding-left: 0px;
		padding-right: 0px;
	}
	/********************************** END SHOP LOGO **************************************/
	.bn_title strong {
		font-weight: bold !important;
	}

	.floor_5 {
		margin-bottom: 30px;
		/*min-height: 237px!important;*/
		min-height: 105px !important;
	}
	.floor_5 .banner {
		width: 60%;
		left: 20%;
		margin-bottom: 0px;
		top: 15px;
	}
	.floor_5 .floor_name p {
		top: 20px;
		position: relative;
		font-size: 16px;
		font-weight: bold;
		color: #fff;
	}
	.floor_5 .floor {
		border: 2px solid #000;
		background-color: #000;
	}
	.floor_5 .floor p {
		color: #fff !important;
	}
	.floor_5 > .vc_column-inner {
		padding-left: 5px !important;
		padding-right: 5px !important;
	}
	h2.name_f {
		font-size: 36px;
		position: relative;
		/* top: 105px; */
		/* top: 145px; */
		font-weight: bold;
		text-align: center;
		line-height: 0.7;
		margin-top: -10px;
	}
	.floor_5::after {
		background: #959595 none repeat scroll 0 0;
		content: "";
		height: 236px;
		position: absolute;
		right: -20px;
		top: 0%;
		width: 2px;
		display: none;
	}
	.vc_gitem-post-data-source-post_excerpt p {
		font-family: "DB-Helvethaica-X";
		font-size: 18px;
		line-height: 1.2;
	}
	.content-article {
		font-family: "roboto";
	}
	/*******************************************************************************************/
	/*********************************** POST CONTRAL ******************************************/
	/*******************************************************************************************/
	/*.wp-picture {
display: none;
}*/
	.post-news img {
		width: 100%;
		text-align: center;
		/*max-height: 255px;*/
	}
	/*********************************** END POST CONTRAL **************************************/

	/*******************************************************************************************/
	/*********************************** SHOP CONTRAL ******************************************/
	/*******************************************************************************************/
	.container30 {
		padding-left: 35px;
		padding-right: 35px;
	}
	.container30 .wpb_column.vc_column_container.vc_col-sm-7 {
		/*padding-left: 60px;*/
	}
	.shop_all select {
		width: 200px !important;
		z-index: 3 !important;
	}
	.shop_all .vc_grid-filter-dropdown {
		position: relative;
		top: -85px;
		/* right: 22%; */
		right: 16%;
	}
	.shop_all .vc_grid-styled-select {
		border: 1px solid #d7d7d7 !important;
		border-radius: 3px;
		padding-right: 0px !important;
	}
	.shop_all i.vc_arrow-icon-navicon {
		background-color: #bdd73c;
		width: 30px !important;
		height: 32px !important;
		position: relative;
		top: -1px;
		display: inline-block !important;
		text-align: center;
	}
	.shop_all .vc_grid.vc_row {
		margin-top: -50px;
	}
	.shop_all {
		padding-left: 60px;
		padding-right: 60px;
	}
	.search_shop {
		margin-bottom: 0px;
		width: 215px;
		top: 12px;
		float: right;
		position: absolute;
		right: 0px;
		z-index: 9;
	}
	.et-search-result {
		background-color: #efefef;
		position: absolute;
		/*margin-top: 0px;*/
		/*padding: 10px 0px;*/
	}
	.et-search-result ul {
		padding-right: 0px;
		padding-left: 0px;
		padding-top: 10px;
	}
	.etheme_widget_search .button {
		height: 30px;
		position: absolute;
		right: 20px;
		background-color: transparent;
		background-image: url(/korat/wp-content/uploads/2016/09/icon-link.png);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		color: transparent;
		border: 1px solid #d7d7d7 !important;
		border-left: 0px !important;
		border-radius: 3px;
		width: 20%;
	}
	.etheme_widget_search .button:hover,
	.etheme_widget_search .button:focus {
		background-color: transparent !important;
		color: transparent !important;
	}
	.etheme_widget_search input[type="text"],
	.etheme_widget_search input[type="email"],
	.etheme_widget_search input[type="password"] {
		height: 30px;
		border: 1px solid #d7d7d7 !important;
		border-radius: 3px;
		/*border-right: 0px!important;*/
		/*width: 80%;*/
		width: 90%;
		background-image: url(/korat/wp-content/uploads/2016/09/icon-link.png);
		background-repeat: no-repeat;
		background-position: 95% 50%;
		padding-right: 35px;
	}
	.search_shop .etheme_widget_search .button {
		display: none;
	}
	.mapplic-pin {
		opacity: 0;
	}
	.mapplic-pin:hover {
		opacity: 1;
	}
	.mapplic-container {
		width: 100% !important;
	}
	.mapplic-sidebar {
		width: 100% !important;
	}
	.mapplic-element {
		background-color: #eeeeee !important;
	}
	.mapplic-tooltip-description,
	.mapplic-tooltip p {
		text-align: center;
	}
	.font-thai p {
		font-family: "DB-Helvethaica-X";
		font-size: 20px;
		line-height: 1;
		color: #000;
	}
	.shop h2 > img {
		margin-right: 10px;
		margin-top: -10px;
	}
	.shop h2 {
		padding-top: 5px;
	}
	/*********************************** END SHOP CONTRAL **************************************/

	/*******************************************************************************************/
	/******************************** SHOP DETAIL CONTRAL **************************************/
	/*******************************************************************************************/
	h4.blue {
		color: #25aae1;
		font-weight: bold;
		text-transform: none;
		margin-top: 50px;
		margin-left: 50px;
	}
	.blog-post .wpb_revslider_element.wpb_content_element {
		padding-top: 60px;
	}

	.vc_tta-style-classic {
		border-top: 0px solid #000;
	}
	.vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:before {
		background-color: transparent;
	}
	.vc_tta-style-classic .vc_tta-tabs-container a span {
		color: #fff !important;
		margin: 20px 30px;
		height: 100px !important;
		display: block !important;
		padding: 20px 0px 0px 0px;
		font-weight: bold;
		font-size: 14px;
	}
	.vc_tta.vc_general .vc_tta-panel-body {
		padding: 0px 15px !important;
		overflow: hidden !important;
	}
	.vc_tta-panel-body .vc_gitem-zone.vc_gitem-zone-c {
		display: none;
	}
	.vc_tta.vc_general .vc_tta-panel {
		/*transition: all 0.5s ease-in-out!important;*/
		animation: opac 2s;
	}
	.vc_tta.vc_general .vc_tta-panel.vc_active,
	.vc_tta.vc_general .vc_tta-panel.vc_active .vc_tta-panel-body {
		/*transition: all 0.5s ease-in-out!important;*/
		animation: opac 2s;
	}

	.floor5_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab,
	.floor4_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab,
	.floor3_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab,
	.floor2_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab,
	.floor1_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab,
	.floorgf_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab,
	.floorlg_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab {
		position: relative;
		max-height: 100px;
		margin-bottom: 10px;
	}
	.floor5_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a span,
	.floor4_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a span,
	.floor3_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a span,
	.floor2_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a span,
	.floor1_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a span,
	.floorgf_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a span,
	.floorlg_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a span {
		color: #fff !important;
		margin: 20px 30px;
		height: 100px !important;
		display: block !important;
		padding: 35px 0px 0px 0px;
		font-weight: bold;
		font-size: 14px;
	}
	.floor5_detail .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-tabs-container,
	.floor4_detail .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-tabs-container,
	.floor3_detail .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-tabs-container,
	.floor2_detail .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-tabs-container,
	.floor1_detail .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-tabs-container,
	.floorgf_detail .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-tabs-container,
	.floorlg_detail .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-tabs-container {
		margin: 0;
		width: 30%;
		border-bottom: 0px solid #ddd !important;
	}
	.floor5_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a,
	.floor4_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a,
	.floor3_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a,
	.floor2_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a,
	.floor1_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a,
	.floorgf_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a,
	.floorlf_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab a {
		padding: 0px !important;
		display: block !important;
		height: 100px !important;
		top: 20px;
	}
	.floor5_detail .wpb_column.vc_column_container.vc_col-sm-3,
	.floor4_detail .wpb_column.vc_column_container.vc_col-sm-3,
	.floor3_detail .wpb_column.vc_column_container.vc_col-sm-3,
	.floor2_detail .wpb_column.vc_column_container.vc_col-sm-3,
	.floor1_detail .wpb_column.vc_column_container.vc_col-sm-3,
	.floorgf_detail .wpb_column.vc_column_container.vc_col-sm-3,
	.floorlg_detail .wpb_column.vc_column_container.vc_col-sm-3 {
		background-color: #f9f9f9 !important;
		min-height: 420px;
		width: 30%;
	}
	.floor5_detail .vc_col-sm-9,
	.floor4_detail .vc_col-sm-9,
	.floor3_detail .vc_col-sm-9,
	.floor2_detail .vc_col-sm-9,
	.floor1_detail .vc_col-sm-9,
	.floorgf_detail .vc_col-sm-9,
	.floorlg_detail .vc_col-sm-9 {
		width: 70%;
	}

	.floor5_detail li.vc_tta-tab.firstItem {
		background-color: #d91b5c;
		min-height: 200px;
	}
	.floor5_detail li.vc_tta-tab:nth-child(2) {
		background-color: #25aae1;
	}
	.floor5_detail li.vc_tta-tab:nth-child(3) {
		background-color: #dd356f;
	}
	.floor5_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a {
		/*padding: 20px 10px 180px 10px !important;*/
		padding: 0px !important;
		height: 200px !important;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/5f_icon_w-1.png);
		background-size: 150px;
		display: inline;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		display: block;
		vertical-align: bottom;
	}
	.floor5_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a span {
		/*margin: 0 20px !important;*/
		color: #fff;
		text-transform: uppercase;
		display: block;
		height: 200px;
		/*text-align: center;*/
		/*padding-top: 150px;*/
		padding: 150px 0px 0px 0px;
		font-weight: bold;
	}
	.floor5_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:nth-child(1):before {
		content: "5F";
		width: 25px !important;
		height: 25px !important;
		top: 5px !important;
		margin-top: 0px;
		left: 5px !important;
		background-color: #000;
		text-align: center;
		color: #d91b5c;
	}
	.floor5_detail .wpb_column.vc_column_container.vc_col-sm-3::after {
		content: "";
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/5f_icon-1.png);
		background-size: 150px;
		background-repeat: no-repeat;
		opacity: 0.1;
		bottom: 0;
		right: 15px;
		position: absolute;
		width: 150px;
		height: 150px;
	}

	.floor4_detail li.vc_tta-tab.firstItem {
		background-color: #bdd73c;
		min-height: 200px;
	}
	.floor4_detail li.vc_tta-tab:nth-child(2) {
		background-color: #25aae1;
	}
	.floor4_detail li.vc_tta-tab:nth-child(3) {
		background-color: #dd356f;
	}
	.floor4_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a {
		/*padding: 20px 10px 180px 10px !important;*/
		padding: 0px !important;
		height: 200px !important;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/4f_icon_nw.png);
		background-size: 120px;
		display: inline;
		background-repeat: no-repeat;
		background-position: 50% 35%;
		display: block;
		vertical-align: bottom;
	}
	.floor4_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a span {
		/*margin: 0 20px !important;*/
		color: #fff;
		text-transform: uppercase;
		display: block;
		height: 200px;
		text-align: center;
		/*padding-top: 150px;*/
		padding: 150px 0px 0px 0px;
		font-weight: bold;
		font-size: 11px !important;
	}
	.floor4_detail .vc_tta-style-classic .vc_tta-tabs-container a span {
		padding: 20px 0px 0px 10px;
	}
	.floor4_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:nth-child(1):before {
		content: "4F";
		width: 25px !important;
		height: 25px !important;
		top: 5px !important;
		margin-top: 0px;
		left: 5px !important;
		background-color: #000;
		text-align: center;
		color: #bdd73c;
	}
	.floor4_detail .wpb_column.vc_column_container.vc_col-sm-3::after {
		content: "";
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/4f_icon_n.png);
		background-size: 150px;
		background-repeat: no-repeat;
		opacity: 0.1;
		bottom: 0;
		right: 15px;
		position: absolute;
		width: 150px;
		height: 150px;
	}

	.floor3_detail li.vc_tta-tab.firstItem {
		background-color: #25aae1;
		min-height: 200px;
	}
	.floor3_detail li.vc_tta-tab:nth-child(2) {
		background-color: #bdd73c;
	}
	.floor3_detail li.vc_tta-tab:nth-child(3) {
		background-color: #dd356f;
	}
	.floor3_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a {
		/*padding: 20px 10px 180px 10px !important;*/
		padding: 0px !important;
		height: 200px !important;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/3f_icon_w-1.png);
		background-size: 120px;
		display: inline;
		background-repeat: no-repeat;
		background-position: 50% 35%;
		display: block;
		vertical-align: bottom;
	}
	.floor3_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a span {
		/*margin: 0 20px !important;*/
		color: #fff;
		text-transform: uppercase;
		display: block;
		height: 200px;
		text-align: center;
		/*padding-top: 150px;*/
		padding: 150px 0px 0px 0px;
		font-weight: bold;
	}
	.floor3_detail .vc_tta-style-classic .vc_tta-tabs-container a span {
		padding: 20px 0px 0px 35px;
	}
	.floor3_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:nth-child(1):before {
		content: "3F";
		width: 25px !important;
		height: 25px !important;
		top: 5px !important;
		margin-top: 0px;
		left: 5px !important;
		background-color: #000;
		text-align: center;
		color: #25aae1;
	}
	.floor3_detail .wpb_column.vc_column_container.vc_col-sm-3::after {
		content: "";
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/3f_icon-1.png);
		background-size: 150px;
		background-repeat: no-repeat;
		opacity: 0.1;
		bottom: 0;
		right: 15px;
		position: absolute;
		width: 150px;
		height: 150px;
	}

	.floor2_detail li.vc_tta-tab.firstItem {
		background-color: #ef519e;
		min-height: 200px;
	}
	.floor2_detail li.vc_tta-tab:nth-child(2) {
		background-color: #25aae1;
	}
	.floor2_detail li.vc_tta-tab:nth-child(3) {
		background-color: #d91b5c;
	}
	.floor2_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a {
		/*padding: 20px 10px 180px 10px !important;*/
		padding: 0px !important;
		height: 200px !important;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/2f_icon_w-1.png);
		background-size: 120px;
		display: inline;
		background-repeat: no-repeat;
		background-position: 50% 35%;
		display: block;
		vertical-align: bottom;
	}
	.floor2_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a span {
		/*margin: 0 20px !important;*/
		color: #fff;
		text-transform: uppercase;
		display: block;
		height: 200px;
		text-align: center;
		/*padding-top: 150px;*/
		padding: 150px 0px 0px 0px;
		font-weight: bold;
	}
	.floor2_detail .vc_tta-style-classic .vc_tta-tabs-container a span {
		padding: 20px 0px 0px 17px;
	}
	.floor2_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:nth-child(1):before {
		content: "2F";
		width: 25px !important;
		height: 25px !important;
		top: 5px !important;
		margin-top: 0px;
		left: 5px !important;
		background-color: #000;
		text-align: center;
		color: #ef519e;
	}
	.floor2_detail .wpb_column.vc_column_container.vc_col-sm-3::after {
		content: "";
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/2f_icon-1.png);
		background-size: 150px;
		background-repeat: no-repeat;
		opacity: 0.1;
		bottom: 0;
		right: 15px;
		position: absolute;
		width: 150px;
		height: 150px;
	}

	.floor1_detail li.vc_tta-tab.firstItem {
		background-color: #d91b5c;
		min-height: 200px;
	}
	.floor1_detail li.vc_tta-tab:nth-child(2) {
		background-color: #25aae1;
	}
	.floor1_detail li.vc_tta-tab:nth-child(3) {
		background-color: #dd356f;
	}
	.floor1_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a {
		/*padding: 20px 10px 180px 10px !important;*/
		padding: 0px !important;
		height: 200px !important;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/1f_icon_w-1.png);
		background-size: 120px;
		display: inline;
		background-repeat: no-repeat;
		background-position: 50% 35%;
		display: block;
		vertical-align: bottom;
	}
	.floor1_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a span {
		/*margin: 0 20px !important;*/
		color: #fff;
		text-transform: uppercase;
		display: block;
		height: 200px;
		text-align: center;
		/*padding-top: 150px;*/
		padding: 150px 0px 0px 0px;
		font-weight: bold;
	}
	.floor1_detail .vc_tta-style-classic .vc_tta-tabs-container a span {
		padding: 20px 0px 0px 27px;
	}
	.floor1_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:nth-child(1):before {
		content: "1F";
		width: 25px !important;
		height: 25px !important;
		top: 5px !important;
		margin-top: 0px;
		left: 5px !important;
		background-color: #000;
		text-align: center;
		color: #d91b5c;
	}
	.floor1_detail .wpb_column.vc_column_container.vc_col-sm-3::after {
		content: "";
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/1f_icon-1.png);
		background-size: 150px;
		background-repeat: no-repeat;
		opacity: 0.1;
		bottom: 0;
		right: 15px;
		position: absolute;
		width: 150px;
		height: 150px;
	}

	.floorgf_detail li.vc_tta-tab.firstItem {
		background-color: #bdd73c;
		min-height: 200px;
	}
	.floorgf_detail li.vc_tta-tab:nth-child(2) {
		background-color: #25aae1;
	}
	.floorgf_detail li.vc_tta-tab:nth-child(3) {
		background-color: #dd356f;
	}
	.floorgf_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a {
		/*padding: 20px 10px 180px 10px !important;*/
		padding: 0px !important;
		height: 200px !important;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/gf_icon_w-1.png);
		background-size: 120px;
		display: inline;
		background-repeat: no-repeat;
		background-position: 50% 35%;
		display: block;
		vertical-align: bottom;
	}
	.floorgf_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a span {
		/*margin: 0 20px !important;*/
		color: #fff;
		text-transform: uppercase;
		display: block;
		height: 200px;
		text-align: center;
		/*padding-top: 150px;*/
		padding: 150px 0px 0px 0px;
		font-weight: bold;
	}
	.floorgf_detail .vc_tta-style-classic .vc_tta-tabs-container a span {
		padding: 20px 0px 0px 35px;
	}
	.floorgf_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:nth-child(1):before {
		content: "GF";
		width: 25px !important;
		height: 25px !important;
		top: 5px !important;
		margin-top: 0px;
		left: 5px !important;
		background-color: #000;
		text-align: center;
		color: #bdd73c;
	}
	.floorgf_detail .wpb_column.vc_column_container.vc_col-sm-3::after {
		content: "";
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/gf_icon-1.png);
		background-size: 150px;
		background-repeat: no-repeat;
		opacity: 0.1;
		bottom: 0;
		right: 15px;
		position: absolute;
		width: 150px;
		height: 150px;
	}

	.floorlg_detail li.vc_tta-tab.firstItem {
		background-color: #25aae1;
		min-height: 200px;
	}
	.floorlg_detail li.vc_tta-tab:nth-child(2) {
		background-color: #bdd73c;
	}
	.floorlg_detail li.vc_tta-tab:nth-child(3) {
		background-color: #dd356f;
	}
	.floorlg_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a {
		/*padding: 20px 10px 180px 10px !important;*/
		padding: 0px !important;
		height: 200px !important;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/lf_icon_w-1.png);
		background-size: 120px;
		display: inline;
		background-repeat: no-repeat;
		background-position: 50% 35%;
		display: block;
		vertical-align: bottom;
	}
	.floorlg_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a span {
		/*margin: 0 20px !important;*/
		color: #fff;
		text-transform: uppercase;
		display: block;
		height: 200px;
		text-align: center;
		/*padding-top: 150px;*/
		padding: 150px 0px 0px 0px;
		font-weight: bold;
	}
	.floorlg_detail .vc_tta-style-classic .vc_tta-tabs-container a span {
		padding: 20px 0px 0px 15px;
	}
	.floorlg_detail .vc_tta-style-classic .vc_tta-tabs-container li.vc_tta-tab:nth-child(1):before {
		content: "LG";
		width: 25px !important;
		height: 25px !important;
		top: 5px !important;
		margin-top: 0px;
		left: 5px !important;
		background-color: #000;
		text-align: center;
		color: #25aae1;
	}
	.floorlg_detail .wpb_column.vc_column_container.vc_col-sm-3::after {
		content: "";
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/lf_icon-1.png);
		background-size: 150px;
		background-repeat: no-repeat;
		opacity: 0.1;
		bottom: 0;
		right: 15px;
		position: absolute;
		width: 150px;
		height: 150px;
	}

	.vc_row.wpb_row.vc_row-fluid.container30.floor5_detail,
	.vc_row.wpb_row.vc_row-fluid.container30.floor4_detail,
	.vc_row.wpb_row.vc_row-fluid.container30.floor3_detail,
	.vc_row.wpb_row.vc_row-fluid.container30.floor2_detail,
	.vc_row.wpb_row.vc_row-fluid.container30.floor1_detail,
	.vc_row.wpb_row.vc_row-fluid.container30.floorgf_detail,
	.vc_row.wpb_row.vc_row-fluid.container30.floorlg_detail {
		padding-right: 60px;
		padding-left: 30px;
	}

	.container30 .vc_gitem-post-data-source-post_excerpt p {
		font-family: "DB-Helvethaica-X";
		font-size: 18px;
		line-height: 1.2;
		color: #000;
	}
	.bottom_color .vc_gitem-post-data-source-post_excerpt p {
		font-family: "DB-Helvethaica-X" !important;
		font-size: 18px !important;
		line-height: 1.2 !important;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.event-bt .meta-post {
		font-family: "DB-Helvethaica-X";
	}
	.event-bt .meta-post::after {
		display: none;
	}
	.event-bt .caption h3 {
		margin-bottom: 0px;
		font-family: "DB-Helvethaica-X" !important;
		font-size: 24px !important;
	}
	.vc_gitem-zone.vc_gitem-zone-a.shoplogo.vc-gitem-zone-height-mode-auto.vc-gitem-zone-height-mode-auto-1-1.vc_gitem-is-link {
		background-image: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/shop-default.jpg) !important;
	}
	.page-heading {
		display: none;
	}

	.content-article .wpb_widgetised_column.wpb_content_element {
		margin-bottom: 0px;
	}
	.content-article .container30 .wpb_widgetised_column.wpb_content_element {
		margin-bottom: 35px;
	}
	.mapplic-tooltip {
		-ms-transform: translate(0px, 70px);
		-webkit-transform: translate(0px, 70px);
		transform: translate(0px, 70px);
	}
	.mapplic-tooltip-close:hover {
		padding: 4px;
	}

	.width50 {
		width: 57% !important;
		float: right;
		margin-top: -108px;
		margin-bottom: 50px;
	}
	.width50 .width14 .banner.align-center {
		height: 60px;
		width: 60px;
	}
	.width50 .width14 .banner img {
		width: 50px;
	}
	.width50 .width14:hover .banner-content {
		background-size: 50px !important;
	}
	.width50 .floor_name {
		display: none;
	}
	.width50 .floor {
		margin-bottom: -5px !important;
		border: 1px solid #959595;
		margin-top: 3px;
		width: 20px;
		height: 20px;
		z-index: 10;
		position: relative;
	}
	.width50 .floor p {
		font-size: 14px !important;
		font-family: "DB-Helvethaica-X";
		line-height: 1.2 !important;
	}
	.vc_tta-panel:nth-child(3) .vc_grid-container-wrapper::after {
		content: "No promotion";
		font-size: 28px;
		color: #767676;
		font-family: "DB-Helvethaica-X";
		font-size: 36px;
		text-transform: uppercase;
		position: absolute;
		margin-top: 35%;
		left: 30%;
	}
	.floor_5 li.vc_tta-tab:nth-child(3) {
		display: none !important;
	}
	.floor_5 li.vc_tta-tab:nth-child(2) {
		min-height: 210px;
	}
	.floor_5 li.vc_tta-tab:nth-child(2):before {
		background-color: transparent;
		width: 228px !important;
		height: 215px !important;
		top: 0px !important;
		z-index: 10;
	}
	.floor_5 li.vc_tta-tab:nth-child(2) a {
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/11/gallery_w_icon.png);
		background-size: 100px;
		background-repeat: no-repeat;
		height: 210px;
		background-position: 50% 35%;
	}
	.floor_5 li.vc_tta-tab:nth-child(2) a span {
		padding: 130px 0px 0px 0px;
		/* top: 100px; */
		text-align: center;
	}
	/*.floor_5 .vc_tta-panel .vc_single_image-wrapper{
width: 50%;
margin-top: 45%;
-ms-transform: translate(0,-50%);
-webkit-transform: translate(0,-50%);
transform: translate(0px, -50%);
}*/
	.floor_5 .vc_tta-panel .wpb_text_column.wpb_content_element > .wpb_wrapper > img {
		max-width: 250px;
		margin-left: 50%;
		margin-top: 42%;
		-ms-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.smalldetail {
		display: none;
	}
	/*********************************** END SHOP DETAIL CONTRAL ******************************************/

	/*******************************************************************************************************/
	/*********************************** PROMOTION DETAIL CONTRAL ******************************************/
	/*******************************************************************************************************/
	.blog-post .content-article h2 {
		font-size: 36px;
		font-family: "DB-Helvethaica-X";
		color: #ef519e;
	}
	.caption {
		display: none;
	}
	.post-news {
		width: 100% !important;
	}
	ul.the_champ_sharing_ul {
		text-align: right !important;
	}
	.the_champ_sharing_container.the_champ_horizontal_sharing {
		margin-top: -5px;
	}
	i.fa.fa-caret-left {
		width: 30px;
		background-color: #bed73d;
		height: 26px;
		color: #fff;
		text-align: center;
		vertical-align: middle;
		font-size: 24px;
		margin-right: 15px;
	}
	.back a {
		width: 100px;
		border: 1px solid #d7d7d7;
		display: block;
		border-radius: 3px;
		/*font-size: 18px;
font-family: 'DB-Helvethaica-X';*/
		color: #9c9c9b !important;
		margin-left: 35px;
	}
	.back strong {
		color: #9c9c9b !important;
	}
	.back a:hover {
		width: 98px;
	}
	div.pp_pic_holder {
		z-index: 10002;
	}
	div.pp_overlay {
		z-index: 10001;
	}
	.slider-container.carousel-area {
		display: none;
	}
	.the_champ_sharing_container.the_champ_horizontal_sharing {
		float: right;
	}
	div.the_champ_horizontal_sharing li.the_champ_facebook_like,
	div.the_champ_horizontal_counter li.the_champ_facebook_like {
		width: 75px !important;
	}
	div.the_champ_horizontal_sharing li.the_champ_facebook_share,
	div.the_champ_horizontal_counter li.the_champ_facebook_share {
		width: 75px !important;
	}
	.left {
		float: left;
		/*margin-left: 20px;*/
		position: absolute;
		right: 170px;
	}
	.bottom-line {
		margin-top: 40px !important;
		background: url(/korat/wp-content/uploads/2016/10/lasso-border-1.png) repeat-x bottom left;
		bottom: 0px;
		left: 0;
		width: 100%;
		height: 60px;
		display: block !important;
		margin-bottom: 40px;
	}
	.carousel-area .owl-item:nth-child(1n + 1) .thumbnails-x .btn_group a {
		background: #ef519e !important;
	}
	.carousel-area .owl-item:nth-child(2n + 1) .thumbnails-x .btn_group a {
		background: #bed73d !important;
	}
	.carousel-area .owl-item:nth-child(3n + 1) .thumbnails-x .btn_group a {
		background: #25aae3 !important;
	}
	.carousel-area .owl-item:nth-child(4n + 1) .thumbnails-x .btn_group a {
		background: #d91a5d !important;
	}
	.post-slide .btn_group {
		margin-top: 0px;
		width: 100% !important;
		height: 100% !important;
		top: 0px;
	}
	.thumbnails-x .btn_group a:hover {
		opacity: 0 !important;
	}
	.thumbnails-x .post-news:hover .zoom {
		opacity: 0;
	}
	.post-slide .btn_group a {
		padding: 0px !important;
		width: 100% !important;
		height: 100% !important;
		margin: 0px;
		opacity: 0;
	}
	article.post h2 {
		margin-bottom: 0px;
	}
	/*.shop_promotion .vc_col-sm-3 {
width: 33.33%!important;
}*/
	.shop_promotion > div > div > div {
		text-align: center;
		font-family: "DB-Helvethaica-X";
		font-size: 30px;
		text-transform: uppercase;
		color: #767676;
	}
	/*.shop_promotion > div > div > div::before{
content: '';
display: block;
width: 37%;
height: 1px;
background: #cbcbcb;
position: absolute;
z-index: 1;
transform: translate(0%, 10px);
left: 15px;
}
.shop_promotion > div > div > div::after{
content: '';
display: block;
width: 37%;
height: 1px;
background: #cbcbcb;
position: absolute;
z-index: 1;
transform: translate(0%, -10px);
right: 15px;
}*/
	.shop_promotion .vc_grid-item {
		text-align: left;
	}
	.shop_promotion .vc_gitem-post-data {
		margin-bottom: 0px;
	}
	.shop_promotion .vc_custom_heading.vc_gitem-post-data.vc_gitem-post-data-source-post_title {
		margin-bottom: -20px;
	}
	.shop_promotion .vc_custom_heading.vc_gitem-post-data.vc_gitem-post-data-source-post_title h4 {
		font-weight: bold;
	}
	.blog-post .shop_promotion .vc_custom_heading.vc_gitem-post-data.vc_gitem-post-data-source-post_title {
		margin-bottom: -10px;
	}
	.blog-post .shop_promotion .vc_custom_heading.vc_gitem-post-data.vc_gitem-post-data-source-post_title h4 {
		color: #000;
	}
	.shop_promotion .vc_custom_heading.vc_gitem-post-data.vc_gitem-post-data-source-post_excerpt {
		margin-top: -15px;
	}
	.shop_promotion .vc_custom_heading.vc_gitem-post-data.vc_gitem-post-data-source-post_excerpt p {
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		color: #000;
	}
	.vc_grid-item:nth-child(1n + 1) .bottom_color {
		border-bottom: 10px solid #bdd73c;
	}
	.vc_grid-item:nth-child(2n + 1) .bottom_color {
		border-bottom: 10px solid #25aae1;
	}
	.vc_grid-item:nth-child(3n + 1) .bottom_color {
		border-bottom: 10px solid #d91b5c;
	}
	.vc_grid-item:nth-child(4n + 1) .bottom_color {
		border-bottom: 10px solid #fff100;
	}
	.bottom_color h4 {
		font-weight: bold;
		font-size: 22px;
	}
	.vc_grid-item:nth-child(1n + 1) .bottom_color h4 {
		color: #bdd73c;
	}
	.vc_grid-item:nth-child(2n + 1) .bottom_color h4 {
		color: #25aae1;
	}
	.vc_grid-item:nth-child(3n + 1) .bottom_color h4 {
		color: #d91b5c;
	}
	.vc_grid-item:nth-child(4n + 1) .bottom_color h4 {
		color: #fff100;
	}
	.bottom_color .vc_gitem-post-data {
		margin-bottom: 0px;
	}

	.blog-post .content-article p {
		margin-bottom: 10px;
		font-family: "DB-Helvethaica-X";
		color: #000;
		font-size: 20px;
		line-height: 1.2 !important;
		margin-bottom: 10px;
	}
	.promo_bt .caption {
		display: block;
		width: 100% !important;
		padding-left: 0px !important;
	}
	.promo_bt .recentCarousel .slide-item .caption p,
	.meta-post {
		font-family: "DB-Helvethaica-X";
		color: #000;
		font-size: 20px;
		line-height: 1.2 !important;
	}
	.meta-post {
		margin-bottom: 10px;
	}
	.meta-post:after {
		display: none;
	}
	.promo_bt .owl-item .post-news {
		margin-bottom: 30px;
	}
	.promo_bt .thumbnails-x:hover .zoom {
		opacity: 0;
	}
	.promo_bt .owl-item:nth-child(1n + 0):hover .post-news {
		border-bottom: 15px solid #ef519e;
		margin-bottom: 15px;
	}
	.promo_bt .owl-item:nth-child(1n + 1):hover .post-news {
		border-bottom: 15px solid #bdd73c;
		margin-bottom: 15px;
	}
	.promo_bt .owl-item:nth-child(2n + 1):hover .post-news {
		border-bottom: 15px solid #25aae1;
		margin-bottom: 15x;
	}
	.promo_bt .owl-item:nth-child(3n + 1):hover .post-news {
		border-bottom: 15px solid #d91b5c;
		margin-bottom: 15px;
	}
	.promo_bt .owl-item h3 a {
		font-weight: bold;
		font-size: 22px;
		font-family: "DB-Helvethaica-X";
	}
	.promo_bt .owl-item:nth-child(1n + 0):hover h3 a {
		color: #ef519e;
	}
	.promo_bt .owl-item:nth-child(1n + 1):hover h3 a {
		color: #bdd73c;
	}
	.promo_bt .owl-item:nth-child(2n + 1):hover h3 a {
		color: #25aae1;
	}
	.promo_bt .owl-item:nth-child(3n + 1):hover h3 a {
		color: #d91b5c;
	}
	/********************************* END PROMOTION DETAIL CONTRAL ****************************************/

	.ai1ec-pull-left {
		float: left !important;
		display: none;
	}
	.ai1ec-btn-toolbar .ai1ec-btn-group {
		float: right;
	}
	.timely {
		font-size: 22px;
		font-family: "DB-Helvethaica-X";
		font-weight: normal !important;
	}
	.timely a {
		color: #000;
		text-decoration: none;
		font-size: 20px;
	}
	.ai1ec-month-view .ai1ec-event {
		height: 20px;
		color: #000;
		text-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
		font-size: 18px;
	}
	.ai1ec-month-view th,
	.ai1ec-week-view th,
	.ai1ec-oneday-view th {
		padding: 0.2em !important;
		font-weight: 500;
		border-bottom: 3px solid #fff200 !important;
	}
	table.cal_blue.ecwd_calendar_container .normal-day-heading,
	table.cal_blue.ecwd_calendar_container .weekend-heading {
		background: transparent !important;
	}
	.ecwd_calendar .ecwd_calendar_prev_next {
		border: 0px solid #e5e5e5;
	}
	#ecwd-events-content {
		max-width: 100%;
		margin: 0 auto 50px;
	}
	.ecwd-event {
		background-color: transparent;
		padding-top: 210px;
	}
	.event-detalis,
	.ecwd-event-video,
	a#ecwd_back_link,
	header.entry-header {
		display: none;
	}
	.ecwd-event .entry-content p {
		font-family: "DB-Helvethaica-X";
		color: #666666 !important;
		color: #000 !important;
		font-size: 20px;
		line-height: 1.1 !important;
		margin-bottom: 10px;
	}
	.ecwd-event .entry-content h2 {
		color: #25aae1;
		line-height: 1;
	}
	.ecwd-event .entry-content img {
		margin-top: 13px;
	}
	.ecwd-event .entry-content p strong {
		font-size: 18px;
		color: #000;
	}
	.red {
		color: #d91b5c;
	}
	.event-bt .layout-horizontal .posts-slider .slide-item .post-news {
		float: left;
		width: 50% !important;
	}
	.event-bt .layout-horizontal .posts-slider .slide-item .caption {
		float: left;
		width: 50%;
		font-family: "DB-Helvethaica-X";
		font-size: 18px;
		line-height: 1.2;
		color: #000;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.event-bt .caption {
		display: block;
	}
	.event-bt .meta-post a {
		display: none;
	}
	.ecwd-organizer-events,
	.ecwd-venue-events,
	.ecwd-upcoming-events,
	.event_cageory_and_tags {
		display: none;
	}
	/********************************* Search ****************************************/
	.blog-masonry.row.isotope {
		margin-top: 240px !important;
		min-height: 100px;
	}
	.blog-masonry.row.isotope h1 {
		text-align: center;
	}
	.et-search-result ul {
		margin-bottom: 20px;
		padding-left: 0px !important;
	}
	.et-search-result ul li {
		list-style: none !important;
	}
	h6.active,
	.wp-picture .btn_group a:nth-child(1) {
		display: none !important;
	}
	.content-grid span.location {
		background-size: 0px;
	}
	/*.content-article.entry-content {
font-family: 'DB-Helvethaica-X';
font-size: 18px!important;
line-height: 1.2;
}*/
	.isotope-item {
		margin-bottom: 20px;
	}
	.blog-masonry .isotope-item:nth-child(4n + 1) .btn_group a {
		background: #d91a5d !important;
	}
	.blog-masonry .isotope-item:nth-child(3n + 1) .btn_group a {
		background: #25aae3 !important;
	}
	.blog-masonry .isotope-item:nth-child(2n + 1) .btn_group a {
		background: #bed73d !important;
	}
	.blog-masonry .isotope-item:nth-child(1n + 1) .btn_group a {
		background: #ef519e !important;
	}
	.blog-post .vc_row:nth-child(3) .wpb_content_element > .wpb_wrapper > h2 {
		float: right !important;
		width: 31%;
		position: absolute;
		z-index: 1;
		right: 0;
		font-size: 28px;
		top: 35px;
	}
	.blog-post .vc_row:nth-child(3).container30 .wpb_content_element > .wpb_wrapper > h2 > img {
		width: 55px;
		margin-top: 15px;
	}
	.et-search-result .title {
		display: none;
	}
	.et-search-result ul {
		padding-right: 30px;
		padding-left: 0px;
	}
	.blog-masonry .post .wp-picture {
		height: 200px;
		overflow: hidden;
		margin-bottom: 20px;
	}
	.et-search-result ul li {
		padding-left: 10px;
	}
	/********************************* END Search ****************************************/

	/********************************** E-Magazine ******************************************/
	.emag .vc_grid-item-mini.vc_clearfix {
		opacity: 0.5;
	}
	.emag .vc_grid-item-mini.vc_clearfix:hover {
		transform: scale(1.2);
		z-index: 9999;
		opacity: 1;
		transition: all 0.5s ease !important;
	}
	.emag .vc_grid-item {
		background-color: #000;
	}
	.emag .vc_arrow-icon-navicon:before {
		content: "\f0d8";
		font-family: FontAwesome;
	}
	.emag .vc_arrow-icon-navicon:after {
		content: "\f0d7";
		font-family: FontAwesome;
		position: absolute;
		bottom: 4px;
		left: 35%;
	}
	.emag select {
		width: 200px !important;
		z-index: 3 !important;
	}
	.emag .vc_grid-filter-dropdown {
		position: relative;
		/*top: -85px;*/
		right: 200px;
	}
	.emag .vc_grid-styled-select {
		border: 1px solid #d7d7d7 !important;
		border-radius: 3px;
		padding-right: 0px !important;
	}
	.emag i.vc_arrow-icon-navicon {
		background-color: #bdd73c;
		width: 30px !important;
		height: 32px !important;
		position: relative;
		top: -1px;
		display: inline-block !important;
		text-align: center;
	}
	.emag .vc_grid.vc_row {
		margin-top: 70px;
	}
	.years {
		float: right;
		margin-bottom: -30px !important;
		z-index: 2;
	}
	.years select {
		z-index: 2;
		border: 1px solid #d7d7d7 !important;
		border-radius: 3px;
		padding-right: 0px !important;
		width: 180px;
		height: 32px;
		padding: 0px 10px;
		background-image: none;
	}
	/*.years i.vc_arrow-icon-navicon {
background-color: #bdd73c;
width: 30px!important;
height: 32px!important;
position: absolute;
top: 0px;
display: inline-block!important;
text-align: center;
z-index: 3;
right: 0;
color: #fff;
}*/
	.years::before {
		background-color: #ef519e;
		width: 32px !important;
		height: 32px !important;
		content: "\f0d8";
		font-family: FontAwesome;
		position: absolute;
		right: 15px;
		z-index: 3;
		font-size: 16px;
		text-align: center;
		color: #fff;
		border-radius: 3px;
	}
	.years::after {
		content: "\f0d7";
		font-family: FontAwesome;
		position: absolute;
		top: 10px;
		right: 15px;
		width: 32px !important;
		height: 32px !important;
		font-size: 16px;
		text-align: center;
		color: #fff;
		z-index: 4;
	}

	/********************************** END E-Magazine **************************************/
	.subscribe input.wpcf7-form-control.wpcf7-text.wpcf7-email.wpcf7-validates-as-required.wpcf7-validates-as-email {
		width: 55% !important;
		margin-top: 0px;
		padding: 5px 10px;
		float: left;
		height: 35px;
		border-radius: 3px 0 0 3px !important;
		border-right: transparent 1px solid;
		height: 33px;
		font-family: "roboto";
	}
	.subscribe input.wpcf7-form-control.wpcf7-submit {
		width: 45%;
		background-color: #f3f4f4 !important;
		color: #000;
		border-radius: 3px;
		border: 1px solid #484848;
		border-left: 1px solid transparent;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		padding: 9px 10px 9px 10px;
		font-size: 12px;
		background-image: url("https://terminal21.co.th/korat/wp-content/uploads/2016/11/icon-plane.png");
		background-repeat: no-repeat;
		background-position: 93% 50%;
		text-align: left !important;
		text-transform: none;
		height: 33px !important;
		background-size: 32%;
		font-family: "roboto";
		margin-top: 0px;
	}
	.subscribe::after {
		width: 1px;
		height: 24px;
		content: "";
		position: absolute;
		top: 4px;
		left: 0;
		z-index: 200;
		background: #484848;
		-ms-transform: translate(138px, 0%);
		-webkit-transform: translate(138px, 0%);
		transform: translate(138px, 0%);
	}
	.subscribe {
		margin: 0;
		padding: 0;
		float: left;
		width: 250px;
		margin: -27px 5px 0px 0px;
	}
	.subscribe div.wpcf7 label {
		font-weight: 400;
		font-size: 12px;
		display: inherit;
		text-align: right !important;
		margin-right: 10px;
		font-family: "roboto";
		text-transform: none;
	}
	.subscribe div.wpcf7 br {
		display: none;
	}
	.subscribe span.wpcf7-not-valid-tip {
		color: #f00;
		font-size: 1em;
		display: inline;
		position: absolute;
		top: 20px;
		width: 200px;
	}
	.subscribe p {
		margin-bottom: 0;
	}
	.subscribe div.wpcf7-validation-errors,
	.subscribe div.wpcf7-mail-sent-ok {
		margin: 0px;
		background: #fff;
	}
	.subscribe div.wpcf7 img.ajax-loader {
		position: absolute;
		left: -30px;
		top: 10px;
	}
	div#cboxLoadedContent {
		text-align: center;
		color: #000;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 14px;
		padding-top: 15px;
	}
	div#cboxMiddleLeft,
	div#cboxMiddleRight,
	div#cboxTopCenter,
	div#cboxTopLeft,
	div#cboxTopRight,
	div#cboxBottomCenter,
	div#cboxBottomLeft,
	div#cboxBottomRight {
		display: none;
	}
	#cboxContent {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		border-radius: 6px;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/top-line-rainbow.png) repeat-x top left;
		position: relative;
		min-height: 100%;
		background-color: #fff;
		margin-bottom: 0px !important;
	}
	#cboxOverlay {
		opacity: 0.5 !important;
		background-color: #000;
	}
	#cboxClose {
		top: 5px;
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/12/controls_1.png) no-repeat -25px 0;
	}
	#cboxClose::after {
		position: absolute;
		width: 25px;
		height: 25px;
		content: "x";
		font-size: 14px;
	}
	.share_block.wpb_column.vc_column_container.vc_col-sm-3 {
		width: 20%;
		position: absolute;
		right: 40px;
	}
	.floor_name p br {
		display: none;
	}
	.font-thai a {
		word-wrap: break-word;
		word-break: break-all;
	}
	.floor_blue a {
		color: #25aae1 !important;
		font-weight: bold;
		font-family: "DB-Helvethaica-X";
		font-size: 18px !important;
	}
	.film_buy {
		position: absolute;
		right: 15px;
		top: 6px;
	}
	.film_buy a {
		background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/ticket_icon.png);
		display: block;
		width: 110px;
		height: 55px;
		text-align: center;
		color: #fff;
		font-size: 20px;
		background-size: 110px;
		background-repeat: no-repeat;
		line-height: 2.5;
		font-family: "DB-Helvethaica-X";
		font-weight: bold;
	}
	.wpb_text_column.wpb_content_element.back {
		margin-left: 35px !important;
	}

	/*******************************************************************************************************************/
	/************************************************ ALL Contest ***************************************************/
	/*******************************************************************************************************************/

	.contest-detail p,
	.contest-detail lable,
	.contest-detail li,
	.form-contest label,
	.form-contest p,
	.form-contest td,
	.form-contest th {
		font-family: "DB-Helvethaica-X";
		font-size: 20px !important;
		text-transform: uppercase;
		color: #000;
		line-height: 1.3;
	}
	.form-contest span {
		font-family: "DB-Helvethaica-X";
		font-size: 20px !important;
		line-height: 1.3;
	}
	.form-contest textarea.wpcf7-form-control.wpcf7-textarea {
		/*width: 475px;*/
		border: 1px solid #445a65;
		height: 200px;
		line-height: 1.1;
		width: 100%;
	}
	.form-contest input.wpcf7-form-control.wpcf7-text,
	.form-contest input[type="number"],
	select {
		border: 1px solid #445a65;
		width: 100%;
		font-size: 20px;
		color: #000;
		font-family: "DB-Helvethaica-X";
	}
	.form-contest input.wpcf7-form-control.wpcf7-submit {
		text-align: right !important;
		float: right;
		border: 1px solid #50626e;
		background-color: #50626e;
		border-radius: 3px;
		font-family: "DB-Helvethaica-X";
		font-size: 20px;
		margin-top: 30px;
	}
	.form-contest input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required:focus,
	.form-contest textarea.wpcf7-form-control.wpcf7-textarea.wpcf7-validates-as-required:focus,
	.form-contest input[type="number"]:focus {
		border: 1px solid #d2d6d9;
		background-color: #d2d6d9;
	}
	.vc_row.wpb_row.vc_inner.vc_row-fluid.top10 {
		margin-top: 15px;
	}
	.address {
		padding: 0px;
	}
	.contest-detail .vc_btn3.vc_btn3-color-grey.vc_btn3-style-modern {
		color: #fff;
		border: 1px solid #50626e;
		background-color: #50626e;
		border-radius: 3px;
		font-family: "DB-Helvethaica-X";
		font-size: 20px;
		padding: 5px 50px;
		background-image: none;
	}
	.form-contest table {
		/*margin-top: 30px;*/
	}
	/*******************************************************************************************************************/
	/************************************************ ALL RESPONSIVE ***************************************************/
	/*******************************************************************************************************************/
	@media screen and (min-width: 1200px) {
		.container {
			width: 1200px !important;
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	/*******************************************************************************************************************/
	/************************************************* 1280px-998px ****************************************************/
	/*******************************************************************************************************************/
	@media screen and (max-width: 1200px) {
		.header-type-8 .menu-wrapper .menu-main-container > ul > li:first-child {
			width: 90px !important;
		}
		.header-type-8 .menu-wrapper .menu-main-container > ul > li > a {
			font-size: 11px;
			/*padding-top: 5px;*/
			line-height: 4.5;
		}
		.header-type-8 .menu-wrapper .menu-main-container > ul > li {
			width: 110px;
		}
		a.link_top {
			width: 110px;
			padding-top: 18px;
			font-size: 8px;
		}
		.lang {
			margin-top: 5px;
		}
		.lang > a {
			width: 30px;
			height: 30px;
		}
		.widget_polylang a {
			width: 30px;
			height: 30px;
		}
		.widget_polylang {
			margin-right: 7px;
			margin-top: 5px;
		}
		.floor_name p br {
			display: block;
		}
		.ecwd-event {
			padding-top: 205px;
		}
	}
	@media (max-width: 1024px) and (min-width: 993px) {
		.width60 {
			width: 75%;
		}
		.left15 {
			left: -15px !important;
			margin-top: 10px;
		}
		.copy p {
			text-align: left !important;
			padding: 0px 15px;
		}
		.content-article .wpb_widgetised_column.wpb_content_element {
			margin-bottom: 0px !important;
		}
		table.cal_blue.ecwd_calendar_container .day-with-date,
		table.cal_blue.ecwd_calendar_container .open-details,
		table.cal_blue.ecwd_calendar_container .day-without-date {
			height: 116px !important;
		}
		.event-status {
			width: 35% !important;
		}
		.search_shop {
			width: 160px !important;
			right: 0px !important;
		}
		.vc_column-inner.vc_custom_1478841707488 {
			padding-right: 0px !important;
			padding-left: 0px !important;
		}
		.vc_gitem-acf.field_58199ddc1f932 {
			/*font-size: 16px!important;*/
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
		.container.content-page {
			padding-top: 190px;
		}
		.width50 .width14 .banner.align-center {
			height: 50px;
			width: 50px;
		}
		.width50 .width14 .banner img {
			width: 40px;
		}
		.width50 .floor5:hover .banner-content,
		.width50 .floor4:hover .banner-content,
		.width50 .floor3:hover .banner-content,
		.width50 .floor2:hover .banner-content,
		.width50 .floor1:hover .banner-content,
		.width50 .floorg:hover .banner-content,
		.width50 .floorl:hover .banner-content {
			background-size: 40px !important;
		}
		.width50 .floor5 .banner-content,
		.width50 .floor4 .banner-content,
		.width50 .floor3 .banner-content,
		.width50 .floor2 .banner-content,
		.width50 .floor1 .banner-content,
		.width50 .floorg .banner-content,
		.width50 .floorl .banner-content {
			background-size: 40px !important;
		}
		.width50 {
			width: 64.5% !important;
		}
		.shop_all .vc_grid-item.vc_col-sm-2 {
			width: 20% !important;
			float: left !important;
		}
		.emagazine img {
			width: 55%;
		}
	}
	/*******************************************************************************************************************/
	/*************************************************** TAPLET ********************************************************/
	/*******************************************************************************************************************/
	@media screen and (max-width: 998px) {
		.normalscreen {
			display: none !important;
		}
		.container {
			width: 100%;
		}
		.container.content-page {
			padding-top: 100px;
		}
		.header-wrapper {
			height: 100px;
			width: 100%;
			box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
			-webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
			-moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
		}
		.header-type-8 .header-logo,
		.header-type-8 .navbar-header {
			/*left: 50%!important;*/
			left: 0% !important;
			width: 100% !important;
			-webkit-transform: translateX(0%);
			transform: translateX(0%);
		}
		.header-type-8 .menu-wrapper .languages-area {
			float: right;
			padding: 20px 0 0;
			display: block !important;
		}
		.navbar-collapse.collapse,
		.header-type-8 .menu-wrapper .languages-area,
		.header-type-10 .menu-wrapper .languages-area,
		.header-type-8 .navbar-header .top-links,
		.header-type-10 .navbar-header .top-links,
		.tbs.blog-description {
			display: block !important;
		}
		.header-type-8 .menu-wrapper .navbar-collapse {
			display: none !important;
		}
		.header-type-8 .menu-wrapper .languages-area {
			background: transparent;
			display: inline-block;
			width: auto;
		}
		.navbar {
			padding-top: 20px !important;
		}
		.st-menu {
			background: #fff;
		}
		.mobile-nav-heading.close-mobile-nav {
			background-color: #fff;
			color: #000;
			margin-bottom: 0px;
		}
		.mobile-nav {
			padding: 0px;
		}
		.mobile-nav ul.et-mobile-menu li a {
			color: #000;
			font-size: 14px;
			font-family: "Roboto";
			padding-left: 25px !important;
		}
		.mobile-nav ul.et-mobile-menu > li:first-child {
			background-color: #fff200;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(2) {
			background: #bdd73c;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(2):hover {
			background: #d4eb5e;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(3) {
			background: #25aae1;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(3):hover {
			background: #4abeef;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(4) {
			background: #d91b5c;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(4):hover {
			background: #e8407b;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(5) {
			background: #ef519e;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(5):hover {
			background: #f675b4;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(6) {
			background: #fff200;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(6):hover {
			background: #faf363;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(7) {
			background: #bdd73c;
		}
		.mobile-nav ul.et-mobile-menu > li:nth-child(7):hover {
			background: #d4eb5e;
		}
		div#text-4 {
			position: relative;
			top: -70px;
			right: 50px;
		}
		div#text-3 {
			display: none;
		}
		div#text-6 {
			top: 10px;
			padding-left: 25px;
		}
		.lang > a {
			display: inline-block;
		}
		a.link_top {
			top: 30px;
			position: relative;
			font-size: 14px;
		}
		a.link_top img {
			margin-left: 5px;
		}
		/*.mc4wp-form {
float: left;
width: 250px;
margin: -26px 5px 0px 0px;
-webkit-transform: translate(20%,0%);
transform: translate(20%,0%);
}*/
		.width20 a {
			font-size: 11px;
		}
		.floor p {
			color: #959595 !important;
			font-size: 12px;
		}
		.width14 .banner.align-center {
			height: 70px;
			width: 70px;
			margin-bottom: 0px !important;
		}
		.width14 .banner img {
			width: 50px;
		}
		.floor5:hover .banner-content,
		.floor4:hover .banner-content,
		.floor3:hover .banner-content,
		.floor2:hover .banner-content,
		.floor1:hover .banner-content,
		.floorg:hover .banner-content,
		.floorl:hover .banner-content {
			background-size: 50px !important;
		}
		.floor {
			width: 22px;
		}
		.floor p {
			font-size: 10px;
		}
		.vc_row.wpb_row.vc_row-fluid.container30.floor5_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floor4_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floor3_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floor2_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floor1_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floorgf_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floorlg_detail {
			padding-right: 30px;
			padding-left: 30px;
		}
		.shop_all {
			padding-left: 0px;
			padding-right: 0px;
		}
		div#polylang-2 {
			display: none;
		}
		.widget_polylang a {
			width: 30px;
			height: 30px;
			display: inline-block !important;
			padding: 0px !important;
			padding-left: 0px !important;
			-ms-transform: translate(0, 0%);
			-webkit-transform: translate(0, 0%);
			transform: translate(0px, 0%);
		}
		.widget_polylang li {
			display: inline-block;
			width: auto;
		}
		.widget_polylang .lang-item-en a {
			margin-left: 10px !important;
		}
		.share_block {
			position: absolute !important;
			width: 35% !important;
			float: right;
			right: 0px;
			/*top: 38px;*/
			-ms-transform: translate(0, 0px);
			-webkit-transform: translate(0, 0px);
			transform: translate(0px, 0px);
		}
		.widget_polylang {
			margin-top: -5px;
			position: absolute;
			top: 70px;
			right: 0;
		}
		div#text-6 {
			position: absolute;
			top: 50px;
			padding-left: 25px;
		}
		.menu-main_menu-container,
		.menu-main_menu_en-container {
			margin-top: 40px;
		}
		.ecwd-event {
			padding-top: 100px !important;
			padding-left: 25px;
			padding-right: 25px;
		}
		.shop_all .vc_grid-item.vc_col-sm-2 {
			width: 20% !important;
			float: left !important;
		}
		.shop_promotion > div > div > div::before {
			width: 30%;
		}
		.shop_promotion > div > div > div::after {
			width: 28%;
		}
	}
	@media (min-width: 992px) {
		.ipadscreen {
			display: none !important;
		}
		.floor_5 .vc_tta-panel:nth-child(2) .owl-carousel .owl-stage-outer {
			height: 420px !important;
		}
		.floor_5 .vc_tta-panel:nth-child(2) .vc_gitem-zone {
			height: 420px !important;
		}
	}

	/*******************************************************************************************************************/
	/*************************************************** IPAD ONLY *****************************************************/
	/*******************************************************************************************************************/
	@media (min-width: 768px) {
		.vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-panels {
			max-height: 420px;
			overflow: hidden;
		}
	}
	@media (max-width: 992px) and (min-width: 768px) {
		.width14 {
			min-height: 153px;
		}
		.blog-post .vc_row:nth-child(3) .wpb_content_element > .wpb_wrapper > h2 {
			font-size: 20px;
		}
		.blog-post .content-article p {
			font-size: 18px;
		}
		.blog-post .vc_row:nth-child(3).container30 .wpb_content_element > .wpb_wrapper > h2 > img {
			width: 45px;
		}
		h4.blue {
			margin-top: 40px;
			margin-left: 30px;
			font-size: 20px;
		}
		.blog-post .vc_row:nth-child(3) .wpb_content_element > .wpb_wrapper > h2 {
			width: 35%;
		}
		.blog-post {
			padding-top: 110px;
			margin-bottom: 0px;
		}
		.vc_tta-style-classic .vc_tta-panel {
			border-top: 0px solid #000 !important;
		}
		.floor_5 .vc_tta-tab {
			min-height: 150px !important;
		}
		.floor_5 .vc_tta-tab a {
			background-size: 80px !important;
			background-position: 50% 15% !important;
			height: 150px !important;
		}
		.floor_5 li.vc_tta-tab:nth-child(2) a {
			background-position: 50% 35% !important;
			background-size: 70px !important;
		}
		.floor_5 .vc_tta-tab a span {
			padding: 110px 0px 0px 0px !important;
			font-size: 12px;
			margin: 0 10px !important;
			text-align: center;
			line-height: 1.5;
		}
		.floor_5 li.vc_tta-tab:nth-child(2) a span {
			padding: 90px 0px 0px 0px !important;
		}
		.floor5_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floor4_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floor3_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floor2_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floor1_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floorgf_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floorlg_detail .wpb_column.vc_column_container.vc_col-sm-3 {
			min-height: 300px;
			width: 35%;
		}
		.floor5_detail .vc_col-sm-9,
		.floor4_detail .vc_col-sm-9,
		.floor3_detail .vc_col-sm-9,
		.floor2_detail .vc_col-sm-9,
		.floor1_detail .vc_col-sm-9,
		.floorgf_detail .vc_col-sm-9,
		.floorlg_detail .vc_col-sm-9 {
			width: 65%;
		}
		.width50 .vc_column_container > .vc_column-inner {
			padding: 0px;
		}
		.width50 .width14 .banner.align-center {
			height: 40px;
			width: 40px;
		}
		.width50 .width14 .banner img {
			width: 30px;
		}
		.width50 .floor p {
			font-size: 10px !important;
		}
		.width50 .floor {
			width: 15px;
			height: 15px;
		}
		.width50 .width14:hover .banner-content {
			background-size: 30px !important;
		}
		.floor5 .banner-content,
		.floor4 .banner-content,
		.floor3 .banner-content,
		.floor2 .banner-content,
		.floor1 .banner-content,
		.floorg .banner-content,
		.floorl .banner-content {
			background-size: 30px !important;
		}
		.promo select {
			width: 150px !important;
		}
		.search_shop {
			width: 150px;
		}
		.promo .vc_grid-filter-dropdown {
			right: 150px !important;
		}
		.shop_all .vc_gitem-post-data-source-post_title h4 {
			font-size: 20px;
		}
		.shop_all .vc_gitem-post-data-source-post_title {
			margin-bottom: -15px;
		}
		.view-all {
			top: 40px !important;
			right: 30px !important;
		}
		.mapplic-element {
			height: 300px !important;
		}
		.mapplic-layer {
			top: 0px;
		}
		.mapplic-tooltip {
			-ms-transform: translate(0px, 0px);
			-webkit-transform: translate(0px, 0px);
			transform: translate(0px, 0px);
		}
		.shop_all .vc_grid-filter-dropdown {
			right: 22%;
		}
		.vc_tta-panel-body {
			margin: 0px 0px !important;
		}
		#st-trigger-effects {
			top: 61.5% !important;
		}
		.mobile-nav-heading.close-mobile-nav {
			padding-top: 42px;
		}
		.main-footer .social-icon img {
			width: 45%;
		}
		.width60 {
			width: 80%;
		}
		.left15 {
			margin-top: 15px;
		}
		.main-footer hr.divider {
			margin-left: 0%;
		}
		.copy.normalscreen1 p {
			text-align: left !important;
			margin-left: 10px;
		}
		.width50 {
			margin-top: -96px;
		}
		.emagazine img {
			width: 121px !important;
		}
		.shopping {
			left: 34% !important;
		}
	}

	/*********************************************************************************************************************/
	/*************************************************** MOBILE LANDSCAPE ************************************************/
	/*********************************************************************************************************************/
	@media (max-width: 767px) and (min-width: 480px) {
		.width14 .banner.align-center {
			/*left: 30%;*/
			margin-left: 0px;
		}
		.bottom_bn iframe {
			margin-left: 50%;
			-ms-transform: translate(-50%, 0%);
			-webkit-transform: translate(-50%, 0%);
			transform: translate(-50%, 0%);
		}
		.container.content-page {
			padding-top: 90px;
		}
		.width33 .vc_col-sm-4 {
			width: 33.33% !important;
			float: left;
		}
		.width60 {
			width: 60%;
			margin-left: 50%;
			-ms-transform: translate(-50%, 0%);
			-webkit-transform: translate(-50%, 0%);
			transform: translate(-50%, 0%);
		}
		.blog-post {
			padding-top: 90px;
		}
		.share_block {
			width: 45% !important;
		}
		.font-thai.nonebr {
			float: left;
			width: 100%;
			text-align: center;
		}
		.font-thai.nonebr br {
			display: none;
		}
		.subscribe {
			width: 200px;
		}
		.subscribe::after {
			-ms-transform: translate(110px, 0%);
			-webkit-transform: translate(110px, 0%);
			transform: translate(110px, 0%);
		}
	}
	@media screen and (max-width: 767px) {
		.normalscreen {
			display: none !important;
		}
		.ipadscreen {
			display: none !important;
		}
		.normalscreen1 {
			display: none !important;
		}
		.content h2 {
			font-size: 32px !important;
		}
		.main-footer .social-icon {
			float: none;
			padding-bottom: 20px;
			text-align: center;
		}
		.left15 .wpb_single_image.vc_align_left {
			text-align: center;
		}
		.left15 {
			left: 0px !important;
		}
		.vc_custom_1474619505316 {
			padding-right: 15px !important;
		}

		.width14 {
			width: 25% !important;
			float: left;
		}
		.bottom_bn .banner.align-left {
			text-align: center;
		}
		.container30 {
			padding-left: 0px;
			padding-right: 0px;
		}
		div#text-3 {
			/* display: none; */
			opacity: 0;
		}
		.header-wrapper {
			width: 100%;
		}
		.vc_row.wpb_row.vc_row-fluid.container30.floor5_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floor4_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floor3_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floor2_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floor1_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floorgf_detail,
		.vc_row.wpb_row.vc_row-fluid.container30.floorlg_detail {
			padding-right: 15px;
			padding-left: 15px;
			position: relative;
			/*top: 400px;*/
			/*min-height: 800px;*/
			/*float: right;*/
		}
		.floor5_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floor4_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floor3_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floor2_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floor1_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floorgf_detail .wpb_column.vc_column_container.vc_col-sm-3,
		.floorlg_detail .wpb_column.vc_column_container.vc_col-sm-3 {
			background-color: #f9f9f9 !important;
			/*min-height: 300px;*/
			width: 100%;
			top: -80px;
			position: absolute;
			float: left;
			/* min-height: 200px; */
			/*left: 0px!important;*/
			display: none;
		}
		.floor5_detail .vc_col-sm-9,
		.floor4_detail .vc_col-sm-9,
		.floor3_detail .vc_col-sm-9,
		.floor2_detail .vc_col-sm-9,
		.floor1_detail .vc_col-sm-9,
		.floorgf_detail .vc_col-sm-9,
		.floorlg_detail .vc_col-sm-9 {
			width: 100%;
			position: relative;
			left: 0px;
			/*top: 400px;
height: 800px;*/
		}
		.width50 {
			width: 100% !important;
			float: right;
			margin-top: 0px;
			left: -15px;
			margin-bottom: 45px;
		}
		.width50 .width14 {
			width: 14.28% !important;
			height: 85px;
		}
		.blog-post .vc_row:nth-child(3) .wpb_content_element > .wpb_wrapper > h2 {
			width: 100%;
			position: relative;
			right: 0;
			top: 0px;
		}
		.floor {
			margin-bottom: -12px !important;
		}
		.floor_name p {
			line-height: 1.2;
		}
		.floorlg_detail .vc_tta-style-classic .vc_tta-tabs-container .vc_tta-tab:nth-child(1) a {
			/* padding: 20px 10px 180px 10px !important; */
			padding: 0px !important;
			height: 200px !important;
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/lf_icon_w-1.png);
			background-size: 120px;
			display: inline;
			background-repeat: no-repeat;
			background-position: 50% 35%;
			display: block;
			vertical-align: bottom;
		}
		.floorlg_detail .vc_tta-style-classic .vc_tta-panel-heading .vc_tta-panel:nth-child(1) a {
			/* padding: 20px 10px 180px 10px !important; */
			padding: 0px !important;
			/*height: 200px!important;*/
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/lf_icon_w-1.png);
			background-size: 120px;
			display: inline;
			background-repeat: no-repeat;
			background-position: 50% 35%;
			display: block;
			vertical-align: bottom;
		}
		.floor_5 .vc_tta-panel:nth-child(3) {
			display: none !important;
		}
		.vc_tta-style-classic .vc_tta-panel {
			border-top: 0px solid #000 !important;
		}
		.floor_5 .vc_tta-panel-heading h4 {
			border-bottom: 0px solid #ddd !important;
			margin-bottom: 0px !important;
		}
		.floor_5 .vc_tta-panels .vc_tta-panel .vc_tta-panel-heading a {
			color: #fff !important;
			font-weight: bold;
		}
		.floor_5 .vc_tta-panels .vc_tta-panel:nth-child(2) .vc_tta-panel-heading:after {
			background-color: transparent;
			width: 100% !important;
			height: 55px !important;
			/*top: 53px!important;*/
			z-index: 10;
			position: absolute;
			content: " ";
			-ms-transform: translate(0%, -70px);
			-webkit-transform: translate(0%, -70px);
			transform: translate(0%, -70px);
		}
		.floorlg_detail .vc_tta-panels .vc_tta-panel:nth-child(1) .vc_tta-panel-heading,
		.floor3_detail .vc_tta-panels .vc_tta-panel:nth-child(1) .vc_tta-panel-heading {
			padding: 0px !important;
			background-color: #25aae1 !important;
			border: 0px solid transparent;
			margin-bottom: -15px !important;
		}
		.floorlg_detail .vc_tta-panels .vc_tta-panel:nth-child(2) .vc_tta-panel-heading,
		.floor3_detail .vc_tta-panels .vc_tta-panel:nth-child(2) .vc_tta-panel-heading {
			padding: 0px !important;
			background-color: #bdd73c !important;
			border: 0px solid transparent;
			margin-bottom: -15px !important;
		}
		.floorgf_detail .vc_tta-panels .vc_tta-panel:nth-child(1) .vc_tta-panel-heading,
		.floor4_detail .vc_tta-panels .vc_tta-panel:nth-child(1) .vc_tta-panel-heading {
			padding: 0px !important;
			background-color: #bdd73c !important;
			border: 0px solid transparent;
			margin-bottom: -15px !important;
		}
		.floorgf_detail .vc_tta-panels .vc_tta-panel:nth-child(2) .vc_tta-panel-heading,
		.floor4_detail .vc_tta-panels .vc_tta-panel:nth-child(2) .vc_tta-panel-heading {
			padding: 0px !important;
			background-color: #25aae1 !important;
			border: 0px solid transparent;
			margin-bottom: -15px !important;
		}
		.floor1_detail .vc_tta-panels .vc_tta-panel:nth-child(1) .vc_tta-panel-heading,
		.floor5_detail .vc_tta-panels .vc_tta-panel:nth-child(1) .vc_tta-panel-heading {
			padding: 0px !important;
			background-color: #d91b5c !important;
			border: 0px solid transparent;
			margin-bottom: -15px !important;
		}
		.floor1_detail .vc_tta-panels .vc_tta-panel:nth-child(2) .vc_tta-panel-heading,
		.floor5_detail .vc_tta-panels .vc_tta-panel:nth-child(2) .vc_tta-panel-heading {
			padding: 0px !important;
			background-color: #25aae1 !important;
			border: 0px solid transparent;
			margin-bottom: -15px !important;
		}
		.floor2_detail .vc_tta-panels .vc_tta-panel:nth-child(1) .vc_tta-panel-heading {
			padding: 0px !important;
			background-color: #ef519e !important;
			border: 0px solid transparent;
			margin-bottom: -15px !important;
		}
		.floor2_detail .vc_tta-panels .vc_tta-panel:nth-child(2) .vc_tta-panel-heading {
			padding: 0px !important;
			background-color: #25aae1 !important;
			border: 0px solid transparent;
			margin-bottom: -15px !important;
		}
		.floorlg_detail .vc_col-sm-9.vc_column_container > .vc_column-inner,
		.floorgf_detail .vc_col-sm-9.vc_column_container > .vc_column-inner,
		.floor5_detail .vc_col-sm-9.vc_column_container > .vc_column-inner,
		.floor4_detail .vc_col-sm-9.vc_column_container > .vc_column-inner,
		.floor3_detail .vc_col-sm-9.vc_column_container > .vc_column-inner,
		.floor2_detail .vc_col-sm-9.vc_column_container > .vc_column-inner,
		.floor1_detail .vc_col-sm-9.vc_column_container > .vc_column-inner {
			padding-left: 0px;
			padding-right: 0px;
		}
		.floor_5 .vc_tta-panel-body {
			margin-top: 50px !important;
			padding: 0px 0px !important;
		}
		.floor_5 .vc_tta-panel .wpb_text_column.wpb_content_element > .wpb_wrapper > img {
			max-width: 100%;
			margin-left: 50%;
			margin-top: 0%;
			-ms-transform: translate(-50%, 0%);
			-webkit-transform: translate(-50%, 0%);
			transform: translate(-50%, 0%);
		}
		/*.floor_5 .vc_tta-panel .wpb_text_column.wpb_content_element img {
max-width: 300px;
}*/
		.smalldetail {
			display: block;
			background-color: #f9f9f9 !important;
			/*min-height: 420px;*/
			width: 100%;
			left: 15px;
			position: relative;
			margin-top: -80px;
		}

		.floor5_detail .smalldetail::after {
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/5f_icon_1.png);
		}
		.floor4_detail .smalldetail::after {
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/4f_icon_n.png);
		}
		.floor3_detail .smalldetail::after {
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/3f_icon-1.png);
		}
		.floor2_detail .smalldetail::after {
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/2f_icon-1.png);
		}
		.floor1_detail .smalldetail::after {
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/1f_icon-1.png);
		}
		.floorgf_detail .smalldetail::after {
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/gf_icon-1.png);
		}
		.floorlg_detail .smalldetail::after {
			background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/lf_icon-1.png);
		}
		.smalldetail::after {
			content: "";
			/*background: url(https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/5f_icon-1.png);*/
			background-size: 150px !important;
			background-repeat: no-repeat;
			opacity: 0.1;
			bottom: 0;
			right: 15px;
			position: absolute;
			width: 150px;
			height: 150px;
		}
		.blog-post
			.vc_row:nth-child(3)
			> .vc_col-sm-12
			> .vc_column-inner
			> .wpb_wrapper
			> .wpb_text_column.wpb_content_element {
			margin-bottom: 0px;
		}
		.promo .vc_grid-filter-dropdown {
			top: 0px !important;
			right: 0px;
		}
		.promo select {
			width: 150px !important;
		}
		.search_shop {
			top: 0px !important;
			right: 0px;
			width: 150px;
			position: relative;
		}
		.floor_5 {
			width: 94% !important;
			margin-left: 3%;
		}
		.title-space table td {
			width: auto !important;
		}
		.shop_all .vc_grid-filter-dropdown {
			top: -95px;
			right: 0%;
		}
		.shop_all select {
			width: 150px !important;
			z-index: 3 !important;
			height: 30px;
			padding: 0px 5px;
		}
		.shop_all i.vc_arrow-icon-navicon {
			height: 30px !important;
			top: -5px;
			right: 30px;
			/*z-index: 5;*/
			color: #fff !important;
			font-size: 14px !important;
		}
		.shop_all .vc_grid-styled-select {
			border: 0px solid #d7d7d7 !important;
			border-radius: 3px;
			text-align: left;
		}
		span.location {
			font-size: 14px;
		}
		.shop_all .vc_gitem-animated-block {
			padding: 10px;
		}
		.young_bander::before {
			display: none;
		}
		.shop_all .vc_gitem-post-data-source-post_title h4 {
			font-size: 20px;
			margin-bottom: -15px;
		}
		.ecwd-event table td {
			width: auto !important;
		}
		#st-trigger-effects {
			top: 61.5% !important;
		}
		.smallscreen .floor_5 {
			width: 50% !important;
			float: left;
		}
		h4.blue {
			margin-left: 43px;
		}
		.blog-post .vc_row:nth-child(2).container30 .wpb_content_element > .wpb_wrapper > h2 > img {
			margin-top: -10px;
			margin-right: 0px;
		}
		.ecwd-event .entry-content img {
			margin-top: 0px;
		}
		.mobile-nav-heading.close-mobile-nav {
			padding-top: 42px;
		}
		.title-space table td:nth-child(1) {
			width: 15% !important;
		}
		.share_block {
			position: absolute !important;
			width: 45% !important;
			float: right;
			right: 0px;
			/*top: 38px;*/
			-ms-transform: translate(0, -65px);
			-webkit-transform: translate(0, -65px);
			transform: translate(0px, -65px);
		}
		.title_mag {
			left: -80% !important;
			top: -44px;
		}
		.ares.tp-bullets {
			display: none;
		}
	}
	@media (min-width: 767px) {
		.smallscreen {
			display: none !important;
		}
	}

	/********************************************************************************************************************/
	/******************************************************* MOBILE *****************************************************/
	/********************************************************************************************************************/
	@media screen and (max-width: 550px) {
		.header.header .menu-wrapper {
			display: block;
			background-color: #fff;
			height: 70px;
			box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
			-webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
			-moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
			padding-top: 3px;
		}
		.main-header > .container {
			background-color: #fff;
			padding-bottom: 0;
			z-index: 9998;
		}
		div#text-4 {
			background-color: #fff;
			position: relative;
			top: 0px;
			right: 40%;
			/*-webkit-transform: translateX(50%);
transform: translateX(50%);*/
		}
	}
	@media screen and (max-width: 480px) {
		.container.content-page {
			padding-top: 160px;
		}
		.header-type-8 .header-logo,
		.header-type-8 .navbar-header {
			left: 50% !important;
			width: 100% !important;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
		}
		.header-logo img {
			max-width: 200px !important;
			margin-left: 0px !important;
		}
		.subscribe div.wpcf7 label {
			text-align: left !important;
		}
		.mobile-nav-heading.close-mobile-nav {
			padding-top: 42px;
		}
		.header-wrapper {
			height: 110px;
		}
		.header.header .menu-wrapper {
			display: block;
			background-color: #fff;
			height: 70px;
			box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
			-webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
			-moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
			padding-top: 3px;
		}
		.header.header .menu-wrapper.active {
			display: none;
		}
		.main-header > .container {
			background-color: #fff;
			padding-bottom: 0;
			z-index: 9998;
		}
		.width14 {
			width: 25% !important;
			float: left;
		}
		.shop_all .vc_grid-item.vc_col-sm-2 {
			width: 50% !important;
			float: left !important;
		}
		div#text-4 {
			background-color: #fff;
			position: relative;
			top: 0px;
			right: 53%;
			-webkit-transform: translateX(50%);
			transform: translateX(50%);
		}
		div#popmake-1642,
		div#popmake-1182 {
			height: 450px !important;
			overflow: scroll !important;
		}
		.title-space {
			margin-top: 0px;
		}
		.width50 .width14 {
			width: 25% !important;
			height: 85px;
		}
		.vc_row.wpb_row.vc_row-fluid.bg_footer.vc_custom_1475464395314 {
			margin-right: 0px !important;
			margin-left: 0px !important;
		}
		.mapplic-element {
			height: 300px !important;
		}
		.mapplic-layer {
			top: 0px !important;
		}
		.mapplic-tooltip {
			-ms-transform: translate(0px, 0px);
			-webkit-transform: translate(0px, 0px);
			transform: translate(0px, 0px);
		}
		#flipbook-container-1 .fb-nav ul li,
		#flipbook-container-1 div.spread.mobile.next,
		#flipbook-container-1 div.spread.mobile.preview,
		#flipbook-container-1 div.spread.big-side,
		#flipbook-container-1 div.spread.big-next {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
		.share_block {
			position: absolute !important;
			width: 67% !important;
			float: right;
			right: 0px !important;
			/*top: 38px;*/
			-ms-transform: translate(0, -65px);
			-webkit-transform: translate(0, -65px);
			transform: translate(0px, -65px);
		}
		.width60 {
			width: 100%;
		}
		.blog-post {
			padding-top: 150px;
			margin-bottom: 0px;
		}
		.ecwd-event {
			padding-top: 160px;
			padding-left: 25px;
			padding-right: 25px;
		}
		.ecwd-event {
			padding-top: 160px !important;
			padding-left: 25px;
			padding-right: 25px;
		}
		.title-space table td:nth-child(1) {
			width: auto !important;
		}
		.shop_promotion > div > div > div::before {
			display: none;
		}
		.shop_promotion > div > div > div::after {
			display: none;
		}
		.width33 .wpb_single_image img {
			max-width: 180px;
		}
		.young_bander .wpb_single_image img {
			max-width: 120px;
		}
		.shop_all .vc_grid-filter-dropdown {
			top: -30px;
			border-radius: 3px;
		}
		.shop_all select {
			width: 100% !important;
			border-radius: 3px !important;
			background: transparent;
		}
		.shop_all i.vc_arrow-icon-navicon {
			top: 0px;
			right: 0;
			position: absolute;
			border-radius: 3px;
		}
		.search_shop {
			top: 0px !important;
			right: 0px;
			width: 100%;
			position: relative;
		}
		.shop_all .vc_grid.vc_row {
			margin-top: 0px;
		}
		.smallscreen .floor_5 {
			margin-left: 25px;
		}
		.width50 > .vc_column_container > .vc_column-inner {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
		.smalldetail {
			width: auto;
			left: 0px;
			margin-left: 10px !important;
			margin-right: 10px !important;
		}
		h4.blue {
			margin-left: 48px;
		}
		.container30 {
			padding-left: 15px;
			padding-right: 15px;
		}
		.font-thai.nonebr p {
			padding-left: 15px;
		}
		span.location {
			padding: 3px 5px 3px 20px;
		}
		.promo_grid {
			padding-right: 0px !important;
			padding-bottom: 30px !important;
			padding-left: 0px !important;
		}
		.emag .vc_grid-item {
			width: 50% !important;
			float: left !important;
		}
		.flipbook-container .fb-nav ul {
			padding-left: 0px;
		}
		.years {
			float: right;
			margin-bottom: 20px !important;
			width: 100%;
		}
		.years select {
			width: 100%;
		}
		.box .emag .vc_grid-filter-dropdown {
			position: relative;
			right: 0px;
		}
		.box .emag select {
			width: 100% !important;
			border-radius: 3px !important;
			height: 32px;
			padding: 0px 10px;
		}
		.box .emag i.vc_arrow-icon-navicon {
			top: 0px;
			right: 0;
			position: absolute;
			border-radius: 3px;
			z-index: 5;
			color: #fff !important;
			font-size: 14px !important;
			width: 32px !important;
		}
		.box .emag .vc_grid-styled-select {
			border: 0px solid #d7d7d7 !important;
			border-radius: 3px;
			padding-right: 0px !important;
		}
		.bottom-line {
			width: auto !important;
		}
		.wpb_text_column.wpb_content_element.back {
			margin-left: 0px !important;
		}
		.back a {
			margin-left: 0px !important;
		}
		.st-container .st-pusher {
			overflow-x: hidden !important;
		}
	}
	/*********************************************************************************************************************/
	/*************************************************** MOBILE SMALL SCREEN *********************************************/
	/*********************************************************************************************************************/
	@media screen and (max-width: 340px) {
		.mc4wp-form {
			width: 200px;
		}
		.mc4wp-form-fields::after {
			left: -28px;
		}
		.subscribe {
			width: 200px;
		}
		.subscribe::after {
			left: -28px;
		}
		.shop_all select {
			/*width: 140px!important;*/
			width: 100% !important;
		}
		.search_shop {
			/*width: 140px!important;*/
			width: 100% !important;
		}
		.promo select {
			/*width: 140px!important;*/
			width: 100% !important;
		}
		div.the_champ_horizontal_sharing li.the_champ_facebook_like,
		div.the_champ_horizontal_counter li.the_champ_facebook_like {
			width: 50px !important;
		}
		.subscribe input.wpcf7-form-control.wpcf7-submit {
			padding: 9px 5px 9px 5px;
			background-size: 30%;
			font-family: "roboto";
			margin-top: 0px;
		}
		.left {
			float: left;
			position: relative;
			right: 0px;
		}
		.share_block {
			position: relative !important;
			width: 100% !important;
			float: left;
			right: 0px !important;
			top: 0px;
			-ms-transform: translate(0, 0px);
			-webkit-transform: translate(0, 0px);
			transform: translate(0px, 0px);
			display: block;
		}
		.bottom-line {
			height: auto;
		}
		.blog-post .vc_row:nth-child(3).container30 .wpb_content_element > .wpb_wrapper > h2 > img {
			width: 55px;
			margin-top: 0px;
			float: left;
		}
		h4.blue {
			margin-top: 60px;
		}
	}
	/*********************************************************************************************************************/
	/************************************************ MOBILE OPEN RIGHT CSS **********************************************/
	/*********************************************************************************************************************/
	.st-menu-open .st-container .st-pusher {
		-webkit-transform: translateX(-250px) !important;
		-moz-transform: translateX(-250px) !important;
		-ms-transform: translateX(-250px) !important;
		-o-transform: translateX(-250px) !important;
		transform: translateX(-250px) !important;
	}
	.st-menu {
		right: 0 !important;
	}
	#st-trigger-effects {
		left: initial !important;
		right: 10px;
	}
	#st-container .mobile-menu-block {
		display: none;
	}
	.st-container.mobile-menu-block .mobile-menu-block {
		display: block !important;
	}
	.floor_5 .vc_tta-panel-heading h4 {
		border-bottom: 0px solid #ddd !important;
		margin-bottom: 0px !important;
	}
	:lang(th) .enlang {
		display: none !important;
	}
	:lang(en) .thlang {
		display: none !important;
	}
	#fancybox-content {
		width: 230px !important;
		min-height: 100px;
		margin: 0px;
		top: 5px;
		border-radius: 0 !important;
	}
	#fancybox-overlay {
		z-index: 9999;
	}
	#fancybox-wrap {
		z-index: 10000;
		max-width: 240px;
	}
	#fancybox-outer {
		background: url(/korat/wp-content/uploads/2016/09/top-line-rainbow.png) repeat-x top !important;
		width: 230px;
	}
	div.wpcf7-mail-sent-ok {
		border: 0px solid #398f14;
	}
	div.wpcf7-validation-errors {
		border: 0px solid #f7e700;
		text-align: center;
		vertical-align: middle;
		padding-top: 20px;
	}
	span.default-shopname {
		position: absolute;
		background: #fff;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		vertical-align: middle;
		display: table;
		font-family: "DB-Helvethaica-X";
		font-size: 20px;
		line-height: 1;
	}
	span.default-shopname span {
		display: table-cell;
		vertical-align: middle;
	}
	.mapplic-tooltip-content {
		margin-right: 0px !important;
	}
	.mapplic-tooltip-content {
		overflow-y: hidden;
	}
	li.ecwd-calendar-more-event.lastItem {
		display: none !important;
	}

	/*disable body scroll */
	#temp-scroll {
		position: relative;
		overflow: scroll;
	}
	#temp-scroll .drag-left,
	#temp-scroll .drag-right {
		display: none;
		color: #a5a5a5;
		z-index: 200;
	}
	@media screen and (max-width: 460px) {
		.pum-open.pum-open-overlay.pum-open-scrollable #st-container {
			height: 110px;
			overflow: hidden;
		}

		.pum-close.popmake-close {
			top: -5px !important;
			right: -5px !important;
		}
		#temp-scroll .drag-left,
		#temp-scroll .drag-right {
			display: block;
			position: absolute;
			height: 100%;
			width: 1px;
			left: 0;
			top: 0;
		}
		#temp-scroll .drag-right {
			left: auto;
			right: 0;
		}

		#temp-scroll .drag-left span,
		#temp-scroll .drag-right span {
			position: absolute;
			width: 40px;
			height: 60px;
			background: rgba(225, 225, 226, 0.8);
			top: 50%;
			left: 0px;
			margin-top: -30px;
			text-align: center;
			line-height: 60px;
		}
		#temp-scroll .drag-right span {
			left: auto;
			right: 0px;
		}
	}
	.vc_custom_1475464395314 {
		display: none;
	}
	.vc_custom_1475464395314:nth-child(1) {
		display: block;
	}
	.ecwd_calendar .ecwd_calendar_prev_next {
		border: 0px solid #e5e5e5 !important;
	}
	.spu-box.spu-centered {
		border-radius: 0px;
		background: url(/korat/wp-content/uploads/2016/09/top-line-rainbow.png) repeat-x top !important;
		background-color: #fff !important;
	}
	#spu-bg-6616 {
		opacity: 0.6;
	}
	.spu-close {
		font-size: 18px;
	}
	.ecwd-event li {
		list-style: inherit;
		font-family: "DB-Helvethaica-X";
		font-size: 20px;
		text-transform: uppercase;
		color: #000;
		line-height: 1.2;
		margin-left: 18px;
	}
	.event_grid select {
		font-size: 18px;
	}
}
