$grid-gap: 16px;
$columns: 12;

.masonry {
  display: grid;
  grid-gap: $grid-gap;
  grid-template-columns: repeat(auto-fill, minmax(calc((100% - #{$grid-gap}) / 2), 1fr));
  grid-auto-rows: 0px;

  @include breakpoint ($sm-screen) {
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));

    @for $index from 1 through $columns {
      &.has-#{$index} {
        grid-template-columns: repeat(auto-fill, minmax(calc((100% - (#{$grid-gap} * #{$index})) / #{$index}), 1fr));
      }
    }
  }
}