$header-min-height: 44px;
$header-title-font-weight: 500;
$header-border-bottom: $border-width $border-style;
$header-padding: $padding-layout;

.header {
  display: flex;
  width: 100%;
  min-height: $header-min-height;
  overflow: hidden;
  // border-bottom: $header-border-bottom;
  outline: none;

  &-left-container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    padding-left: $header-padding;
    outline: none;
  }

  &-right-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding-right: $header-padding;
    outline: none;
  }

  &-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1.5;
  }

  &-title {
    font-size: $size-4;
    font-weight: $header-title-font-weight;
  }

  @include breakpoint($lg-screen) {

  }
}