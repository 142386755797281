.loading {
  position: relative;
  height: 100vh;
	height: calc(var(--vh, 1vh) * 100);

  &-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 235px;

    & > img {
      width: 180px;
      height: 180px;
    }
  }

  &-text {
    margin-top: 20px;
  }

  &-body {
    flex: 1;
  }

  // for small mobile
  @include breakpoint ($ip-5) {
    &-banner {
      height: 140px;

      & > img {
        width: 130px;
        height: 130px;
      }
    }
  }

  @include breakpoint ($ip-6-x) {
    &-banner {
      height: 235px;

      & > img {
        width: 180px;
        height: 180px;
      }
    }
  }

  @include breakpoint ($md-screen) {
    &-banner {
      height: 325px;

      & > img {
        width: 225px;
        height: 225px;
      }
    }
  }
}