$level-item-space: $padding-layout;

.level {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left, &-right {
    display: flex;
    align-items: center;
  }

  &-left {
    justify-content: flex-start;
  }

  &-right {
    justify-content: flex-end;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;

    &.has-align-left {
      justify-content: left;
    }

    &.has-align-right {
      justify-content: right;
    }
    
    &:not(:last-child) {
      margin-right: $level-item-space;
    }
  }

  /* tablet and mobile */
  @include breakpoint-desktop-first ($sm-screen) {
    flex-direction: column;
    justify-content: center;

    &-left, &-right {
      flex-direction: column;
      width: 100%;
    }

    &-left+&-right {
      margin-top: $level-item-space;
    }

    &-item {
      width: 100%;
      margin-bottom: $level-item-space;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}