/*=================================*/
/* Responsive Styles / #responsive */
/*=================================*/

#customise {
  @media only screen and (min-width: 992px) {
    .banner iframe {
      max-height: 190px;
    }
    .lookBook-1 .swiper-slide {
      width: 402px !important;
    }
    .header-vertical-enable .esg-container-fullscreen-forcer {
      padding-left: 260px !important;
    }
    .modal-type-1 .mfp-close {
      top: -60px !important;
      right: 13px !important;
      font-size: 0 !important;
    }
    .header-vertical-enable .page-wrapper {
      padding-left: 260px;
    }
    .header-vertical-enable .page-wrapper .top-bar {
      display: none;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical,
    .header-vertical-enable .page-wrapper .header-type-vertical2 {
      position: fixed;
      background-color: #111111;
      left: 0;
      top: 0;
      width: 260px;
      height: 100%;
      z-index: 10000;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container {
      width: 100%;
      padding: 0;
      position: static;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .header-logo,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .header-logo,
    .header-vertical-enable .page-wrapper .header-type-vertical .container .tbs,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .tbs,
    .header-vertical-enable .page-wrapper .header-type-vertical .container .navbar-right,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .navbar-right {
      float: left !important;
      width: 258px;
      max-width: 100%;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .navbar-right
      .et-search-trigger
      .popup-with-form
      > span,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .navbar-right
      .et-search-trigger
      .popup-with-form
      > span {
      display: inline-block;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .navbar,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .navbar {
      position: static;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .navbar-collapse.collapse,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .navbar-collapse.collapse {
      display: block !important;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .header-logo,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .header-logo {
      margin-bottom: 40px;
      margin-top: 15px;
      padding-right: 0;
      text-align: center;
      left: 0 !important;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .header-logo img,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .header-logo img {
      max-width: 220px;
      float: none;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .hidden-tooltip,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .hidden-tooltip {
      display: none;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .navbar-right,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .navbar-right {
      display: block;
      margin-top: -1px;
      border-top: 1px solid #2c2c2c;
      margin-bottom: -1px;
      border-bottom: 1px solid #2c2c2c;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .navbar-right {
      border-bottom: none;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu {
      padding-top: 15px;
      display: inline-block;
      width: 100%;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu li,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu li {
      width: 100%;
      display: inline-block;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu li .open-this,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu li .open-this {
      cursor: pointer;
      float: right;
      height: 26px;
      position: relative;
      top: 12px;
      z-index: 1;
      font-size: 0;
      width: 26px;
      display: block;
      margin: 0;
      margin-right: 10px;
      background-image: url("https://terminal21.co.th/images/small-plus-light.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu li.opened .open-this,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu li.opened .open-this {
      background-image: url("https://terminal21.co.th/images/small-minus-light.png");
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu li.menu-item-has-children > a:after,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu li.menu-item-has-children > a:after {
      content: "\f105";
      padding-left: 5px;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu > li,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu > li {
      border-top: none;
      border-bottom: none;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu > li > a,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu > li > a {
      color: #ccc;
      padding: 15px 20px;
      font-size: 14px;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu > li.lastItem,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu > li.lastItem {
      border-bottom: none;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu .nav-sublist-dropdown,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu .nav-sublist-dropdown {
      position: absolute;
      visibility: hidden;
      float: left;
      width: 100%;
      right: auto;
      left: 100% !important;
      margin-left: 0 !important;
      top: 0;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu .nav-sublist-dropdown ul,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu .nav-sublist-dropdown ul {
      width: 100%;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children
      .nav-sublist
      ul,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children
      .nav-sublist
      ul {
      right: auto;
      top: -15px;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children
      .nav-sublist
      ul
      li
      a,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children
      .nav-sublist
      ul
      li
      a {
      color: #666;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu .nav-sublist-dropdown:before,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu .nav-sublist-dropdown:before,
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu .nav-sublist-dropdown .container:after,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .nav-sublist-dropdown
      .container:after {
      display: none;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .nav-sublist-dropdown
      .container:before,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .nav-sublist-dropdown
      .container:before {
      border-color: rgba(0, 0, 0, 0) #2c2c2c rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      border-style: solid;
      border-width: 12px;
      content: " ";
      left: -25px;
      position: absolute;
      top: 15px;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu > li:hover .nav-sublist-dropdown,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu > li:hover .nav-sublist-dropdown {
      visibility: visible;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu .menu-full-width,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu .menu-full-width {
      position: relative;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu .menu-full-width .nav-sublist-dropdown,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown {
      box-sizing: border-box;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul {
      background-color: transparent !important;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li {
      width: 150px;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li
      > a,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li
      > a {
      color: #ccc;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children {
      padding-bottom: 0;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children.lastItem,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children.lastItem {
      padding-bottom: 0;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children
      .nav-sublist
      ul,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li.menu-item-has-children
      .nav-sublist
      ul {
      right: auto;
      border: none;
      box-shadow: none;
      border: none !important;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical #st-trigger-effects,
    .header-vertical-enable .page-wrapper .header-type-vertical2 #st-trigger-effects {
      display: none !important;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .shopping-container,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .shopping-container,
    .header-vertical-enable .page-wrapper .header-type-vertical .header-search,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .header-search {
      width: 50%;
      margin: 0;
      padding: 10px 0;
    }
    .header-vertical-enable.top-cart-disabled .header-search {
      float: left;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .shopping-container .shop-text,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .shopping-container .shop-text {
      font-size: 0;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .shopping-container .shop-text .total .amount,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .shopping-container .shop-text .total .amount {
      margin-left: 30px;
      color: #ccc;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .shopping-container .shopping-cart-widget,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .shopping-container .shopping-cart-widget {
      width: 100%;
      box-sizing: border-box;
      border: none;
      text-align: center;
      padding-right: 0;
      padding-top: 10px;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .shopping-container .shopping-cart-widget .cart-summ,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .shopping-container .shopping-cart-widget .cart-summ {
      color: #ccc;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .shopping-container
      .shopping-cart-widget
      .cart-summ
      .cart-bag,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .shopping-container
      .shopping-cart-widget
      .cart-summ
      .cart-bag {
      top: -5px !important;
      margin-top: 0;
      left: 18px;
      right: auto !important;
      padding: 12px 0 !important;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .shopping-container
      .shopping-cart-widget
      .cart-summ
      .cart-bag
      .badge-number,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .shopping-container
      .shopping-cart-widget
      .cart-summ
      .cart-bag
      .badge-number {
      display: none;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical
      .shopping-container
      .shopping-cart-widget
      .cart-summ
      .total,
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .shopping-container
      .shopping-cart-widget
      .cart-summ
      .total {
      padding-left: 15px;
      font-size: 14px !important;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .shopping-container:hover .cart-popup-container,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .shopping-container:hover .cart-popup-container {
      display: none;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .header-search a,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .header-search a {
      color: #ccc;
      text-transform: uppercase;
      line-height: 40px;
      padding-left: 14px;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .header-search a .fa-search,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .header-search a .fa-search {
      background: none;
      color: #cda85c;
      font-size: 16px;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .header-custom-block,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .header-custom-block {
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
      padding-right: 15px;
      color: #ccc;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .header-custom-block .menu-social-icons,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .header-custom-block .menu-social-icons {
      margin-bottom: 0;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .header-custom-block .menu-social-icons li,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .header-custom-block .menu-social-icons li {
      margin-right: 10px;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical2 {
      background-color: #fff;
      border-right: 1px solid #f0f0f0;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu > li > a {
      color: #808080;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu .nav-sublist-dropdown {
      background-color: #fff !important;
      border: 3px solid #e6e6e6;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical2 .container .menu .nav-sublist-dropdown ul > li ul {
      border: 3px solid #e6e6e6 !important;
      top: -18px !important;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .nav-sublist-dropdown
      .container:before {
      border-color: rgba(0, 0, 0, 0) #dddddd rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      border-style: solid;
      border-width: 12px;
      content: " ";
      left: -25px;
      position: absolute;
      top: 15px;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown {
      box-shadow: none;
    }
    .header-vertical-enable
      .page-wrapper
      .header-type-vertical2
      .container
      .menu
      .menu-full-width
      .nav-sublist-dropdown
      ul
      > li
      > a {
      color: #000;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical2 .shopping-container,
    .header-vertical-enable .page-wrapper .header-type-vertical2 .header-search {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical2 .shopping-container .shopping-cart-widget .cart-summ {
      color: #808080;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical2 .header-search a {
      color: #808080;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu .nav-sublist-dropdown {
      background-color: #2c2c2c !important;
      border-color: #2c2c2c !important;
    }
    .header-vertical-enable .page-wrapper .header-type-vertical .container .menu .nav-sublist-dropdown ul > li ul {
      background-color: #2c2c2c !important;
    }
    .header-vertical-enable .fullscreen-container {
      width: 100% !important;
      left: 0 !important;
    }
    .header-vertical-enable .fixed-header-area {
      display: none;
    }
    .header-vertical-enable .upb_row_bg {
      margin-left: 260px;
    }
    .menu-column2 .nav-sublist-dropdown {
      width: 422px !important;
    }
    .menu-column2 .nav-sublist-dropdown ul li:nth-child(2n + 1) {
      clear: both !important;
    }
    .menu-column2.with-image .nav-sublist-dropdown {
      width: 422px !important;
    }
    .menu-column3 .nav-sublist-dropdown {
      width: 600px !important;
    }
    .menu-column3 .nav-sublist-dropdown ul li:nth-child(3n + 1) {
      clear: both;
    }
    .menu-column4 .nav-sublist-dropdown {
      width: 778px !important;
    }
    .menu-column4 .nav-sublist-dropdown ul li:nth-child(4n + 1) {
      clear: both;
    }
    .menu-column5 .nav-sublist-dropdown {
      width: 960px !important;
    }
    .menu-column5 .nav-sublist-dropdown ul li:nth-child(5n + 1) {
      clear: both;
    }
    .demo-column4 .nav-sublist-dropdown,
    .demo-column4-2 .nav-sublist-dropdown {
      width: 990px !important;
    }
    .demo-column5 .nav-sublist-dropdown {
      width: 1150px !important;
    }
    .demo-column-without-img .nav-sublist-dropdown {
      width: 760px !important;
    }
    .menu-column2.with-image .nav-sublist-dropdown {
      width: 590px !important;
    }
    .menu-column3.with-image .nav-sublist-dropdown {
      width: 770px !important;
    }
    .menu-column4.with-image .nav-sublist-dropdown {
      width: 960px !important;
    }
    .menu-column5.with-image .nav-sublist-dropdown {
      width: 1130px !important;
    }
    .header-type-15 .main-header > .container,
    .header-type-16 .main-header > .container,
    .fixed-header-type-15 .fixed-header > .container,
    .fixed-header-type-16 .fixed-header > .container,
    .header-type-15 .top-bar > .container,
    .header-type-16 .top-bar > .container,
    .fixed-header-type-15 .top-bar > .container,
    .fixed-header-type-16 .top-bar > .container {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    .header-type-15 .main-header > .container .navbar,
    .header-type-16 .main-header > .container .navbar,
    .fixed-header-type-15 .fixed-header > .container .navbar,
    .fixed-header-type-16 .fixed-header > .container .navbar,
    .header-type-15 .top-bar > .container .navbar,
    .header-type-16 .top-bar > .container .navbar,
    .fixed-header-type-15 .top-bar > .container .navbar,
    .fixed-header-type-16 .top-bar > .container .navbar {
      padding: 20px 0;
    }
    .header-type-15 .main-header > .container .menu,
    .header-type-16 .main-header > .container .menu,
    .fixed-header-type-15 .fixed-header > .container .menu,
    .fixed-header-type-16 .fixed-header > .container .menu,
    .header-type-15 .top-bar > .container .menu,
    .header-type-16 .top-bar > .container .menu,
    .fixed-header-type-15 .top-bar > .container .menu,
    .fixed-header-type-16 .top-bar > .container .menu {
      text-align: center;
    }
    .header-type-15 .main-header > .container .menu > li,
    .header-type-16 .main-header > .container .menu > li,
    .fixed-header-type-15 .fixed-header > .container .menu > li,
    .fixed-header-type-16 .fixed-header > .container .menu > li,
    .header-type-15 .top-bar > .container .menu > li,
    .header-type-16 .top-bar > .container .menu > li,
    .fixed-header-type-15 .top-bar > .container .menu > li,
    .fixed-header-type-16 .top-bar > .container .menu > li {
      float: none;
      display: inline-block;
    }
    .header-type-15 .main-header > .container .menu > li a,
    .header-type-16 .main-header > .container .menu > li a,
    .fixed-header-type-15 .fixed-header > .container .menu > li a,
    .fixed-header-type-16 .fixed-header > .container .menu > li a,
    .header-type-15 .top-bar > .container .menu > li a,
    .header-type-16 .top-bar > .container .menu > li a,
    .fixed-header-type-15 .top-bar > .container .menu > li a,
    .fixed-header-type-16 .top-bar > .container .menu > li a {
      text-align: left;
    }
    .header-type-15 .main-header > .container .header-logo,
    .header-type-16 .main-header > .container .header-logo,
    .fixed-header-type-15 .fixed-header > .container .header-logo,
    .fixed-header-type-16 .fixed-header > .container .header-logo,
    .header-type-15 .top-bar > .container .header-logo,
    .header-type-16 .top-bar > .container .header-logo,
    .fixed-header-type-15 .top-bar > .container .header-logo,
    .fixed-header-type-16 .top-bar > .container .header-logo,
    .header-type-15 .main-header > .container .navbar-header,
    .header-type-16 .main-header > .container .navbar-header,
    .fixed-header-type-15 .fixed-header > .container .navbar-header,
    .fixed-header-type-16 .fixed-header > .container .navbar-header,
    .header-type-15 .top-bar > .container .navbar-header,
    .header-type-16 .top-bar > .container .navbar-header,
    .fixed-header-type-15 .top-bar > .container .navbar-header,
    .fixed-header-type-16 .top-bar > .container .navbar-header {
      width: 20%;
    }
    .header-type-15 .main-header > .container .tbs,
    .header-type-16 .main-header > .container .tbs,
    .fixed-header-type-15 .fixed-header > .container .tbs,
    .fixed-header-type-16 .fixed-header > .container .tbs,
    .header-type-15 .top-bar > .container .tbs,
    .header-type-16 .top-bar > .container .tbs,
    .fixed-header-type-15 .top-bar > .container .tbs,
    .fixed-header-type-16 .top-bar > .container .tbs {
      width: 60%;
    }
    .header-type-16 .top-bar {
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
    }
    .header-type-16 .top-bar a {
      color: #999;
    }
    .header-type-16 .top-bar .languages li.active,
    .header-type-16 .top-bar .currency li.active,
    .header-type-16 .top-bar .top-links li.active {
      color: #000;
    }
    .header-type-16 .top-bar .languages li a:hover,
    .header-type-16 .top-bar .currency li a:hover,
    .header-type-16 .top-bar .top-links li a:hover {
      color: #000;
    }
    .header-type-16 .main-header > .container .shopping-cart-widget,
    .fixed-header-type-16 .main-header > .container .shopping-cart-widget,
    .header-type-16 .fixed-header > .container .shopping-cart-widget,
    .fixed-header-type-16 .fixed-header > .container .shopping-cart-widget {
      border: none;
    }
    .header-type-16 .main-header > .container .shopping-cart-widget .shop-text,
    .fixed-header-type-16 .main-header > .container .shopping-cart-widget .shop-text,
    .header-type-16 .fixed-header > .container .shopping-cart-widget .shop-text,
    .fixed-header-type-16 .fixed-header > .container .shopping-cart-widget .shop-text,
    .header-type-16 .main-header > .container .shopping-cart-widget .total,
    .fixed-header-type-16 .main-header > .container .shopping-cart-widget .total,
    .header-type-16 .fixed-header > .container .shopping-cart-widget .total,
    .fixed-header-type-16 .fixed-header > .container .shopping-cart-widget .total {
      display: none;
    }
    .header-type-16 .main-header > .container .shopping-cart-widget .cart-bag,
    .fixed-header-type-16 .main-header > .container .shopping-cart-widget .cart-bag,
    .header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag,
    .fixed-header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag {
      top: 4px;
      right: 0;
      padding: 0;
    }

    .header-type-16 .main-header > .container .shopping-cart-widget .cart-bag .badge-number,
    .fixed-header-type-16 .main-header > .container .shopping-cart-widget .cart-bag .badge-number,
    .header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag .badge-number,
    .fixed-header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag .badge-number {
      top: -15px;
      right: -12px;
    }
    .header-type-16 .main-header > .container .shopping-cart-widget .cart-bag .ico-sum,
    .fixed-header-type-16 .main-header > .container .shopping-cart-widget .cart-bag .ico-sum,
    .header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag .ico-sum,
    .fixed-header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag .ico-sum {
      height: 19px;
      width: 24px;
      top: 8px;
    }
    .header-type-16 .main-header > .container .shopping-cart-widget .cart-bag .ico-sum:before,
    .fixed-header-type-16 .main-header > .container .shopping-cart-widget .cart-bag .ico-sum:before,
    .header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag .ico-sum:before,
    .fixed-header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag .ico-sum:before {
      width: 13px;
      top: -7px;
      -webkit-transform: translateX(-49%);
      transform: translateX(-49%);
    }
    .header-type-16 .main-header > .container .shopping-cart-widget .cart-bag .ico-sum:after,
    .fixed-header-type-16 .main-header > .container .shopping-cart-widget .cart-bag .ico-sum:after,
    .header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag .ico-sum:after,
    .fixed-header-type-16 .fixed-header > .container .shopping-cart-widget .cart-bag .ico-sum:after {
      bottom: -4px;
    }
  }
  @media only screen and (min-width: 980px) and (max-width: 1400px) {
    .header-vertical-enable .container {
      width: 100%;
    }
    .header-vertical-enable .container .product-information .cart button[type="submit"] {
      width: 65% !important;
    }
    .header-vertical-enable .container #product-pager {
      width: 210px !important;
    }
    .header-vertical-enable .container .product-information .quantity {
      width: 65px;
    }
    .header-vertical-enable .container .product-information .quantity input[type="text"] {
      width: 36px;
    }
    .header-vertical-enable .container .row-count-2.products-grid .product {
      width: 50%;
    }
  }
  @media only screen and (min-width: 980px) and (max-width: 1025px) {
    .wp-picture .zoom a,
    .swiper-slide .zoom a,
    .portfolio-image .zoom a {
      width: 45px;
      height: 45px;
    }
    .wp-picture .zoom a:before,
    .swiper-slide .zoom a:before,
    .portfolio-image .zoom a:before,
    .wp-picture .zoom a:after,
    .swiper-slide .zoom a:after,
    .portfolio-image .zoom a:after {
      display: none;
    }
    .wp-picture .zoom a span,
    .swiper-slide .zoom a span,
    .portfolio-image .zoom a span {
      font-size: 0;
    }
    .wp-picture .zoom a span:after,
    .swiper-slide .zoom a span:after,
    .portfolio-image .zoom a span:after {
      display: none;
    }
    .wp-picture .zoom a:first-child span:before,
    .swiper-slide .zoom a:first-child span:before,
    .portfolio-image .zoom a:first-child span:before {
      content: "\f0b2";
      font-size: 18px;
      font-family: FontAwesome;
      left: 50%;
      top: 50%;
      margin-top: -6px;
      margin-left: -7px;
    }
    .wp-picture .zoom a:last-child span:before,
    .swiper-slide .zoom a:last-child span:before,
    .portfolio-image .zoom a:last-child span:before {
      content: "\f0c1";
      font-size: 18px;
      font-family: FontAwesome;
      left: 50%;
      top: 50%;
      margin-top: -6px;
      margin-left: -7px;
    }
  }
  @media screen and (max-width: 600px) {
    .admin-bar .fixed-header-area.fixed-already {
      top: 0;
    }
  }
  /* Large Resolution Styles */
  @media screen and (min-width: 1200px) {
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
      float: left;
    }
    .col-lg-12 {
      width: 100%;
    }
    .col-lg-11 {
      width: 91.66666667%;
    }
    .col-lg-10 {
      width: 83.33333333%;
    }
    .col-lg-9 {
      width: 75%;
    }
    .col-lg-8 {
      width: 66.66666667%;
    }
    .col-lg-7 {
      width: 58.33333333%;
    }
    .col-lg-6 {
      width: 50%;
    }
    .col-lg-5 {
      width: 41.66666667%;
    }
    .col-lg-4 {
      width: 33.33333333%;
    }
    .col-lg-3 {
      width: 25%;
    }
    .col-lg-2 {
      width: 16.66666667%;
    }
    .col-lg-1 {
      width: 8.33333333%;
    }
    .col-lg-pull-12 {
      right: 100%;
    }
    .col-lg-pull-11 {
      right: 91.66666667%;
    }
    .col-lg-pull-10 {
      right: 83.33333333%;
    }
    .col-lg-pull-9 {
      right: 75%;
    }
    .col-lg-pull-8 {
      right: 66.66666667%;
    }
    .col-lg-pull-7 {
      right: 58.33333333%;
    }
    .col-lg-pull-6 {
      right: 50%;
    }
    .col-lg-pull-5 {
      right: 41.66666667%;
    }
    .col-lg-pull-4 {
      right: 33.33333333%;
    }
    .col-lg-pull-3 {
      right: 25%;
    }
    .col-lg-pull-2 {
      right: 16.66666667%;
    }
    .col-lg-pull-1 {
      right: 8.33333333%;
    }
    .col-lg-pull-0 {
      right: 0;
    }
    .col-lg-push-12 {
      left: 100%;
    }
    .col-lg-push-11 {
      left: 91.66666667%;
    }
    .col-lg-push-10 {
      left: 83.33333333%;
    }
    .col-lg-push-9 {
      left: 75%;
    }
    .col-lg-push-8 {
      left: 66.66666667%;
    }
    .col-lg-push-7 {
      left: 58.33333333%;
    }
    .col-lg-push-6 {
      left: 50%;
    }
    .col-lg-push-5 {
      left: 41.66666667%;
    }
    .col-lg-push-4 {
      left: 33.33333333%;
    }
    .col-lg-push-3 {
      left: 25%;
    }
    .col-lg-push-2 {
      left: 16.66666667%;
    }
    .col-lg-push-1 {
      left: 8.33333333%;
    }
    .col-lg-push-0 {
      left: 0;
    }
    .col-lg-offset-12 {
      margin-left: 100%;
    }
    .col-lg-offset-11 {
      margin-left: 91.66666667%;
    }
    .col-lg-offset-10 {
      margin-left: 83.33333333%;
    }
    .col-lg-offset-9 {
      margin-left: 75%;
    }
    .col-lg-offset-8 {
      margin-left: 66.66666667%;
    }
    .col-lg-offset-7 {
      margin-left: 58.33333333%;
    }
    .col-lg-offset-6 {
      margin-left: 50%;
    }
    .col-lg-offset-5 {
      margin-left: 41.66666667%;
    }
    .col-lg-offset-4 {
      margin-left: 33.33333333%;
    }
    .col-lg-offset-3 {
      margin-left: 25%;
    }
    .col-lg-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-lg-offset-1 {
      margin-left: 8.33333333%;
    }
    .col-lg-offset-0 {
      margin-left: 0;
    }
    .hidden-lg {
      display: none !important;
    }
    .visible-lg {
      display: block !important;
    }
    table.visible-lg {
      display: table;
    }
    tr.visible-lg {
      display: table-row !important;
    }
    th.visible-lg,
    td.visible-lg {
      display: table-cell !important;
    }
    .container {
      width: 1170px;
    }
    .boxed .st-container {
      width: 1200px;
    }

    .row-count-2 .product:nth-child(2n + 1) {
      clear: both;
    }
    .row-count-4 .product:nth-child(4n + 1),
    .sidebar-position-without .row-count-4 .product:nth-child(4n + 1) {
      clear: both !important;
    }
    .row-count-4 .product:nth-child(3n + 1),
    .sidebar-position-without .row-count-4 .product:nth-child(3n + 1) {
      clear: none;
    }
    .row-count-3 .product:nth-child(3n + 1),
    .sidebar-position-without .row-count-3 .product:nth-child(3n + 1) {
      clear: both;
    }
    .row-count-5 .product:nth-child(5n + 1),
    .row-count-6 .product:nth-child(5n + 1) {
      clear: both;
    }
    .row-count-5 .product:nth-child(3n + 1),
    .row-count-6 .product:nth-child(3n + 1) {
      clear: none;
    }
    .sidebar-position-without .row-count-5 .product:nth-child(5n + 1),
    .sidebar-position-without .row-count-6 .product:nth-child(6n + 1) {
      clear: both;
    }
    .sidebar-position-without .row-count-6 .product:nth-child(5n + 1) {
      clear: none;
    }
    .row-count-5 .product-category {
      width: 175px !important;
    }
    .row-count-2 .product {
      width: 50%;
    }
    .row-count-4 .product {
      width: 25%;
    }
    .sidebar-position-without .row-count-4 .product {
      width: 25%;
    }
    .row-count-5 .product {
      width: 20%;
    }
    .row-count-6 .product {
      width: 16.666%;
    }
    .sidebar-position-without .row-count-5 .product {
      width: 20%;
    }
    .sidebar-position-without .row-count-6 .product {
      width: 16.666%;
    }
    .row-count-3 .product-category,
    .products-list .product-category {
      width: 33.3% !important;
    }
    .owl-carousel .product-category {
      width: 100% !important;
    }
    .wpb_wrapper .products-list .product {
      width: 100% !important;
    }
    .product-information .cart button[type="submit"] {
      width: 315px !important;
    }
    body.js-preloader {
      visibility: hidden;
      opacity: 0;
    }
    body.js-preloader.page-loaded {
      visibility: visible;
      opacity: 1;
    }
    .header-vertical-enable .container .row-count-4.products-grid .product {
      width: 25%;
    }
    .header-vertical-enable .container .sidebar-position-left .row-count-5.products-grid .product,
    .header-vertical-enable .container .sidebar-position-right .row-count-5.products-grid .product {
      width: 25%;
    }
    .header-vertical-enable .container .sidebar-position-left .row-count-5.products-grid .product:nth-child(4n + 1),
    .header-vertical-enable .container .sidebar-position-right .row-count-5.products-grid .product:nth-child(4n + 1) {
      clear: both;
    }
    .header-vertical-enable .container .sidebar-position-left .row-count-5.products-grid .product:nth-child(5n + 1),
    .header-vertical-enable .container .sidebar-position-right .row-count-5.products-grid .product:nth-child(5n + 1) {
      clear: none !important;
    }
    .header-vertical-enable .container .row-count-5.products-grid .product {
      width: 20%;
    }
    .header-vertical-enable .container .row-count-5.products-grid .product:nth-child(5n + 1) {
      clear: both;
    }
    .header-vertical-enable .container .row-count-6.products-grid .product {
      width: 20%;
    }
    .header-vertical-enable .container .row-count-6.products-grid .product:nth-child(5n + 1) {
      clear: both;
    }
    .header-vertical-enable .container .row-count-6.products-grid .product:nth-child(6n + 1) {
      clear: none;
    }
    .header-vertical-enable .container .row-count-2.products-grid .product {
      width: 50%;
    }
    .page-wrapper {
      overflow-x: visible;
    }
  }

  @media screen and (min-width: 992px) {
    .header-type-6 .header .tbs,
    .header-type-6 .header .header-logo,
    .header-type-6 .header .navbar-header,
    .header-type-7 .header .tbs,
    .header-type-7 .header .header-logo,
    .header-type-7 .header .navbar-header,
    .header-type-8 .header .tbs,
    .header-type-8 .header .header-logo,
    .header-type-8 .header .navbar-header,
    .header-type-10 .header .tbs,
    .header-type-10 .header .header-logo,
    .header-type-10 .header .navbar-header {
      width: 33.33%;
    }
    .header-type-4 .header .shopping-cart-widget .cart-bag {
      top: 4px;
      right: 0;
      padding: 0;
    }
    .header-type-4 .header .shopping-cart-widget .cart-bag .ico-sum {
      height: 19px;
      width: 24px;
      top: 8px;
    }
    .header-type-4 .header .shopping-cart-widget .cart-bag .ico-sum:before {
      width: 13px;
      top: -7px;
      -webkit-transform: translateX(-49%);
      transform: translateX(-49%);
    }
    .header-type-4 .header .shopping-cart-widget .cart-bag .ico-sum:after {
      bottom: -4px;
    }
    .header-type-4 .header .shopping-cart-widget .cart-bag .badge-number {
      top: -15px;
      right: -12px;
    }
    .header-type-12 {
      margin-top: -1px;
    }
    .header-type-12 .navbar {
      padding: 0;
    }
    .header-type-12 .shopping-container {
      padding: 21px 30px 21px 70px;
    }
    .header-type-12 .shopping-container .shopping-cart-widget {
      border: none;
      padding: 0;
    }
    .header-type-12 .shopping-container .shopping-cart-widget .shop-text,
    .header-type-12 .shopping-container .shopping-cart-widget .total {
      color: #fff;
    }
    .header-type-12 .shopping-container .shopping-cart-widget .cart-bag {
      right: auto;
      left: -50px;
      top: -15px;
    }

    .header-type-12 .header-search {
      margin: 0;
      padding: 15px 30px;
      border-left: 1px solid #f2f2f2;
    }
    .header-type-12 .header-search a {
      text-transform: uppercase;
      color: #767676;
    }
    .header-type-12 .header-search a .fa-search {
      background-color: transparent;
      color: inherit;
      line-height: 34px;
    }
    .header-type-12 .navbar-collapse {
      margin-top: 0;
    }
    .header-type-12 .navbar-collapse .menu > li > a {
      padding: 21px 17px;
      border-left: 1px solid #ebebeb;
    }
    .header-type-12 .navbar-collapse .menu > li:last-child > a {
      border-right: 1px solid #ebebeb;
    }
    .header-type-12 .navbar-collapse .menu .nav-sublist-dropdown {
      top: 100%;
      margin-top: 0;
    }
    .header-type-12 .top-links {
      margin: 0;
      padding: 19px 30px;
    }
    .shop-full-width .products-grid.row-count-5 .product:nth-child(5n + 1) {
      clear: both;
    }
    .shop-full-width .products-grid.row-count-6 .product:nth-child(6n + 1) {
      clear: both !important;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .header-type-4 .header .shopping-cart-widget .cart-bag .ico-sum,
    .header-type-16 .header .shopping-cart-widget .cart-bag .ico-sum {
      top: -6px;
    }
    .navbar-right {
      min-width: 85px;
    }
    .st-menu {
      -webkit-transform: translateX(-300px);
      transform: translateX(-300px);
    }
    .woocommerce-MyAccount-navigation {
      width: 27%;
    }
    .woocommerce-MyAccount-content {
      width: 70%;
    }
  }
  @media only screen and (max-width: 1200px) {
    .hidden-tooltip {
      display: none !important;
    }
    .shopping-cart-widget {
      margin-bottom: 0;
    }

    .fixed-header-area .badge-number {
      display: block;
    }
    .collapse {
      width: 100%;
    }
    .menu > li > a {
      padding-right: 5px;
      padding-left: 5px;
    }
    .header-type-12 .cart-bag .ico-sum,
    .header-type-12 .cart-bag .ico-sum:after {
      background-color: #c1c1c1;
    }
    .header-type-12 .cart-bag .ico-sum:before {
      border-color: #c1c1c1;
    }
    .header-type-2.slider-overlap .header .menu > li.menu-item-has-children:after {
      right: 6px;
    }
    .header-logo,
    .header-logo img {
      max-width: 250px;
    }
    .header-type-3.slider-overlap .header .shopping-cart-widget {
      border: none;
    }
    .header-type-2.slider-overlap .header .shopping-container {
      top: 5px;
    }
    .header-type-4 .header .shopping-cart-widget {
      margin-top: 9px;
    }
    .header-type-12 .shopping-container {
      background-color: transparent !important;
    }
    .header-type-6 .header-logo,
    .header-type-7 .header-logo {
      text-align: center !important;
    }
    .header-type-10 .menu-wrapper {
      border-bottom: none;
    }
    article.format-video iframe {
      max-width: 100% !important;
    }
    .widget_search .form-group.has-border,
    .etheme_widget_search input[type="text"],
    .etheme_widget_search input[type="email"],
    .etheme_widget_search input[type="password"] {
      width: 100%;
      margin-bottom: 10px !important;
    }
    .sidebar-position-left .coupon input[type="text"],
    .sidebar-position-right .coupon input[type="text"] {
      width: 100%;
      margin-bottom: 10px !important;
    }
    .cart-bag {
      background-color: transparent;
      margin-top: -12px;
      top: 50% !important;
      right: 15px;
      padding: 12px 12px 0 !important;
    }

    .shopping-cart-widget {
      border: none;
      padding-top: 5px;
    }
    .shopping-cart-widget > a {
      line-height: 6px;
    }
    .shopping-cart-widget .cart-summ .shop-text,
    .shopping-cart-widget .cart-summ .total,
    .header-type-18 .navbar-right .navbar-right .shop-text {
      font-size: 0;
    }
    .plus:after {
      content: "";
      font-family: FontAwesome;
      color: #cda85c;
      position: absolute;
      right: 0;
      top: 12px;
      font-size: 20px;
    }
    .minus:after {
      content: "";
      font-family: FontAwesome;
      color: #cda85c;
      position: absolute;
      right: 0;
      top: 12px;
      font-size: 20px;
    }

    .header-type-6 .header .tbs {
      padding-left: 35px;
    }
    .header-type-6 .menu-icon {
      z-index: 100;
    }
    .header-type-12 .header-search .et-search-trigger a span {
      font-size: 0;
    }
    .header-type-11 .top-links,
    .header-type-12 .top-links {
      display: none;
    }

    .banner .banner-content {
      font-size: 0.8em;
    }
    .banner.responsive-fonts .banner-content {
      zoom: 0.8;
    }
    .banner .banner-content h1 {
      font-size: 30px !important;
    }
    .banner .banner-content h5 {
      display: none;
    }
    .tbs {
      display: table-cell;
      vertical-align: middle;
    }
    .xxlarge-h {
      font-size: 30px;
      line-height: 30px;
    }
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
    .slide-body {
      right: 0;
      text-align: center;
      width: 100%;
    }
    .page-wrapper {
      overflow: hidden;
    }
    .large-slide-h {
      line-height: 86px;
    }
    .slide-body {
      top: 50px;
    }
    .banner .banner-content h2.banner-title {
      font-size: 30px !important;
    }
    .product-slider {
      width: 470px;
      margin: 0 auto;
    }
    .two-column-search .pm-image {
      margin-bottom: 10px;
    }
    .two-column-search .pm-details {
      width: 100%;
      padding-left: 0;
    }
    .one-column-search .pm-image {
      margin-bottom: 10px;
    }
    .one-column-search .pm-details {
      max-width: 345px;
      padding-left: 20px;
    }
    .title-page {
      width: 60%;
    }
    .fix {
      margin-left: -30px !important;
      margin-right: 0 !important;
    }
    .text-result,
    .filter-ct-1 .full-filter,
    .filter-ct-1 {
      display: none !important;
    }
    .blog-publisher input[type="text"] {
      margin-bottom: 20px;
    }
    .list-grid-switcher {
      float: left;
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .filter-wrap #st-trigger-effects {
      width: auto !important;
      position: relative;
      margin-top: 1px;
      margin-right: 20px;
    }
    .product-information .menu-social-icons {
      margin-bottom: 30px;
    }
    .left-bar .left-titles {
      width: 100%;
      border-right: none;
    }
    .left-bar .left-titles a {
      border-right: 1px solid #ddd !important;
    }
    .left-bar .tab-content {
      padding: 0 20px 20px 20px !important;
      margin-right: 0;
      box-sizing: border-box;
    }
    .right-bar .left-titles {
      width: 100%;
      border-left: none;
    }
    .right-bar .left-titles a {
      border-left: 1px solid #ddd !important;
    }
    .right-bar .tab-content {
      border-right: none !important;
      width: 100% !important;
      padding: 0 20px 20px 20px !important;
      margin-right: 0;
      box-sizing: border-box;
    }
    #bbpress-forums #bbp-search-form #bbp_search {
      width: 70% !important;
    }
    .tabs .tab-title {
      margin-right: 12px;
    }
    footer .blog-post-list .media {
      margin-right: 10px;
    }
    footer .date-event {
      font-size: 12px;
    }
    footer .date-event .number {
      width: 40px;
      height: 35px;
      line-height: 15px;
      margin-bottom: 5px;
      padding: 10px 6px 0 6px;
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 992px) {
    .content-page {
      display: inline-block;
      width: 100%;
    }
    /* VC FLEXBOX FIX */
    .vc_row.vc_row-o-content-middle {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    @-ms-viewport {
      width: device-width;
    }
    .boxed .st-container {
      width: 100%;
    }
    /* SETTINGS_MAIN_MENU */
    #st-trigger-effects {
      display: block !important;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 0;
      margin-top: -15px;
    }

    .navbar .container-fluid {
      display: block;
    }
    .top-bar {
      display: block;
    }

    .top-bar .top-links .links {
      display: none;
    }
    .header-type-17 .header-top .header-search {
      margin-right: 0;
    }
    .header-type-17 .header-top .shopping-container {
      border-left: none;
    }
    .blog-full-width article {
      width: 49%;
    }
    .header-logo img {
      max-width: 160px;
    }
    .header-logo,
    .header-type-6 .header .header-logo,
    .fixed-header-area .header-logo {
      max-width: 250px;
      width: auto;
      padding-right: 0;
      margin-left: -7px;
      float: left;
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .woocommerce-MyAccount-navigation {
      margin-bottom: 40px;
    }
    .woocommerce-MyAccount-navigation,
    .woocommerce-MyAccount-content {
      width: 100%;
    }
    .navbar-collapse.collapse,
    .header-type-8 .menu-wrapper .languages-area,
    .header-type-10 .menu-wrapper .languages-area,
    .header-type-8 .navbar-header .top-links,
    .header-type-10 .navbar-header .top-links,
    .tbs.blog-description {
      display: none;
      /*display: none !important;*/
    }
    .banner.responsive-fonts .banner-content {
      zoom: 0.7;
    }
    .navbar-header.navbar-right {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -18px;
      width: 28% !important;
    }
    .vc_tta-style-classic .vc_tta-panel-heading {
      background-color: transparent !important;
      border-color: transparent !important;
    }
    .vc_tta-style-classic {
      border-top: none;
    }
    .vc_tta-style-classic .vc_tta-panel-heading h4 {
      border-bottom: 1px solid #ddd;
      margin-bottom: 15px !important;
    }
    .vc_tta-style-classic .vc_tta-panel {
      border-top: 1px solid #000;
    }
    .modal-type-1 .mfp-close {
      font-size: 0;
    }
    .menu > li {
      float: none;
    }
    .menu-all-pages-container {
      float: none;
      margin: 20px 0 0;
    }
    .menu > li.sfHover > a:before {
      content: none;
    }
    .menu > li > a {
      float: none;
    }
    .menu > li > a:focus {
      outline: none;
    }
    .menu > li {
      border-bottom: 1px solid #e4e4e4;
    }
    .menu > li.lastItem {
      border: none;
    }
    .et-search-trigger.search-dropdown form .form-group.form-button .btn-black {
      padding: 12px 20px !important;
    }
    .esg-container {
      padding-left: 30px !important;
    }
    .wishlist {
      margin-right: 10px;
    }
    .banner .ov_hidden:hover img {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    .tbs.blog-description {
      display: none;
    }
    .banner {
      margin-bottom: 20px !important;
    }
    .et-search-trigger .fa-search {
      width: 25px;
      height: 25px;
      line-height: 25px;
    }
    .fixed-header-area,
    .fixed-header-area .fixed-header > .container {
      min-height: 1px !important;
    }
    .big-banner {
      text-align: center;
    }
    .statick-banner {
      text-align: center;
    }
    .statick-banner .ov_hidden {
      display: inline-block;
    }
    .rev_slider_wrapper .tparrows.tp-rightarrow.default {
      right: 40px !important;
      opacity: 1 !important;
    }
    .rev_slider_wrapper .tparrows.tp-leftarrow.default {
      left: 40px !important;
      opacity: 1 !important;
    }
    .products-tabs:before {
      display: none;
    }
    .header-search {
      margin-top: 9px !important;
      float: left;
      margin-right: 24px;
    }
    .header-type-11 .header-search .et-search-trigger a span {
      display: none;
    }
    .header-type-17 .header-logo {
      width: auto;
    }
    .header-type-vertical .et-search-trigger a,
    .header-type-vertical2 .et-search-trigger a {
      font-size: 0;
    }
    .header-custom-block {
      display: none;
    }
    .header-type-10 .menu-wrapper {
      border-bottom: none;
    }
    .fixed-header .shopping-cart-link,
    .fixed-header .my-account-link {
      height: 35px !important;
      width: 35px !important;
      text-align: center;
      line-height: 42px;
      float: left;
      margin-left: 10px;
      font-size: 0;
      cursor: pointer;
    }
    .fixed-header .my-account-link {
      padding-left: 5px;
      width: 35px;
      height: 35px;
    }
    .fixed-header .shopping-cart-count {
      position: absolute;
      right: 17px;
      top: 16px;
      font-size: 16px;
      color: #6f6f6f;
    }
    .fixed-header .shopping-cart-link:hover,
    .fixed-header .my-account-link:hover {
      text-decoration: none;
      box-sizing: border-box;
    }
    .fixed-header .my-account-link:before,
    .fixed-header .shopping-cart-link:before {
      font-family: FontAwesome;
      line-height: 30px;
      color: #8e8e8e;
    }
    .fixed-header .shopping-cart-link:before {
      content: "\f07a";
      font-size: 18px;
    }
    .fixed-header .my-account-link:before {
      content: "\f007";
      font-size: 16px;
    }
    .fixed-header-area #st-trigger-effects {
      display: table-cell;
      vertical-align: middle;
      left: 30px;
    }
    .fixed-header-area .cart-bag {
      display: block;
    }
    .fixed-header-area .navbar-right .navbar-right {
      left: -22px;
    }
    .header-type-6 .header .tbs,
    .header-type-11 .top-links {
      display: none;
    }
    .page-heading.bc-type-8 {
      padding: 100px 0;
    }
    .et-video-content {
      margin: 10px 0 0;
    }
    .et-video-content .ico-brand {
      margin: 0 auto;
      display: block;
    }
    .brand-title {
      margin: 15px 0;
      line-height: 80px;
    }
    .brand-title:after {
      bottom: -3px;
    }
    .brand-title:before {
      top: -3px;
    }
    .navbar-right {
      min-width: 1px !important;
    }
    .pagination-cubic {
      float: right !important;
      text-align: center;
      margin-right: -5px;
    }
    .top-bar {
      display: block;
    }
    .navbar {
      padding: 10px;
    }
    .navbar-right .navbar-right {
      position: relative;
      left: 10px;
    }

    .navbar-toggle {
      margin: 0;
      position: absolute;
      top: 65px;
      right: 15px;
    }
    .cart-popup-container {
      display: none;
    }

    .header-logo img,
    .header-type-6 .header .header-logo img {
      width: 100%;
    }
    .header-type-6 .header .tbs {
      display: none;
    }

    .title-page {
      width: 50%;
    }
    .coupon-form .btn-group {
      padding-top: 10px;
      padding-left: 15px;
      text-align: right;
    }
    .coupon-form .btn-group .btn {
      width: auto;
      float: none;
    }
    .doctor-team .icon_list_item {
      text-align: center;
    }
    .icon_list_item {
      list-style: none;
    }
    .doctor-hover-box .wpb_single_image {
      background-color: #fff !important;
    }
    .doctor-hover-box .wpb_single_image .wpb_wrapper {
      text-align: center;
    }
    .doctor-hover-box .wpb_single_image .wpb_wrapper img {
      display: inline;
    }
    .mailchimp-inline input[type="submit"] {
      padding: 9px 12px;
      width: auto;
    }
    .desc-contact {
      margin-top: 20px;
    }
    .related-posts .thumbnails-x {
      margin-bottom: 30px;
    }
    .etheme_widget_qr_code,
    .sidebar .qr-lighbox,
    .etheme_widget_qr_code .widget-title {
      width: 100%;
      text-align: center;
    }
    .etheme_widget_qr_code .qr-lighbox {
      position: relative;
      left: 50%;
      float: left;
      height: 100px;
      width: 100%;
      margin-top: -12px;
      background-image: url("https://terminal21.co.th/images/qr-responsive.jpg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50px 91px;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      opacity: 1;
    }
    .etheme_widget_qr_code {
      padding-bottom: 15px;
    }
    .product-navigation .next-product .hide-info,
    .product-navigation .prev-product .hide-info,
    .project-navigation .next-project .hide-info,
    .project-navigation .prev-project .hide-info {
      display: none;
    }
    .filters-wrapper .product-categories > li,
    .custom-checkbox li {
      float: left;
      width: 100%;
      box-sizing: border-box;
    }
    .reviews-position-outside .tabs,
    .reviews-position-outside #reviews {
      width: 100% !important;
      padding-left: 0;
      padding-right: 0;
    }
    .responsive-sidebar-top .sidebar-left {
      display: block !important;
      float: left;
    }
    .responsive-sidebar-top .sidebar-right {
      display: none !important;
      float: left;
    }
    .responsive-sidebar-top .span5 {
      float: left;
    }
    .responsive-sidebar-bottom .sidebar-right {
      display: inline-block !important;
      width: 100%;
    }
    .responsive-sidebar-bottom .sidebar-left {
      display: none !important;
    }
    .filter-wrap .woocommerce-result-count {
      display: none;
    }
    .products-list .product-image-wrapper {
      margin-bottom: 1em;
    }
    .products-list .add_to_cart_button {
      float: right;
    }
    .step-title {
      clear: both;
    }
    /* Responsive Sidebar  */
    .sidebar-mobile-top > .row,
    .sidebar-mobile-top > .product > .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .sidebar-mobile-top .content,
    .sidebar-mobile-top .product-content,
    .sidebar-mobile-top .sidebar,
    .sidebar-mobile-top .single-product-sidebar {
      -webkit-box-flex: 1;
      -webkit-flex: 1 100%;
      -ms-flex: 1 100%;
      flex: 1 100%;
      max-width: 100%;
    }
    .sidebar-mobile-top .content,
    .sidebar-mobile-top .product-content {
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
      -ms-flex-order: 2;
      order: 2;
    }
    .sidebar-mobile-top .sidebar,
    .sidebar-mobile-top .single-product-sidebar {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
    }

    .widget-title span,
    .footer-top .title span {
      padding: 0 5px;
    }

    .address-company {
      font-size: 9px;
    }
    .fixed-product-block {
      height: auto;
      display: block;
      min-height: 100px;
    }
    .fixed-product-block .cart {
      display: inline-block;
      float: none;
    }
    .fixed-product-block .yith-wcwl-add-to-wishlist {
      width: auto;
      float: none;
      display: inline-block;
    }
    .single-product-fixed .product-images {
      width: 100%;
    }
    .single-product-fixed .product-images-gallery div {
      width: 50%;
      display: inline-block;
      padding: 10px;
      float: left;
    }
    .single-product-fixed .product-images-gallery div:nth-child(2n + 1) {
      clear: both;
    }
    .fixed-product-block .fixed-content {
      display: block;
    }
    .about-company > .pull-left {
      margin-right: 10px;
    }
    .et-brands-grid.columns-number-5 .et-brand,
    .et-brands-grid.columns-number-6 .et-brand {
      width: 33.3%;
    }
    .main-footer .widget-title,
    .main-footer .text-widget {
      display: inline-block;
      width: 100%;
    }
    .footer-top .wpb_content_element .widget-container {
      margin-bottom: 30px !important;
    }
    .before-checkout-form {
      display: inline-block;
      width: 100%;
      float: none;
    }
    .shop_table.wishlist_table td.product-name {
      width: 240px !important;
    }
    .shop_table.wishlist_table td,
    .shop_table.wishlist_table th {
      padding: 15px 8px !important;
    }
    .shop-filters-area .sidebar-widget {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 48%;
      -ms-flex: 0 0 48%;
      flex: 0 0 48%;
    }
    .shop-table .table-bordered td.product-name .product-thumbnail img {
      max-width: 100px;
    }
    .lost-password {
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
      line-height: 30px;
      display: inline-block;
      margin-bottom: 10px;
    }
    .woocommerce-account.logged-in .content-page .content {
      width: 100% !important;
    }
    .woocommerce-account.logged-in .sidebar {
      margin-bottom: 30px !important;
      float: left;
      width: 100%;
    }
    .empty-category-block h2 {
      margin-top: 120px;
    }
    .empty-category-block h2:before {
      bottom: 130px;
    }
    .tabs .tabs-nav li,
    .tabs .tabs-nav,
    .tabs .tab-content {
      width: 100%;
      float: none !important;
    }
    .tabs .tabs-nav li {
      width: auto;
    }
    .tabs.accordion .tabs-nav li {
      float: none !important;
    }
    .tabs .tab-content {
      padding-top: 20px !important;
      border-left: none !important;
      border-right: none !important;
    }
    .tabs .tab-title {
      width: 100%;
      padding: 13px 5px !important;
      padding-left: 0;
      margin: 0;
      margin-top: -1px;
      margin-bottom: -1px;
      color: #000;
      border-left: none;
      font-size: 13px;
      border-right: none;
    }
    .tabs .tab-title:hover {
      box-sizing: border-box;
    }
    .tabs .tab-title:after {
      top: 4px !important;
    }
    .tabs .tab-title.opened:before {
      bottom: -6px;
    }
    .tabs.accordion .tab-title,
    .tabs.accordion .tab-title.opened {
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 10px 5px 10px 10px !important;
      border: 1px solid #d5d5d5;
      border-left: 1px solid #d5d5d5;
      border-right: 1px solid #d5d5d5;
    }
    .tabs.accordion .tab-title:before,
    .tabs.accordion .tab-title.opened:before {
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      background: #cda85c;
      position: relative;
      left: 0;
      margin-left: 0;
      bottom: 0;
      border: none;
      margin: 0 6px 1px 0;
    }
    .right-bar .wpb_tabs_nav li a,
    .right-bar .tabs-nav li a {
      border-width: 0 0 0 0;
    }
    .reviews-position-outside #reviews {
      float: none;
      display: inline-block;
    }
    .sidebar-widget .widget-title,
    .sidebar-slider .widget-title {
      width: 100%;
      display: inline-block;
    }
    .products-tabs {
      border: none;
      padding: 0;
    }
    .flickr_badge_image {
      margin: 0 5px 10px !important;
      width: 58px !important;
      height: 58px !important;
    }
    .main-footer.container > .col-md-3 {
      margin-bottom: 30px;
    }
    .transport-list .icon_description {
      float: left;
      margin-left: 0 !important;
      width: 100%;
    }
    .transport-list .icon_list_icon {
      height: auto !important;
    }
    .info-circle-wrapper .smile_icon_list.left li .icon_description {
      background-color: transparent !important;
    }
    .market-icon .icon_description {
      margin-left: 0 !important;
    }
    .market-icon .icon_list_icon {
      margin-right: 10px;
    }
    .quick-view-popup .product-images .label-product .out-stock,
    .single-product .product-images .label-product .out-stock {
      display: none;
    }
  }
  /*Parallax fix on responsive layout */
  @media only screen and (max-width: 1030px) {
    .upb_row_bg {
      background-attachment: scroll !important;
      background-position: 50% 50% !important;
      background-size: cover !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .added-text {
      top: 18%;
      font-size: 11px;
    }
    .banner iframe {
      max-height: 224px;
    }
    .banner .banner-content {
      font-size: 0.8em;
    }
    .banner .banner-content h1 {
      font-size: 30px !important;
    }
    .banner .banner-content h5 {
      display: none;
    }
    .fixed-header-area {
      min-height: 0px !important;
      padding: 10px 0;
    }
    .carousel-area .owl-controls {
      opacity: 1;
    }
    .one-column-search .pm-image {
      max-width: 200px;
    }
    .one-column-search .pm-details {
      width: 270px;
    }
    .footer-product a,
    .show-quickly {
      font-size: 0;
    }
    .footer-product a:before,
    .show-quickly:before {
      font-size: 14px;
    }
    .main-footer .menu-social-icons li {
      margin: 0;
    }
    .main-footer .menu-social-icons.larger {
      margin: 0 5px;
    }
    .show-quickly,
    .yith-wcwl-add-to-wishlist {
      width: 50%;
      text-align: center;
      cursor: pointer;
    }
    .product-information .yith-wcwl-add-to-wishlist {
      width: auto;
      padding-left: 0;
    }
    .show-quickly {
      position: relative;
      top: 2px;
      padding-right: 10px;
    }
    .yith-wcwl-add-to-wishlist {
      padding-left: 10px;
    }

    .flickr_badge_image {
      width: 65px !important;
      height: 65px !important;
    }
    .category-grid.product-category {
      width: 50%;
      float: left;
    }
    .products-list .product .product-image-wrapper {
      width: 34%;
      margin-right: 20px;
    }
    .contact-form .captcha-block input {
      width: 85px !important;
    }
    .fixed-header-area .fixed-header > .container {
      width: 750px;
      box-sizing: border-box;
    }
    .product-images {
      max-width: 440px;
      margin: 0 auto 30px;
      float: none;
    }
    .footer-top .wpb_row .vc_span3 {
      width: 50% !important;
    }
    .footer-top .wpb_row .vc_span3 .wpb_single_image {
      text-align: center;
    }
    .footer-top .wpb_row .vc_span3:nth-child(2n + 1) {
      clear: both;
    }
    .banner-wrapper {
      margin-bottom: 0 !important;
    }
    .banner-wrapper .column_container {
      margin-bottom: 0 !important;
    }
    .rev_slider_wrapper .type-label-2 {
      width: 70px;
      height: 70px;
    }
    .rev_slider_wrapper .type-label-2 .sale {
      font-size: 16px;
    }
    .rev_slider_wrapper .type-label-2 span {
      font-size: 10px !important;
    }
    .rev_slider_wrapper .type-label-2 span:first-child {
      padding-top: 18px !important;
    }
    .mailchimp-inline.blog-publisher {
      width: 100% !important;
    }
    .mailchimp-white input[type="email"],
    .mailchimp-white input[type="submit"] {
      width: auto !important;
    }
    .shop-full-width .products-grid.row-count-2 .product {
      width: 50%;
    }
    .shop-full-width .products-grid.row-count-3 .product {
      width: 33.3%;
    }
    .shop-full-width .products-grid.row-count-4 .product {
      width: 50%;
    }
    .shop-full-width .products-grid.row-count-5 .product {
      width: 50%;
    }
    .shop-full-width .products-grid.row-count-6 .product {
      width: 33.3%;
    }
    .shop-full-width .products-grid.row-count-6 .product img {
      width: 100%;
    }
    .shop-full-width .products-grid.row-count-6 .product:nth-child(3n + 1) {
      clear: both !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .fixed-header-area .fixed-header > .container {
      width: 100%;
      box-sizing: border-box;
    }

    .mobile-nav ul {
      width: 100%;
    }
    .products-tabs .tab-title:after,
    .products-tabs .wpb_tabs_nav li a:after {
      display: none !important;
    }
    body.bordered {
      border: none;
      .body-border-left,
      .body-border-right,
      .body-border-top,
      .body-border-bottom {
        display: none;
      }
    }
    .page-heading.bc-type-5,
    .page-heading.bc-type-6 {
      background-attachment: scroll !important;
      background-position: center !important;
    }
    .banner.responsive-fonts .banner-content {
      zoom: 1;
    }
    .copyright-1,
    .copyright-2 {
      text-align: center;
    }
    .copyright-1 .copyright-payment,
    .copyright-2 .copyright-payment {
      margin-bottom: 10px;
    }
    .copyright-1 .copyright-payment li,
    .copyright-2 .copyright-payment li {
      margin: 5px 5px;
    }
    .copyright-1 .cop-text,
    .copyright-2 .cop-text {
      margin-bottom: 20px;
    }
    .header-type-2.slider-overlap .header .cart-bag {
      right: 8px !important;
      left: auto;
    }
    .header-type-18 .navbar-right .navbar-right .shop-text,
    .header-type-18 .navbar-right .navbar-right .shop-text .total {
      font-size: 0 !important;
    }
    .header-type-17 .header-top .shopping-container {
      border-left: none;
    }
    .main-footer .col-md-3 {
      margin-bottom: 35px;
    }
    .main-footer .address-company {
      display: inline-block;
      width: 100%;
    }
    .main-footer .menu-social-icons {
      text-align: center;
      width: 100%;
    }
    .main-footer .menu-social-icons li {
      float: none;
      display: inline-block;
    }
    .main-footer .centered-logo {
      text-align: center;
    }
    .rev_slider_wrapper hr {
      display: none;
    }
    .shop-filters-area .sidebar-widget {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
    .blog-full-width article {
      width: 100%;
    }
    .content .home-video-section {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
    .section-breadcrumbs .size-x2 {
      font-size: 34px;
    }
    .section-breadcrumbs .upb_row_bg {
      background-attachment: scroll !important;
      background-position: center !important;
    }
    .copyright-1 .pull-left,
    .copyright-1 .pull-right,
    .copyright-2 .pull-left,
    .copyright-2 .pull-right {
      float: left !important;
      position: relative;
      left: 50%;
      width: 100%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .footer-top .cta-block .table-cell {
      position: relative;
    }
    .footer-top .cta-block .table-cell i {
      position: absolute;
      top: 50%;
      margin-top: -10px;
    }
    .footer-top .cta-block .table-cell i:first-child {
      left: -20px;
    }
    .footer-top .cta-block .table-cell i:last-child {
      right: -20px;
    }
    .row-copyrights div {
      width: 100% !important;
    }
    .row-copyrights div:first-child {
      margin-bottom: 10px;
      margin-top: 10px;
      text-align: center;
    }
    .row-copyrights .copyright-payment {
      text-align: center;
    }
    .sidebar-blog,
    .sidebar {
      float: left;
      width: 100%;
    }
    .some-banners {
      text-align: center;
    }
    .some-banners img {
      width: auto;
    }
    .page-heading {
      text-align: left;
    }
    .title-page {
      margin: 0;
      width: 100%;
      text-align: center;
    }
    .woocommerce-breadcrumb,
    .breadcrumbs {
      text-align: center;
      width: 100%;
    }
    .bc-type-2 .woocommerce-breadcrumb,
    .bc-type-4 .woocommerce-breadcrumb,
    .bc-type-6 .woocommerce-breadcrumb,
    .bc-type-2 .breadcrumbs,
    .bc-type-4 .breadcrumbs,
    .bc-type-6 .breadcrumbs {
      text-align: left;
    }

    .back-history {
      top: 50%;
      margin-top: -9px;
      display: none;
    }
    .comment-reply-link {
      position: static;
      display: table-row;
    }
    .product-images {
      max-width: 440px;
      margin: 0 auto 30px;
      float: none;
    }
    .product-images-slider {
      width: 100%;
      text-align: center;
    }
    .product-images-slider .bx-wrapper img {
      display: inline-block;
    }
    .products-list .product .product-image-wrapper {
      width: 100%;
      text-align: center;
      margin-right: 0;
      max-width: 450px;
      float: left;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .products-list .product .product-details {
      width: 100%;
      box-sizing: border-box;
    }
    .products-list .product .product-image-wrapper img {
      max-width: 450px !important;
    }
    .info-circle-wrapper {
      float: none !important;
    }
    .comments-list .comment-reply-link {
      margin-top: 10px;
      display: block;
    }
    .margin0.image-responsive-margin {
      margin-bottom: 30px !important;
    }
    .nav-bar-1 li.active a:before,
    .nav-bar-1 li.active a:after {
      display: none;
    }
    .blog-post .wp-picture {
      margin-bottom: 15px;
    }
    .blog-post {
      margin-bottom: 40px;
    }
    .sm-arrow,
    .product-slider .bx-wrapper .bx-controls-direction a {
      display: none !important;
    }
    .carousel-area .owl-controls {
      opacity: 1;
      display: none !important;
    }
    .carousel-area .owl-controls .owl-prev {
      left: 3px;
    }
    .carousel-area .owl-controls .owl-prev:after {
      width: 65px;
    }
    .carousel-area .owl-controls .owl-next {
      right: 3px;
    }
    .carousel-area .owl-controls .owl-next:after {
      width: 65px;
    }
    .related-posts .owl-controls {
      display: block !important;
    }

    .post-slide .btn_group a {
      margin-bottom: 10px;
      margin: 0 2px 10px;
    }
    .portfolio.with-transition {
      margin-bottom: 30px;
    }
    .footer-product {
      display: none;
      bottom: -64px;
    }
    .footer-product a {
      margin: 0 0 10px 10px;
    }
    .footer-product a:last-child {
      margin-bottom: 0;
    }
    .footer-product a.wishlist:before {
      margin-left: 0;
    }
    .team-member {
      margin-bottom: 20px !important;
    }
    .wrap-h,
    .latest-post-list li,
    .later-product-list li {
      float: left;
      width: 100%;
    }
    .filter-wrap a.btn-black,
    .text-result {
      float: none !important;
      display: block;
      margin-left: 0;
    }
    .filter-ct-1 {
      margin-left: 0;
    }
    .modal-type-1 .modal-dialog {
      width: 100%;
    }
    .big-alert {
      text-align: center;
    }
    .alert-message .first-star {
      position: absolute;
      left: 5px;
      top: 50%;
      margin-top: -7px;
    }
    .alert-message .second-star {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -7px;
    }
    .reference {
      padding-left: 20px;
      padding-right: 20px;
    }
    .fix {
      margin-left: -30px !important;
      margin-right: -30px !important;
    }
    .large-banner .md-title-banner,
    .xlarge-banner .md-title-banner {
      top: 50%;
      margin-top: -40px;
    }
    .large-banner .md-title-banner .xxlarge-h,
    .xlarge-banner .md-title-banner .xxlarge-h {
      margin-bottom: 15px;
    }
    .large-banner .md-title-banner .xxlarge-h:after,
    .xlarge-banner .md-title-banner .xxlarge-h:after {
      display: none;
    }
    .cta-block .container {
      width: 100%;
    }
    .step-nav li {
      display: block;
      border: none;
    }
    .style-paragraph {
      box-sizing: border-box;
    }
    .blog-sidebar {
      float: left;
      width: 100%;
    }
    .list-grid-switcher {
      display: none;
    }
    .blogCarousel {
      float: left;
      width: 100%;
    }
    .blog-p {
      display: block !important;
    }
    .filter-wrap {
      text-align: center;
    }
    .pagination-cubic {
      float: right !important;
      display: inline-block;
      text-align: center;
    }
    .pagination-cubic .page-numbers {
      text-align: center;
    }
    .filter-wrap .woocommerce-ordering {
      float: left;
      margin-right: 30px;
      display: inline-block;
      margin-bottom: 20px;
    }
    .filter-wrap .view-switcher {
      float: left;
      display: inline-block;
    }
    .filter-wrap .view-switcher .switchToGrid,
    .filter-wrap .view-switcher .switchToList {
      margin-bottom: 0;
    }
    .banner-left-extreme {
      margin: 0;
    }
    .banner-left-extreme .vc_col-sm-3 {
      float: left;
    }
    .shop-table .table-bordered > tbody > tr > td {
      padding: 20px 6px;
    }
    .shop-table .table-bordered td.product-name .product-thumbnail {
      max-width: 97px;
    }
    .shop-table .table-bordered td.product-name .product-thumbnail img {
      max-width: 90px;
    }
    .responsive-switcher {
      float: left;
      width: 100%;
      padding-top: 10px;
    }
    .info-book {
      padding-top: 30px;
    }
    .shop-table {
      overflow: visible !important;
    }
    .shop-table .shop_table .product-subtotal {
      display: none;
    }
    .shop-table .table-bordered {
      border-width: 1px 0 1px 1px;
    }
    .teaser-box {
      width: 100%;
    }
    .categoriesCarousel .slide-item {
      text-align: center;
    }
    .blogCarousel {
      margin-bottom: 70px;
    }
    .et-brands-grid.columns-number-5 .et-brand,
    .et-brands-grid.columns-number-6 .et-brand {
      width: 33.3%;
    }
    .shop_table.wishlist_table td.product-name {
      width: auto !important;
    }
    .shop_table.wishlist_table .product-thumbnail {
      display: none;
    }
    .main-footer .widget-container {
      display: inline-block;
      width: 100%;
    }
    .cta-block .button-right {
      padding-left: 10px;
    }
    .etheme_cp {
      display: none;
    }
    article.format-video iframe {
      max-width: 100% !important;
    }
    .open-video-popup img {
      width: 100%;
      height: auto;
    }
    .responsive-sidebar-bottom .sidebar-right {
      margin-top: 20px;
    }
    .woocommerce-message,
    .alert-success,
    .success,
    .woocommerce-error,
    .alert-error,
    .error,
    .woocommerce-info,
    .alert-info,
    .info,
    .woocommerce-warning,
    .alert-warning,
    .warning {
      float: left;
      width: 100%;
    }
    .woocommerce-account form .form-row-first,
    .woocommerce-account form .form-row-last {
      width: 100%;
    }
    .landing-copyright p {
      float: left !important;
      width: 100% !important;
      margin-top: 30px !important;
      margin-bottom: 20px !important;
      text-align: center !important;
    }
    .one-page-footer .about-company a {
      width: 100%;
      text-align: center;
    }
    .smile_icon_list.left li .icon_list_connector {
      border-right-width: 0px;
    }
    .shop-full-width .products-grid.row-count-2 .product,
    .shop-full-width .products-grid.row-count-3 .product,
    .shop-full-width .products-grid.row-count-4 .product,
    .shop-full-width .products-grid.row-count-5 .product,
    .shop-full-width .products-grid.row-count-6 .product {
      width: 50%;
    }
    .woocommerce-account .addresses > div {
      width: 100% !important;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    .one-column-search .pm-image {
      max-width: 40%;
    }
    .one-column-search .pm-details {
      max-width: 58%;
    }
    .category-grid.product-category {
      width: 50%;
      float: left;
    }
    .show-quickly,
    .yith-wcwl-add-to-wishlist {
      width: 50%;
      text-align: right;
      cursor: pointer;
    }
    .pricing-table ul {
      width: 50% !important;
    }
    .pricing-table ul.selected-column {
      top: 0;
    }
    .shop-table .table-bordered td.product-name .cart-item-details {
      max-width: 100px;
      overflow: hidden;
    }
    .shop-table .table-bordered td.product-quantity .quantity input {
      text-align: center;
    }
    .shop-table .table-bordered td.product-quantity .quantity input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -ms-appearance: none;
    }
    .shop-table .table-bordered td.product-price span,
    .shop-table .table-bordered td.product-subtotal span {
      font-weight: 400;
    }
    .pricing-table ul.selected-column li.row-title,
    .pricing-table ul.selected-column li:last-child {
      padding: 20px !important;
    }
    .pricing-table.columns1 ul {
      width: 100% !important;
    }
    .share-post .share-title {
      display: block !important;
    }
    .rev_slider_wrapper .type-label-2 {
      width: 70px;
      height: 70px;
    }
    .rev_slider_wrapper .type-label-2 .sale {
      font-size: 16px !important;
      line-height: 20px !important;
    }
    .rev_slider_wrapper .type-label-2 span {
      font-size: 10px !important;
    }
    .rev_slider_wrapper .type-label-2 span:first-child {
      padding-top: 18px !important;
    }
    .modal-type-1 .mfp-close {
      right: -27px !important;
    }
    .wpb_widgetised_column .banner {
      width: 50%;
      margin: 0 auto;
    }
    .main-footer .about-company > .pull-left {
      width: 100%;
      margin-bottom: 10px;
      text-align: center;
    }
    .main-footer > .pull-right {
      width: 100%;
      margin-bottom: 10px;
      text-align: center;
    }
    .top-bar .languages-area {
      margin: 0 auto;
    }
    .shop_table .product-name {
      white-space: normal;
    }
    .top-bar .languages-area .languages,
    .top-bar .languages-area .currency {
      display: inline-block;
      float: none;
    }
  }
  @media only screen and (max-width: 480px) {
    .some-banners img {
      width: 100%;
    }
    .mobile-nav-heading {
      width: 100%;
    }
    .shop-text {
      margin: 7px 5px 0 0;
    }
    #product-pager {
      width: 200px;
    }
    .top-bar .languages-area,
    .top-bar .top-links {
      width: 100%;
      text-align: center;
    }
    .top-bar .languages-area li,
    .top-bar .top-links li,
    .top-bar .topbar-widget,
    .top-bar .links,
    .header-custom-block .menu-social-icons li {
      float: none;
    }
    .header-custom-block {
      text-align: center;
    }
    .header-custom-block .menu-social-icons {
      width: 100%;
    }
    .header-custom-block .menu-social-icons li {
      display: inline-block;
    }
    .banner.responsive-fonts .banner-content {
      zoom: 0.8;
    }
    .vimeo-video iframe,
    .youtube-video iframe,
    .vimeo-video,
    .youtube-video {
      height: auto !important;
    }
    .navbar {
      padding: 15px 0;
    }
    .navbar-right .navbar-right {
      left: 26px;
    }
    .badge-number {
      display: none;
    }
    .banner .banner-content p {
      display: none;
    }
    .woocommerce-cart,
    .st-container,
    .st-container .st-pusher,
    .page-wrapper {
      overflow-x: visible !important;
      -ms-overflow-x: visible !important;
    }
    .banner-left-extreme {
      padding-bottom: 10px;
    }
    .wpb_widgetised_column .banner .banner-content p {
      display: block;
    }
    .header-logo {
      max-width: 200px !important;
      margin-left: 0px;
    }
    .header-type-17 .header-logo {
      width: auto;
    }
    .header-type-17 .header-top .header-custom-block {
      width: 100%;
      text-align: center;
      padding-bottom: 5px;
    }
    .header-type-17 .header-top .container > .navbar-right {
      float: left !important;
      width: auto;
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .header-type-17 .header-top .header-search {
      margin-right: 15px;
    }
    .header-type-17 .header-top .shopping-cart-widget {
      padding-left: 25px;
    }
    .fixed-header-area {
      min-height: 0px !important;
      padding: 10px 0;
    }
    .fixed-header-area .header-logo {
      padding: 5px 0;
    }
    .fixed-header-area .fixed-header > .container {
      min-height: 30px !important;
    }
    .author-info .author-social {
      position: relative;
      top: 0;
      margin-top: 10px;
      width: 100%;
    }
    .author-info .author-social li a {
      border: none !important;
      width: 32px;
      height: 35px;
      line-height: 35px;
    }
    .bc-type-3 .title,
    .bc-type-4 .title,
    .bc-type-5 .title,
    .bc-type-6 .title,
    .bc-type-7 .title,
    .bc-type-8 .title {
      font-size: 28px !important;
      width: 100%;
      line-height: 32px !important;
    }
    .bc-type-3,
    .bc-type-4,
    .bc-type-5,
    .bc-type-6,
    .bc-type-7 .title,
    .bc-type-8 .title {
      padding: 20px 0 !important;
    }
    .related-posts .recent-p {
      display: block !important;
    }
    .related-posts .recentCarousel .owl-buttons {
      display: block !important;
    }
    .rev_slider_wrapper .type-label-2 {
      display: none;
    }
    table.variations td select,
    .has-border .form-control {
      font-size: 16px;
    }
    .recent-categ .pagination-slider {
      display: none;
    }
    .meta-title {
      text-align: center;
    }
    .meta-title span {
      padding: 0 6px;
    }
    .wp-picture .btn_group a,
    .swiper-slide .btn_group a,
    .portfolio-image .btn_group a,
    .thumbnails-x .btn_group a {
      padding: 10px;
      margin: 0 5px;
    }
    .info-circle-wrapper .smile_icon_list.left li .icon_description {
      margin-left: 1em !important;
    }
    .format-quote blockquote p {
      line-height: 32px !important;
    }
    table.variations td select,
    .has-border .form-control {
      font-size: 16px;
    }
    .product-navigation .product-arrows {
      display: none;
    }
    .sidebar-widget.null-instagram-feed li {
      width: 33.333% !important;
      display: inline-block;
      padding: 0 0.3571em;
    }

    .wpb_widgetised_column .widget-container.null-instagram-feed .instagram-size-small li {
      width: 33.33%;
    }
    .wpb_widgetised_column .widget-container.null-instagram-feed .instagram-size-thumbnail li {
      width: 33.33%;
    }
    .wpb_widgetised_column .widget-container.null-instagram-feed .instagram-size-large li {
      width: 50%;
      padding: 0 0.3571em;
    }
    .wpb_widgetised_column .widget-container.null-instagram-feed .instagram-no-space li {
      padding: 0 !important;
      margin: 0 !important;
    }

    .products-tabs .tab-title {
      position: relative;
      border: 1px solid #ddd;
      width: 100%;
      padding: 10px;
      margin: 10px 0 !important;
      box-sizing: border-box;
    }
    .products-tabs .tab-title:after {
      display: none !important;
    }
    .products-tabs .tab-content {
      float: none !important;
    }
    #commentform input.form-control {
      width: 100%;
    }
    .recentCarousel .owl-buttons {
      display: block !important;
    }
    .mailchimp-inline input#mc4wp_email {
      width: 100% !important;
    }
    .send-comm .form input.form-control {
      width: 100% !important;
    }
    .large-slider .bx-wrapper .bx-controls-direction a {
      opacity: 1;
    }
    .large-slider .bx-wrapper .bx-next {
      right: 5px !important;
    }
    .large-slider .bx-wrapper .bx-prev {
      left: 5px !important;
    }
    .et-mobile-menu li > ul > li a:hover {
      color: #767676;
    }
    .et-mobile-menu li > ul > li a:active {
      color: #cda85c;
    }
    .title-page {
      font-size: 18px;
    }
    .back-history {
      display: none;
    }
    .et_section.et-section-video {
      height: 270px;
    }
    .et_section.et-section-video .brand-title {
      font-size: 40px;
      line-height: 35px;
    }
    .et_section.et-section-video .brand-title:before {
      top: -8px;
    }
    .et_section.et-section-video .brand-title:after {
      bottom: -8px;
    }
    .lookbook-share {
      font-size: 0;
      line-height: 12px;
    }
    .lookbook-share ul {
      margin-left: 0;
      float: none;
      width: 100%;
      text-align: center;
    }
    .lookbook-share ul li {
      float: none;
    }
    article.format-video iframe {
      max-width: 100% !important;
      height: auto !important;
    }
    .wp-picture .btn_group a,
    .swiper-slide .btn_group a {
      font-size: 12px;
    }
    .pag-wrap .text-result {
      display: none;
    }
    .pag-wrap .pagination-cubic {
      float: left !important;
      text-align: left;
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .pagination-slider,
    .owl-controls {
      display: none;
    }
    .sidebar {
      margin-top: 30px;
    }
    .filter-wrap #st-trigger-effects {
      width: 100% !important;
      position: relative;
      margin-top: 0px;
      margin-bottom: 15px;
      margin-right: 0;
      left: 50%;
      text-align: center;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .filter-wrap .view-switcher {
      display: none;
    }
    .filter-wrap .pagination-cubic {
      text-align: center;
    }
    .filter-wrap .woocommerce-ordering {
      float: none;
      margin-right: 0;
    }
    .pagination-cubic {
      margin-right: 0;
      width: 100%;
    }
    .pagination-cubic li {
      border: none;
      float: none;
    }
    .pagination-cubic ul {
      width: 100%;
      text-align: center !important;
    }
    .footer-product {
      display: none;
    }
    .products-grid .product {
      margin-bottom: 40px !important;
    }
    .category-grid {
      margin-bottom: 30px !important;
    }
    .one-column-search .pm-image,
    .one-column-search .pm-details {
      max-width: 100%;
      width: 100%;
      text-align: center;
      padding-left: 0;
    }
    .rating,
    .review {
      width: 100%;
      padding-left: 0;
    }
    .pm-details .pm-meta {
      padding-bottom: 10px;
    }
    .shop-table .medium-coast {
      font-size: 16px;
    }
    .shop-table .table-bordered > tbody > tr > td {
      padding: 12px 0;
    }
    .shop-table .table-bordered > tbody > tr > td,
    .shop-table .table-bordered > thead > tr > th {
      border: none;
    }
    .shop-table {
      position: relative;
    }
    .shop-table tbody {
      border-top: 1px solid #ddd;
    }
    .shop-table thead .product-quantity {
      display: none;
    }
    .shop-table .table-bordered td.product-quantity .quantity input {
      -webkit-appearance: none;
      appearance: none;
    }
    .shop-table .table-bordered td.product-quantity .quantity input[type="number"] {
      width: 35px !important;
      height: 30px !important;
      padding: 5px;
      margin-right: 7px;
      text-align: center;
    }
    .shop_table tbody .product-price {
      display: none !important;
    }
    .shop-table .table-bordered td.product-quantity .quantity input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
    .shop_table.wishlist_table .product-stock-status {
      display: none;
    }
    .shop_table.wishlist_table td,
    .shop_table.wishlist_table th {
      padding: 15px 6px !important;
    }
    .product-content-image .label-product {
      display: none;
    }
    .shop-table .table-bordered td.product-name .product-thumbnail img {
      max-width: 45px;
    }
    .shop-table .table-bordered td.product-name {
      max-width: 140px !important;
    }
    .shop_table tbody .product-name,
    .shop-table .table-bordered td.product-name a {
      overflow: hidden;
      line-height: 16px;
    }
    .shop-table thead .product-name {
      left: 38%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      width: auto;
      position: relative;
      float: none;
    }
    .shop_table tbody .product-name .product-thumbnail {
      padding-right: 0 !important;
    }
    .shop-table .table-bordered td.product-name .cart-item-details {
      white-space: normal;
      text-align: left;
      line-height: 16px;
      vertical-align: top;
    }
    .shop-table .table-bordered td.product-quantity .quantity {
      top: 5px;
    }
    .shop-table tbody .mobile-price {
      text-align: left;
      margin-top: 5px;
    }
    .mobile-price {
      display: block;
    }
    .shop-table tbody .mobile-price .amount {
      font-weight: bold;
    }
    .shop-table .table-bordered td.product-name img {
      margin-right: 10px;
    }
    .shop_table tbody .product-price {
      display: table-cell;
    }
    .et-brands-slider {
      margin-bottom: 0;
    }
    .share-post {
      padding: 0;
      border: none;
      text-align: center;
      margin-bottom: 0;
    }
    .share-post ul {
      float: none !important;
    }
    .share-post ul li a {
      border-left: none !important;
    }
    .mailchimp-white input[type="email"] {
      width: 100% !important;
      margin-right: 0 !important;
      left: 0;
    }
    .mailchimp-inline input[type="submit"] {
      float: left;
      width: 50%;
      left: 50%;
      position: relative;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .product-information .woocommerce-product-rating {
      margin-bottom: 10px;
    }
    .single-post .page-heading {
      margin-bottom: 0;
    }
    .actions input.btn {
      margin-left: 0;
    }
    .st-menu.hide-filters-block {
      width: 270px !important;
    }
    .st-menu-open .hide-filters-block .st-pusher {
      -webkit-transform: translate3d(270px, 0, 0) !important;
      transform: translate3d(270px, 0, 0) !important;
      -ms-transform: translate3d(270px, 0, 0) !important;
    }
    .details-tools .btn-black {
      width: 185px;
    }
    .sub-tools {
      text-align: center;
    }
    .sub-tools li {
      margin-bottom: 0;
      line-height: 29px;
    }
    .sub-tools li:first-child {
      margin-left: 0;
    }
    .sub-tools li.email-link {
      margin-left: 0;
    }
    .login-form .btn,
    .register .button {
      width: 100%;
    }
    .nav-bar-1 li {
      width: 100%;
      margin: 10px 0;
      text-align: center;
    }
    .lookBook-1 .swiper-slide {
      max-height: 395px;
    }
    .carousel-area .owl-controls .owl-next:after,
    .carousel-area .owl-controls .owl-prev:after {
      height: 80px;
      top: -22px;
    }
    .categoriesCarousel .owl-controls .owl-prev,
    .categoriesCarousel .owl-controls .owl-next {
      top: 50%;
      margin-top: -20px;
    }
    .comments-list .children {
      margin-left: 20px;
    }
    .comments-list .children li .media:before {
      display: none;
    }
    .products-list .product .product-image-wrapper {
      width: 100%;
    }
    .products-list .add_to_cart_button {
      float: left;
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .creative-hover {
      padding-bottom: 20px;
    }
    .wp-picture .zoom a,
    .swiper-slide .zoom a,
    .portfolio-image .zoom a {
      width: 45px;
      height: 45px;
    }
    .wp-picture .zoom a:before,
    .swiper-slide .zoom a:before,
    .portfolio-image .zoom a:before,
    .wp-picture .zoom a:after,
    .swiper-slide .zoom a:after,
    .portfolio-image .zoom a:after {
      display: none;
    }
    .wp-picture .zoom a span,
    .swiper-slide .zoom a span,
    .portfolio-image .zoom a span {
      font-size: 0;
    }
    .wp-picture .zoom a span:after,
    .swiper-slide .zoom a span:after,
    .portfolio-image .zoom a span:after {
      display: none;
    }
    .wp-picture .zoom a:first-child span:before,
    .swiper-slide .zoom a:first-child span:before,
    .portfolio-image .zoom a:first-child span:before {
      content: "\f0b2";
      font-size: 18px;
      font-family: FontAwesome;
      left: 50%;
      top: 50%;
      margin-top: -6px;
      margin-left: -7px;
    }
    .wp-picture .zoom a:last-child span:before,
    .swiper-slide .zoom a:last-child span:before,
    .portfolio-image .zoom a:last-child span:before {
      content: "\f0c1";
      font-size: 18px;
      font-family: FontAwesome;
      left: 50%;
      top: 50%;
      margin-top: -6px;
      margin-left: -7px;
    }
    .format-gallery .post-gallery-slider .owl-buttons .owl-next {
      right: 20px !important;
      opacity: 1 !important;
    }
    .format-gallery .post-gallery-slider .owl-buttons .owl-prev {
      left: 20px !important;
      opacity: 1 !important;
    }
    .product-information .cart button[type="submit"] {
      width: auto;
    }
    .tabs-default .tab-title {
      width: 100%;
      text-align: center;
    }
    .product-information .price {
      text-align: center;
      margin: 0 0 10px;
    }
    .product-information .price del {
      display: inline-block;
      float: left;
      width: 100%;
    }
    .modal-type-1 .modal-dialog {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2);
    }
    .shop_table .product-price {
      display: block;
    }
    .shop_table thead .product-price {
      display: none;
    }
    .shop-table .table-bordered td.product-price span,
    .shop-table .table-bordered td.product-subtotal span {
      font-size: 16px;
      font-weight: 400;
    }
    .shop_table td.actions .button {
      float: left;
      width: 200px;
      margin: 5px 0;
      clear: both;
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .actions input {
      width: 100%;
      margin-bottom: 10px;
    }
    .coupon input[type="text"] {
      width: 100px;
      float: left;
    }
    .cta-block {
      padding: 20px;
    }
    .cta-block .table-cell {
      display: block;
      vertical-align: middle;
      width: 100%;
      text-align: center;
    }
    .slide-item .product-type-variable {
      margin: 0;
    }
    .et-brands-grid .et-brand {
      width: 50% !important;
    }
    .woocommerce-message,
    .alert-success,
    .success,
    .woocommerce-error,
    .alert-error,
    .error,
    .woocommerce-info,
    .alert-info,
    .info,
    .woocommerce-warning,
    .alert-warning,
    .warning {
      line-height: 20px;
    }
    .bag-total-table th,
    .shipping th {
      width: 130px;
    }
    .shop_table .shipping th {
      width: 100px;
    }
    select.country_select {
      width: 100%;
    }
    .order-review {
      padding: 40px 10px 22px;
    }
    .order-review .place-order {
      text-align: center;
    }
    .before-checkout-form .login .form-row {
      width: 100%;
    }
    .pricing-table > ul {
      width: 100% !important;
    }
    .vc_separator h4 {
      white-space: normal !important;
      text-align: center;
    }
    .vc_separator .vc_sep_holder {
      width: 25% !important;
    }
    #bbpress-forums #bbp-search-form #bbp_search {
      width: 50% !important;
    }
    #bbpress-forums li.bbp-header ul li {
      display: none;
    }
    #bbpress-forums li.bbp-header li.bbp-forum-info,
    .bbp-arrow {
      display: block !important;
    }
    #etheme-popup div {
      min-width: 200px !important;
      width: 100% !important;
    }
    .modal-type-1 .mfp-close {
      top: -60px;
      right: -21px !important;
    }
    .modal-type-1 .modal-dialog {
      padding: 20px 10px;
      max-width: 100%;
    }
    .upb_video-src {
      height: 100% !important;
    }
    .content .home-video-section {
      padding: 0 !important;
    }
    .ultimate-map-wrapper {
      max-height: none !important;
    }
    .ultimate-map-wrapper div {
      max-height: none !important;
    }
    .upb_row_bg {
      background-position: center center !important;
    }
    .woocommerce-account.logged-in .sidebar {
      margin-bottom: 30px;
    }
    .mfp-arrow {
      margin-top: -23px !important;
    }
    .woocommerce-demo-store .demo_store {
      display: none;
    }
    body.woocommerce-demo-store {
      padding-top: 0 !important;
    }
    .flickr_badge_image {
      width: 71px !important;
      height: 71px !important;
    }
    .menu-social-icons.larger li {
      margin: 5px;
    }
    .menu-social-icons.larger li a {
      line-height: 30px;
    }
    .menu-social-icons.larger li a i {
      line-height: 30px;
      font-size: 18px;
    }
    .vc_separator.vc_separator_align_left .vc_sep_holder.vc_sep_holder_l {
      display: table-cell !important;
    }
    .transport-list {
      width: 100% !important;
    }
    .layout-horizontal .posts-slider .slide-item .post-news,
    .layout-horizontal .posts-slider .slide-item .caption {
      width: 100% !important;
      padding-left: 0 !important;
    }
    .food-banner.wpb_row {
      background-position: center center !important;
    }
    .food-banner.wpb_row h1 {
      font-size: 36px !important;
    }
    .travel-map {
      margin-bottom: 80px;
    }
    #back-top {
      display: none;
    }
    .green-btn .btn.border-white {
      margin-bottom: 10px;
    }
    .main-footer .pull-right,
    .main-footer .pull-left {
      width: 100%;
    }

    .main-footer .media .pull-right,
    .main-footer .media .pull-left,
    .main-footer .about-company .pull-left {
      width: auto;
    }
    .fixed-already .cart-bag {
      right: -5px;
    }
    .fixed-already .header-search {
      margin-right: 0;
    }
    .top-bar .languages-area {
      margin: 0 auto;
    }
    .top-bar .languages-area .languages,
    .top-bar .languages-area .currency {
      display: inline-block;
      float: none;
    }
    .header-search {
      margin-right: 16px;
    }
    .header-logo img {
      max-width: 170px;
      margin-left: -11px;
    }
    .cart-bag {
      right: 10px;
    }
    .et-search-trigger.search-dropdown form {
      width: 300px;
    }
    .et-search-trigger.search-dropdown form .modal-form .has-border > div {
      padding-right: 0;
    }
    .et-search-trigger.search-dropdown form .form-group.form-button .btn-black {
      margin-right: 0;
    }
    .shop-full-width .products-grid.row-count-2 .product,
    .shop-full-width .products-grid.row-count-3 .product,
    .shop-full-width .products-grid.row-count-4 .product,
    .shop-full-width .products-grid.row-count-5 .product,
    .shop-full-width .products-grid.row-count-6 .product {
      width: 100%;
    }
    .tabs .tabs-nav {
      text-align: center;
    }
    .tabs .tabs-nav li {
      float: none !important;
    }
    .tabs .tab-title.opened:after,
    .tabs .wpb_tabs_nav li.ui-state-active a:after {
      height: 35px;
    }
    .tabs .tab-title.opened:before {
      bottom: -6px;
    }
  }
  @media (max-width: 768px) {
    .checkout_coupon {
      display: inline-block;
      margin-bottom: 15px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 320px) {
    .header-logo {
      max-width: 170px !important;
    }
    .fixed-header-area .navbar-right .navbar-right {
      left: -2px !important;
    }
    .fixed-header-area .badge-number {
      display: none;
    }
    .fixed-header-area #st-trigger-effects {
      left: 15px !important;
    }
    .post-slide .btn_group {
      margin-top: -25px;
    }
    .post-slide .btn_group a {
      margin-bottom: 10px;
    }
    .coupon input[type="text"] {
      width: 100%;
      margin-bottom: 20px;
    }
    .coupon input[type="submit"] {
      width: 100%;
    }
    .mfp-close-btn-in .mfp-close {
      right: 13px;
    }
    .emodal {
      min-width: 230px !important;
    }
    .emodal img {
      display: none;
    }
    .emodal .emodal-text {
      width: 100% !important;
      text-align: center;
    }
    .emodal .emodal-text .btn {
      margin: 15px 0 0 !important;
      display: inline-block;
      float: none !important;
    }
    .contact-form .captcha-block input {
      width: 153px !important;
    }
    .contact-form .captcha-block + p {
      width: 100%;
    }
    .contact-form .captcha-block + p .button {
      float: left !important;
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .modal-type-1 .modal-dialog {
      max-width: 90% !important;
    }
    .modal-type-1 .modal-dialog .modal-form {
      margin-top: 20px;
    }
    .bag-total-table .btn {
      width: 100%;
    }
    .order-review {
      border: 1px solid #222;
    }
    .captcha-block input[type="text"] {
      width: 153px !important;
    }
    .et-register-form {
      margin-bottom: 20px;
    }
    .et-register-form .form-row.right {
      width: 100%;
    }
    .et-register-form .form-row.right .btn {
      width: 100%;
    }
    .et-search-trigger.search-dropdown form {
      right: -40px;
    }
    .et-search-trigger form:before {
      right: 43px;
    }
    .fixed-already .et-search-trigger.search-dropdown form {
      right: -34px;
    }
    .cart_totals #shipping_method li:last-child input,
    #order_review #shipping_method li:last-child input {
      display: block;
      position: relative;
      right: -10px;
      float: left;
    }
    .cart_totals #shipping_method li:last-child label,
    #order_review #shipping_method li:last-child label {
      display: block;
    }
  }

  @media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    /**
       * Remove all paddings around the image on small screen
       */
    .mfp-img-mobile .mfp-image-holder {
      padding-left: 0;
      padding-right: 0;
    }
    .mfp-img-mobile img.mfp-img {
      padding: 0;
    }
    .mfp-img-mobile .mfp-figure:after {
      top: 0;
      bottom: 0;
    }
    .mfp-img-mobile .mfp-figure small {
      display: inline;
      margin-left: 5px;
    }
    .mfp-img-mobile .mfp-bottom-bar {
      background: rgba(0, 0, 0, 0.6);
      bottom: 0;
      margin: 0;
      top: auto;
      padding: 3px 5px;
      position: fixed;
      box-sizing: border-box;
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0;
    }
    .mfp-img-mobile .mfp-counter {
      right: 5px;
      top: 3px;
    }
    .mfp-img-mobile .mfp-close {
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      line-height: 35px;
      background: rgba(0, 0, 0, 0.6);
      position: fixed;
      text-align: center;
      padding: 0;
    }
  }

  @media all and (max-width: 900px) {
    .mfp-arrow {
      -webkit-transform: scale(0.75);
      transform: scale(0.75);
    }
    .mfp-arrow-left {
      -webkit-transform-origin: 0;
      transform-origin: 0;
    }
    .mfp-arrow-right {
      -webkit-transform-origin: 100%;
      transform-origin: 100%;
    }
    .mfp-container {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
