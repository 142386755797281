.pinch-zoom-pan {
  border: 1px solid #e5e5e5;
  .zoom-button-container {
    position: absolute;
    left: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;

    > .pinch-pan-zoom-button:not(:last-child) {
      margin-bottom: 20px;
      @include breakpoint-desktop-first($sm-screen) {
        margin-bottom: 10px;
      }
    }

    .pinch-pan-zoom-button {
      cursor: pointer;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f5f5f5;
      box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.1);
      background: #FFF;
      @include transition($transition-duration $transition-timing);
      @include breakpoint-desktop-first($sm-screen) {
        width: 30px;
        height: 30px;
      }
    }
  }
}