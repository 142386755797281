$column-gap: .75rem;
$margin: .75rem;
$columns: 12;
$generic-size-system: (
  three-quarters: calc((3 / 4) * 100%),
  two-thirds: calc((2 / 3) * 100%),
  half: 50%,1
  one-third: calc((1 / 3) * 100%),
  one-quarter: calc((1 / 4) * 100%),
  full: 100%,
);
$twenty-percent-size-system: (
  one-fifth: 1,
  two-fifth: 2,
  three-fifth: 3,
  four-fifth: 4,
);
$arrow-size: 30px;

$gaps-size: (
  1: .75rem,
  2: 1rem,
  3: 1.25rem,
);

.column-container {
  position: relative;
  .arrow-wrapper {
    height: 100%;
    top: 0;
    margin: 0;
    position: absolute;
    z-index: 1;
    width: $arrow-size;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
  }
  .arrow {
    width: $arrow-size;
    height: $arrow-size;
    position: absolute;
    top: 50%;
    margin-top: -($arrow-size / 2);
  }
  @include breakpoint-desktop-first ($sm-screen) {
    .arrow-wrapper {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
      @include transition($transition-duration $transition-timing);
      &.hide {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }
  }
}

.column {
  display: flex;
  margin-bottom: calc(1.5rem - #{$margin});
  margin-left: -$margin;
  margin-right: -$margin;
  margin-top: -$margin;
  justify-content: baseline;
  align-items: center;

  &.is-multiline {
    flex-wrap: wrap;
  }

  &.is-centered {
    justify-content: center;
  }

  &.is-left {
    justify-content: flex-start;
  }

  &.is-right {
    justify-content: flex-end;
  }

  &.is-vertical-align-top {
    align-items: flex-start;
  }

  &.is-vertical-align-bottom {
    align-items: flex-end;
  }

  &.is-vertical-align-center {
    align-items: center;
  }

  &-item {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: $column-gap;

    @each $name, $size in $gaps-size {
      &.is-gaps-#{$name} {
        padding: $size;
      }
    }

    /* 12 columns system */
    @for $index from 1 through $columns {
      &.is-#{$index} {
        flex: none;
        width: calc((#{$index} * 100%) / #{$columns});
      }
    }

    /* generic columns system */
    @each $name, $size in $generic-size-system {
      &.is-#{$name} {
        flex: none;
        width: $size;
      }
    }

    /* 20% columns system */
    @each $name, $size in $twenty-percent-size-system {
      &.is-#{$name} {
        flex: none;
        width: calc(20% * #{$size});
      }
    }

    @include breakpoint-desktop-first ($sm-screen) {
      &.is-three-quarters {
        width: 100%;
      }
    }
  }

  /* tablet and mobile */
  @include breakpoint-desktop-first ($sm-screen) {
    // flex-direction: column;

    &.is-mobile-scrollable {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;

      .column-item {
        /* 12 columns system */
        @for $index from 1 through $columns {
          &.is-#{$index} {
            flex: none;
            width: 200px;
          }
        }

        /* 20% columns system */
        @each $name, $size in $twenty-percent-size-system {
          &.is-#{$name} {
            flex: none;
            width: 200px;
          }
        }
      }

    }

    &-item {
      /* 12 columns system */
      @for $index from 1 through $columns {
        &.is-#{$index} {
          flex: 1;
          width: initial;
        }
      }

      /* 20% columns system */
      @each $name, $size in $twenty-percent-size-system {
        &.is-#{$name} {
          flex: 1;
          width: initial;
        }
      }
    }
  }

}