$slide-item-perspective: 600px;

$slide-dimension: 200px;
$slide-dimension-ip-5: 150px;

$content-dimension: 80px;
$content-dimension-ip-5: 60px;
$content-dimension-ip-x: $content-dimension;

.cover-flow {
  .slider {
    display: flex;
    flex-direction: row;
    width: $slide-dimension;
    height: $slide-dimension;
    margin: 0 auto;

    .slide-container {
      display: flex;
      flex-direction: row;
    }

    .slide-item {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      perspective: $slide-item-perspective;
      
      img {
        width: 100%;
        height: 100%;
        box-shadow: $box-shadow;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $content-dimension;
    margin: calc(#{$padding-layout} * 2);
  }

  .content-item {
    text-align: center;
  }

  @include breakpoint ($ip-5) {
    .slider {
      width: $slide-dimension-ip-5;
      height: $slide-dimension-ip-5;
    }

    .content-container {
      height: $content-dimension-ip-5;
      margin: calc(#{$padding-layout});
    }
  }

  @include breakpoint($ip-6-x) {
    .slider {
      width: $slide-dimension;
      height: $slide-dimension;
    }

    .content-container {
      height: $content-dimension-ip-x;
      margin: calc(#{$padding-layout} * 2);
    }
  }
}