.building-selector {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  min-width: 270px;
  height: 100%;
  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    .logo {
      max-width: 170px;
      margin: 0 5px;
    }
    .logo-chevron {
      height: 100%;
      display: flex;
      align-items: center;
      transition: transform 0.1s ease;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
  .building-selector-list {
    display: flex;
    visibility: hidden;
    flex-direction: column;
    position: absolute;
    margin-top: 40px;
    left: 0;
    z-index: 101;
    width: 100%;
    background: white;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease-in-out, visibility 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    .list-container {
      display: block;
      margin: 0;
      width: 100%;

      .list {
        padding: 20px 0;
        display: flex;
        justify-content: center;
      }
    }

    &.is-active {
      visibility: visible;
      max-height: 500px;
      opacity: 1;
    }
  }
}
.backdrop {
  background: rgba(255, 255, 255, 0.1);
  z-index: 100;
  visibility: hidden;
  transition: background 0.3s ease;

  &.is-active {
    width: 999px;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    visibility: visible !important;
    position: absolute;
    transform: translateX(-200px);
    overflow-y: hidden;
    position: fixed;
  }
}
.list-separator {
  width: 100%;
  height: 1px;
}
