$search-bar-padding: 7px $padding-layout;
$search-input-height: $input-height;

.search-bar {
  margin-top: 16px;
  width: 100%;
  padding: $search-bar-padding;

  .text-head, .text-head span {
    margin-bottom: 16px;
    @include font-weight('black');
  }

  &-container {
    position: relative;
    overflow: hidden;

    &.has-icons-left {
      input[type=text] {
        padding-left: 1.75em;
      }
    }

    &.has-icons-right {
      input[type=text] {
        padding-right: $search-input-height;
      }
    }

    &.has-icons-left, &.has-icons-right {
      .input-icon {
        position: absolute;
        top: 0;
        height: 100%;
      }
    }

    .input-icon {
      align-items: center;
      display: inline-flex;
      justify-content: center;

      &.is-left {
        left: 0;
        font-size: $size-4;
      }

      &.is-right {
        right: 0;
        font-size: $size-5;
      }
    }

  }

    input[type=text] {
    padding: 0 16px;
    margin-bottom: 7px;
    width: 100%;
    font-size: $size-5;
    border: none;
  }

  .devided {
    border-bottom: 1px solid;
    position: relative;
    max-width: 90%;
    top: -8px;
    left: 35px;
    z-index: 99;
  }

}
