.contact {
  display: flex;
  > .contact-icon {
    flex: 0 0 40px;
    font-size: 24px;
  }
  > .contact-text {
    display: flex;
    flex-direction: column;
    > a:not(:last-child) {
      margin-bottom: 3px;
    }
    > a {
      font-size: $size-6;
      text-decoration: underline;
      @include font-weight('thin');
    }
  }
}
