.search-page {
  @include page-basis;

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 235px;

    & > img {
      width: 180px;
      height: 180px;
    }
  }

  &-button {
    font-size: $size-5;
  }

  /** for small mobile */
  @include breakpoint($ip-5) {
    &-header {
      height: 140px;

      &>img {
        width: 130px;
        height: 130px;
      }
    }
  }

  @include breakpoint($ip-6-x) {
    &-header {
      height: 235px;

      &>img {
        width: 180px;
        height: 180px;
      }
    }
  }
}