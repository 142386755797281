.entity-overview-card {
  position: relative;
  &.shop {
    max-width: 280px;
    width: 100%;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint-desktop-first ($sm-screen) {
      max-width: 100%;
    }
  }
  .text-shop {
    text-transform: uppercase;
    background-image: url("/images/bg-shop-non-logo.png");
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: $size-3;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h3 {
      width: 60%;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
    }
  }
  img.entity-banner {
    width: 100%;
    height: 100%;
    max-height: 150px;
    object-fit: cover;
  }
  div.logo-container {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 100px;
    left: 15px;
    z-index: 3;
  }
  div.entity-body {
    padding: 10px 25px;
    .entity-header {
      margin-bottom: 10px;
      .entity-title-block {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        > h2, > span {
          margin-bottom: 2px;
        }
      }
    }
    .entity-description {
      padding: 0 5px;
      position: relative;
      .entity-description-text {
        height: 34px;
        margin-bottom: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: 0.3s ease-out;
        &.extend {
          height: 180px;
        }
      }
      .button-view-more {
        position: absolute;
        bottom: -17px;
        left: 4px;
      }
    }
    .section-line {
      margin: 25px 0 10px;
      height: 1px;
      border: 0;
    }
    .entity-contact-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .entity-contact-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > span {
          margin-left: 5px;
        }
      }
    }
  }
}



