.paragraph-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: $size-4;
    margin-bottom: 5px;
    @include breakpoint-desktop-first($sm-screen) {
      text-align: left;
    }
  }
  .subtitle {
    text-transform: uppercase;
    font-size: $size-5;
  }
  @include breakpoint-desktop-first($sm-screen) {
    flex-direction: column;
    .m-order-1 {
      text-align: left;
      order: 1;
      margin-bottom: 30px;
    }
    .m-order-2 {
      order: 2;
    }
  }
}