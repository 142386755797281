/**
 * Screen width
 */
$lg-screen:  1200px; /** large device (desktop) */
$md-screen:  992px; /** medium device (desktop) */
$sm-screen:   768px; /** small device (tablet) */
$ip-5:       320px; /** iPhone 5/SE */
$ip-6-x:     375px; /** iPhone 6/6S/X */
$ip-plus:    414px; /** iPhone XS MAX/XR/end with Plus */

/**
 * Border
 */
$border-width:  1px;
$border-radius: 14px;
$card-border-radius: 0%;
$input-border-radius: 0px;
$border-style: solid;
$circle-border-radius:  100%;

$outline: none;

/**
 * font-size
 * see more (https://ivomynttinen.com/blog/ios-design-guidelines)
 */
$size-1:   3rem;
$size-2:   2.5rem;
$size-3:   2rem;
$size-4:   1.5rem;
$size-5:   1.25rem;
$size-6:   1rem;
$size-6_1:   0.9rem;
$size-7:   .75rem;
$size-8:   .6rem;
$size-9:   .5rem;

 /**
  * letter-spacing
  */
$title-letter-spacing: 0.075rem;

 /**
  * layout
  */
$padding-layout:          16px;
// $desktop-padding-layout:  29px;

/**
 * shadow
 */
$box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
$hover-box-shadow: 0 0 5px hsla(0, 0%, 0%, .3);

/*
 *
 */
$larger-header-height: 447px;

/*
 *
 */
$disabled-opacity: 0.3;

/*
 * Transition
 */
$transition-duration: .3s;
$transition-timing: ease-in-out;

/*
 * Colors
 */
$white: hsl(0, 0%, 100%);
$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$grey-lightest: hsl(0, 3%, 93%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-light: hsl(0, 0%, 71%);
$grey: hsl(0, 0%, 48%);
$black: hsl(0, 0%, 4%);
$red: hsl(0, 81%, 56%);
$green: hsl(98, 99%, 36%);
$orange: hsl(31, 92%, 54%);
