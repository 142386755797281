$avatar-box-shadow: $box-shadow;
$avatar-rounded-border: $border-radius;
$avatar-circle-border: 50%;

.avatar {
  box-shadow: $avatar-box-shadow;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  &.is-small {
    width: 30px;
    height: 30px;
  }

  &.is-medium {
    width: 60px;
    height: 60px;
  }

  &.is-large {
    width: 90px;
    height: 90px;
  }

  &.is-rounded {
    border-radius: $avatar-rounded-border;
  }

  &.is-circle {
    border-radius: $avatar-circle-border;
  }
}