.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $lightbox-z-index;
  height: 100%;
  width: 100%;

  &.is-open {
    display: block;
  }

  &.is-close {
    display: none;
  }

  &-container {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &-toolbar {
    position: absolute;
    z-index: $lightbox-toolbar-z-index;
    top: 0;
    left: 0;
    width: 100%;
    transition: visibility $transition-duration $transition-timing,
                opacity $transition-duration $transition-timing,
                transform $transition-duration $transition-timing;

    &.is-open {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    &.is-close {
      visibility: hidden;
      opacity: 0;
      transform: translateY(-30px);
    }

    &-container {
      display: flex;
      flex-direction: row;
      padding: $padding-layout;
      justify-content: space-between;
      align-items: center;
      font-size: $size-5;
    }

    &-title {
      text-transform: capitalize;
      font-size: $size-5;
    }

    &-sub-title {
      font-size: $size-7;
    }

    .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .lightbox-slider {
      display: flex;
    }

    .lightbox-image {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .left-arrow-container, .right-arrow-container {
    display: none;
  }

  /** desktop */
  @include breakpoint ($md-screen) {
    .lightbox-body {
      .lightbox-image {
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
          pointer-events: none;
          width: auto;
        }
      }
    }

    .close-button {
      cursor: pointer;
      transition: opacity $transition-duration $transition-timing;
      opacity: .7;

      &:hover {
        opacity: 1;
      }
    }

    .left-arrow-container, .right-arrow-container {
      display: flex;
      align-items: center;
      position: absolute;
      z-index: $lightbox-toolbar-z-index;
      top: 0;
      height: 100%;
      width: 70px;
      font-size: $size-6;
    }

    .left-arrow-container {
      left: 0;
      justify-content: flex-end;
    }
    
    .right-arrow-container {
      right: 0;
      justify-content: flex-start;
    }

    .next-button, .prev-button {
      cursor: pointer;
      transition: opacity $transition-duration $transition-timing;
      opacity: .7;

      &:hover {
        opacity: 1;
      }

      &.is-disabled {
        cursor: not-allowed;
        opacity: .1;
      }
    }
  }
}