$shop-nearby-card-cursor: pointer;
$shop-nearby-card-box-shadow: $box-shadow;
$shop-nearby-card-box-shadow-hover: $hover-box-shadow;

$shop-nearby-card-box-image-small: 20px;
$shop-nearby-card-box-image-normal: 50px;
$shop-nearby-card-box-image-medium: 60px;
$shop-nearby-card-box-image-size: ('small': 30px, 'normal': $shop-nearby-card-box-image-normal, 'medium': $shop-nearby-card-box-image-medium, 'large': 90px);

.shop-nearby-card {
  width: 350px;
  padding: $padding-layout;
  // padding-right: calc(#{$padding-layout} * 3);
  position: relative;
  box-shadow: $shop-nearby-card-box-shadow;
  cursor: $shop-nearby-card-cursor;
  transition: box-shadow $transition-duration $transition-timing;
  background: linear-gradient(to right, $grey-lightest, $white);

  @include breakpoint-desktop-first ($sm-screen) {
    width: 300px;
  }

  &:hover {
    box-shadow: $shop-nearby-card-box-shadow-hover;
    color: $white;
  }

  &-icon {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
  }

  .icon {
    opacity: 0.5;
    width: $shop-nearby-card-box-image-normal;
    height: $shop-nearby-card-box-image-normal;
    @include breakpoint-desktop-first ($sm-screen) {
      width: $shop-nearby-card-box-image-small;
      height: $shop-nearby-card-box-image-small;
    }
  }

  &.is-clickable {
    transition: $transition-duration $transition-timing;
  }

  &.is-clickable:hover {
    .icon {
      // color: $white;
      opacity: 1;
    }
  }

  &-control {
    display: flex;
    flex-direction: row;
  }

  &-content {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 50%;
  }

  .title {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-title {
    opacity: 0.6;
    font-size: $size-6;
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &-img {
    border-radius: $input-border-radius;
    box-shadow: $shop-nearby-card-box-shadow;
    height: $shop-nearby-card-box-image-medium;
    width: $shop-nearby-card-box-image-medium;
    overflow: hidden;
    margin-right: $padding-layout;
    flex: 0 0 60px;

    @each $name, $size in $shop-nearby-card-box-image-size {
      &.is-#{$name} {
        height: $size !important;
        width: $size !important;
      }
    }
  }

  &-floor {
    padding-top: 7px;
  }

}