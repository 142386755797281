$logo-dimension: 70px;

.get-direction-logo {
  margin: 0 auto;
  height: $logo-dimension;
  width: $logo-dimension;

  img {
    width: 100%;
    height: 100%;
  }
}