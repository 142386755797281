$rounded-border-radius: 5px;
$box-shadow: 0 2px 0 rgba(0, 0, 0, 0.15);

.shop-direction-hero-card {
  width: 300px;
  height: 100px;
  border: 1px solid #e5e5e5;
  border-radius: $rounded-border-radius;
  display: flex;
  box-shadow: $box-shadow;
  .left {
    padding: 0.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 80px;
    }
  }

  .right {
    width: 200px;
    height: 100%;
    padding: 0.5rem;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .shop-name {
      font-size: $size-5;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .shop-category {
      text-transform: uppercase;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .shop-text {
      font-size: $size-6;
      text-transform: uppercase;
    }

    .direction-button {
      text-transform: uppercase;
      border-radius: 6px;
      margin-top: 0.5rem;
      height: 2rem;
    }
  }
}

@media only screen and (max-width: 500px) {
  .shop-direction-hero-card {
    width: 150px;
    height: 60px;
    margin-bottom: 10px;
    .left {
      width: 60px;
      padding: 0.2rem;
      img {
        width: 50px;
      }
    }
    .right {
      width: 90px;
      line-height: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .shop-name {
        font-size: $size-6_1;
        margin-bottom: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .shop-category {
        font-size: $size-8;
      }
    }
  }
}
