$drawer-content-width: 325px;
$drawer-large-content-width: 400px;

.drawer {
  position: fixed;
  top: 0;
  z-index: $drawer-z-index;
  width: $drawer-content-width;
  height: 100vh;
  box-shadow: $box-shadow;
  transition: transform $transition-duration $transition-timing;

  &.is-left {
    left: 0;

    .drawer-toggle {
      left: 100%;
    }
  }

  &.is-right {
    right: 0;

    .drawer-toggle {
      right: 100%;
    }
  }

  &.is-open {
    transform: translateX(0);

    .drawer-toggle-icon {
      transform: rotate(0);
    }
  }

  &.is-close {
    transform: translateX(100%);

    .drawer-toggle-icon {
      transform: rotate(180deg);
    }
  }

  &-backdrop {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .drawer-header {
      width: 100%;
      padding: $padding-layout;

      &.is-position-fixed {
        position: fixed;
        z-index: $bring-to-front;
      }
    }

    .drawer-body {
      flex: 1;
    }

    .drawer-footer {
      padding: 30px;
    }
  }

  &-toggle {
    position: absolute;
    top: 12rem;
    width: 30px;
    height: 65px;
    cursor: pointer;
    box-shadow: $box-shadow;
    border-radius: 12px 0 0 12px;

    &-icon {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-size: $size-6;
      transition: transform $transition-duration $transition-timing;
    }
  }

  .tag-container {
    position: absolute;
    right: 150%;
    top: 1rem;
    opacity: 1;
    @include transition($transition-duration $transition-timing);
    &.hidden {
      opacity: 0;
    }
  }

  @include breakpoint ($lg-screen) {
    width: $drawer-large-content-width;
  }
}