$bottom-drawer-border-radius: $padding-layout $padding-layout 0 0;
// $bottom-drawer-height: 247px;
$bottom-drawer-height: 107px;
$pinch-bar-container-height: 30px;
$pinch-bar-height: 5px;

.bottom-drawer {
  position: fixed;
  top: calc(100% - 20px);
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $drawer-z-index;

  &-container {
    position: relative;
    border-radius: $bottom-drawer-border-radius;
    height: $bottom-drawer-height;
    box-shadow: $box-shadow;

    .bottom-drawer-content-collapse {
      height: 60px;
    }
  }

  &-content {
  }

  &-widget {
    position: absolute;
    bottom: calc(100% + #{$padding-layout});
    right: calc(#{$padding-layout});
  }

  .pinch-bar {
    display: flex;
    height: $pinch-bar-container-height;
    width: 100%;

    &:after {
      content: '';
      margin: 5px auto;
      border-radius: calc(#{$pinch-bar-height} / 2);
      height: $pinch-bar-height;
      width: 40px;
    }
  }
}