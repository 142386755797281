.privacy-policy {
  position: fixed;
  padding: 12px 6px;
  visibility: hidden;
  opacity: 0;
  transition: opacity $transition-duration $transition-timing;
  z-index: $lightbox-z-index;
  &.fixed-bottom {
    bottom: 0;
  }
  &.show {
    visibility: visible;
    opacity: 1;
  }
  &-text {
    text-align: center;
    margin-bottom: 5px;
    line-height: initial;
    font-size: $size-5;
  }
  > button {
    margin: 0 auto;
  }

  @media only screen and (max-device-width: 320px) {
    &-text {
      font-size: $size-5;
    }
  }
}