$logo-dimension: 70px;
$logo-dimension-ip-5: 40px;
$logo-dimension-ip-x: $logo-dimension;

.home-page {
  @include page-basis;
  justify-content: space-around;

  .logo-section {
    text-align: center;

    .logo {
      margin-bottom: $padding-layout;
      width: $logo-dimension;
      height: $logo-dimension;
    }

    .brand-name {
      font-size: $size-3;
    }
  }

  .cover-flow-title {
    font-size: $size-2;
  }

  .cover-flow-sub-title {
    font-size: $size-3;
  }

  @include breakpoint ($ip-5) {
    .logo-section {
      .logo {
        width: $logo-dimension-ip-5;
        height: $logo-dimension-ip-5;
      }

      .brand-name {
        font-size: $size-3;
      }
    }

    .cover-flow-title {
      font-size: $size-3;
    }

    .cover-flow-sub-title {
      font-size: $size-4;
    }
  }

  @include breakpoint($ip-6-x) {
    .logo-section {
      .logo {
        width: $logo-dimension-ip-x;
        height: $logo-dimension-ip-x;
      }

      .brand-name {
        font-size: $size-4;
      }
    }

    .cover-flow-title {
      font-size: $size-3;
    }

    .cover-flow-sub-title {
      font-size: $size-4;
    }
  }
}