$navbar-max-height: 3.25em;
$navbar-item-padding: $padding-layout;
$navbar-dropdown-box-shadow: $box-shadow;

.navbar {
  position: relative;
  width: 100%;
  padding: .5rem 0;

  & > .container, & > .container-fluid {
    display: flex;
    align-items: stretch;
    min-height: $navbar-max-height;
  }
  
  &-brand {
    display: flex;
    align-items: stretch;
    flex-shrink: 0;
    margin-right: 0;

    &.is-small {
      img {
        height: 30px;
      }
    }

    &.is-medium {
      img {
        height: 70px;
      }
    }

    &.is-large {
      height: 90px;
    }

    img {
      height: 50px;
      width: auto;
    }
  }

  &-burger {
    display: none;
    margin-left: auto;
    height: 3.25rem;
    width: 3.25rem;

    span {
      position: relative;
      display: block;
      height: 2px;
      width: 20px;
      background-color: currentColor;
      border-radius: $input-border-radius;

      &:before, &:after {
        content: '';
        position: absolute;
        display: block;
        height: 2px;
        width: 20px;
        background-color: currentColor;
        border-radius: $input-border-radius;
      }

      &:before {
        bottom: 6px;
      }

      &:after {
        top: 6px;
      }
    }
  }

  &-menu {
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 0;
  }

  &-start, &-end {
    display: flex;
    align-items: center;
  }

  &-start {
    justify-content: flex-start;
    margin-right: auto;

    .navbar-dropdown {
      left: 0;
    }
  }

  &-end {
    justify-content: flex-end;
    margin-left: auto;

    .navbar-dropdown {
      right: 0;
    }
  }

  &-item {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    padding: $navbar-item-padding;
    height: 100%;
    font-size: $size-5;



    &:link {
      text-decoration: none;
    }

    // &-icon {
    //   margin-right: 1rem; 
    // } not evenlly Header / features/header-spacing

    &.has-dropdown {
      cursor: pointer;
      position: relative;
    }
  }

  &-dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    min-width: 100%;
    box-shadow: $navbar-dropdown-box-shadow;
    padding: .5rem 0;
    z-index: $dropdown-z-index;
    border-radius: 6px;
    transition: 
      visibility $transition-duration $transition-timing,
      opacity $transition-duration $transition-timing,
      max-height $transition-duration $transition-timing;

    &.is-active {
      visibility: visible;
      opacity: 1;
    }

    .navbar-item {
      padding: .375rem 1rem;
      white-space: nowrap;

      &:hover {
        transition: background $transition-duration $transition-timing;
      }
    }
  }

  /** tablet and mobile */
  @include breakpoint-desktop-first ($sm-screen) {
    &-brand {
      flex: 1;
      align-items: center;
      .navbar-item {
        padding: 0;
        height: auto;
      }
    }
    
    &-burger {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-menu {
      display: flex;
      visibility: hidden;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: $dropdown-z-index;
      width: 100%;
      background: white;
      box-shadow: $navbar-dropdown-box-shadow;
      max-height: 0;
      opacity: 0;
      transition: 
        max-height $transition-duration $transition-timing,
        visibility $transition-duration $transition-timing,
        opacity $transition-duration $transition-timing;

      &.is-active {
        visibility: visible;
        max-height: 500px;
        opacity: 1;
      }
    }

    &-item {
      align-items: flex-start;

      &.has-dropdown {
        flex-direction: column;
      }
    }

    &-start, &-end {
      display: block;
      margin: 0;
    }
    
    &-dropdown {
      position: static;
      box-shadow: none;
      max-height: 0;

      &.is-active {
        max-height: 100px;
      }
    }
  }
}