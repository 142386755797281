$colors: (
  white: $white, 
  white-ter: $white-ter,
  white-bis: $white-bis,
  grey-lighter: $grey-lighter,
  grey-light: $grey-light,
  grey: $grey,
  black: $black,
);

@each $name, $color in $colors {
  .has-color-#{$name} {
    color: $color;
  }
}