.not-found-page {
  @include page-basis;

  .icon-container {
    width: 100%;
    
    & > svg {
      display: block;
      margin: 0 auto;
      width: 200px;
      height: 200px;
    }
  }

  .title-container {
    text-align: center;

    .title {
      margin-bottom: 2rem;
    }

    .sub-title {
      font-weight: normal;
      line-height: 1.2;
    }
  }

  @include breakpoint ($ip-5) {
    .icon-container {
      & > svg {
        width: 150px;
        height: 150px;
      }
    }
  }

  @include breakpoint ($ip-6-x) {
    .icon-container {
      &>svg {
        width: 200px;
        height: 200px;
      }
    }
  }
}