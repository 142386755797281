.wayfinder-desktop-page {
  overflow: auto;

  .container-control {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - (28px * 2) - 87px);
  }

  .origin-destination-panel {
    display: flex;
    margin: 15px auto;
    .shop-card-wrapper {
      > .label {
        color: #000;
        font-size: $size-6;
        margin-bottom: 15px;
        text-transform: uppercase;
      }
    }
    .icon-wrapper {
      display: flex;
      margin: 0 20px;
      justify-content: center;
      align-items: center;
      .icon-swap {
        font-size: 24px;
      }
    }
  }

  .content-container {
    box-shadow: $box-shadow;
  }

  .floor-level-container {
    position: relative;
    .you-are-here-tag {
      position: absolute;
      z-index: $bring-to-front;
      right: 0;
      transform: translateX(100%);
    }
  }

  .navigation {
    flex: 1;
  }

  .location-separator-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    padding: $padding-layout;

    &-icon {
      height: 60px;
      width: 60px;
      > svg {
        width: 100%;
        height: 100%;
      }
    }

    &-icon {
      margin: 16px;
      margin-top: 0;
    }

    & > p {
      text-align: center;
      font-size: $size-6;
      line-height: $size-5;
    }
  }

  .details-tab-container {
    padding: 0 $padding-layout;

    .details-tab {
      font-size: $size-6;
    }
  }

  .no-description {
    font-size: $size-6;
  }

  .floor-list {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .autocomplete-dropdown-item {
    transition: background $transition-duration $transition-timing;
    // border-radius: 4px;
    padding: 6px $padding-layout;
  }

  .separator {
    margin: 2px 0;
    height: 1px;
  }

  .active-step {
    .floor-item {
      margin: 0;
    }
  }
}
