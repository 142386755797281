.shop-detail {
  &-header {
    padding: $padding-layout;
    border-top: 1px solid;
  }

  .head-content {
    display: flex;
    flex-wrap: wrap;

    .left {
      flex: 0 0 85%;
      max-width: 85%;
    }

    .right {
      flex: 0 0 15%;
      max-width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .header-content-block {
    text-transform: capitalize;
  }

  .icon-description {
    display: flex;
    padding: 20px;
    margin-left: -5px;
    .description {
      padding-left: 20px;
    }
  }
}
