$font-sizes: $size-1, $size-2, $size-3, $size-4, $size-5, $size-6, $size-7;

@each $font-size in $font-sizes {
  $index: index($font-sizes, $font-size);
  .is-size-#{$index} {
    font-size: $font-size;
    @include breakpoint-desktop-first ($sm-screen) {
      font-size: $font-size * 0.75;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
b,
i,
strong {
  // line-height: 1;
  font-weight: normal;
  font-style: normal;
}

p,
a,
b,
i,
strong {
  font-size: 0.9rem;
}
