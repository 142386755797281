$tab-height: 50px;

.tab-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .tab {
    display: flex;
    width: 100%;
    height: $tab-height;

    .tab-pane {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      position: relative;
      font-size: $size-6;
      outline: none;
      transition: color $transition-duration $transition-timing;
      text-transform: uppercase;
      cursor: pointer;

      border-left-color: transparent;
      border-top: 0.1px solid;
      border-bottom: 0.1px solid;

      &:not(:first-child) {
        border-left: 0.1px solid;
      }

      &-indicator {
        transition: opacity $transition-duration $transition-timing;
        height: 4px;
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0;
      }

      &.active {
        border-color: #e60621;
        .tab-pane-indicator {
          opacity: 1;
        }
      }

      &.is-disabled {
        cursor: not-allowed;
        color: #efefef;
        outline: none;
      }
    }
  }

  .tab-content-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    .tab-content {
      width: 100%;
      flex: 1;
      padding: $padding-layout 0;
      line-height: 1.05;
      max-height: 200px;
      overflow-y: scroll;

      &.is-disabled {
        display: none;
      }
    }
  }
}
