.floor-item {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 10px auto;
  padding: $padding-layout;
  box-shadow: $box-shadow;
  border-radius: $input-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  filter: grayscale(1);

  @include breakpoint-desktop-first ($sm-screen) {
    width: 60px;
    height: 60px;
  }

  img {
    @include transition($transition-duration $transition-timing);
  }

  &.active {
    filter: none;
  }
  &.mbk {
    padding: $padding-layout;
    box-shadow: $box-shadow;
  }
  &.fashionisland {
    padding: 0;
    border-radius: $circle-border-radius;
    box-shadow: none;
  }
  &.101-the-third-place {

  }
}