@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
#thepromenade {
	header {
    padding: 0 !important;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1010;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    &:before {
      content: none;
    }
    a {
      color: #565454;
    }
    #nav {
      padding: 0 !important;
    }
    .navbar {
      background-color: #FFF !important;
      background: none;
      min-height: initial;
      .navbar-header {
        display: flex;
        .navbar-toggle {
          position: relative !important;
          z-index: 999;
          top: 0;
          float: left;
          margin-left: 25px;
          margin-top: 8px;
          margin-bottom: 8px;
        }
        .brand {
          padding: 15px;
        }
      }
      .navbar-collapse {
        display: flex;
        align-items: flex-start;
        ul {
          display: flex;
          margin: 0 !important;
          li > a {
            font-size: 12px;
            position: relative;
            display: block;
            padding: 15px;
            line-height: 20px;
            &:hover {
              color: #000;
            }
          }
        }
        @media (max-width: 768px) {
          ul {
            flex-direction: column;
            li > a {
              padding: 10px 15px;
            }
          }
        }
        @media (max-width: 575.98px) {
          flex-direction: column;
        }
      }
      .collapse.navbar-collapse {
        @media (max-width: 768px) {
          display: none !important;
        }
      }
      .languages {
        cursor: pointer;
        .language {
          position: relative !important;
          left: -5px !important;
          height: 34px;
          width: 44px;
          text-align: start;
          padding: 0 5px;
        }
        .language2 {
          position: relative !important;
          font-size: 16px;
          height: 34px;
          width: 44px;
          background-color: #DD6E7A;
          color: white;
          border-radius: 6px;
          border: none;
          text-align: start;
          padding: 0 5px;
        }
        &:after {
          position: relative;
          top: -17px;
          left: 30px;
          content: "\f107";
          font-family: FontAwesome;
          color: #FFF;
          font-size: 16px;
          @media (max-width: 768px) {
            top: -12px;
            left: 25px;
          }
        }
      }
    }
  }
  form {
    .input-sm {
      border-radius: 3px;
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
    }
    .btn-default {
      height: 35px;
      border-radius: 4px;
      padding: 6px 12px;
    }
  }
  .app-content {
    margin-top: 130px;
    .app-card {
      border-radius: 5px;
      background: rgb(241,241,241);
      background: linear-gradient(90deg, rgba(241,241,241,1) 30%, rgba(255,255,255,0.5) 100%);
      .category-icon {
        background-color: #fff;
        border-radius: 50%;
        justify-content: center;
        width: 55px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        > img {
          width: 100%;
          height: 35px;
        }
      }
      .category-title {
        padding: 0 4vmin;
      }
    }
   
    @media (max-width: 768px) {
      margin-top: 100px;
    }
  }
  footer {
    padding: 0;
  }
// #customise #start_preloader {
// 	 position: fixed;
// 	 top: 0;
// 	 left: 0;
// 	 right: 0;
// 	 bottom: 0;
// 	 background-color: #000;
// 	 z-index: 1000;
// }

//  #customise #preloader {
// 	 background-size: cover;
// 	 background-position: center center;
// 	 background-repeat: no-repeat;
// 	 background-image: url(http://placehold.it/1280x850);
// }
//  #customise #qLpercentage {
// 	 display: none;
// }
//  #customise #coupletitle {
// 	 position: absolute;
// 	 top: 50%;
// 	 left: 0;
// 	 right: 0;
// 	 height: 120px;
// 	 margin-top: -60px;
// }
//  #customise #loader_heart {
// 	 font-size: 38px;
// 	 line-height: 70px;
// 	 color: #00c0b6;
// }
//  #customise #hisname {
// 	 font-family: 'Montserrat', sans-serif;
// 	 font-size: 38px;
// 	 font-weight: bold;
// 	 color: #fff;
// 	 line-height: 28px;
// }
//  #customise #hername {
// 	 font-family: 'Montserrat', sans-serif;
// 	 font-size: 38px;
// 	 font-weight: bold;
// 	 color: #00c0b6;
// 	 line-height: 28px;
// }
//  #customise .animated_heart {
// 	 -webkit-animation-duration: 1s;
// 	 -webkit-animation-name: flipYloop;
// 	 -webkit-animation-iteration-count: infinite;
// 	 -webkit-animation-delay: 2s;
// 	 -webkit-animation-direction: alternate;
// 	 animation-duration: 1s;
// 	 animation-name: flipYloop;
// 	 animation-iteration-count: infinite;
// 	 animation-delay: 2s;
// 	 animation-direction: alternate;
// }
//  @-webkit-keyframes flipYloop {
// 	 from {
// 		 -webkit-transform: rotateY(0deg);
// 	}
// 	 to {
// 		 -webkit-transform: rotateY(180deg);
// 	}
// }
//  @keyframes flipYloop {
// 	 from {
// 		 transform: rotateY(0deg);
// 	}
// 	 to {
// 		 transform: rotateY(180deg);
// 	}
// }
//  #customise body {
// 	 font-family: 'Montserrat', sans-serif;
// 	 color: #747474;
// }
//  #customise section {
// 	 background-color: #fff;
// }
//  #customise h1, #customise h2, #customise h3, #customise h4, #customise h5, #customise h6 {
// 	 font-family: 'Montserrat', sans-serif;
// 	 font-weight: bold;
// 	 color: #444;
// }
//  #customise h1 a, #customise h2 a, #customise h3 a, #customise h4 a, #customise h5 a, #customise h6 a {
// 	 color: #444;
// }
//  #customise h1 a:hover, #customise h2 a:hover, #customise h3 a:hover, #customise h4 a:hover, #customise h5 a:hover, #customise h6 a:hover {
// 	 color: #00c0b6;
// }
//  #customise h2 {
// 	 font-size: 54px;
// }
//  #customise h3 {
// 	 font-size: 30px;
// }
//  #customise p {
// 	 font-size: 14px;
// 	 line-height: 24px;
// }
//  #customise p a {
// 	 font-weight: bold;
// 	 color: #00c0b6;
// }
//  #customise p a:hover {
// 	 color: #444;
// }
//  #customise a, #customise a:hover {
// 	 text-decoration: none;
// }
//  #customise a:focus {
// 	 outline: 0;
// }
//  #customise .padding0 {
// 	 padding: 0;
// }
//  #customise .margin0 {
// 	 margin: 0;
// }
//  #customise .marginbottom50 {
// 	 margin-bottom: 50px;
// }
//  #customise .the_title {
// 	 margin-top: 140px;
// 	 margin-bottom: 40px;
// }
//  #customise .sub_title {
// 	 font-family: 'Montserrat', sans-serif;
// 	 font-size: 28px;
// 	 color: #8b8a8a;
// 	 line-height: 20px;
// 	 letter-spacing: 2px;
// 	 display: block;
// }
//  #customise .intro_heart {
// 	 position: absolute;
// 	 top: -15px;
// 	 left: 50%;
// 	 margin-left: -25px;
// 	 color: #00c0b6;
// 	 font-size: 35px;
// }
//  #customise .intro {
// 	 font-size: 20px;
// 	 padding: 35px 85px;
// 	 display: block;
// 	 border-bottom: 1px solid #9e9d9d;
// 	 border-top: 1px solid #9e9d9d;
// 	 margin-bottom: 60px;
// }
//  #customise .photo_divider {
// 	 height: 375px;
// 	 width: 100%;
// 	 background-size: cover;
// 	 background-attachment: fixed;
// 	 background-position: center center;
// 	 background-repeat: no-repeat;
// 	 margin-top: 140px;
// }
//  #customise .photo_divider_ie11 {
// 	 background-attachment: scroll !important;
// }
//  #customise #photo_divider1 {
// 	 background-image: url(http://placehold.it/1366x804);
// }
//  #customise #photo_divider2 {
// 	 background-image: url(http://placehold.it/1366x804);
// 	 background-position: top center;
// }
//  #customise #photo_divider3 {
// 	 background-image: url(http://placehold.it/1366x804);
// 	 background-position: top center;
// }
//  #customise #photo_divider4 {
// 	 background-image: url(http://placehold.it/1366x804);
// }
//  #customise #photo_divider5 {
// 	 background-image: url(http://placehold.it/1366x804);
// }
//  #customise #photo_divider6 {
// 	 background-image: url(http://placehold.it/1366x804);
// 	 background-position: bottom center;
// }
//  #customise #photo_divider7 {
// 	 background-image: url(http://placehold.it/1366x804);
// }
//  #customise #photo_divider8 {
// 	 background-image: url(http://placehold.it/1366x804);
// }
//  #customise #photo_divider9 {
// 	 background-image: url(http://placehold.it/1366x804);
// }
//  #customise .btn-default {
// 	 background-color: #00c0b6 !important;
// 	 color: #fff !important;
// 	 border-radius: 2 !important;
// 	 border-color: #01b7ad !important;
// }
//  #customise .btn-default:hover {
// 	 background-color: #000 !important;
// 	 border-color: #000 !important;
// }
//  #customise .photo_item {
// 	 position: relative;
// 	 overflow: hidden;
// }
//  #customise .photo_item_preview > a img {
// 	 display: block;
// 	 width: 100%;
// 	 -webkit-transition: -webkit-transform 0.5s;
// 	 -ms-transition: -ms-transform 0.5s;
// 	 -o-transition: -o-transform 0.5s;
// 	 transition: transform 0.5s;
// }
//  #customise .photo_item_overlay {
// 	 position: absolute;
// 	 top: 0;
// 	 right: 0;
// 	 bottom: 0;
// 	 left: 0;
// 	 background-color: rgba(0, 192, 182, 0.5);
// 	 opacity: 0;
// 	 -webkit-transform: scale(0);
// 	 -ms-transform: scale(0);
// 	 -o-transform: scale(0);
// 	 transform: scale(0);
// 	 -webkit-transition: -webkit-transform 0.5s;
// 	 -ms-transition: -ms-transform 0.5s;
// 	 -o-transition: -o-transform 0.5s;
// 	 transition: transform 0.5s;
// }
//  #customise .photo_item_overlay a {
// 	 position: absolute;
// 	 top: 50%;
// 	 left: 50%;
// 	 margin-top: -30px;
// 	 margin-left: -30px;
// }
//  #customise .photo_item:hover .photo_item_overlay {
// 	 opacity: 1;
// 	 -webkit-transform: scale(1);
// 	 -ms-transform: scale(1);
// 	 -o-transform: scale(1);
// 	 transform: scale(1);
// }
//  #customise .photo_item:hover .photo_item_preview > a img {
// 	 -webkit-transform: scale(1.1);
// 	 -ms-transform: scale(1.1);
// 	 -o-transform: scale(1.1);
// 	 transform: scale(1.1);
// }
//  #customise .icon_background {
// 	 display: block;
// 	 background: rgba(0, 0, 0, 0);
// 	 height: 60px;
// 	 width: 60px;
// 	 padding: 13px;
// 	 -webkit-border-radius: 150px;
// 	 -moz-border-radius: 150px;
// 	 border-radius: 150px;
// 	 -webkit-transform: scale(0);
// 	 -ms-transform: scale(0);
// 	 -o-transform: scale(0);
// 	 transform: scale(0);
// 	 -webkit-transition: 0.3s all ease;
// 	 -moz-transition: 0.3s all ease;
// 	 -o-transition: 0.3s all ease;
// 	 -ms-transition: 0.3s all ease;
// 	 transition: 0.3s all ease;
// }
//  #customise .icon_button {
// 	 position: absolute;
// 	 padding: 13px;
// 	 font-size: 30px;
// 	 color: #fff;
// }
//  #customise .photo_item_overlay a:hover .icon_background {
// 	 background: rgba(0, 0, 0, 0.7);
// 	 -webkit-transform: scale(1.1);
// 	 -ms-transform: scale(1.1);
// 	 -o-transform: scale(1.1);
// 	 transform: scale(1.1);
// }
//  #customise .photo_caption_wrapper {
// 	 position: absolute;
// 	 bottom: 0;
// 	 display: block;
// 	 width: 100%;
// 	 height: 30%;
// }
//  #customise .photo_caption {
// 	 color: #fff;
// 	 font-size: 18px;
// 	 font-family: 'Montserrat', sans-serif;
// 	 letter-spacing: 3px;
// 	 text-align: center;
// 	 text-decoration: none;
// 	 display: block;
// }
//  #customise #home {
// 	 background-color: transparent !important;
// 	 padding: 0 !important;
// }
//  #customise #logo {
// 	 font-family: 'Montserrat', sans-serif;
// 	 display: block;
// 	 background-color: #000;
// 	 font-size: 20px;
// 	 letter-spacing: 2px;
// 	 color: #fff;
// 	 width: 90px;
// 	 height: 80px;
// 	 padding-top: 25px;
// 	 text-align: center;
// 	 z-index: 999;
// }
//  #customise #logo_link {
// 	 text-decoration: none;
// }
//  #customise #logo_link:focus {
// 	 outline: 0;
// }
//  #customise #slide_content {
// 	 margin: 310px 0 90px 0;
// 	 font-family: 'Montserrat', sans-serif;
// }
//  #customise #slide_text {
// 	 color: #fff;
// }
//  #customise #slide_text1 {
// 	 font-size: 48px;
// 	 line-height: 45px;
// 	 font-weight: bold;
// }
//  #customise #slide_text2 {
// 	 font-size: 38px;
// 	 line-height: 35px;
// 	 letter-spacing: 3px;
// }
//  #customise #slide_text3 {
// 	 font-size: 40px;
// 	 line-height: 60px;
// 	 font-weight: bold;
// 	 border-bottom: solid 3px #fff;
// 	 border-top: solid 3px #fff;
// }
//  #customise #slide_text4 {
// 	 font-size: 42px;
// 	 font-weight: bold;
// }
//  #customise #slide_text4 span {
// 	 font-size: 12px;
// 	 padding: 0 8px;
// }
//  #customise .heart_row {
// 	 color: #00c0b6;
// 	 margin: 0;
// }
//  #customise .heart_row span {
// 	 padding: 0 3.7px;
// }
//  #customise #nav {
// 	 font-family: 'Montserrat', sans-serif;
// 	 font-size: 12px;
// 	 text-transform: uppercase;
// 	 z-index: 998;
// 	 width: 100%;
// 	 box-shadow: rgba(239, 239, 239, 0.32) 0px 5px 10px -1px;
// 	 background-color: #fff;
// }
//  #customise #nav {
// 	/*padding:15px 0;
// 	*/
// 	 -webkit-transition: 0.3s all ease;
// 	 -moz-transition: 0.3s all ease;
// 	 -o-transition: 0.3s all ease;
// 	 -ms-transition: 0.3s all ease;
// 	 transition: 0.3s all ease;
// }
//  #customise .is-sticky #nav {
// 	/*padding:15px 0;
// 	*/
// 	 -webkit-transition: 0.3s all ease;
// 	 -moz-transition: 0.3s all ease;
// 	 -o-transition: 0.3s all ease;
// 	 -ms-transition: 0.3s all ease;
// 	 transition: 0.3s all ease;
// }
//  #customise .navbar {
// 	 border-radius: 0;
// 	 margin-bottom: 0;
// }
//  #customise .navbar-default {
// 	 border: none;
// }
//  #customise .navbar-default .navbar-nav > li > a:focus {
// 	 outline: 0;
// }
//  #customise .navbar-default {
// 	 background-color: #fff;
// 	 border-color: #e7e7e7;
// }
//  #customise .navbar-default .navbar-brand {
// 	 color: #fff;
// }
//  #customise .navbar-default .navbar-brand:hover, #customise .navbar-default .navbar-brand:focus {
// 	 color: #5e5e5e;
// }
//  #customise .navbar-default .navbar-nav {
// 	/*background-color: #FFFFFF;
// 	*/
// }
//  #customise .navbar-default .navbar-nav > li > a {
// 	 color: #565454;
// 	 -webkit-transition: 0.3s all ease;
// 	 -moz-transition: 0.3s all ease;
// 	 -o-transition: 0.3s all ease;
// 	 -ms-transition: 0.3s all ease;
// 	 transition: 0.3s all ease;
// }
//  #customise .navbar-default .navbar-nav > li > a:hover, #customise .navbar-default .navbar-nav > li > a:focus {
// 	 color: #000;
// 	 -webkit-transition: 0.3s all ease;
// 	 -moz-transition: 0.3s all ease;
// 	 -o-transition: 0.3s all ease;
// 	 -ms-transition: 0.3s all ease;
// 	 transition: 0.3s all ease;
// }
//  #customise .navbar-default .navbar-nav > .active > a, #customise .navbar-default .navbar-nav > .active > a:hover, #customise .navbar-default .navbar-nav > .active > a:focus {
// 	 color: #dd6e7a;
// 	 background-color: rgba(255, 255, 255, 0);
// }
//  #customise .navbar-default .navbar-nav > .open > a, #customise .navbar-default .navbar-nav > .open > a:hover, #customise .navbar-default .navbar-nav > .open > a:focus {
// 	 color: #555;
// 	 background-color: #d5d5d5;
// }
//  #customise .navbar-default .navbar-nav > .dropdown > a .caret {
// 	 border-top-color: #777;
// 	 border-bottom-color: #777;
// }
//  #customise .navbar-default .navbar-nav > .dropdown > a:hover .caret, #customise .navbar-default .navbar-nav > .dropdown > a:focus .caret {
// 	 border-top-color: #333;
// 	 border-bottom-color: #333;
// }
//  #customise .navbar-default .navbar-nav > .open > a .caret, #customise .navbar-default .navbar-nav > .open > a:hover .caret, #customise .navbar-default .navbar-nav > .open > a:focus .caret {
// 	 border-top-color: #555;
// 	 border-bottom-color: #555;
// }
//  #customise .navbar-default .navbar-toggle {
// 	 background-color: #dd6e7a;
// 	 border-color: #dd6e7a;
// 	 -webkit-transition: 0.3s all ease;
// 	 -moz-transition: 0.3s all ease;
// 	 -o-transition: 0.3s all ease;
// 	 -ms-transition: 0.3s all ease;
// 	 transition: 0.3s all ease;
// }
//  #customise .navbar-default .navbar-toggle:hover, #customise .navbar-default .navbar-toggle:focus {
// 	 background-color: #000;
// 	 border-color: #000;
// 	 -webkit-transition: 0.3s all ease;
// 	 -moz-transition: 0.3s all ease;
// 	 -o-transition: 0.3s all ease;
// 	 -ms-transition: 0.3s all ease;
// 	 transition: 0.3s all ease;
// }
//  #customise .navbar-default .navbar-toggle .icon-bar {
// 	 background-color: #fff;
// }
//  @media (max-width: 767px) {
// 	 #customise .navbar-default .navbar-nav .open .dropdown-menu > li > a {
// 		 color: #777;
// 	}
// 	 #customise .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, #customise .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
// 		 color: #333;
// 	}
// }
//  #customise .profile {
// 	 background-color: #fff;
// 	 padding: 25px 20px;
// }
//  #customise .subname {
// 	 font-family: 'Montserrat', sans-serif;
// 	 font-size: 22px;
// 	 line-height: 20px;
// 	 color: #8b8a8a;
// }
//  #customise .profile_text {
// 	 margin-top: 10px;
// }
//  #customise .popup_content {
// 	 width: 60%;
// 	 margin: 30px auto;
// 	 background-color: #fff;
// 	 height: 100%;
// 	 padding: 30px;
// }
//  #customise .popup_content img {
// 	 width: 100%;
// 	 margin: 20px 0 20px 0;
// }
//  #customise .box {
// 	 background-color: #fff;
// 	 margin-top: 20px;
// }
//  #customise .box_icon {
// 	 background-color: #00c0b6;
// 	 margin-top: -1px;
// 	 padding: 18px;
// 	 height: 60px;
// 	 width: 60px;
// 	 color: #fff;
// 	 font-size: 26px;
// }
//  #customise .box_title {
// 	 padding-left: 20px;
// 	 font-weight: bold;
// 	 font-family: 'Montserrat', sans-serif;
// 	 font-size: 18px;
// 	 color: #444;
// }
//  #customise .box_text {
// 	 padding: 10px 10px;
// 	 background-color: #eaeaea;
// }
//  #customise #location_map {
// 	 width: 100%;
// 	 height: 450px;
// 	 margin-top: 30px;
// }
//  #customise .name_wrapper {
// 	 background-color: #fff;
// 	 padding: 20px 0;
// }
//  #customise .photo_col {
// 	 margin-bottom: 30px;
// }
//  #customise .masonry_col, #customise .grid_sizer {
// 	 width: 25%;
// }
//  #customise .masonry_col.w2 {
// 	 width: 50%;
// }
//  #customise .masonry_col.w3 {
// 	 width: 75%;
// }
//  #customise .masonry_col.w4 {
// 	 width: 100%;
// }
//  #customise #registry_logo .item {
// 	 text-align: center;
// }
//  #customise #registry_logo .item img {
// 	 width: 80%;
// 	 height: auto;
// }
//  #customise .blog_wrapper {
// 	 background-color: #fff;
// 	 padding: 25px 20px;
// }
//  #customise .blog_text {
// 	 margin-top: 10px;
// }
//  #customise .blog_info a {
// 	 color: #00c0b6;
// }
//  #customise .blog_info a:hover {
// 	 color: #444;
// }
//  #customise .masonry_col_blog, #customise .grid_sizer_blog {
// 	 width: 32%;
// 	 margin-bottom: 20px;
// }
//  #customise .masonry_col_blog.w2 {
// 	 width: 65.7%;
// }
//  #customise .masonry_col_blog.w3 {
// 	 width: 100%;
// }
//  #customise #loading_more {
// 	 display: none;
// }
//  #customise input[type=text], #customise input[type=email] {
// 	 height: 30px;
// }
//  #customise input[type=text], #customise input[type=email], #customise select, #customise textarea {
// 	 border-radius: 2 !important;
// }
//  #customise option {
// 	 color: #747474;
// }
//  #customise #result {
// 	 margin-bottom: 20px;
// }
//  #customise #result .bg-danger, #customise #result .bg-success {
// 	 padding: 10px;
// 	 color: #fff;
// }
//  #customise #result .bg-danger {
// 	 background-color: #c33;
// }
//  #customise #result .bg-success {
// 	 background-color: #00c0b6;
// }
//  #customise .footer_text {
// 	 background-color: #fff;
// 	 padding: 30px;
// 	 overflow: hidden;
// }
//  #customise #footer1 {
// 	 background-color: #dd6e7a;
// 	 padding: 30px;
// }
//  #customise .sidemap a {
// 	 color: #fff;
// 	 padding: 5px 10px;
// 	 font-family: 'Montserrat', sans-serif;
// 	 font-size: 14px;
// 	 text-transform: uppercase;
// 	 display: inline-block;
// }
//  #customise .sidemap {
// 	 padding: 20px 0;
// }
//  @media (max-width: 1199px) {
// 	 #customise .animated_heart {
// 		 -webkit-animation-name: none;
// 	}
// }
//  @media (min-width: 992px) and (max-width: 1199px) {
// 	 #customise {
// 		/* Blog -----------------------------*/
// 	}
// 	 #customise .masonry_col_blog, #customise .grid_sizer_blog {
// 		 width: 31.9%;
// 	}
// }
//  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
// 	 #customise .photo_divider {
// 		 background-attachment: scroll !important;
// 	}
// }
//  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
// 	 #customise .photo_divider {
// 		 background-attachment: scroll !important;
// 	}
// }
//  @media (max-width: 991px) {
// 	 #customise #email-to, #customise #contact {
// 		display: flex;
// 		justify-content: center;
// 	}
// 	#customise #slide_content > .text-right {
// 		 text-align: center !important;
// 	}
// 	 #customise #slide_content > .pull-right {
// 		 float: none !important;
// 	}
// 	 #customise .navbar-collapse {
// 		 margin-top: 15px;
// 	}
// 	 #customise #his_profile {
// 		 margin-bottom: 40px;
// 	}
// 	 #customise .masonry_col, #customise .grid_sizer {
// 		 width: 50% !important;
// 	}
// 	 #customise .masonry_col_blog, #customise .grid_sizer_blog {
// 		 width: 48.5%;
// 	}
// 	 #customise .masonry_col_blog.w2 {
// 		 width: 100%;
// 	}
// 	 #customise .popup_content {
// 		 width: 70%;
// 	}
// }
//  @media (min-width: 768px) and (max-width: 991px) {
// 	 #customise {
// 		/* Photo Divider ----------------------- */
// 	}
// 	 #customise #slide_content {
// 		 margin: 100px 0;
// 	}
// 	 #customise .photo_divider {
// 		 background-attachment: scroll !important;
// 	}
// }
//  @media (max-width: 767px) {
// 	 #customise {
// 		/* Blog 2 -----------------------------*/
// 		/* Popup Content ----------------------- */
// 		/* Photo Divider ----------------------- */
// 	}
// 	 #customise #slide_content {
// 		 margin: 80px 0;
// 	}
// 	 #customise .masonry_col_blog, #customise .grid_sizer_blog {
// 		 width: 48.15%;
// 	}
// 	 #customise .popup_content {
// 		 width: 80%;
// 	}
// 	 #customise .photo_divider {
// 		 background-attachment: scroll !important;
// 	}
// }
//  @media (max-width: 578px) {
// 	 #customise {
// 		/* Slider-----------------------------*/
// 	}
// 	 #customise #slide_content {
// 		 margin: 30px 0 30px 0;
// 	}
// 	 #customise #slide_text1 {
// 		 font-size: 38px;
// 		 line-height: 34px;
// 	}
// 	 #customise #slide_text2 {
// 		 font-size: 30px;
// 		 line-height: 26px;
// 	}
// 	 #customise #slide_text3 {
// 		 font-size: 32px;
// 		 line-height: 46px;
// 	}
// 	 #customise #slide_text4 {
// 		 font-size: 30px;
// 		 line-height: 30px;
// 	}
// 	 #customise #slide_text4 span {
// 		 font-size: 12px;
// 		 padding: 0 8px;
// 	}
// 	 #customise .heart_row {
// 		 color: #00c0b6;
// 		 margin: 0;
// 	}
// 	 #customise .heart_row span {
// 		 padding: 0 1px;
// 	}
// }
//  @media (max-width: 570px) {
// 	 #customise {
// 		/* Blog 2 -----------------------------*/
// 	}
// 	 #customise .masonry_col_blog, #customise .grid_sizer_blog {
// 		 width: 100%;
// 	}
// }
//  @media (max-width: 480px) {
// 	 #customise {
// 		/* Common --------------------------*/
// 		/* Title & Intro --------------------*/
// 		/* His/Her Profile ------------------*/
// 		/* Logo ------------------------------*/
// 		/* Slider-----------------------------*/
// 		/* Menu-----------------------------*/
// 		/* His/Her Profile -----------------------*/
// 	}
// 	 #customise h2 {
// 		 font-size: 30px;
// 	}
// 	 #customise h3 {
// 		 font-size: 24px;
// 	}
// 	 #customise .sub_title {
// 		 font-size: 20px;
// 	}
// 	 #customise .intro {
// 		 padding: 35px 5px;
// 		 font-size: 18px;
// 	}
// 	 #customise .subname {
// 		 font-size: 18px;
// 	}
// 	 #customise #logo {
// 		 width: 70px;
// 		 height: 60px;
// 		 padding-top: 15px;
// 		 font-size: 16px;
// 	}
// 	 #customise #slide_content {
// 		 margin: 40px 0 40px 0;
// 	}
// 	 #customise #nav {
// 		 padding: 0 0;
// 		 -webkit-transition: 0.3s all ease;
// 		 -moz-transition: 0.3s all ease;
// 		 -o-transition: 0.3s all ease;
// 		 -ms-transition: 0.3s all ease;
// 		 transition: 0.3s all ease;
// 	}
// 	 #customise .is-sticky #nav {
// 		 padding: 0px 0;
// 		 -webkit-transition: 0.3s all ease;
// 		 -moz-transition: 0.3s all ease;
// 		 -o-transition: 0.3s all ease;
// 		 -ms-transition: 0.3s all ease;
// 		 transition: 0.3s all ease;
// 	}
// 	 #customise .navbar-collapse {
// 		 margin-top: 5px;
// 	}
// 	 #customise #male_icon, #customise #female_icon {
// 		 display: none;
// 	}
// }
//  #customise #style_switcher {
// 	 background-color: #fff;
// 	 position: fixed;
// 	 top: 30%;
// 	 padding: 5px;
// 	 z-index: 999;
// 	 box-shadow: 2px 2px 3px #888;
// 	 left: -110px;
// }
//  #customise #theme_options {
// 	 color: #444;
// }
//  #customise .color_pallete {
// 	 display: inline-block;
// 	 width: 20px;
// 	 height: 20px;
// 	 margin-right: 3px;
// }
//  #customise #reset > span {
// 	 background-color: #00c0b6;
// }
//  #customise #pink > span {
// 	 background-color: #d56363;
// }
//  #customise #purple > span {
// 	 background-color: #b06c8b;
// }
//  #customise #brown > span {
// 	 background-color: #ad8763;
// }
//  #customise #theme_options > span {
// 	 font-size: 24px;
// 	 margin: 0 5px 0 10px;
// }
//  #customise .logo-footer a {
// 	 padding: 0 10px;
// }
//  #customise .shere p {
// 	 font-weight: 600;
// 	 margin-top: 50px;
// }
//  #customise .shere {
// 	 float: right;
// }
//  #customise .img-box {
// 	 position: relative;
// 	 border: 7px solid #e68787;
// }
//  #customise .shadow {
// 	 -webkit-box-shadow: inset 0px 0px 50px -6px rgba(0, 0, 0, 0.75);
// 	 -moz-box-shadow: inset 0px 0px 50px -6px rgba(0, 0, 0, 0.75);
// 	 box-shadow: inset 0px 0px 50px -6px rgba(0, 0, 0, 0.75);
// 	 position: absolute;
// 	 height: 100%;
// 	 width: 100%;
// }
//  #customise header a {
// 	 font-family: 'Montserrat', sans-serif;
// 	 color: #747474;
// }

//  #customise .boder {
// 	 border-bottom: 1px solid #c5edec;
// }
//  #customise .img.img-res {
// 	 width: 100%;
// }
//  #customise .pdding-lr-30 {
// 	 padding: 0 30px;
// }
//  #customise .pdding-tp-30 {
// 	 padding: 30px 0;
// }
//  #customise .title-detail {
// 	 font-size: 28px;
// 	 color: #dd6e7a;
// 	 font-weight: 600;
// }
//  #customise .description {
// 	 margin-top: 30px;
// }
//  #customise #bg-form {
// 	 margin-top: 10px;
// 	 padding: 10px;
// 	 border-radius: 5px;
// 	 background-color: #dd6e7a;
// }
//  #customise #rsvpform2 {
// 	 padding: 10px 20px;
// 	 border: 5px #fff;
// 	 border-style: dotted;
// }
//  #customise #rsvpform2 label {
// 	 color: #fff;
// }
//  #customise #bg-contact {
// 	 margin-top: 10px;
// 	 border: 5px #de6e7a;
// 	 border-style: dotted;
// 	 padding: 20px;
// 	 border-radius: 5px;
// }
//  #customise .language {
// 	 position: absolute;
// 	 font-size: 16px;
// 	 left: 70px;
// 	 top: 10px;
// 	 height: 33px;
// 	 background-color: #5b8d9e;
// 	 color: white;
// 	 border-radius: 6px;
// 	 border: none;
// }
//  #customise .back-g {
// 	 height: 70px;
// 	 background-size: cover;
// 	 background-repeat: no-repeat;
// }
//  #customise .title-book {
// 	 min-height: 45px;
// }
//  #customise .ban-bg {
// 	 height: 480px;
// 	 background-repeat: no-repeat;
// 	 background-size: cover;
// 	 background-position: top center;
// }
//  #customise #menu-t {
// 	 position: static;
// 	 width: 100%;
// 	 margin: 10px 0;
// 	 background-color: rgba(255, 255, 255, 0.7);
// 	 text-transform: uppercase;
// }
//  #customise .st_instagram_large .stLarge {
// 	 background-image: url(https://www.liberty.edu/media/8030/icons/instagram_36x36.png) !important;
// 	 background-size: 100% !important;
// }
//  #customise .cap label {
// 	 display: none;
// }
//  #customise #captcha_code {
// 	 width: 179px;
// 	 float: right;
// }
//  #customise .icon_facebook {
// 	 background-image: url(http://w.sharethis.com/images/facebook_32.png);
// 	 display: inline-block;
// 	 height: 32px;
// 	 width: 32px;
// 	 position: relative;
// 	 cursor: pointer;
// }
//  #customise .icon_facebook:hover {
// 	 opacity: 0.75;
// 	 background-position: -32px;
// }
//  #customise .book {
// 	 background-image: url(../images/bg-book.png);
// 	 min-height: 835px;
// 	 background-repeat: no-repeat;
// 	 background-size: 100%;
// 	 padding: 30px 0px 20px 0;
// }
//  #customise .line {
// 	 font-size: 13px;
// }
//  #customise .language2 {
// 	 position: absolute;
// 	 font-size: 16px;
// 	 height: 34px;
// 	 top: 6px;
// 	 background-color: #dd6e7a;
// 	 color: white;
// 	 border-radius: 6px;
// 	 border: none;
// }
//  @media (min-width: 767px) {
// 	 #customise .display-mobie {
// 		 display: none !important;
// 	}
// 	 #customise .res-img {
// 		 width: auto !important;
// 	}
// 	 #customise .language {
// 		 display: none !important;
// 	}
// }
//  @media (max-width: 767px) {
// 	 #customise .display-desk {
// 		 display: none !important;
// 	}
// 	 #customise .footer_text {
// 		 background-color: #dd6e7a !important;
// 		 padding: 15px !important;
// 	}
// 	 #customise .title-detail {
// 		 margin: 20px 0 !important;
// 	}
// 	 #customise .description {
// 		 margin-top: 0px !important;
// 	}
// 	 #customise .back-g {
// 		 height: 84px !important;
// 	}
// 	 #customise .ban-bg {
// 		 height: 110px !important;
// 	}
// 	 #customise #menu-t {
// 		 margin: 0px 0 !important;
// 		 background-color: #fff !important;
// 		 position: relative !important;
// 	}
// 	 #customise .book {
// 		 padding: 20px 0px 20px 0;
// 	}
// 	 #customise .line {
// 		 line-height: 18px;
//   }
//   }
}