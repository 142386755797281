$fake-search-bar-height: 51px;
$search-bar-padding: 7px $padding-layout;
$logo-container-margin-right: 10px;
$floor-level-guide-width: 60px;
$separator-width: 300px;
$wayfinder-border-radius: 5px;
$wayfinder-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);

.wayfinder-page {
  @include page-basis;

  .fake-search-bar {
    display: flex;
    flex-direction: row;
    padding: $search-bar-padding;

    &-left {
      margin-right: $padding-layout;
    }

    &-right {
      flex: 1;
    }
  }

  .navigation {
    overflow: hidden;
    flex: 1;

    .svg-list-floor-plan {
      height: 100%;
      width: 100%;
    }
  }

  .linear-fade-background {
    position: absolute;
    top: 0;
    height: 50px;
    width: 100%;
    z-index: $linear-fade-background;
  }

  .origin-destination-panel {
    display: flex;
    justify-content: space-between;
    .shop-card-wrapper {
      > .label {
        color: #000;
        margin-bottom: 5px;
        font-size: $size-6;
        text-transform: uppercase;
      }
    }
    .icon-wrapper {
      display: flex;
      margin: 0 5px;
      justify-content: center;
      align-items: center;
      .icon-swap {
        font-size: 24px;
        margin-top: 20px;
      }
    }
  }

  .wayfinder-drawer-widget {
    display: flex;
    justify-content: flex-end;
  }

  .floor-level-guide {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    overflow: hidden;
    width: $floor-level-guide-width;

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-around;
      align-items: center;
    }

    &-arrow-direction {
      width: 13px;
      height: 16px;
    }

    &-title-container {
      margin: $padding-layout;
      text-align: center;
    }

    &-sub-title {
      font-size: $size-1;
    }

    &-title {
      font-size: $size-6;
    }
  }

  .way-finder {
    &-name-container {
    }

    &-name {
      text-align: center;
      font-weight: 600;
    }
  }

  .direction-arrow {
    svg {
      height: 100%;
      width: 100%;
    }
  }
  .separator-mobile {
    width: $separator-width;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 10px auto;

    &-icon {
      img {
        width: 30px;
      }
      svg {
        width: 25px;
        height: 25px;
      }
    }

    &-text {
      font-size: $size-7;
      margin-top: 5px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .collapse-shop-detail,
  .expanded-shop-detail {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 $padding-layout;
  }

  .collapse-shop-detail {
    justify-content: space-between;

    .content-wrapper {
      display: flex;
      // justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .logo-container {
        flex: 0 0 auto;
        margin-right: $logo-container-margin-right;

        .logo {
          width: 60px;
          height: 60px;
          border-radius: $wayfinder-border-radius;
          box-shadow: $wayfinder-box-shadow;
          object-fit: scale-down;
        }
      }

      .content-container {
        flex: 1 1 auto;

        .title,
        .sub-title {
          line-height: 1;
        }
        .title-and-hours {
          display: flex;
          align-items: baseline;
          .title {
            max-width: 38%;
            font-size: $size-5;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 5px;
          }
          .opening-hours {
            width: 60%;
            font-size: $size-7;
            display: flex;
            @include font-weight("thin");

            .icon {
              margin-right: 2px;
              display: flex;
              align-items: center;
              svg {
                margin-right: 2px;
              }
            }
            span.is-open {
              color: $green;
            }
            span.is-closed {
              color: $red;
            }
            span.suggestion-text {
              margin-left: 5px;
            }
          }
        }
        .text-floor {
          font-size: $size-7;
          display: flex;
          align-items: center;
          line-height: 1.75;

          svg {
            margin-right: 2px;
          }
        }
      }
    }

    .floor-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .floor-title {
        font-size: $size-4;
        text-align: center;
      }

      .floor-sub-title {
        font-size: $size-1;
        text-transform: uppercase;
      }
    }
  }

  .expanded-shop-detail {
    flex-direction: column;
    justify-content: center;

    .content-wrapper {
      display: inline-flex;
      align-items: center;
      margin-bottom: 2.5rem;

      .logo-container {
        margin-right: $logo-container-margin-right;
      }

      .content-container {
        display: flex;
        flex: 1;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        .title-container {
          margin-bottom: 10px;
        }

        .content-title {
          font-size: $size-4;
          text-transform: uppercase;
          letter-spacing: $title-letter-spacing;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .content-sub-title {
          font-size: $size-6;
          text-transform: uppercase;
        }

        .floor-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .floor-title {
            font-size: $size-4;
          }

          .floor-sub-title {
            font-size: $size-1;
            text-transform: uppercase;
          }
        }
      }
    }

    .buttons-wrapper {
    }

    .step-and-more-container {
      display: flex;
      justify-content: center;
    }
  }

  @include breakpoint($ip-5) {
    .collapse-shop-detail {
      .content-wrapper {
        .content-container {
          width: 154px;
        }
      }
    }

    .expanded-shop-detail {
      .content-title {
        width: 180px;
      }
    }

    .floor-level-guide {
      &-arrow-direction {
        width: 13px;
        height: 16px;
      }
    }
  }

  @include breakpoint($ip-6-x) {
    .collapse-shop-detail\ {
      .content-wrapper {
        .content-container {
          width: 189px;
        }
      }
    }

    .expanded-shop-detail {
      .content-title {
        width: 230px;
      }
    }

    .floor-level-guide {
      &-arrow-direction {
        width: 13px;
        height: 16px;
      }
    }
  }

  .active-step {
    .floor-item {
      margin: 10px 0;
    }
  }
}
