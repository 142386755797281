$tag-border-radius: 2px;

.tag {
	position: relative;
	@include breakpoint-desktop-first($sm-screen) {
		display: none;
		pointer-events: none;
  }
  .rectangle-right {
    height: 40px;
    width: 200px;
    position: relative;
    border: 0.7px;
    border-color: $orange;
    border-style: $border-style;
    border-radius: $tag-border-radius;
    float: right;
    font-size: $size-6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rectangle-right:after {
    content: "";
    background-color: $orange;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: $tag-border-radius;
    z-index: -1;
  }

  .triangle-right {
    height: 20px;
    width: 20px;
    position: relative;
    border: 0.7px;
    border-color: $orange;
    border-style: $border-style;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(50%) rotate(45deg);
    transform: translateY(50%) rotate(45deg);
    right: -5px;
  }

  .triangle-right:after {
    content: "";
    background-color: $orange;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
  }

  .rectangle-left {
    height: 40px;
    width: 200px;
    position: relative;
    border: 0.7px;
    border-color: $orange;
    border-style: $border-style;
    border-radius: $tag-border-radius;
    float: left;
    font-size: $size-6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rectangle-left:after {
    content: "";
    background-color: $orange;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: $tag-border-radius;
    z-index: -1;
  }

  .triangle-left {
    height: 20px;
    width: 20px;
    position: relative;
    border: 0.7px;
    border-color: $orange;
    border-style: $border-style;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(50%) rotate(45deg);
    transform: translateY(50%) rotate(45deg);
    left: -5px;
  }

  .triangle-left:after {
    content: "";
    background-color: $orange;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
  }
}
