.search-destination-page {
  @include page-basis;

  .auto-complete-container {
    padding: $padding-layout;
    padding-right: 0;
    border: $border-width $border-style;
    border-radius: $border-radius;
    overflow: hidden;
  }
  .auto-complete-container-em {
    padding: $padding-layout;
    padding-right: 0;
    border-radius: $border-radius;
    overflow: hidden;
  }

  .list-item-title {
    text-transform: uppercase;
    // letter-spacing: $title-letter-spacing;
    font-size: $size-5;
  }
  
  .list-item-description {
    font-size: $size-6;
  }

  .separator {
    margin: $padding-layout 0;
    height: 1px;
  }
  
  .recent-search {
    &-title-container {
      padding-bottom: $padding-layout;
    }

    &-title {
      font-size: $size-7;
    }
  }

  /** small screen mobile */
  @include breakpoint($ip-5) {
    .header {
      height: 90px;

      img {
        height: 90px;
        width: 90px;
      }
    }
  }

  @include breakpoint($ip-6-x) {
    .header {
      height: 150px;

      img {
        height: auto;
        width: auto;
      }
    }
  }
}