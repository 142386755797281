$input-height: 2.25em;
$menu-max-height: 200px;
$has-icon-padding: 3rem;

.input {
  &.auto-complete {
    position: relative;
  }

  &.is-borderless {
    input[type=text] {
      border-width: 0;
      padding: 0;
    }

    .input-container {
      &.has-icons-left, &.has-icons-right {
        .input-icon {
          width: calc(#{$input-height} / 2);
        }
      }

      &.has-icons-left {
        input {
          padding-left: calc(#{$input-height} / 1.5);
        }
      }

      &.has-icons-right {
        input {
          padding-right: calc(#{$input-height} / 1.5);
        }
      }
    }
  }

  input[type=text] {
    width: 100%;
    outline: $outline;
    font-size: $size-5;
    height: $input-height;
    padding: 8px 10px;
    margin: 0;
    border-width: $border-width;
    border-radius: $input-border-radius;
    border-style: $border-style;
    transition: border-color $transition-duration $transition-timing;

    & ~ span {
      transition: color $transition-duration $transition-timing;
    }

    &:read-only {
      cursor: default;
    }

    &.is-active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .menu {
    position: absolute;
    width: 100%;
    max-height: $menu-max-height;
    overflow-x: hidden;
    overflow-y: auto;
    border-width: $border-width;
    border-top-width: 0;
    border-style: $border-style;
    border-radius: $border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &-item {
      padding: 8px 10px;
    }
  }

  &-container {
    position: relative;
    // overflow: hidden;

    &.has-icons-left {
      input {
        padding-left: $input-height;
      }
    }

    &.has-icons-right {
      input {
        padding-right: $input-height;
      }
    }

    &.has-icons-left, &.has-icons-right {
      .input-icon {
        position: absolute;
        top: 0;
        height: $input-height;
        width: $input-height;
      }
    }

    .input-icon {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      font-size: $size-6;

      &.is-left {
        left: 0;
      }

      &.is-right {
        right: 1;
      }

    }

    input[type=text] {
      flex: 1;
    }
  }

  &-label-container {

  }

  &-label {
  }

  .help-text {
    font-weight: 400;
    font-size: $size-6;
  }

  .help-text-container {
    margin: calc(#{$padding-layout} / 2);
  }

  ::-webkit-input-placeholder { /* WebKit browsers */
    font-weight: 300;
    text-transform: uppercase;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-weight: 300;
    text-transform: uppercase;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-weight: 300;
    text-transform: uppercase;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    font-weight: 300;
    text-transform: uppercase;
  }
  ::placeholder { /* Recent browsers */
    font-weight: 300;
    text-transform: uppercase;
  }
}

.input-search-getdirection {
  > .input-container > input {
    max-width: 85%;
    border: none;
    border-bottom: 1px solid;
    position: relative;
    top: -6px;
    left: 50px;
    padding-bottom: 3px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 8px;
  }
}
