.card-qrcode {
  border-radius: $card-border-radius;
  border: 0.5px solid $grey-lighter;
  width: 100%;
  height: 100%;
  display: flex;
  padding: $padding-layout / 2;
  .card-qrcode-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 40%;
    img {
      width: 100%;
      height: auto;
      max-width: 75px;
    }
  }
  .card-qrcode-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
  }
}
