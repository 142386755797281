.explore {
  .filter-input {
    .search-dropdown {
      transition: background $transition-duration $transition-timing;
      padding: 6px $padding-layout;
    }

    .separator {
      margin: 2px 0;
      height: 1px;
    }
  }

  .filter-category {
    .category-logo-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .category-icon {
        flex: 1 0 auto;

      }

      .category-title {
        padding: 0 40px;
        text-align: left;
        font-size: $size-5;
      }

      @media only screen and (max-device-width: 450px) {
        .category-title {
          padding: 0;
          padding-left: 16px;
          font-size: $size-6;
          &.tiny {
            font-size: $size-7;
          }
        }
      }
    }

    .category-card {
      height: 80px;
      &.is-active {
        height: 100px;
        opacity: 1;
      }
    }
  }

  .filter-result {
    .shop-card {
      position: relative;
      z-index: 1; /* matters! */
      overflow: hidden;
      transition: color $transition-duration $transition-timing, box-shadow $transition-duration $transition-timing;
      &-body {
        flex-direction: column;
        overflow: hidden;
      }

      .title {
        margin-bottom: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .subtitle {
        font-size: $size-6;
        @include font-weight('thin');
      }

      &:hover {
        .title {
          text-decoration: underline;
        }
      }

      .footer-left {
        text-align: left;
        flex: 1 0 auto;
      }

      .footer-right {
        text-align: right;
        flex: 0 1 auto;
      }
    }
    .text-title {
      opacity: 0.5;
    }

    .icon {
      opacity: 0.3;
      width: 40px;
      height: 40px;
    }

    .is-clickable:hover {
      .icon {
        color: rgb(211, 62, 137);
        opacity: 1;
      }
    }

    .group-button {
      margin-bottom: 10px;
    }
  }

  .view-more-button {
    padding: $padding-layout;
    width: 100%;
  }

  .tiny-bar {
    &-content {
      p {
        font-size: $size-6;
      }

      @media only screen and (max-device-width: 450px) {
        p {
          font-size: $size-5;
        }
      }
    }
  }

  @include breakpoint-desktop-first($sm-screen) {
    .filter-input,
    .filter-category {
      padding: 0;
    }

    .filter-result {
      .view-more-button {
        display: none;
      }
    }
  }
}

.explore-building-selector {
  min-width: 200px;
  > div {
    border-radius: $input-border-radius;
  }
}

.floor-selector-list {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  .column-item {
    flex-grow: 0;
  }
}