.get-direction-button {
  display: inline-block;
  > button {
    margin: 5px auto;
    border-radius: $circle-border-radius;
    width: 50px;
    height: 50px;
    > svg.icon-left, > svg.icon-right {
      font-size: 24px;
      margin-right: 0px;
    }
  }
  > p {
    font-size: $size-7;
    text-align: center;
    text-transform: uppercase;
  }
}