.where-is-it {
  min-height: 100vh;
  position: relative;

  .shop-item {
    transition: background $transition-duration $transition-timing;
    border-radius: 4px;
    padding: 6px;
  }

  .separator {
    margin: 2px 0;
    height: 1px;
  }

  .shop-banner {
    border-bottom: 1px solid $grey-lighter;
  }

  .dot-dot-dot-container {
    width: 18px;

    .dot-dot-dot {
      width: 5px;
      height: 5px;
      border-radius: 99rem;
      margin: 1.5px auto;
    }
  }

  .shop-detail-header {
    padding: $padding-layout;
  }

}