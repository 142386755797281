.hero-text {
  display: flex;
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    > :nth-child(2) {
      margin: 0 5px;
    }
    > .text > span {
      text-transform: uppercase;
      font-size: $size-2;
    }
    @include breakpoint-desktop-first($sm-screen) {
      margin: 0 10px;
      > .text > span {
        font-size: $size-5;
      }
    }
  }

  &-line-container {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    > .line {
      display: block;
      width: 100%;
      height: 2px;
    }
  }
}