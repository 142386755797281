.floor-level-bar {
  position: relative;

  &:before, &:after {
    content: '';
    display: block;
    border-width: 0;
    width: 100%;
    margin-bottom: 5px;
    z-index: $send-to-back-z-index;
  }

  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 6.5px;
  }

  &:after {
    position: absolute;
    top: 59%;
    transform: translateY(-59%);
    height: 2px;
  }

  &-container {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .floor-icon-container {
    width: 80px;
    height: 80px;
    margin-right: $padding-layout;
    padding: $padding-layout;
    box-shadow: $box-shadow;
    border-radius: $input-border-radius;
  }

  .floor-title-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex: 1;

    .floor-name {
      display: flex;
      align-items: baseline;
    }

    .title, .sub-title {
      padding: $padding-layout;
    }

    a.see-more {
      display: none;
    }
  }

  /* tablet and mobile */
  @include breakpoint-desktop-first ($sm-screen) {
    &:before, &:after {
      display: none;
    }
    
    .floor-icon-container {
      display: none;
    }

    .floor-title-container {
      flex-direction: row;
      align-items: center;

      .floor-name {
        flex-direction: column;
      }

      .title, .sub-title {
        padding: 0 0 5px 0;
      }

      a.see-more {
        display: block;
        text-decoration: none;
        font-size: $size-6;
      }
    }
  }
}