.header-content {
  text-transform: uppercase;

  &-block {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    @include breakpoint-desktop-first('sm-screen') {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .title {
      font-size: $size-6;
    }
    .opening-hours {
      font-size: $size-7;
      padding-left: 5px;
      @include font-weight("thin");
      .icon > svg {
        margin-right: 3px;
      }
      span.is-open {
        color: $green;
      }
      span.is-closed {
        color: $red;
      }
      span.suggestion-text {
        margin-left: 5px;
      }
    }
    .sub-title {
      font-size: $size-7;
      @include font-weight("thin");
    }
    .text-floor {
      font-size: $size-7;
      display: flex;
      align-items: flex-end;
      @include font-weight("thin");
      > svg {
        margin-right: 3px;
      }
    }
  }
}
