/**
 * ----------------------------------------------------------------------------
 * Font Face
 * ----------------------------------------------------------------------------
 * see more information https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
 */
@mixin font-face ($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    src: $src;
    font-weight: $weight;
    font-style: $style;
  }
}

/**
 * ----------------------------------------------------------------------------
 * Media queries
 * ----------------------------------------------------------------------------
 */
@mixin breakpoint ($breakpoint) {
	@media only screen and (min-device-width: $breakpoint) {
		@content;
	};
}

/**
 * ----------------------------------------------------------------------------
 * Media queries
 * ----------------------------------------------------------------------------
 */
@mixin breakpoint-desktop-first ($breakpoint) {
	@media only screen and (max-device-width: $breakpoint) {
		@content;
	};
}

/**
 * ----------------------------------------------------------------------------
 * basis style of page
 * ----------------------------------------------------------------------------
 */
@mixin page-basis {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	overflow-y: scroll;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin font-weight($key) {
	font-weight: map-get($font-weights, $key);
}

