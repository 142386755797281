.mobile-navigation-button {
  .icon-left {
    position: relative;
    top: 10px;
  }

  .opacity-panel {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: transparent;
    z-index: $linear-fade-background;
    transition: all $transition-duration $transition-timing;
    opacity: 0;
    visibility: hidden;
    &.is-open {
      background-color: rgba(0, 0, 0, 0.6);
      visibility: visible;
      opacity: 1;
    }
  }

  .drawer-content {
    position: fixed;
    left: 0;
    top: 0;
    z-index: $drawer-z-index;
    width: 85%;
    height: 100vh;
    box-shadow: $box-shadow;
    transition: transform 0.3s ease-out;

    &.is-open {
      transform: translateX(0);
    }
    &.is-close {
      transform: translateX(-100%);
    }

    .toggle {
      transform: rotate(180deg);
      width: 30px;
      height: 30px;
      padding-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: 90%;
      top: 50%;

      .icon {
        width: 20px;
        height: 20px;
        color: #b6a16c;
      }
    }

    &-header {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      > img {
        height: 100%;
        width: auto;
      }
    }

    &-body {
      width: 100%;
      padding: 20px;
      .menu-item {
        color: $grey;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        span {
          text-transform: uppercase;
        }
      }
    }
  }
}
