$avatar-image-container-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
$navigate-button-size: 45px;
$navigate-button-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

.avatar-card {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-image-container {
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: $avatar-image-container-box-shadow;
    width: 65px;
    height: 65px;
    padding: 10px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 100px;
    }
  }

  &-content {
    display: flex;
    flex: 3;
    justify-content: center;
    flex-direction: column;

    .title-and-category{
      line-height: 1;
    }
    .location {
      display: flex;
      margin-top: 10px;

      p {
        margin-left: 5px;
      }
    }
  }

  &-navigate-button {
    width: $navigate-button-size;
    height: $navigate-button-size;
    border-radius: $navigate-button-size;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $padding-layout;
    box-shadow: $navigate-button-box-shadow;
  }
}
