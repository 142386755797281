@import 'vendor/_index';
@import './abstracts/index';
@import './base/index';
@import 'explicit-components/_index';
@import './components/index';
@import './page/index';
@import 'vendor/_index';
@import 'vendor/thepromenade-styles';

.switch-wrapper {
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.mobile-nav {
  z-index: $drawer-z-index;
  pointer-events: none;
  transition: all 0.3s ease-out;
  @include breakpoint-desktop-first($sm-screen) {
    &.is-open {
      opacity: 1;
      pointer-events: auto;
    }
  }
  > .wrp-close-btn > button {
    text-align: right;
  }
}

.top-sec {
  @include breakpoint-desktop-first($sm-screen) {
    display: none;
  }
}

.mobile-nav {
  pointer-events: none;
}
