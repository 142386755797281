$box-shadow-item: 0 5px 10px rgba(0, 0, 0, 0.15);

.facility-list {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  bottom: 0;
  width: 100vw;
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 20px 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  .item,
  .button-show-more {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    display: inline-block;
    margin-right: 9px;
    text-align: center;

    &.button-show-more {
      margin-right: 10px;
    }

    .facility-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
