$animation-timing-function: ease-in-out;
$animation-duration: $transition-duration;
$animation-fill-mode: forwards;

@keyframes rubber-band {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeInOut {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.is-fade-out {
  animation-name: fadeOut;
  animation-timing-function: $animation-timing-function;
  animation-duration: $animation-duration;
  animation-fill-mode: $animation-fill-mode;
}

.is-fade-in {
  animation-name: fadeIn;
  animation-timing-function: $animation-timing-function;
  animation-duration: $animation-duration;
  animation-fill-mode: $animation-fill-mode;
}

.rubber-band {
  -webkit-animation-name: rubber-band;
  animation-name: rubber-band;
  animation-timing-function: $animation-timing-function;
  animation-duration: $animation-duration;
  animation-fill-mode: $animation-fill-mode;
}

.fade-in-out {
  -webkit-animation-name: fadeInOut;
  animation-name: fadeInOut;
  animation-iteration-count: infinite;
  animation-timing-function: $animation-timing-function;
  animation-duration: 2s;
  animation-fill-mode: $animation-fill-mode;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}