#customise {
  .vc_custom_1475464395314 {
    margin-right: 25px !important;
    margin-bottom: 0px !important;
    margin-left: 25px !important;
  }
  
  .vc_custom_1475464663038 {
    padding-top: 10px !important;
  }
  
  .vc_custom_1475145263754 {
    border-right-width: 2px !important;
    padding-top: 10px !important;
    border-right-color: #f6f6f6 !important;
  }
  
  .vc_custom_1475464663038 {
    padding-top: 10px !important;
  }
  
  .vc_custom_1475145263754 {
    border-right-width: 2px !important;
    padding-top: 10px !important;
    border-right-color: #f6f6f6 !important;
  }
  
  .vc_custom_1537181371616 {
    margin-bottom: 20px !important;
  }
  
  .vc_custom_1536910422566 {
    padding-top: 5px !important;
    padding-right: 0px !important;
  }

  .vc_custom_1536808683459 {
    margin-bottom: 0px !important;
  }
  
  .vc_custom_1536807670863 {
    margin-bottom: 20px !important;
  }
}