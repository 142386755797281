$padding: 20px;
$padding-mobile: 13px;
$max-width: 80px;
$min-height: 130px;
$max-width-mobile: 51px;
$min-height-mobile: 80px;
$margin-bottom: 3px;
$arrow-size: 18px;
$arrow-size-mobile: 10px;

.animated-gradient-button {
  position: relative;
  padding: $padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $max-width;
  min-height: $min-height;

  .title {
    text-align: center;
    margin-bottom: $margin-bottom;
  }
  &.show-arrow {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-left: $arrow-size solid transparent;
      border-right: $arrow-size solid transparent;
    }
  }
  &.show-arrow.top {
    &:before {
      top: 0;
      bottom: auto;
    }
  }
  &.show-arrow.bottom {
    &:before {
      top: auto;
      bottom: 0;
    }
  }
}

@media only screen and (max-width: 500px) {
  .animated-gradient-button {
    max-width: $max-width-mobile;
    min-height: $min-height-mobile;
    padding: $padding-mobile;

    &.bottom {
      border-radius: 5px 5px 0 0;
    }
    &.top {
      border-radius: 0 0 5px 5px;
    }
    .title {
      line-height: 1;
      p {
        font-size: $size-8;
        font-weight: 500;
      }
    }
    &.show-arrow {
      &:before {
        border-left: $arrow-size-mobile solid transparent;
        border-right: $arrow-size-mobile solid transparent;
      }
    }
  }
}
