$button-addons-height: 2.25em;
$button-addons-border-radius: $input-border-radius;
$skew: 28deg;
$sizes: (
  small: $size-7,
  medium: $size-5,
  large: $size-4,
);

.button-addons {
  display: flex;
  border-radius: $button-addons-border-radius;
  box-shadow: $box-shadow;
  overflow: hidden;
  position: relative;

  &-title, &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 
      visibility $transition-duration $transition-timing,
      opacity $transition-duration $transition-timing;
  }

  &-title {
    visibility: hidden;
    opacity: 0;
  }
  
  &-icon {
    visibility: visible;
    opacity: 1;
  }

  &-item {
    flex: none;
    cursor: pointer;
    position: relative;
    border-width: 0;
    height: $button-addons-height;
    padding: 0 $padding-layout;
    background: transparent;
    font-size: $size-6;
    transition:
      color $transition-duration $transition-timing,
      flex $transition-duration $transition-timing;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) skew(-#{$skew}, 0deg);
      height: 100%;
      width: 100%;
      z-index: -1;
      opacity: 0;
      transition: 
        opacity $transition-duration $transition-timing .1s;
    }

    &:first-child:before {
      transform-origin: top left;
    }

    &:last-child:before {
      transform-origin: bottom left;
    }

    &.is-active {
      flex: 1;

      .button-addons-title {
        visibility: visible;
        opacity: 1;
      }

      .button-addons-icon {
        visibility: hidden;
        opacity: 0;
      }

      &:before {
        opacity: 1;
      }
    }

    @each $size-name, $value in $sizes {
      &.is-#{$size-name} {
        font-size: #{$value};
      }
    }
  }
}