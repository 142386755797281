/**
 * ----------------------------------------------------------------------------
 * Roboto
 * ----------------------------------------------------------------------------
 * format: truetype(ttf)
 * Weight scale: thin     100
 *               light    300
 *               regular  400
 *               medium   500
 *               bold     700
 *               black    900
 */
@include font-face(Roboto, "/fonts/roboto/Roboto-Thin", 100, normal, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-ThinItalic", 100, italic, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-Light", 300, normal, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-LightItalic", 300, italic, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-Regular", 400, normal, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-Italic", 400, italic, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-Medium", 500, normal, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-MediumItalic", 500, normal, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-Bold", 700, normal, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-Bold", 700, italic, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-Black", 900, normal, ttf);
@include font-face(Roboto, "/fonts/roboto/Roboto-BlackItalic", 900, italic, ttf);

/**
 * ----------------------------------------------------------------------------
 * Prompt
 * ----------------------------------------------------------------------------
 * format: woff
 * Weight scale: light      300
 *               regular    400
 *               semibold   600
 */
@include font-face(Prompt, "/fonts/prompt/Prompt-Light", 300, normal, woff);
@include font-face(Prompt, "/fonts/prompt/Prompt-LightItalic", 300, italic, woff);
@include font-face(Prompt, "/fonts/prompt/Prompt-Regular", 400, normal, woff);
@include font-face(Prompt, "/fonts/prompt/Prompt-RegularItalic", 400, italic, woff);
@include font-face(Prompt, "/fonts/prompt/Prompt-SemiBold", 600, normal, woff);
@include font-face(Prompt, "/fonts/prompt/Prompt-SemiBoldItalic", 600, italic, woff);

/**
 * ----------------------------------------------------------------------------
 * DB Adman X
 * ----------------------------------------------------------------------------
 * format: ttf
 * Weight scale: regular    400
 *               bold       700
 */
@include font-face("DB Adman X", "/fonts/db-admin-x/DB-Adman-X-Regular", 400, normal, ttf);
@include font-face("DB Adman X", "/fonts/db-admin-x/DB-Adman-X-Bold", 700, normal, ttf);
// @include font-face("DB Adman X", "/fonts/db-admin-x/DBAdmanX", 400, normal, eot svg woff ttf);
// @include font-face("DB Adman X", "/fonts/db-admin-x/DBAdmanX-Bold", 700, normal, eot svg woff ttf);

