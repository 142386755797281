.input-autocomplete {
  position: relative;
  z-index: $filter-input-index;

  & input[type=text], &-dropdown {
    box-shadow: $hover-box-shadow;
  }

  &.is-shadowless {
    & input[type=text] {
      box-shadow: none;
    }
  }

  &-dropdown {
    position: absolute;
    top: 110%;
    width: 100%;
    border-radius: $border-radius;
    padding: $padding-layout $padding-layout / 2;

    &.is-open {
      display: block;
    }

    &.is-close {
      display: none;
    }

    .total-result {
      text-align: center;
    }

    ul {
      max-height: 300px;
      overflow-y: scroll;
    }
  }
}