.floor-plan {
  position: relative;
  width: 100%;
  height: 100%;

  &-title-container {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &-title {
    font-size: 2rem;
  }
}